import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import {
  Location,
  LocationType,
  Machine,
  MachineStatus,
  MarketSegment,
  Region,
} from "../Machines/MachineParentComponent";
import { Customer, Distributor } from "../Customers/CustomerParentComponent";
import CustomerSatisfactionSummary from "./CustomerSatisfactionSummary";

interface CustomerSatisfactionParentComponentQueryResponse {
  getCustomerList: Customer[];
  getLocationList: Location[];
  getRegions: Region[];
  getMachineStatus: MachineStatus[];
  getLocationTypes: LocationType[];
  getMarketSegment: MarketSegment[];
  getDistributors: Distributor[];
  getMachineList: Machine[];
}

export interface CustomerSatisfactionProps {
  customers: Customer[];
  locations: Location[];
  locationTypes: LocationType[];
  machineStatus: MachineStatus[];
  marketSegments: MarketSegment[];
  regions: Region[];
}

type CustomerSatisfactionParentComponentQuery = OperationType & {
  response: CustomerSatisfactionParentComponentQueryResponse;
  variables: Record<string, never>;
};

class CustomerSatisfactionParentComponent extends React.Component <any, any>  {
  render() {
    return (
      <QueryRenderer<CustomerSatisfactionParentComponentQuery>
        environment={environment}
        query={graphql`
          query CustomerSatisfactionParentComponentQuery {
            getRegions {
              id
              name
            }
            getLocationList {
              id
              uuid
              name
            }
            getMachineStatus {
              id
              name
              description
            }
            getLocationTypes {
              id
              name
              description
              isDefault
            }
            getCustomerList {
              id
              name
            }
            getMarketSegment {
              id
              name
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>Error loading customer satisfaction data: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const {
            getCustomerList,
            getLocationList,
            getLocationTypes,
            getMachineStatus,
            getMarketSegment,
            getRegions,
          } = props;

            return (
              <CustomerSatisfactionSummary
                customers={getCustomerList}
                locations={getLocationList}
                locationTypes={getLocationTypes}
                machineStatus={getMachineStatus}
                marketSegments={getMarketSegment}
                regions={getRegions}
              />
            );
        }}
      />
    );
  }
}

export default CustomerSatisfactionParentComponent;
