import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { 
    GetMachineServiceType,
    GetOperationType,
    GetMarketingType, } from '../models/_tickets';
import { GetMarketingMaterialForFilterType } from '../models/_marketingMaterial';
import { getAvailableMachinesForReplace, GetAvailableMachinesForReplaceType, getBlenders, GetBlendersType, getBoards, GetBoardsType, getDiagnosis, GetDiagnosisType, getFlavors, GetFlavorsType, getLocations, GetLocationsType, getLogJobById, GetLogJobByIdResponseType, getMachineServices, getMarketingMaterials, getMarketings, getOperations, getTubeShields, GetTubeShieldsType } from '../models/_publicCall';

interface SiteVisitationContextProps {
    machineServicesDB: GetMachineServiceType[];
    operationDB: GetOperationType[];
    marketingDB: GetMarketingType[];
    machinesDB: GetAvailableMachinesForReplaceType[];
    locationsDB: GetLocationsType[];
    blendersDB: GetBlendersType[];
    boardsDB: GetBoardsType[];
    tubeShieldsDB: GetTubeShieldsType[];
    materialDB: GetMarketingMaterialForFilterType[];
    fetchSiteVisitJobOrder: (id: string) => void;
    logJob: GetLogJobByIdResponseType | null;
    diagnosis:GetDiagnosisType[];
    flavors: GetFlavorsType[];
    loading: boolean;
}

const SiteVisitationContext = createContext<SiteVisitationContextProps>({
    machineServicesDB: [],
    operationDB: [],
    marketingDB: [],
    materialDB: [],
    machinesDB: [],
    blendersDB: [],
    locationsDB: [],
    tubeShieldsDB: [],
    boardsDB: [],
    fetchSiteVisitJobOrder: () => {},
    logJob: null,
    diagnosis:[],
    flavors:[],
    loading: false,
});

interface SiteVisitationProviderProps {
    children: ReactNode;
}

export const SiteVisitationProvider: React.FC<SiteVisitationProviderProps> = ({ children }) => {
    const [machineServicesDB, setMachineServicesDB] = useState<GetMachineServiceType[]>([]);
    const [operationDB, setOperationDB] = useState<GetOperationType[]>([]);
    const [marketingDB, setMarketingDB] = useState<GetMarketingType[]>([]);
    const [materialDB, setMaterialDB] = useState<GetMarketingMaterialForFilterType[]>([]);
    const [logJob, setLogJob] = useState<GetLogJobByIdResponseType | null>(null);
    const [flavors, setFlavors] = useState<GetFlavorsType[]>([]);
    const [diagnosis, setDiagnosis] = useState<GetDiagnosisType[]>([]);
    const [machinesDB, setMachinesDB] = useState<GetAvailableMachinesForReplaceType[]>([]);
    const [locationsDB, setLocationsDB] = useState<GetLocationsType[]>([]);
    const [blendersDB, setBlendersDB] = useState<GetBlendersType[]>([]);
    const [tubeShieldsDB, setTubeShieldsDB] = useState<GetTubeShieldsType[]>([]);
    const [boardsDB, setBoardsDB] = useState<GetBoardsType[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchDataFromAPI = async (apiCall: () => Promise<any>, defaultItem: any = null) => {
        try {
            const record = await apiCall();
            const results = record ? record : [];
            if (defaultItem) {
                results.push(defaultItem);
            }
            return results;
        } catch (err) {
            console.log(err);
            return [];
        }
    };

    const fetchData = async () => {
        try {
            setLoading(true);

            const [machineServiceResults, operationResults, marketingResults, materialResult, machinesResult,  diagnosisResult, flavorsResult, blendersResult, boardsResult, tubeShieldsResult, locationsResult] = await Promise.all([
                fetchDataFromAPI(getMachineServices, { id: "other", name: "Other" }),
                fetchDataFromAPI(getOperations, { id: "other", name: "Other" }),
                fetchDataFromAPI(getMarketings, { id: "other", name: "Other" }),
                fetchDataFromAPI(getMarketingMaterials),
                fetchDataFromAPI(getAvailableMachinesForReplace),
                fetchDataFromAPI(getDiagnosis),
                fetchDataFromAPI(getFlavors),
                fetchDataFromAPI(getBlenders),
                fetchDataFromAPI(getBoards),
                fetchDataFromAPI(getTubeShields),
                fetchDataFromAPI(getLocations)
            ]);
            setMachineServicesDB(machineServiceResults);
            setOperationDB(operationResults);
            setMarketingDB(marketingResults);
            setMaterialDB(materialResult);
            setMachinesDB(machinesResult);
            setFlavors(flavorsResult);
            setDiagnosis(diagnosisResult);
            setBlendersDB(blendersResult);
            setBoardsDB(boardsResult);
            setTubeShieldsDB(tubeShieldsResult);
            setLocationsDB(locationsResult);
            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, []);

    const fetchSiteVisitJobOrder = async (id: string) => {
        try {
            setLoading(true);
            const logJob = await getLogJobById(id);
            setLogJob(logJob);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <SiteVisitationContext.Provider value={{  loading, machineServicesDB, operationDB, marketingDB, materialDB, logJob, flavors,diagnosis, machinesDB, blendersDB, boardsDB , tubeShieldsDB , locationsDB ,fetchSiteVisitJobOrder }}>
            {children}
        </SiteVisitationContext.Provider>
    );
};

export const useSiteVisitation = () => useContext(SiteVisitationContext);
