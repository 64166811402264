import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateTicketSourceInput {
  name: string;
}
export async function createTicketSource(input: CreateTicketSourceInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createTicketSource (
            $input:CreateTicketSourceInput!
          ){
            createTicketSource(
                createTicketSourceInput:$input
              ) 
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getTicketSourceList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                  query getAllTicketSources(
                    $where:TicketSourceResolver_GetAllTicketSources_FilterInputType
                  ){
                    getAllTicketSources (where: $where){
                      id
                      name
                    }
                    }
                  `,
      variables: { where },
    },
  });
  return data?.data?.data?.getAllTicketSources;
}

interface UpdateTicketSource {
  name: string;
}
export async function updateTicketSource(input: UpdateTicketSource) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateTicketSource (
              $input: UpdateTicketSource!
            ){
                updateTicketSource(
                    updateTicketSourceInput: $input
                )  
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}