/**
 * @generated SignedSource<<6945ac67f4a96420360cf62c4c6165a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TypeOfContactPhoneNumbers = "OFFICE" | "PERSONAL" | "%future added value";
export type EditLocation_getLocationQuery$variables = {
  id: string;
};
export type EditLocation_getLocationQuery$data = {
  readonly getLocation: {
    readonly address: string;
    readonly businessHours: ReadonlyArray<{
      readonly closeTime: string | null;
      readonly createdAt: any | null;
      readonly dayOfWeek: number;
      readonly id: string;
      readonly isOpen: boolean | null;
      readonly locationId: string;
      readonly openTime: string | null;
      readonly updatedAt: any | null;
    }> | null;
    readonly businessModel: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly contactEmail: string | null;
    readonly contactName: string | null;
    readonly contactPhone: string | null;
    readonly customer: {
      readonly id: string;
      readonly isActive: boolean;
    } | null;
    readonly customerItContact: boolean | null;
    readonly customerSecondContact: boolean;
    readonly customerServiceContact: boolean;
    readonly deploymentManager: {
      readonly firstName: string;
    } | null;
    readonly deploymentManagerId: string | null;
    readonly distributor: {
      readonly name: string;
    } | null;
    readonly distributorId: string | null;
    readonly filterProvider: {
      readonly title: string;
    } | null;
    readonly filterProviderId: string | null;
    readonly gasSupplier: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly id: string;
    readonly itContactEmail: string | null;
    readonly itContactName: string | null;
    readonly itContactNameRole: string | null;
    readonly itContactPhoneNumber: string | null;
    readonly launchDate: string | null;
    readonly locationType: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly locationTypeId: string | null;
    readonly marketSegment: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly marketSegmentId: string | null;
    readonly name: string;
    readonly noOfBlenders: number | null;
    readonly noOfFreezers: number | null;
    readonly region: {
      readonly name: string;
    } | null;
    readonly regionId: string | null;
    readonly samplingRecommendedDays: number | null;
    readonly samplingRecommendedTime: string | null;
    readonly secondContactEmail: string | null;
    readonly secondContactName: string | null;
    readonly secondContactNameRole: string | null;
    readonly secondContactPhoneNumber: string | null;
    readonly serviceContactEmail: string | null;
    readonly serviceContactName: string | null;
    readonly serviceContactNameRole: string | null;
    readonly serviceContactPhoneNumber: string | null;
    readonly storeManagerContact: boolean;
    readonly storeManagerContactEmail: string | null;
    readonly storeManagerContactName: string | null;
    readonly storeManagerContactPhoneNumber: string | null;
    readonly targetLaunchDate: string | null;
    readonly typeOfContactPhoneNumber: TypeOfContactPhoneNumbers;
    readonly typeOfItContactPhoneNumber: TypeOfContactPhoneNumbers | null;
    readonly typeOfSecondContactPhoneNumber: TypeOfContactPhoneNumbers | null;
    readonly typeOfServiceContactPhoneNumber: TypeOfContactPhoneNumbers | null;
    readonly typeOfStoreManagerContactPhoneNumber: TypeOfContactPhoneNumbers | null;
    readonly uuid: string | null;
    readonly wifiCredentials: {
      readonly name: string | null;
      readonly password: string | null;
    } | null;
    readonly workOrders: ReadonlyArray<{
      readonly deploymentManagerId: string | null;
    }> | null;
  };
};
export type EditLocation_getLocationQuery = {
  response: EditLocation_getLocationQuery$data;
  variables: EditLocation_getLocationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deploymentManagerId",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v5 = [
  (v2/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocation",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "regionId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filterProviderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contactPhone",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contactEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "marketSegmentId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationBusinessHourObjectType",
        "kind": "LinkedField",
        "name": "businessHours",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dayOfWeek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "openTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closeTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpen",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationTypeId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "targetLaunchDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "launchDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noOfBlenders",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noOfFreezers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "distributorId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "samplingRecommendedTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "samplingRecommendedDays",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerSecondContact",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondContactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondContactEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondContactNameRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerItContact",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itContactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itContactEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "itContactNameRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerServiceContact",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceContactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceContactEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serviceContactNameRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfSecondContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfItContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfServiceContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeManagerContact",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeManagerContactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeManagerContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeManagerContactEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeOfStoreManagerContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MarketSegmentObjectType",
        "kind": "LinkedField",
        "name": "marketSegment",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationTypeObjectType",
        "kind": "LinkedField",
        "name": "locationType",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserObjectType",
        "kind": "LinkedField",
        "name": "deploymentManager",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DistributorObjectType",
        "kind": "LinkedField",
        "name": "distributor",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RegionObjectType",
        "kind": "LinkedField",
        "name": "region",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderObjectType",
        "kind": "LinkedField",
        "name": "workOrders",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GasSupplierObjectType",
        "kind": "LinkedField",
        "name": "gasSupplier",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "FilterProviderObjectType",
        "kind": "LinkedField",
        "name": "filterProvider",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WifiCredentialsObjectType",
        "kind": "LinkedField",
        "name": "wifiCredentials",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "password",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessModelsObjectType",
        "kind": "LinkedField",
        "name": "businessModel",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerObjectType",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLocation_getLocationQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLocation_getLocationQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "289beea599e1c1611745ee0bcebbdbcd",
    "id": null,
    "metadata": {},
    "name": "EditLocation_getLocationQuery",
    "operationKind": "query",
    "text": "query EditLocation_getLocationQuery(\n  $id: String!\n) {\n  getLocation(id: $id) {\n    id\n    uuid\n    name\n    address\n    regionId\n    contactName\n    filterProviderId\n    contactPhone\n    deploymentManagerId\n    contactEmail\n    marketSegmentId\n    businessHours {\n      id\n      locationId\n      dayOfWeek\n      openTime\n      closeTime\n      isOpen\n      createdAt\n      updatedAt\n    }\n    locationTypeId\n    targetLaunchDate\n    launchDate\n    noOfBlenders\n    noOfFreezers\n    distributorId\n    samplingRecommendedTime\n    samplingRecommendedDays\n    customerSecondContact\n    secondContactName\n    secondContactPhoneNumber\n    secondContactEmail\n    secondContactNameRole\n    customerItContact\n    itContactName\n    itContactPhoneNumber\n    itContactEmail\n    itContactNameRole\n    customerServiceContact\n    serviceContactName\n    serviceContactPhoneNumber\n    serviceContactEmail\n    serviceContactNameRole\n    typeOfContactPhoneNumber\n    typeOfSecondContactPhoneNumber\n    typeOfItContactPhoneNumber\n    typeOfServiceContactPhoneNumber\n    storeManagerContact\n    storeManagerContactName\n    storeManagerContactPhoneNumber\n    storeManagerContactEmail\n    typeOfStoreManagerContactPhoneNumber\n    marketSegment {\n      id\n      name\n    }\n    locationType {\n      id\n      name\n    }\n    deploymentManager {\n      firstName\n    }\n    distributor {\n      name\n    }\n    region {\n      name\n    }\n    workOrders {\n      deploymentManagerId\n    }\n    gasSupplier {\n      id\n      name\n    }\n    filterProvider {\n      title\n    }\n    wifiCredentials {\n      name\n      password\n    }\n    businessModel {\n      id\n      name\n    }\n    customer {\n      id\n      isActive\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c941ddac0b3bb16025c9d6d79df5c522";

export default node;
