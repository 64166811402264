/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { Error404 } from "../modules/errors/Error404";
import { RelayEnvironmentProvider } from "react-relay";
import environment from "../../environment";
import { JobOrder } from "../../_smoodi/components/Tickets/ScheduleTickets/JobOrder";
import WorkOrder from "../../_smoodi/components/WorkOrders/WorkOrder";
import { FlavorProvider } from "../../_smoodi/providers/flavorProvider";
import { SiteVisitation } from "../../_smoodi/components/SiteVisitation/SiteVisitation";
import { SiteVisitationProvider } from "../../_smoodi/providers/SiteVisitationProvider";

/**
 * Base URL of the website.
 * 
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const { auth } = useAuth();
  return (
    <RelayEnvironmentProvider environment={environment}>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path="error/*" element={<Error404 />} />
            <Route path="logout" element={<Logout />} />
            <Route path="/job-order/:ticketId" element={<JobOrder />} />
            <Route path="/work-order" element={<WorkOrder />} />
            <Route path="/site-visitation/:id" element={
              <SiteVisitationProvider>
                <SiteVisitation />
              </SiteVisitationProvider>
            } />
            {auth?.user ? (
              <>
                            <Route path="/*" element={<FlavorProvider>
                      <PrivateRoutes />
                    </FlavorProvider>} />
                <Route index element={<Navigate to="/dashboard" />} />

              </>
            ) : (
              <>
                <Route path="auth/*" element={<AuthPage />} />
                <Route path="*" element={<Navigate to="/auth" />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
    </RelayEnvironmentProvider>
  );
};

export { AppRoutes };
