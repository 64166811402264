import { Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Moment from "moment";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { getLocationsForFilter, getUsers } from "../../../models/_location";
import { getMachinesNameAndSerials } from "../../../models/_machine";
import * as _ from "lodash";
import {
  capitalizeWord,
  WorkOrderInstallationSteps,
  WorkOrderTestPerformed,
} from "../WorkOrderHelper";
import { WorkOrderHistory_getWorkOrderByIdQuery } from "./__generated__/WorkOrderHistory_getWorkOrderByIdQuery.graphql";
import { WORK_ORDER_STATUS } from "../../../../_metronic/helpers/enums";

export const getWorkOrderListById = graphql`
  query WorkOrderHistory_getWorkOrderByIdQuery($id: String!) {
    getWorkOrderById(id: $id) {
      id
      deploymentManagerId
      locationId
      closeUpMedia
      storeSetUpMedia
      freezerDoorOpenMedia
      status
      drainSetUpMedia
      serialNumberMedia
      comment
      machineId1
      isSecondMachineInstalled
      machineId2
      firstName
      lastName
      date
      signature
      storeManagerSignature
      storeContactName
      storeContactPhoneNumber
      storeContactRole
      installationSteps {
        freezerInstalled
        footerInstalled
        sanitizerInstalled
        headerInstalled
        boosterBarInstalled
        drainageInstalled
        internetConnected
        flavorTagsInstalled
        serviceStickerInstalled
        oneTwoThreeStickersInstalled
        palmTree
        filledFreezer
        pressureWithWeighingScale
      }
      testPerformance {
        blendedFourSmoodies
        sanitizer
        getStatus
        drainTest
        waterPressureAbove3100
      }
      staffTraining {
        makingSmoodies
        benefitsOfSmoodi
        accessingServiceMenu
        cleaningWipers
        checkingSanitizer
        smoodiStore
        squeezingCupsBeforePuttingInFreezer
      }
      materialInstallation {
        boosterPump
        windowDecal
        doorDecal
        palmTree
        neonSign
        smoodiMat
        videoOnTv
        videoOnOutdoorSign
        freezerMagnet
        machineSideSticker
        outdoorFlag
        rollUpBanner
      }
      requiredTask {
        action
        priority
        dueDate
      }
      createdAt
      installerId
      installerName
    }
  }
`;

export const WorkOrderHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [workOrder, setWorkOrder] = useState<any>({});
  const [deploymentManagerName, setDeploymentManagerName] = useState<any>("");
  const [installerPersonName, setInstallerPersonName] = useState<any>("");
  const [locationName, setLocationName] = useState<any>("");
  const [machineOneName, setMachineOneName] = useState<any>("");
  const [machineTwoName, setMachineTwoName] = useState<any>("");
  const [refreshedQueryOptions, setRefreshedQueryOptions] = useState<any>(null);
  const workOrderId = location.pathname.split("/").reverse()[0];

  if (!workOrderId) {
    navigate("/workOrder");
  }

  const WorkOrderRecord =
    useLazyLoadQuery<WorkOrderHistory_getWorkOrderByIdQuery>(
      getWorkOrderListById,
      {
        id: workOrderId ?? "",
      },
      refreshedQueryOptions ?? {}
    );
  const result: any = WorkOrderRecord?.getWorkOrderById ?? [];

  const fetchWorkOrderData = async () => {
    try {
      if (!result) navigate("/workOrder");
      setWorkOrder(result);
      if (result.deploymentManagerId)
        await getDeploymentManagerNameAndInstallerPersonName(
          result.deploymentManagerId,
          false
        );
      if (result.installerId)
        await getDeploymentManagerNameAndInstallerPersonName(
          false,
          result.installerId
        );
      if (result.locationId) await getLocationName(result.locationId);
      if (result?.machineId1 || result?.machineId2)
        await getMachineName(result.machineId1, result.machineId2);
      refresh();
    } catch (err) {
      console.log("work order details page", err);
    }
  };

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "store-and-network",
    }));
  }, []);

  const getDeploymentManagerNameAndInstallerPersonName = async (
    deploymentMangerid: any,
    installerPersonid: any
  ) => {
    const result = await getUsers();

    if (deploymentMangerid) {
      const sortedDeploymentManagers = result.filter(
        (user: any) => user.isRegionalManager
      );
      const deploymentManagerResults: any = sortedDeploymentManagers
        ? sortedDeploymentManagers
        : [];

      const manager = deploymentManagerResults.find((record: any) => {
        return record.id === deploymentMangerid;
      });

      setDeploymentManagerName(manager?.firstName);
    }

    if (installerPersonid) {
      const installerPersonResults: any = result ? result : [];

      const installerPerson = installerPersonResults.find((record: any) => {
        return record.id === installerPersonid;
      });

      setInstallerPersonName(installerPerson?.firstName);
    }
  };

  const getLocationName = async (id: string) => {
    const locationRecord = await getLocationsForFilter();
    const locationResults: any = locationRecord.data ? locationRecord.data : [];
    const location = locationResults.find((record: any) => {
      return record.id === id;
    });
    setLocationName(location.name);
  };

  const getMachineName = async (machine1Id: string, machine2Id: string) => {
    const machinesRecords = await getMachinesNameAndSerials();
    const machinesResults: any = machinesRecords.data
      ? machinesRecords.data
      : [];

    const matchedMachine1 = machinesResults.find(
      (machine: any) => machine.id === machine1Id
    );
    const machineName1 = matchedMachine1 ? matchedMachine1.name : "";
    const machineSerialNumber1 = matchedMachine1
      ? matchedMachine1.serialNumber
      : "";

    const matchedMachine2 = machinesResults.find(
      (machine: any) => machine.id === machine2Id
    );
    const machineName2 = matchedMachine2 ? matchedMachine2.name : "";
    const machineSerialNumber2 = matchedMachine2
      ? matchedMachine2.serialNumber
      : "";

    setMachineOneName(
      machineSerialNumber1 && machineName1
        ? `${machineSerialNumber1} (${machineName1})`
        : machineName1
        ? `(${machineName1})`
        : ""
    );
    setMachineTwoName(
      machineSerialNumber2 && machineName2
        ? `${machineSerialNumber2} (${machineName2})`
        : machineName2
        ? `(${machineName2})`
        : ""
    );
  };

  useEffect(() => {
    fetchWorkOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <Suspense>
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Work Order History
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item text-muted">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                  <a
                    href="/workOrder"
                    className="text-muted text-hover-primary ps-2 fs-5"
                  >
                    Work Order List
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item fs-5 text-dark">
                  Work Order History
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mx-xl-4 mt-9">
          <div className="card">
            <div className="card-header cursor-pointer border-bottom-0 py-4">
              <div className="card-title m-0">
                <h3 className="fw-semibold m-0">Work Order Details</h3>
              </div>
            </div>
          </div>
          <div className="mt-1 row g-6  g-xl-9">
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Deployment Manager Name
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {deploymentManagerName}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Location Name
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">{locationName}</span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Installer Person Name
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {workOrder?.installerName || installerPersonName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card h-100">
                  <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        First Name
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.firstName}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Last Name
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Date
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.date
                            ? Moment.utc(workOrder.date)
                                .local()
                                .format("YYYY-MM-DD")
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Store Contact Name
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.storeContactName}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Store Contact Role
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.storeContactRole}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Store Contact Phone Number
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder.storeContactPhoneNumber}
                        </span>
                      </div>
                    </div>
                    <div className="row mb-5">
                      <label className="col-lg-6 fs-4 fw-semibold text-muted">
                        Status
                      </label>
                      <div className="col-lg-6 fv-row">
                        <span className="text-dark fs-4">
                          {workOrder?.status ? (workOrder?.status  === WORK_ORDER_STATUS.COMPLETED ? "Completed" : "Pending") : ""}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Mandatory Steps
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.installationSteps ? (
                    <table className="table wo-table mt-4">
                      <thead className="wo-thead">
                        <tr>
                          <th></th>
                          {WorkOrderInstallationSteps?.map((step: any) => (
                            <th key={step.id} className="wo-th">
                              {step.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="wo-tbody">
                        {(
                          Object.keys(
                            workOrder?.installationSteps
                          ) as (keyof typeof workOrder.installationSteps)[]
                        ).map((key: any, index) => (
                          <tr key={index}>
                            {key !== "workOrderId" && key !== "createdAt" ? (
                              <td>{capitalizeWord(key)}</td>
                            ) : (
                              ""
                            )}

                            {WorkOrderInstallationSteps?.map(
                              (step: any, idx: any) => {
                                return key !== "workOrderId" &&
                                  key !== "createdAt" ? (
                                  <>
                                    <td key={idx} data-label={step.name}>
                                      <div className="center-radio">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          id={`${key}_${step.id}`}
                                          name={`installationSteps.${key}`}
                                          value={step.id}
                                          checked={
                                            workOrder.installationSteps[key] ===
                                            step.id
                                          }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No mandatory steps detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Test Performed
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.testPerformance ? (
                    <table className="table wo-table mt-4">
                      <thead className="wo-thead">
                        <tr>
                          <th></th>
                          {WorkOrderTestPerformed?.map((step: any) => (
                            <th key={step.id} className="wo-th">
                              {step.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="wo-tbody">
                        {(
                          Object.keys(
                            workOrder?.testPerformance
                          ) as (keyof typeof workOrder.testPerformance)[]
                        ).map((key: any, index) => (
                          <tr key={index}>
                            {key !== "workOrderId" && key !== "createdAt" ? (
                              <td>{capitalizeWord(key)}</td>
                            ) : (
                              ""
                            )}

                            {WorkOrderTestPerformed?.map(
                              (step: any, idx: any) => {
                                return key !== "workOrderId" &&
                                  key !== "createdAt" ? (
                                  <>
                                    <td key={idx} data-label={step.name}>
                                      <div className="center-radio">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          id={`${key}_${step.id}`}
                                          name={`testPerformance.${key}`}
                                          value={step.id}
                                          checked={
                                            workOrder.testPerformance[key] ===
                                            step.id
                                          }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No test performed detail found
                      </span>
                    </div>
                  )}
                </div>
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Trained staff on the following
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.staffTraining ? (
                    <table className="table wo-table mt-4">
                      <thead className="wo-thead">
                        <tr>
                          <th></th>
                          {WorkOrderInstallationSteps?.map((step: any) => (
                            <th key={step.id} className="wo-th">
                              {step.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="wo-tbody">
                        {(
                          Object.keys(
                            workOrder?.staffTraining
                          ) as (keyof typeof workOrder.staffTraining)[]
                        ).map((key: any, index) => (
                          <tr key={index}>
                            {key !== "workOrderId" && key !== "createdAt" ? (
                              <td>{capitalizeWord(key)}</td>
                            ) : (
                              ""
                            )}

                            {WorkOrderInstallationSteps?.map(
                              (step: any, idx: any) => {
                                return key !== "workOrderId" &&
                                  key !== "createdAt" ? (
                                  <>
                                    <td key={idx} data-label={step.name}>
                                      <div className="center-radio">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          id={`${key}_${step.id}`}
                                          name={`staffTraining.${key}`}
                                          value={step.id}
                                          checked={
                                            workOrder.staffTraining[key] ===
                                            step.id
                                          }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No trained staff detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Optional Materials Installed
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.materialInstallation ? (
                    <table className="table wo-table mt-4">
                      <thead className="wo-thead">
                        <tr>
                          <th></th>
                          {WorkOrderInstallationSteps?.map((step: any) => (
                            <th key={step.id} className="wo-th">
                              {step.name}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="wo-tbody">
                        {(
                          Object.keys(
                            workOrder?.materialInstallation
                          ) as (keyof typeof workOrder.materialInstallation)[]
                        ).map((key: any, index) => (
                          <tr key={index}>
                            {key !== "workOrderId" && key !== "createdAt" ? (
                              <td>{capitalizeWord(key)}</td>
                            ) : (
                              ""
                            )}

                            {WorkOrderInstallationSteps?.map(
                              (step: any, idx: any) => {
                                return key !== "workOrderId" &&
                                  key !== "createdAt" ? (
                                  <>
                                    <td key={idx} data-label={step.name}>
                                      <div className="center-radio">
                                        <input
                                          className="form-check-input"
                                          type="radio"
                                          id={`${key}_${step.id}`}
                                          name={`materialInstallation.${key}`}
                                          value={step.id}
                                          checked={
                                            workOrder.materialInstallation[
                                              key
                                            ] === step.id
                                          }
                                          disabled
                                        />
                                      </div>
                                    </td>
                                  </>
                                ) : (
                                  ""
                                );
                              }
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No optional materials installed detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Is Second Machine Installed
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {workOrder.isSecondMachineInstalled === true
                          ? "Yes"
                          : workOrder.isSecondMachineInstalled === false
                          ? "No"
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Machine One Name
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">{machineOneName}</span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Machine Two Name
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">{machineTwoName}</span>
                    </div>
                  </div>
                </div>
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Tasks Performed Details
                  </label>
                  <div className="separator separator-dashed my-3"></div>

                  {workOrder?.requiredTask ? (
                    workOrder?.requiredTask?.map((task: any, index: any) => (
                      <div key={index} className="form-field">
                        <div className="row mt-4">
                          <div className="col-3">
                            <label
                              htmlFor={`requiredTask[${index}].action`}
                              className="wo-label"
                            >
                              Machine
                            </label>

                            <input
                              id={`newTaskMachineId-${index}`}
                              type="text"
                              value={
                                index === 0
                                  ? machineOneName
                                  : machineTwoName
                                  ? machineTwoName
                                  : ""
                              }
                              className={`wo-input form-control`}
                              disabled
                            />
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor={`requiredTask[${index}].action`}
                              className="wo-label"
                            >
                              Action
                            </label>
                            <input
                              id={`requiredTask[${index}].action`}
                              type="text"
                              name={`requiredTask[${index}].action`}
                              value={task.action}
                              className={`wo-input form-control`}
                              disabled
                            />
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor={`requiredTask[${index}].priority`}
                              className="wo-label"
                            >
                              Priority
                            </label>
                            <input
                              id={`requiredTask[${index}].priority`}
                              type="text"
                              name={`requiredTask[${index}].priority`}
                              value={task.priority === "LOW" ? "Low" : "High"}
                              className={`wo-input form-control`}
                              disabled
                            />
                          </div>
                          <div className="col-3">
                            <label
                              htmlFor={`requiredTask[${index}].dueDate`}
                              className="wo-label"
                            >
                              Due Date
                            </label>
                            <input
                              id={`requiredTask[${index}].dueDate`}
                              type="text"
                              name={`requiredTask[${index}].dueDate`}
                              value={Moment.utc(task.dueDate)
                                .local()
                                .format("YYYY-MM-DD")}
                              className={`wo-input form-control`}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      <span className="fs-6">
                        No tasks performed detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Close Up Media
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.closeUpMedia?.length > 0 ? (
                    <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <div className="ms-3 text-primary">
                        {workOrder.closeUpMedia.map(
                          (a: string, index: number) => {
                            const isVideo =
                              a.endsWith(".mp4") ||
                              a.endsWith(".webm") ||
                              a.endsWith(".ogg");
                            return (
                              <div className="col-12 mb-5" key={index}>
                                <div className="media-box-custom">
                                  {isVideo ? (
                                    <video className="media-content" controls>
                                      <source src={a} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={a}
                                      alt={`Close Up ${index + 1}`}
                                      className="media-content"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No close up media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Store Set Up Media
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.storeSetUpMedia?.length > 0 ? (
                    <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <div className="ms-3 text-primary">
                        {workOrder?.storeSetUpMedia?.map(
                          (a: string, index: number) => {
                            const isVideo =
                              a.endsWith(".mp4") ||
                              a.endsWith(".webm") ||
                              a.endsWith(".ogg");
                            return (
                              <div className="col-12 mb-3" key={index}>
                                <div className="media-box-custom">
                                  {isVideo ? (
                                    <video className="media-content" controls>
                                      <source src={a} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={a}
                                      alt={`Store Set Up Media ${index + 1}`}
                                      className="media-content"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No store set up media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Drain Set Up Media
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.drainSetUpMedia?.length > 0 ? (
                    <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <div className="ms-3 text-primary">
                        {workOrder?.drainSetUpMedia?.map(
                          (a: string, index: number) => {
                            const isVideo =
                              a.endsWith(".mp4") ||
                              a.endsWith(".webm") ||
                              a.endsWith(".ogg");
                            return (
                              <div className="col-12 mb-5" key={index}>
                                <div className="media-box-custom">
                                  {isVideo ? (
                                    <video className="media-content" controls>
                                      <source src={a} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={a}
                                      alt={`Drain Set Up Media ${index + 1}`}
                                      className="media-content"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No drain set up media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Serial Number Media
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.serialNumberMedia?.length > 0 ? (
                    <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <div className="ms-3 text-primary ">
                        {workOrder?.serialNumberMedia?.map(
                          (a: string, index: number) => {
                            const isVideo =
                              a.endsWith(".mp4") ||
                              a.endsWith(".webm") ||
                              a.endsWith(".ogg");
                            return (
                              <div className="col-12 mb-5" key={index}>
                                <div className="media-box-custom">
                                  {isVideo ? (
                                    <video className="media-content" controls>
                                      <source src={a} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={a}
                                      alt={`Serial Number Media ${index + 1}`}
                                      className="media-content"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No serial number media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Freezer Door Open Media
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder?.freezerDoorOpenMedia?.length > 0 ? (
                    <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <div className="ms-3 text-primary">
                        {workOrder?.freezerDoorOpenMedia?.map(
                          (a: string, index: number) => {
                            const isVideo =
                              a.endsWith(".mp4") ||
                              a.endsWith(".webm") ||
                              a.endsWith(".ogg");
                            return (
                              <div className="col-12 mb-5" key={index}>
                                <div className="media-box-custom">
                                  {isVideo ? (
                                    <video className="media-content" controls>
                                      <source src={a} type="video/mp4" />
                                    </video>
                                  ) : (
                                    <img
                                      src={a}
                                      alt={`Freezer Door Open Media ${
                                        index + 1
                                      }`}
                                      className="media-content"
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No freezer door open media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    General Comments
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {workOrder.comment ? (
                    <div className="ms-3 text-gray-800">
                      {workOrder.comment}
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">
                        No store set up media detail found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Signature
                    </label>
                    <div className="separator separator-dashed my-3"></div>
                    {workOrder?.signature ? (
                      <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                        <div className="ms-3 text-primary">
                          <div className="col-12 mb-5">
                            <div className="media-box">
                              <img
                                src={workOrder.signature}
                                alt="storeManagerSignature"
                                className="media-content"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span className="fs-6">No signature detail found</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Store Manager Signature
                    </label>
                    <div className="separator separator-dashed my-3"></div>
                    {workOrder?.storeManagerSignature ? (
                      <div className="fw-bold ms-2 d-lg-flex d-md-flex d-block align-items-center text-gray-500 fs-6 mb-3 mt-3 mt-lg-0 mt-md-0">
                        <div className="ms-3 text-primary">
                          <div className="col-12 mb-5">
                            <div className="media-box">
                              <img
                                src={workOrder?.storeManagerSignature}
                                alt="storeManagerSignature"
                                className="media-content"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span className="fs-6">
                          No store manager signature detail found
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
