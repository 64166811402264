/**
 * @generated SignedSource<<5ee2ebb8c6c82704ccb4141e8f84914b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorkOrderInstallationStepsEnum = "NOT_INCLUDE" | "OK" | "REQUIRED_ACTION" | "%future added value";
export type WorkOrderInstallationTestEnum = "FAIL" | "NOT_CHECKED" | "PASSED" | "%future added value";
export type WorkOrderStatusEnum = "COMPLETED" | "PENDING" | "%future added value";
export type WorkOrderTaskPriorityEnum = "HIGH" | "LOW" | "%future added value";
export type WorkOrderHistory_getWorkOrderByIdQuery$variables = {
  id: string;
};
export type WorkOrderHistory_getWorkOrderByIdQuery$data = {
  readonly getWorkOrderById: {
    readonly closeUpMedia: ReadonlyArray<string> | null;
    readonly comment: string | null;
    readonly createdAt: any | null;
    readonly date: any | null;
    readonly deploymentManagerId: string | null;
    readonly drainSetUpMedia: ReadonlyArray<string> | null;
    readonly firstName: string | null;
    readonly freezerDoorOpenMedia: ReadonlyArray<string> | null;
    readonly id: string;
    readonly installationSteps: {
      readonly boosterBarInstalled: WorkOrderInstallationStepsEnum | null;
      readonly drainageInstalled: WorkOrderInstallationStepsEnum | null;
      readonly filledFreezer: WorkOrderInstallationStepsEnum | null;
      readonly flavorTagsInstalled: WorkOrderInstallationStepsEnum | null;
      readonly footerInstalled: WorkOrderInstallationStepsEnum | null;
      readonly freezerInstalled: WorkOrderInstallationStepsEnum | null;
      readonly headerInstalled: WorkOrderInstallationStepsEnum | null;
      readonly internetConnected: WorkOrderInstallationStepsEnum | null;
      readonly oneTwoThreeStickersInstalled: WorkOrderInstallationStepsEnum | null;
      readonly palmTree: WorkOrderInstallationStepsEnum | null;
      readonly pressureWithWeighingScale: WorkOrderInstallationStepsEnum | null;
      readonly sanitizerInstalled: WorkOrderInstallationStepsEnum | null;
      readonly serviceStickerInstalled: WorkOrderInstallationStepsEnum | null;
    } | null;
    readonly installerId: string | null;
    readonly installerName: string | null;
    readonly isSecondMachineInstalled: boolean | null;
    readonly lastName: string | null;
    readonly locationId: string | null;
    readonly machineId1: string | null;
    readonly machineId2: string | null;
    readonly materialInstallation: {
      readonly boosterPump: WorkOrderInstallationStepsEnum | null;
      readonly doorDecal: WorkOrderInstallationStepsEnum | null;
      readonly freezerMagnet: WorkOrderInstallationStepsEnum | null;
      readonly machineSideSticker: WorkOrderInstallationStepsEnum | null;
      readonly neonSign: WorkOrderInstallationStepsEnum | null;
      readonly outdoorFlag: WorkOrderInstallationStepsEnum | null;
      readonly palmTree: WorkOrderInstallationStepsEnum | null;
      readonly rollUpBanner: WorkOrderInstallationStepsEnum | null;
      readonly smoodiMat: WorkOrderInstallationStepsEnum | null;
      readonly videoOnOutdoorSign: WorkOrderInstallationStepsEnum | null;
      readonly videoOnTv: WorkOrderInstallationStepsEnum | null;
      readonly windowDecal: WorkOrderInstallationStepsEnum | null;
    } | null;
    readonly requiredTask: ReadonlyArray<{
      readonly action: string | null;
      readonly dueDate: any | null;
      readonly priority: WorkOrderTaskPriorityEnum | null;
    }> | null;
    readonly serialNumberMedia: ReadonlyArray<string> | null;
    readonly signature: string | null;
    readonly staffTraining: {
      readonly accessingServiceMenu: WorkOrderInstallationStepsEnum | null;
      readonly benefitsOfSmoodi: WorkOrderInstallationStepsEnum | null;
      readonly checkingSanitizer: WorkOrderInstallationStepsEnum | null;
      readonly cleaningWipers: WorkOrderInstallationStepsEnum | null;
      readonly makingSmoodies: WorkOrderInstallationStepsEnum | null;
      readonly smoodiStore: WorkOrderInstallationStepsEnum | null;
      readonly squeezingCupsBeforePuttingInFreezer: WorkOrderInstallationStepsEnum | null;
    } | null;
    readonly status: WorkOrderStatusEnum | null;
    readonly storeContactName: string | null;
    readonly storeContactPhoneNumber: string | null;
    readonly storeContactRole: string | null;
    readonly storeManagerSignature: string | null;
    readonly storeSetUpMedia: ReadonlyArray<string> | null;
    readonly testPerformance: {
      readonly blendedFourSmoodies: WorkOrderInstallationTestEnum | null;
      readonly drainTest: WorkOrderInstallationTestEnum | null;
      readonly getStatus: WorkOrderInstallationTestEnum | null;
      readonly sanitizer: WorkOrderInstallationTestEnum | null;
      readonly waterPressureAbove3100: WorkOrderInstallationTestEnum | null;
    } | null;
  };
};
export type WorkOrderHistory_getWorkOrderByIdQuery = {
  response: WorkOrderHistory_getWorkOrderByIdQuery$data;
  variables: WorkOrderHistory_getWorkOrderByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "palmTree",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "WorkOrderObjectType",
    "kind": "LinkedField",
    "name": "getWorkOrderById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deploymentManagerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "closeUpMedia",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeSetUpMedia",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "freezerDoorOpenMedia",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "drainSetUpMedia",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumberMedia",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "comment",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineId1",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSecondMachineInstalled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineId2",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeManagerSignature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeContactName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeContactPhoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "storeContactRole",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderInstallationStepsObjectType",
        "kind": "LinkedField",
        "name": "installationSteps",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freezerInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "footerInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sanitizerInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "headerInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boosterBarInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "drainageInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internetConnected",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flavorTagsInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceStickerInstalled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oneTwoThreeStickersInstalled",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filledFreezer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pressureWithWeighingScale",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderTestPerformanceObjectType",
        "kind": "LinkedField",
        "name": "testPerformance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "blendedFourSmoodies",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sanitizer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "getStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "drainTest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "waterPressureAbove3100",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderStaffTrainingObjectType",
        "kind": "LinkedField",
        "name": "staffTraining",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "makingSmoodies",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "benefitsOfSmoodi",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "accessingServiceMenu",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cleaningWipers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checkingSanitizer",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "smoodiStore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "squeezingCupsBeforePuttingInFreezer",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderMaterialInstallationObjectType",
        "kind": "LinkedField",
        "name": "materialInstallation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "boosterPump",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "windowDecal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "doorDecal",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "neonSign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "smoodiMat",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoOnTv",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "videoOnOutdoorSign",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "freezerMagnet",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "machineSideSticker",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "outdoorFlag",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rollUpBanner",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "WorkOrderRequiredTaskObjectType",
        "kind": "LinkedField",
        "name": "requiredTask",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "action",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "priority",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dueDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "installerId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "installerName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkOrderHistory_getWorkOrderByIdQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkOrderHistory_getWorkOrderByIdQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5bb53f4a1d7047586522750c6d918a7c",
    "id": null,
    "metadata": {},
    "name": "WorkOrderHistory_getWorkOrderByIdQuery",
    "operationKind": "query",
    "text": "query WorkOrderHistory_getWorkOrderByIdQuery(\n  $id: String!\n) {\n  getWorkOrderById(id: $id) {\n    id\n    deploymentManagerId\n    locationId\n    closeUpMedia\n    storeSetUpMedia\n    freezerDoorOpenMedia\n    status\n    drainSetUpMedia\n    serialNumberMedia\n    comment\n    machineId1\n    isSecondMachineInstalled\n    machineId2\n    firstName\n    lastName\n    date\n    signature\n    storeManagerSignature\n    storeContactName\n    storeContactPhoneNumber\n    storeContactRole\n    installationSteps {\n      freezerInstalled\n      footerInstalled\n      sanitizerInstalled\n      headerInstalled\n      boosterBarInstalled\n      drainageInstalled\n      internetConnected\n      flavorTagsInstalled\n      serviceStickerInstalled\n      oneTwoThreeStickersInstalled\n      palmTree\n      filledFreezer\n      pressureWithWeighingScale\n    }\n    testPerformance {\n      blendedFourSmoodies\n      sanitizer\n      getStatus\n      drainTest\n      waterPressureAbove3100\n    }\n    staffTraining {\n      makingSmoodies\n      benefitsOfSmoodi\n      accessingServiceMenu\n      cleaningWipers\n      checkingSanitizer\n      smoodiStore\n      squeezingCupsBeforePuttingInFreezer\n    }\n    materialInstallation {\n      boosterPump\n      windowDecal\n      doorDecal\n      palmTree\n      neonSign\n      smoodiMat\n      videoOnTv\n      videoOnOutdoorSign\n      freezerMagnet\n      machineSideSticker\n      outdoorFlag\n      rollUpBanner\n    }\n    requiredTask {\n      action\n      priority\n      dueDate\n    }\n    createdAt\n    installerId\n    installerName\n  }\n}\n"
  }
};
})();

(node as any).hash = "530df099b92a50a342d2ca939fbc6e4b";

export default node;
