import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateGasSupplierInput {
  name: string;
  isActive: boolean;
}
export async function createGasSupplier(input: CreateGasSupplierInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createGasSupplier (
          $input:CreateGasSupplierInput!
        ) {
          createGasSupplier(
            createGasSupplierInput:$input
          )  
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateGasSupplier(id: string, input: CreateGasSupplierInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateGasSupplier (
          $id: String!
          $input: CreateGasSupplierInput!
        ) {
          updateGasSupplier(
            id: $id
            createGasSupplierInput: $input
          ) 
        }
      `,
      variables: { id, input },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateGasSupplierStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation (
          $id: String!, 
          $isActive: Boolean! 
        ) {
          updateGasSupplierStatus(
            updateGasSupplierStatus: { 
              id: $id, 
              isActive: $isActive 
            }
          )
        }
      `,
      variables: { id, isActive },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getGasSupplierList() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query {
                getGasSupplierList {
                  id
                  name
                  createdById
                  isActive
                }
              }
              
              `,
        variables: {},
      },
    });
    return data?.data?.data?.getGasSupplierList;
  }