import { Component, Suspense, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Moment from "moment";
import $ from "jquery";
import * as _ from "lodash";
import ReactSelect from "react-select";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import Columns from "../../../_metronic/helpers/Columns";
import {
  getModuleDataDisplaySettingByKey,
  updateModuleDataDisplaySettingByKey,
} from "../../models/_user";
import { deleteCustomerAccount, getCustomerAccounts } from "../../models/_customerAccounts";
import { Option } from "../../../_metronic/helpers/MySelect";
import { DownloadExcel } from "../ExcelHelper";
import { EditCustomerAccount } from "./EditCustomerAccount";
import toast, { Toaster } from "react-hot-toast";
class CustomerAccountsList extends Component<any, any> {
  readonly props: any;

  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
      filterLoading: false,
      resetFrom: createRef(),
      resetTo: createRef(),
      resetLocation: createRef(),
      resetCustomer: createRef(),
      resetCustomerRole: createRef(),
      downloadLoading: false,
      filterFromDate: "",
      filterName: "",
      filterEmail: "",
      filterToDate: "",
      filterCustomer: "",
      filterLocation: "",
      filterCustomerRole: "",
      customerAccountEditModal: false,
      currentCustomerAccountId: "",
      loading: false,
      locationSelected: null,
      customerSelected: null,
      customerRoleSelected: null,
      customerAccountsList: [],
      customerDB: [],
      locationDB: [],
      customerRoleDB: [],
      page: 0,
      perPage: 50,
      moduleKeyName: "customer_account_list",
      selectedColumns: [],
      columnData: [
        { id: 0, label: "Sr.No", key: "index" },
        { id: 1, label: "Partner Account Name", key: "customerAccountName" },
        { id: 2, label: "Partner Account Email", key: "customerAccountEmail" },
        { id: 3, label: "Location Name", key: "locationName" },
        { id: 4, label: "Customer Name", key: "customerName" },
        { id: 5, label: "Partner Role", key: "customerRole" },
        { id: 6, label: "Portal User ID", key: "portalUserId" },
        { id: 7, label: "Created At", key: "createdAt" },
        { id: 8, label: "Action", key: "action" },
        { id: 9, label: "Last Updated By", key: "LastUpdatedBy" },
      ],
    };
  }

  async componentWillMount() {}

  async componentDidUpdate() {}

  async componentDidMount() {
    $("body").off("click", ".editBtn").on("click", ".editBtn", (e) => {
      const record = $(e.currentTarget).attr("data-recordId");
      this.setState({
        customerAccountEditModal: true,
        currentCustomerAccountId: record,
      });
    });

    $("body").off("click", ".deleteBtn").on("click", ".deleteBtn", async (e) => {
      e.stopPropagation();
      const customerAccountId = $(e.currentTarget).attr(
        "customer-account-delete-recordId"
      );
      if (customerAccountId) {
        await this.handleDeleteCustomerAccount(customerAccountId);
      }
    });

    await this.getFilterRecords();
    this.renderDataTable([]);
  }
  getFilter = () => {
    let filter: any = {};
    if (this.state.filterLocation.length > 0) {
      filter["location_id"] = { in: this.state.filterLocation };
    }

    if (this.state.filterCustomer.length > 0) {
      filter["customer_id"] = { in: this.state.filterCustomer };
    }

    if (this.state.filterCustomerRole.length > 0) {
      filter["role_id"] = { in: this.state.filterCustomerRole };
    }

    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }

    if (this.state.filterEmail) {
      filter["email"] = { like: "%" + this.state.filterEmail + "%" };
    }

    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter;
  };

  renderDataTable = async (rows: any) => {
    const _this = this;
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    await this.getColumnData();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }

    $(document).ready(function () {
      $("#customerAccountsTable").DataTable().destroy();
      // eslint-disable-next-line
      const table = $("#customerAccountsTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: _this.state.perPage,
        paging: true,
        searching: false,
        ajax: {
          url: process.env.REACT_APP_API_URL,
          type: "POST",
          contentType: "application/json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", "Bearer " + authToken);
          },
          data: function (d: any) {
            const gql = `query  getCustomerAccounts(  
                $where: CustomerAccountsResolver_GetCustomerAccounts_FilterInputType
                $paginate:PaginatorArgs
            ) {
                getCustomerAccounts(where: $where ,paginate: $paginate) {
                    data {
                            id
                            name
                            email
                            portalUserId
                            updatedAt
                            createdAt
                            createdBy{
                              id
                              firstName
                              lastName
                            }
                            location{
                              id
                              name
                            }
                            customer{
                              id
                              name
                            }
                            updatedBy{
                              id
                              firstName
                            }
                            customerRole{
                              id
                              name
                            }
                    }
                    totalPages
                    total
                    perPage
                    page 
                  }
          }`;
            const currentPage = d?.start ? d.start / d.length + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            const query = {
              operationName: null,
              query: gql,
              variables: {
                where: filter,
                paginate: {
                  page: currentPage,
                  per_page: length,
                },
              },
            };

            return JSON.stringify(query);
          },
          dataSrc: function (json) {
            json.recordsTotal = json.data.getCustomerAccounts.total;
            json.recordsFiltered = json.data.getCustomerAccounts.total;

            const rows: any[] = [];
            const records = json.data.getCustomerAccounts.data;
              const currentPage = json.data.getCustomerAccounts.page;
              const recordsPerPage = json.data.getCustomerAccounts.perPage;
            let counter = (currentPage - 1) * recordsPerPage + 1;
            for (const record of records) {
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let updatedAt = "";
              if (record.updatedAt) {
                updatedAt = Moment.utc(record.updatedAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord: any = {
                id: counter,
                recordId: record.id,
                customerAccountName: record.name,
                customerAccountEmail: record.email,
                customerName: record.customer.name,
                locationName: record?.location?.name ?? "",
                customerRole: record.customerRole.name,
                createdBy: record.createdBy?.firstName,
                LastUpdatedBy: record?.updatedBy?.firstName ?? "",
                portalUserId: record.portalUserId,
                createdAt,
                updatedAt,
              };
              rows.push(tempRecord);
              counter++;
            }
            if (rows && rows.length !== 0) {
              _this.setState({
                customerAccountsList: rows,
              });
            }
            return rows;
          },
        },
        initComplete: function (settings, json) {
          table.page(Number(_this.state.page)).draw(false);
        },
        columns: [
          {
            name: "index",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("index"),
            render: function (data, type, row) {
              return row.id;
            },
          },

          {
            name: "customerAccountName",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("customerAccountName"),
            render: function (data, type, row) {
              return row.customerAccountName;
            },
          },
          {
            name: "customerAccountEmail",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("customerAccountEmail"),
            render: function (data, type, row) {
              return row.customerAccountEmail;
            },
          },
          {
            name: "locationName",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("locationName"),
            render: function (data, type, row) {
              return row.locationName;
            },
          },
          {
            name: "customerName",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("customerName"),
            render: function (data, type, row) {
              return row.customerName;
            },
          },
          {
            name: "customerRole",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("customerRole"),
            render: function (data, type, row) {
              return row.customerRole;
            },
          },
          {
            name: "portalUserId",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("portalUserId"),
            render: function (data, type, row) {
              return row.portalUserId;
            },
          },

          {
            name: "createdAt",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("createdAt"),
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "LastUpdatedBy",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("LastUpdatedBy"),
            render: function (data, type, row) {
              return row.LastUpdatedBy;
            },
          },
          {
            name: "action",
            visible:
              _this.state.selectedColumns ||
              _this.state.selectedColumns.includes("action"),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-partner-account") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit partner Account"><button class="btn btn-secondary editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              if (permissions?.includes("delete-partner-account") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to delete partner Account"><button class="btn btn-secondary deleteBtn p-2 px-4 m-1" customer-account-delete-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-trash' style='font-size:1rem;'></i></button></div>";
              }
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          const title: any = [];
          $("#customerAccountsTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      table.on("page", function () {
        const currentPage = table.page.info();

        _this.setState({
          page: currentPage.page,
        });
      });
      $(document)[0].oncontextmenu = function () {
        return false;
      };

      $("html").click(function () {
        $("#context-menu").hide();
      });

      $("#context-menu a").click(function (e) {});
      _this.displaySelectedColumns(table);
    });

    $(".dt-buttons").remove();
  };

  async getFilterRecords() {
    let {customers,locations,customerRoles} = this.props;
    this.setState({
      locationDB: locations,
      customerDB: customers,
      customerRoleDB: customerRoles,
    });
  }

  handleDeleteCustomerAccount = async (recordId: any) => {
    try {
      const result = await deleteCustomerAccount(recordId);
      const isDeleted = result?.data?.data?.deleteCustomerAccount;
      if (!isDeleted) {
        toast.error("Failed to delete partner account");
        return;
      }
      toast.success("Partner Account deleted successfully");
      this.renderDataTable([]);
      return;
    } catch (error) {
      console.log("Error in partner account delete functionality", error);
    }
  };

    handleDownloadExcel = async () => {
      this.setState({
        downloadLoading: true
      })
      const filter = this.getFilter();
      const result: any = await getCustomerAccounts(filter);
      const allKeys = this.state.columnData.map((col: any) => col.key);
      const selectedColumns = this.state.selectedColumns?.length > 0 ? this.state.selectedColumns : allKeys;
      const excelRecords = [];
      let counter = 0;
      for (const record of result.data) {
        counter++;
        const tempRecord: any = {};
        if (selectedColumns.includes('index')) tempRecord['Sr.No'] = counter;
        if (selectedColumns.includes('customerAccountName')) tempRecord['Partner Account Name'] = record.name;
        if (selectedColumns.includes('customerAccountEmail')) tempRecord['Partner Account Email'] = record.email;
        if (selectedColumns.includes('locationName')) tempRecord['Location Name'] = record?.location?.name ?? "";
        if (selectedColumns.includes('customerName')) tempRecord['Customer Name'] = record.customer.name;
        if (selectedColumns.includes('customerRole')) tempRecord['Partner Role'] = record.customerRole.name;
        if (selectedColumns.includes('portalUserId')) tempRecord['Portal User ID'] = record.portalUserId;
        if (selectedColumns.includes('createdAt')) tempRecord['Created At'] = Moment.utc(record.createdAt).local().format("YYYY-MM-DD HH:mm:ss");
        if (selectedColumns.includes('LastUpdatedBy')) tempRecord['Last Updated By'] = record?.updatedBy?.firstName ?? '';      
  
      excelRecords.push(tempRecord);
      }
      DownloadExcel(excelRecords, "SHEET_NAME", "partner_accounts_list");
      this.setState({
        downloadLoading: false
      })
    };

    handleCustomerAccountClose = () => {
      this.setState({
        customerAccountEditModal: false,
        currentCustomerAccountId: ""
      })
    }

  refreshTable = async () => {
    await this.getFilterRecords();
    this.renderDataTable([]);
  };

  getColumnData = async () => {
    const _ = this;
    const data = await getModuleDataDisplaySettingByKey(
      this.state.moduleKeyName
    );
    if (data) {
      _.setState({ selectedColumns: data.metaValue });
      return data.metaValue;
    }
    return [];
  };

  displaySelectedColumns = async (table: any) => {
    const _this = this;
    if (_this.state.selectedColumns.length > 0) {
      table
        .settings()
        .init()
        .columns?.map(function (val: any, key: any) {
          if (!_this.state.selectedColumns.includes(val.name)) {
            table.column(key).visible(false);
          }
          return false;
        });
    }
    $("#customerAccountsTable").show();
    return false;
  };

  handleColumnDataSelect = async (e: any) => {
    const selCol = e.target.checked
      ? [...this.state.selectedColumns, e.target.value]
      : this.state.selectedColumns.filter(
          (item: string) => item !== e.target.value
        );
    this.setState({ selectedColumns: selCol });
  };

  handleColumnDataSubmit = async (e: any) => {
    const selectedColumns = this.state.selectedColumns;
    await updateModuleDataDisplaySettingByKey(
      this.state.moduleKeyName,
      selectedColumns
    );
    this.refreshTable();
  };
  handleSelectAll = () => {
    const { selectedColumns, columnData } = this.state;
    const allKeys = columnData.map((col: any) => col.key);
    if (selectedColumns.length === columnData.length) {
      this.setState({ selectedColumns: [] });
    } else {
      this.setState({ selectedColumns: allKeys });
    }
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    this.refreshTable();
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterName: "",
      filterEmail: "",
      filterLocation: "",
      filterCustomerRole: "",
      filterCustomer: "",
      locationSelected: null,
      customerSelected: null,
      customerRoleSelected: null,
    });
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Partner Accounts List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#customer-filter"
                aria-expanded="false"
                aria-controls="customer-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Partner Accounts List
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="collapse"
          id="customer-filter"
          aria-labelledby="customer-filter"
        >
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (From Date)
                  </label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (To Date)
                  </label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Name</label>
                  <input
                    className="form-control"
                    autoComplete="off"
                    name="filterName"
                    id="filterName"
                    value={this.state.filterName}
                    onChange={(event: any) => {
                      this.setState({
                        filterName: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Email</label>
                  <input
                    className="form-control"
                    autoComplete="off"
                    name="filterEmail"
                    id="filterEmail"
                    value={this.state.filterEmail}
                    onChange={(event: any) => {
                      this.setState({
                        filterEmail: event.target.value,
                      });
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customer</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerSelected: selected,
                        filterCustomer: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.customerSelected}
                    options={Array.from(this.state.customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Partner Role
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerRoleSelected: selected,
                        filterCustomerRole: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.customerRoleSelected}
                    options={Array.from(this.state.customerRoleDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <button
                      type="submit"
                      onClick={this.searchData}
                      className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                    >
                      {this.state.addLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!this.state.addLoading && (
                        <span className="indicator-label">ADD FILTER</span>
                      )}
                    </button>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <button
                      type="submit"
                      onClick={this.removeSearch}
                      className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                    >
                      {this.state.filterLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!this.state.filterLoading && (
                        <span className="indicator-label">RESET FILTER</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Columns
          columnData={this.state.columnData}
          selectedColumns={this.state.selectedColumns}
          handleColumnDataSelect={this.handleColumnDataSelect}
          handleColumnDataSubmit={this.handleColumnDataSubmit}
          handleSelectAll={this.handleSelectAll}
        />
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      <button
                        type="button"
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#columnsModal"
                      >
                        Columns
                      </button>
                    </div>

                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      <button
                        onClick={this.handleDownloadExcel}
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                      >
                        {this.state.downloadLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please Wait..
                            <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                          </span>
                        )}
                        {!this.state.downloadLoading && (
                          <span className="indicator-label">
                            {" "}
                            <KTSVG
                              path="/media/icons/duotune/files/fil021.svg"
                              className="svg-icon-4 me-2"
                            />
                            Export Excel
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                  <table
                    id="customerAccountsTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="companyId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr.No
                        </th>
                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Partner Account Name
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Partner Account Email
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Role
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Portal User Id
                        </th>

                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Updated By
                        </th>
                        <th className=" text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            id="context-menu"
          >
            <li>
              <button className="dropdown-item editBtn" id="edit_record_link">
                <i className="fa fa-pencil fs-6"></i>{" "}
                <span className="ms-2">Edit</span>
              </button>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" id="view_record_link">
                <i className="fa fa-eye f-6"></i>
                <span className="ms-2"> View </span>
              </a>
            </li>
          </ul>

              
            {this.state.customerAccountEditModal === true && (
            <EditCustomerAccount 
              currentCustomerAccountId={this.state.currentCustomerAccountId} 
              customerAccountEditModal={this.state.customerAccountEditModal}
              handleCustomerAccountClose={this.handleCustomerAccountClose} 
              refreshTable={this.refreshTable}
            />)}
           <Toaster
              position="bottom-left"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              containerStyle={{}}
              toastOptions={{
                className: "",
                duration: 3000,
              }}
            />
        </div>
      </Suspense>
    );
  }
}

export default CustomerAccountsList;
