/**
 * @generated SignedSource<<5cdf014de78a4707a4342b1d7db16f0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Priority = "HIGH" | "HIGHEST" | "LOW" | "MEDIUM" | "%future added value";
export type PriorityModal_getTicketQuery$variables = {
  id: string;
};
export type PriorityModal_getTicketQuery$data = {
  readonly getTicket: {
    readonly id: string;
    readonly priority: Priority;
    readonly uuid: string | null;
  };
};
export type PriorityModal_getTicketQuery = {
  response: PriorityModal_getTicketQuery$data;
  variables: PriorityModal_getTicketQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "TicketObjectType",
    "kind": "LinkedField",
    "name": "getTicket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "priority",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PriorityModal_getTicketQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PriorityModal_getTicketQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "278468d38a482d5a94081f3136f42d3f",
    "id": null,
    "metadata": {},
    "name": "PriorityModal_getTicketQuery",
    "operationKind": "query",
    "text": "query PriorityModal_getTicketQuery(\n  $id: String!\n) {\n  getTicket(id: $id) {\n    id\n    uuid\n    priority\n  }\n}\n"
  }
};
})();

(node as any).hash = "0afa440703acb7bf62502a2cda6d0f25";

export default node;
