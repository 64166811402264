import _ from "lodash";
import React, { useEffect } from "react";
import Moment from "moment";
import { MACHINE_SERVICES } from "../../../../../_metronic/helpers/enums";
import { SlideshowLightbox } from "lightbox.js-react";

const Timeline = (props: { machines: any; logJobs: any; tickets: any }) => {
  const { machines,logJobs,tickets } = props;
  const [history, setHistory] = React.useState<any>([]);

  const sortArray = (data: any) => {
    return data?.length > 0 ? data.map((a: any) => {
      return a.name
    }) : [];
  };

  useEffect(() => {
    const fetchData = () => {
      const machineDetail = Array.from(machines).map((record: any) => {
        return {
          activity: `Machine name ${record.name} with serial number ${record.serialNumber} is created by ${
            record.createdBy?.firstName ?? ""
          }`,
          action: "Machine Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
        };
      });

      const jobsDetail = Array.from(logJobs).map((record: any) => {
        return {
          activity: `Log job of job type ${_.capitalize(
            _.lowerCase(record.jobType)
          )} is created by ${record.createdBy?.firstName ?? ""}`,
          action: "Log Job Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
        };
      });

      const ticketsDetail = Array.from(tickets).map((record: any) => {
        let board = { label: '', value: '' };
        if (record?.boardId && record?.board && record?.machineServicesDone) {
          const filterBoard = record.machineServicesDone.filter((x: any) => [MACHINE_SERVICES.UPGRADE_PCB, MACHINE_SERVICES.REPLACE_PCB].includes(x.id));
          if (filterBoard?.length > 0) {
            board.label = filterBoard[0].name;
            board.value = record?.board?.title;
          }
        }
        let blender = { label: '', value: '' };
        if (record?.blenderId && record?.blender && record?.machineServicesDone) {
          const filterBlender = record.machineServicesDone.filter((x: any) => [MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY].includes(x.id));
          if (filterBlender?.length > 0) {
            blender.label = filterBlender[0].name;
            blender.value = record?.blender?.title;
          }
        }
        return {
          activity: `Ticket of machine ${
            record.machine?.name ?? ""
          } is created by ${record.createdBy?.firstName ?? ""}`,
          action: "Ticket Created",
          createdAt: Moment.utc(record.createdAt)
            .local()
            .format("YYYY-MM-DD HH:mm"),
          machineServiceOtherInput: record?.machineServiceOtherInput || null,
          operationOtherInput: record?.operationOtherInput || null,
          issueReportedOtherInput: record?.issueReportedOtherInput || null,
          diagnosisOtherInput: record?.diagnosisOtherInput || null,
          machineServicesNeeded: sortArray(record?.machineServices),
          operationNeeded: sortArray(record?.operations),
          machineServicesDone: sortArray(record?.machineServicesDone),
          operationDone: sortArray(record?.operationsDone),
          issuesReported: sortArray(record?.issuesReported),
          diagnosisDone: sortArray(record?.diagnosisDone),
          comment: record?.comment,
          media: record?.media,
          isFilterReplaced: record?.isFilterReplaced,
          isSanitizerReplaced: record?.isSanitizerReplaced,
          status: record?.status,
          workStartTime: record?.workStartTime,
          closingDateAndTime: record?.closingDateAndTime,
          replacedBoard: board,
          replacedBlender: blender,
        };
      });
      const allHistory = [...machineDetail, ...jobsDetail, ...ticketsDetail];
      const sortedRecord = allHistory.sort(function (a, b) {
        return (
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );
      });
      setHistory(sortedRecord);
    };

    fetchData();
    // eslint-disable-next-line
  }, [history]);

  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Timeline</span>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-9">
              {history.length > 0 ? (
                history.map((value: any) => {
                  return (
                    <div className="timeline-label pb-9">
                      <div className="timeline-item">
                        <div className="timeline-label fw-semibold text-gray-800 fs-6">
                          {Moment.utc(value.createdAt)
                            .local()
                            .format("YYYY-MM-DD HH:mm")}
                        </div>
                        <div className="timeline-badge">
                          {value.action === "Ticket Created" ? (
                            <i className="fa fa-genderless text-danger fs-1"></i>
                          ) : (
                            ""
                          )}
                          {value.action === "Log Job Created" ? (
                            <i className="fa fa-genderless text-info fs-1"></i>
                          ) : (
                            " "
                          )}
                          {value.action === "Machine Created" ? (
                            <i className="fa fa-genderless text-success fs-1"></i>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="fw-semibold timeline-content">
                          <div className="fw-semibold text-muted ps-3 fs-4">
                            Action : {value.action}
                          </div>
                          <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                            {value.activity}
                          </div>
                          {
                            (value?.workStartTime && value?.closingDateAndTime) ? (
                              (() => {
                                const workStartDate = new Date(value.workStartTime);
                                const closingDate = new Date(value.closingDateAndTime);

                                // Calculate the duration in milliseconds
                                const durationInMs = closingDate.getTime() - workStartDate.getTime();

                                // Calculate the duration in hours, minutes, and seconds
                                const hours = Math.floor(durationInMs / (1000 * 60 * 60));
                                const minutes = Math.floor((durationInMs % (1000 * 60 * 60)) / (1000 * 60));
                                const seconds = Math.floor((durationInMs % (1000 * 60)) / 1000);

                                const durationString = `${hours} hours, ${minutes} minutes, ${seconds} seconds`;

                                return (
                                  <div className="text-muted fs-6 mt-3">
                                    <div className="text-dark fw-normal ps-3 fs-4">Ticket Duration:</div>
                                    <div className="ps-3 mt-2">
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Ticket Duration:</span> {durationString}
                                      </li>
                                    </div>
                                  </div>
                                );
                              })()
                            ) : null
                          }
                          {value.action === "Ticket Created" && (
                            <div className="text-dark fw-normal ps-3 mt-3">
                              {(value.machineServicesNeeded?.length || value.operationNeeded?.length || value.issuesReported?.length) ? (
                                <div className="text-muted fs-6">
                                  <div className="text-dark fw-normal ps-3 fs-4">Issues Reported:</div>
                                  <div className="ps-3 mt-2">
                                    {value.issuesReported?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Issue Reported:</span> {value.issuesReported.join(", ")}
                                      </li>
                                    )}
                                    {value.machineServicesNeeded?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Machine Services Needed:</span> {value.machineServicesNeeded.join(", ")}
                                      </li>
                                    )}
                                    {value.operationNeeded?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Operation Needed:</span> {value.operationNeeded.join(", ")}
                                      </li>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {(value.machineServicesDone?.length || value.operationDone?.length || value.diagnosisDone?.length) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Services Done:</div>
                                  <div className="ps-3 mt-2">
                                    {value.machineServicesDone?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Machine Services Done:</span> {value.machineServicesDone.join(", ")}
                                      </li>
                                    )}
                                    {value.operationDone?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Operation Done:</span> {value.operationDone.join(", ")}
                                      </li>
                                    )}
                                    {value.diagnosisDone?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Diagnosis Done:</span> {value.diagnosisDone.join(", ")}
                                      </li>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {(value?.issueReportedOtherInput || value?.machineServiceOtherInput || value?.operationOtherInput || value?.diagnosisOtherInput) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Others:</div>
                                  <div className="ps-3 mt-2">
                                    {value?.issueReportedOtherInput && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Other Issue Reported:</span> {value?.issueReportedOtherInput}
                                      </li>
                                    )}
                                    {value?.machineServiceOtherInput && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Other Machine Service:</span> {value?.machineServiceOtherInput}
                                      </li>
                                    )}
                                    {value?.operationOtherInput && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Other Operation:</span> {value?.operationOtherInput}
                                      </li>
                                    )}
                                    {value?.diagnosisOtherInput && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Other Diagnosis:</span> {value?.diagnosisOtherInput}
                                      </li>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}

                              {(value?.replacedBoard?.label && value?.replacedBoard?.value) || (value?.replacedBlender?.label && value?.replacedBlender?.value) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Replace Details:</div>
                                  <div className="ps-3 mt-2">
                                    {value?.replacedBoard?.label && value?.replacedBoard?.value && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">{value?.replacedBoard?.label}:</span> {value?.replacedBoard?.value}
                                      </li>
                                    )}
                                    {value?.replacedBlender?.label && value?.replacedBlender?.value && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">{value?.replacedBlender?.label}:</span> {value?.replacedBlender?.value}
                                      </li>
                                    )}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                                {(value.comment?.length) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Comment:</div>
                                  <div className="ps-3 mt-2">
                                    {value.comment?.length > 0 && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">comment:</span> {value.comment}
                                      </li>
                                    )}

                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {(value.isFilterReplaced) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Replaced Filter:</div>
                                  <div className="ps-3 mt-2">
                                    {value.isFilterReplaced && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Replaced Filter:</span> {value.isFilterReplaced === true ? "Yes" : "No"}
                                      </li>
                                    )}

                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {(value.isSanitizerReplaced) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Replaced Sanitizer:</div>
                                  <div className="ps-3 mt-2">
                                    {value.isSanitizerReplaced && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Replaced Sanitizer:</span> {value.isSanitizerReplaced === true ? "Yes" : "No"}
                                      </li>
                                    )}

                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {(value.status) ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Status:</div>
                                  <div className="ps-3 mt-2">
                                    {value.status && (
                                      <li className="text-dark fw-normal ps-4">
                                        <span className="fw-semibold text-muted fs-6">Status:</span> {value.status}
                                      </li>
                                    )}

                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              {value.media?.length ? (
                                <div className="text-muted fs-6 mt-3">
                                  <div className="text-dark fw-normal ps-3 fs-4">Media:</div>
                                  <div className="row ps-3">
                                    {value.media.map((image: any, index: number) => {
                                      // Check if image and its type exist
                                      if (!image || !image.type || !image.url) return null;
                                      return (
                                        <div key={index} className="col-3">
                                          {["image/png", "image/jpeg", "image/webp", "image/jpg"].includes(image.type) ? (
                                            <SlideshowLightbox className="container mx-auto">
                                              <img
                                                src={image.url}
                                                width="100%"
                                                alt="Ticket Image"
                                                className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light"
                                              />
                                            </SlideshowLightbox>
                                          ) : (
                                            <video
                                              controls
                                              src={image.url}
                                              width="100%"
                                              className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light"
                                            />
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="fs-6 text-muted">No records found</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
