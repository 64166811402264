import React from "react";
import Moment from "moment";
import _ from "lodash";
import { Link } from 'react-router-dom';

const LocationHistoryOverview = (props: { locationHistory: any }) => {
  const { locationHistory } = props;


  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="fw-bolder card-label fw-bold fs-3">Location</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total Location : {locationHistory.length ? locationHistory.length : 0}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Location ID</th>
                      <th className="fs-5 text-muted">Location Name</th>
                      <th className="fs-5 text-muted">Location Address</th>
                      <th className="fs-5 text-muted">Location Region</th>
                      <th className="fs-5 text-muted">Market Segment</th>
                      <th className="fs-5 text-muted">Deployment Manager</th>
                      <th className="fs-5 text-muted">Distributor</th>
                      <th className="fs-5 text-muted">Location Type</th>
                      <th className="fs-5 text-muted">Target Date</th>
                      <th className="fs-5 text-muted">Launch Date</th>
                      <th className="fs-5 text-muted">Created At</th>
                      <th className="fs-5 text-muted">Deployed At</th>
                      <th className="fs-5 text-muted">Location Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationHistory.length > 0 ? (
                      Array.from(locationHistory).map((locationHistory: any, index) => (

                        <tr key={index}>
                          <td>
                            <Link
                              data-bs-toggle="tooltip"
                              title="Click here to view location"
                              to={`/locations/list/history/${locationHistory.id}`}
                            >
                              {locationHistory.location.uuid ?? ""}
                            </Link>
                          </td>
                          <td>
                            {_.capitalize(
                              _.lowerCase(locationHistory.location.name ?? "")
                            )}
                          </td>
                          <td>{locationHistory?.location.address ?? ""}</td>
                          <td>{locationHistory.location?.region?.name ?? ""}</td>
                          <td>
                            {_.capitalize(
                              _.lowerCase(locationHistory.location?.marketSegment?.name ?? "")
                            )}
                          </td>
                          <td>{locationHistory.location?.deploymentManager?.firstName ?? ""}</td>
                          <td>
                            {_.capitalize(_.lowerCase(locationHistory.location?.distributor?.name ?? ""))}
                          </td>
                          <td> {_.capitalize(_.lowerCase(locationHistory.location?.locationType?.name ?? ""))}</td>
                          <td>
                            {locationHistory.location.targetLaunchDate ?? ""}
                          </td>
                          <td>
                            {locationHistory.location?.launchDate ?? ""}
                          </td>
                          <td>
                            {Moment(locationHistory.location?.createdAt)
                              .local()
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>
                            {locationHistory.location?.deploymentDate ? Moment(locationHistory.location?.deploymentDate)
                              .local()
                              .format("YYYY-MM-DD HH:mm") : ''}
                          </td>
                          <td>{
                            <span className="fw-bold fs-6 ms-2">
                              {locationHistory.isAssign === true ? (
                                <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                                  Active
                                </span>
                              ) : (
                                <span className="badge badge-light-danger fw-bolder me-auto px-4 py-2">
                                  Inactive
                                </span>
                              )}
                            </span>}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={11}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationHistoryOverview;
