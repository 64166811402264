import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import Moment from "moment";
import {
  getMachineStatusList,
  createMachine,
} from "../../models/_machine";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  getLocationsForFilter,
  getUsers,
} from "../../models/_location";
import { getBoardsList } from "../../models/_board";
import { getBlendersList } from "../../models/_blenders";
import { getCupHoldersList } from "../../models/_cupHolders";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { getTubeShieldList } from "../../models/_tubeShield";
import {Html5Qrcode, Html5QrcodeScanner} from "html5-qrcode"

const validationSchema = yup.object({
  // name: yup.string().required("Machine name is required"),
  serialNumber: yup.string().required("Serial number is required"),
  //macAddress: yup.string().required("Serial number is required"),
  // connectionStatus: yup.string().required("Connection status is required"),
  deploymentDate: yup.date().required("Deployment date is required"),
  blenderId: yup.string().required("Blender is required"),
  boardId: yup.string().required("Board is required"),
  machineStatusId: yup.string().required("Machine Status is required"),
});

const CreateMachine = () => {
  const navigate = useNavigate();
  const resetManager = React.useRef<any>(null);
  const resetBlender = React.useRef<any>(null);
  const resetBoard = React.useRef<any>(null);
  const resetCupHolder = React.useRef<any>(null);
  const resetLocation = React.useRef<any>(null);
  const resetDeploymentDate = React.useRef<any>(null);
  const resetTrialEndDate = React.useRef<any>(null);
  const resetMachineStatus = React.useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [managerDB, setManagerDB] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [managerName, setManagerName] = useState("");
  const [locationId, setLocationId] = useState("");
  const [deployingPersonId, setDeployingPersonId] = useState(null);
  const [locationResult, setLocationResult] = useState<any>({});
  const [locationsData, setLocationsData] = useState<any>([]);
  const [blenderDB, setBlenderDB] = useState<any>([])
  const [boardDB, setBoardDB] = useState<any>([])
  const [tubeShieldDB, setTubeShieldDB] = useState<any>([])
  const [cupHolderDB, setCupHolderDB] = useState<any>([])
  const [machineStatusDB, setMachineStatusDB] = useState<any>([])
  const [displayMachineStatus, setDisplayMachineStatus] = useState<any>(null);
  const [barcode, setBarcode] = useState<any>("");
  const [isEnabledScanBarcodeViaCamera, setIsEnabledScanBarcodeViaCamera] = useState<boolean>(false);
  const fetchData = async () => {
    try {
      const locationIdRecord = await getLocationsForFilter();
      const locationIdResults: any = locationIdRecord?.data ? locationIdRecord?.data : [];
      setLocationsData(locationIdRecord);
      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const deploymentManagerRecord = await getUsers();
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const blenderRecord = await getBlendersList();
      const blenderResults: any = blenderRecord
        ? blenderRecord
        : [];

      blenderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const boardRecord = await getBoardsList();
      const boardResults: any = boardRecord
        ? boardRecord
        : [];


      boardResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const cupHolderRecord = await getCupHoldersList();
      const cupHolderResults: any = cupHolderRecord
        ? cupHolderRecord
        : [];


      cupHolderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const machineStatusRecord = await getMachineStatusList();

      const tubeShieldRecord = await getTubeShieldList();
      const tubeShieldResults: any = tubeShieldRecord
        ? tubeShieldRecord
        : [];
        
      setBlenderDB(blenderResults)
      setBoardDB(boardResults)
      setLocationDB(locationIdResults);
      setManagerDB(deploymentManagerResults);
      setCupHolderDB(cupHolderResults);
      setMachineStatusDB(machineStatusRecord);
      setTubeShieldDB(tubeShieldResults);
    } catch (error) {
      console.log(error);
    }
  };

  const handleLocationChange = async (e: any) => {
    setLocationId(e.value);
    try {
      const locations: any = locationsData?.data ?? []
      const locationRecords = locations.filter(
        (location: any) => location?.id === e.value
      );

      const locationRecord: any = locationRecords[0];
      if (locationRecord !== null) {
        setLocationResult(locationRecord);
        setManagerName(locationRecord?.deploymentManager?.firstName);
        setDeployingPersonId(locationRecord?.deploymentManagerId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleScanBarCodeViaCamera = async (setFieldValue: any) => {
    setIsEnabledScanBarcodeViaCamera(true);
    try {
        const cameras = await Html5Qrcode.getCameras();
        if (cameras && cameras.length > 0) {  
          const scanner = new Html5QrcodeScanner(
            "reader",
            {
              qrbox: {
                width: 260,
                height: 260,
              },
              fps: 20,
            },
            false 
          );

        scanner.render(
          async(barcodeDecodedValue:any) => {
            setBarcode(barcodeDecodedValue);
            setFieldValue("serialNumber", barcodeDecodedValue);
            await scanner.clear()
            toast.success('Barcode successfully scanned');
            setIsEnabledScanBarcodeViaCamera(false);
          },
          (error:any) => {
            console.log("Barcode scanneed failed",error);
          }
        );
      } else {
        setIsEnabledScanBarcodeViaCamera(false);
      }
    } catch (error) {
      toast.error('No camera detected. Please ensure a camera is connected and try again.');
      setIsEnabledScanBarcodeViaCamera(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Machine
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Machine</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="reader" className="card mx-xl-4 mt-9" style={{ display: isEnabledScanBarcodeViaCamera ? "block" : "none" }}></div>
      <div className="card mx-xl-4 mt-9" style={{ display: !isEnabledScanBarcodeViaCamera ? "block" : "none" }}>
        <Formik
          initialValues={{
            locationId: "",
            name: "",
            macAddress: "",
            serialNumber: "",
            deploymentDate: "",
            isActive: true,
            blenderId: "",
            boardId: "",
            machineStatusId: "",
            cupHolderId : null,
            tubeShieldId:null,
            machineRemark: "",
            isPilot: false,
            trialEndDate: "",
            noteRelatedToMechanicalHardwareVersion: "",
            isPolyfuses: false,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            let hasErrors = false;
            // if (!deployingPersonId) {
            //   hasErrors = true;
            //   toast.error("Deploying person is required");
            // }
            if (hasErrors) {
              setLoading(false);
              return false;
            }


            const $inputData: any = {
              name: values.name,
              macAddress: values.macAddress,
              deploymentDate: Moment.utc(values.deploymentDate)
                .local()
                .format("YYYY-MM-DD"),
              deployingPersonId: deployingPersonId,
              serialNumber: values.serialNumber,
              remark: values.machineRemark,
              noteRelatedToMechanicalHardwareVersion: values.noteRelatedToMechanicalHardwareVersion,
              isActive: values.isActive,
              isPolyfuses: values.isPolyfuses,
              blenderId: values.blenderId,
              machineStatusId: values.machineStatusId,
              boardId: values.boardId,
              cupHolderId:values.cupHolderId !== null ? values.cupHolderId : null,
              tubeShieldId:values.tubeShieldId !== null ? values.tubeShieldId : null,
              isPilot: values.isPilot,
              trialEndDate: values.trialEndDate
            };

            if (values.trialEndDate) {
              $inputData['trialEndDate'] = Moment.utc(values.trialEndDate)
                .local()
                .format("YYYY-MM-DD")
            }

            if (locationId !== "") {
              $inputData["locationId"] =
                locationId;
            }

            const result = await createMachine($inputData);
            if (result?.data?.data?.createMachine) {
              toast.success("Machine created successfully");
              resetLocation?.current?.setValue("");
              resetManager.current.setValue("");
              resetBlender.current.setValue("");
              resetBoard.current.setValue("");
              resetCupHolder.current.setValue("");
              setLocationResult("");
              setManagerName("");
              setLoading(false);
              resetDeploymentDate.current.state.inputValue = "";
              resetForm();
              navigate("/machines/list");
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="assignLocations form-label fs-4  fw-bold">
                      Assign Locations
                    </label>
                    <i className="assignLocations fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".assignLocations" className="tooltip-dashboard">
                      Choose a location where the machine is placed
                    </Tooltip>

                    <Select
                      ref={resetLocation}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="locationId"
                      defaultValue={{ label: locationResult?.name, value: locationId }}
                      placeholder=""
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        handleLocationChange(e);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="serialNumber form-label fs-4 required fw-bold">
                      Serial Number
                    </label>  <i className="serialNumber fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".serialNumber" className="tooltip-dashboard">
                      Enter the machine's serial number, located inside the service door
                    </Tooltip>
                    <Tooltip anchorSelect=".scanBarcode" className="tooltip-dashboard">
                      Click to scan the barcode.
                    </Tooltip>
                    <i className="scanBarcode fas fa-barcode fs-5 ps-2" style={{ cursor: "pointer" }} onClick={()=>handleScanBarCodeViaCamera(setFieldValue)}/>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                        !barcode ? Boolean(errors.serialNumber) && touched.serialNumber : false ,
                      })}
                      name="serialNumber"
                      id="serialNumber"
                      value={values.serialNumber || barcode || ""}
                      onChange={(e) => {
                        handleChange(e);
                        if (e.target.value === "") {
                          setBarcode("");
                        }
                      }}
                    />
                    {!barcode && (
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="serialNumber" />
                    </div>
                    )}
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="macAddress form-label fs-4 fw-bold">
                      Mac Address
                    </label>  <i className=" macAddress fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".macAddress" className="tooltip-dashboard">
                      The Mac address can be found on the bottom part of the service menu
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.macAddress) && touched.macAddress,
                      })}
                      name="macAddress"
                      id="macAddress"
                      value={values.macAddress}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="macAddress" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="machineName form-label fs-4  fw-bold">
                      Machine Name
                    </label>  <i className="machineName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".machineName" className="tooltip-dashboard">
                      the machine name should be comprised as the location name-citiy all in one word lower case letters
                    </Tooltip>
                    <input
                      className={clsx("form-control name", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="deploymentDate form-label fs-4 required fw-bold">
                      Deployment Date
                    </label>  <i className="deploymentDate fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".deploymentDate" className="tooltip-dashboard">
                      What capacity is the machine performing right now.
                    </Tooltip>
                    <Datetime
                      ref={resetDeploymentDate}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      timeFormat={false}
                      className={clsx("date-control", {
                        "is-invalid":
                          Boolean(errors.deploymentDate) &&
                          touched.deploymentDate,
                      })}
                      value={values.deploymentDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("deploymentDate", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="deploymentDate" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="personDeploying form-label fs-4 fw-bold">
                      Person Deploying
                    </label>  <i className="personDeploying fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".personDeploying" className="tooltip-dashboard">
                      The Smoodi team member responsible for deploying the machine, or overseeing its deployment if conducted by a third party
                    </Tooltip>
                    <Select
                      ref={resetManager}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="deployingPersonId"
                      placeholder={managerName}
                      options={Array.from(managerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      })}
                      onChange={(e: any) => {
                        setDeployingPersonId(e.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="remark form-label fs-4 fw-bold">Remark </label>
                    <i className="remark fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".remark" className="tooltip-dashboard">
                      Add a note pertaining to this machine.
                    </Tooltip>
                    <textarea
                      name="machineRemark"
                      id="machineRemark"
                      className="form-control"
                      value={values.machineRemark}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="blender form-label required fs-4 fw-bold">
                      Blender
                    </label>  <i className="blender fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".blender" className="tooltip-dashboard">
                      The version of the blender assembly. Can be indentifiesd if blade guard is present  (version N and above) or written on the assembly (rev Q)
                    </Tooltip>
                    <Select
                      ref={resetBlender}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="blenderId"
                      placeholder=""
                      options={Array.from(blenderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                          description: value.description,
                          mediaUrl: value.mediaUrl,
                        };
                      })}
                      formatOptionLabel={({ label, description,mediaUrl }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                            {mediaUrl && (
                              <div className="mt-2">
                              <img 
                                src={mediaUrl} 
                                alt={label} 
                                style={{ height:'100px',objectFit: 'cover' }} 
                              />
                            </div>
                            )}
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("blenderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="blenderId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="board form-label required fs-4 fw-bold">
                      Board
                    </label>  <i className="board fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".board" className="tooltip-dashboard">
                      Version of the board in the back of the machine, if there is no need for ribbon cable, the version of the board is C
                    </Tooltip>
                    <Select
                      ref={resetBoard}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="boardId"
                      placeholder=""
                      options={Array.from(boardDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                          description: value.description,
                          mediaUrl: value.mediaUrl,
                        };
                      })}
                      formatOptionLabel={({ label, description,mediaUrl }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                            {mediaUrl && (
                              <div className="mt-2">
                              <img 
                                src={mediaUrl} 
                                alt={label} 
                                style={{ height:'100px',objectFit: 'cover' }} 
                              />
                            </div>
                            )}
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("boardId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="boardId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className={'form-label fs-4 fw-bold required'}>
                    Machine Status
                    </label>
                    <Select
                      ref={resetMachineStatus}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.machineStatusId) &&
                          touched.machineStatusId,
                      })}
                      value={displayMachineStatus ? {
                        label: displayMachineStatus.name,
                        value: displayMachineStatus.id
                      } : null}
                      
                      classNamePrefix="my-react-select"
                      name="businessModelId"
                      placeholder=""
                      options={Array.from(machineStatusDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          description: value.description,
                        };
                      })}
                      formatOptionLabel={({ label, description }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("machineStatusId", e.value);
                        setDisplayMachineStatus(
                          machineStatusDB.find((model: any) => model.id === e.value) ||  null
                        );
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="businessModelId" />
                    </div>
                  </div>
                  

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label  fs-4 fw-bold">
                      Cup Holder
                    </label>
                    <Select
                      ref={resetCupHolder}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="cupHolderId"
                      placeholder=""
                      options={Array.from(cupHolderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title,
                          description: value.description,
                          mediaUrl: value.mediaUrl,
                        };
                      })}
                      formatOptionLabel={({ label, description,mediaUrl }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                            {mediaUrl && (
                              <div className="mt-2">
                              <img 
                                src={mediaUrl} 
                                alt={label} 
                                style={{ height:'100px',objectFit: 'cover' }} 
                              />
                            </div>
                            )}
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("cupHolderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="cupHolderId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label  fs-4 fw-bold">
                     Tube Shield
                    </label>
                    <Select
                      ref={resetCupHolder}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="tubeShieldId"
                      placeholder=""
                      options={Array.from(tubeShieldDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          mediaUrl: value.mediaUrl,
                          description: value.description,
                        };
                      })}
                      formatOptionLabel={({ label, description,mediaUrl }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                            {mediaUrl && (
                              <div className="mt-2">
                              <img 
                                src={mediaUrl} 
                                alt={label} 
                                style={{ height:'100px',objectFit: 'cover' }} 
                              />
                            </div>
                            )}
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("tubeShieldId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="tubeShieldId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                    <label className="form-label fs-4 fw-bold d-block">
                      Is Polyfuses?
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        defaultChecked={values.isPolyfuses === true}
                        onChange={(event) => {
                          setFieldValue("isPolyfuses", event.target.checked)
                        }}
                        name="isPolyfuses"
                      />
                    </div>
                  </div>

                  {locationId !== '' ? <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-3">
                    <label className="form-label fs-4 fw-bold d-block">
                      Is Pilot?
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"

                        defaultChecked={values.isPilot === true}
                        onChange={(event) => {
                          setFieldValue("isPilot", event.target.checked)
                          setFieldValue("trialEndDate", "")
                        }
                        }
                        name="isPilot"
                      />
                    </div>


                  </div> : ''}

                  {values.isPilot ?
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Trial End Date
                      </label>
                      <Datetime
                        ref={resetTrialEndDate}
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect
                        timeFormat={false}
                        className={clsx("date-control", {
                          "is-invalid":
                            Boolean(errors.trialEndDate) &&
                            touched.trialEndDate,
                        })}
                        value={values.trialEndDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("trialEndDate", dt);
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="trialEndDate" />
                      </div>
                    </div> : ''}


                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="mechanicalHardwareVersion form-label fs-4 fw-bold">Note related to mechanical hardware version </label>
                    <i className="mechanicalHardwareVersion fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".mechanicalHardwareVersion" className="tooltip-dashboard">
                      Comment if the mechanical hardware is abnormal or has an unusual combination of parts.
                    </Tooltip>
                    <textarea
                      name="noteRelatedToMechanicalHardwareVersion"
                      id="noteRelatedToMechanicalHardwareVersion"
                      className="form-control"
                      value={values.noteRelatedToMechanicalHardwareVersion}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD MACHINE</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateMachine;
