import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateLogJobsInput {
  jobType: string;
  locationId: string;
  status: string;
  dateAndTime: string;
  closingDateAndTime?: string;
  marketingMaterials?: string[];
  travelTime?: number;
  uploadImagesOrVideos? :string[],
  machineSerialNumber: string,
  machineName: string,
  additionalComments: string
  cleanliness?: string;
  customerSatisfaction?: string;
  trafficRate?: string;
  staffEnthusiasm?: string;
  sanitizerStatus?:string;
}

export async function createLogJob(input: CreateLogJobsInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `mutation createLogJob(
          $input: CreateLogJobsInput!
            )
            {
              createLogJob(
                createLogJobsInput:$input
                  ) 
            }   
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getLocation() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
                getLocations {
                  data {
                    id
                    name
                  }
                }
              }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getLocations;
}

export async function getLogJobs(where: {} = {},locationTypeId: []) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getLogJobs(
        $where: LogJobsResolver_GetLogJobs_FilterInputType
        $locationTypeId:[String!]
            ){
        getLogJobs(where: $where ,locationTypeId:$locationTypeId) {
          id
          uuid
          jobType
          locationId
          dateAndTime
          status
          closingDateAndTime
          sanitizerStatus
          travelTime
          marketingMaterials {
            id
            name
          }
          machineSerialNumber
          machineName
          additionalComments
          cleanliness
          customerSatisfaction
          trafficRate
          staffEnthusiasm
          createdAt
          updatedAt
          createdBy {
            id
            firstName
            lastName
            email
          }
        }
        }`,
      variables: { where,locationTypeId },
    },
  });
  return data?.data?.data?.getLogJobs;
}

interface UpdateLogJobsInput {
  id?: string;
  jobType: string;
  closingDateAndTime?: string;
  travelTime?: number;
  marketingMaterials?: string[],
  status: string;
  locationId: string;
  dateAndTime: string;
  uploadImagesOrVideos?: string[];
  machineSerialNumber?: string;
  machineName?: string;
  additionalComments: string;
  cleanliness?: string;
  customerSatisfaction?: string;
  trafficRate?: string;
  staffEnthusiasm?: string;
  sanitizerStatus?:string;
}
export async function updateLogJob(input: UpdateLogJobsInput) {
  let params = { ...input };
  delete params["id"];
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateLogJob (
         $id: String!
         $input: UpdateLogJobsInput!
       ){
        updateLogJob(
           id: $id
           updateLogJobsInput: $input
           ) 
          
       }
       `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getJobById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query($id: String!) {
              getLogJob(id: $id) {
                id
                uuid
                jobType
                locationId
                dateAndTime
                uploadImagesOrVideos {
                  url
                  type
                }
                marketingMaterials {
                  id
                  name
                }
                sanitizerStatus
                machineSerialNumber
                machineName
                additionalComments
                createdById
                createdAt
                updatedById
                updatedAt
                customerSatisfaction
                cleanliness
                trafficRate
                staffEnthusiasm
                status
                closingDateAndTime
                travelTime
                createdBy {
                  id
                  firstName
                  lastName
                }
                updatedBy {
                  id
                  firstName
                  lastName
                }
              }
            }
            `,
      variables: { id },
    },
  });
  return data?.data?.data?.getLogJob;
}