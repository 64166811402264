import { FC, Suspense } from 'react';
import Modal from 'react-responsive-modal';
import Moment from 'moment';
import { fetchQuery,  useRelayEnvironment } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { useQuery } from 'react-query';

type Props = {
  activityModalOpen: boolean
  currentTicketId: string
  handleCloseActivity: () => void
}

export const getTicketListById = graphql`
 query ActivityModal_getTicketQuery($id:String!){
  getTicket(id:$id){
    id
    uuid
    machineServiceOtherInput
    operationOtherInput
    scheduleStartDate
    scheduleEndDate
    travelingStartTime
    travelingEndTime
    machineServicesDone {
      id
      name
    }
    operationsDone {
      id
      name
    }
    diagnosisDone {
      id
      name
    }
    diagnosisOtherInput
    closingDateAndTime
    isFilterReplaced
    isSanitizerReplaced
  }
}
`
export const ActivityModal: FC<Props> = ({
  activityModalOpen,
  handleCloseActivity,
  currentTicketId,
}
) => {
  const environment = useRelayEnvironment();
  const { data: ticketRecord, refetch } = useQuery(
        ["getTicketActivityModalById", currentTicketId],
        () => fetchQuery(environment, getTicketListById, { id: currentTicketId ?? undefined }).toPromise(),
        {
          enabled: !!currentTicketId,
        }
      );
  
      const ticket: any = ticketRecord && typeof ticketRecord === 'object' && 'getTicket' in ticketRecord ? ticketRecord.getTicket : null;
  
  

  return (
    <div>
      <Suspense>
        <Modal
          open={activityModalOpen}
          onClose={() => handleCloseActivity()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
         {ticket && <>
          <div className="modal-header">
            <h2 className='mb-5'>Job Order Activity - {ticket.uuid}</h2>
          </div>
          <div className="modal-body p-6 pb-0">
            <div className="timeline-label pb-3">
              {ticket.scheduleStartDate &&
                <div className="timeline-item">
                  <div className="timeline-label fw-semibold text-gray-800 fs-6">
                    {Moment.utc(ticket.scheduleStartDate)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-primary fs-1"></i>
                  </div>
                  <div className="fw-semibold timeline-content">
                    <div className="fw-semibold text-muted ps-3 fs-3">
                      Action : Ticket Scheduled
                    </div>
                    <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                      <li>Ticket scheduled upto {Moment.utc(ticket.scheduleEndDate)
                        .local()
                        .format("YYYY-MM-DD HH:mm")}</li>
                    </div>
                  </div>
                </div>
              }
              {ticket.travelingStartTime &&
                <div className="timeline-item">
                  <div className="timeline-label fw-semibold text-gray-800 fs-6">
                    {Moment.utc(ticket.travelingStartTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-warning fs-1"></i>
                  </div>
                  <div className="fw-semibold timeline-content">
                    <div className="fw-semibold text-muted ps-3 fs-3">
                      Action : Traveling Started
                    </div>
                    <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                    </div>
                  </div>
                </div>}
              {ticket.travelingEndTime &&
                <div className="timeline-item">
                  <div className="timeline-label fw-semibold text-gray-800 fs-6">
                    {Moment.utc(ticket.travelingEndTime)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-saffron fs-1"></i>
                  </div>
                  <div className="fw-semibold timeline-content">
                    <div className="fw-semibold text-muted ps-3 fs-3">
                      Action : Work Started
                    </div>
                    <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                    </div>
                  </div>
                </div>}
              {ticket.closingDateAndTime &&
                <div className="timeline-item">
                  <div className="timeline-label fw-semibold text-gray-800 fs-6">
                    {Moment.unix(ticket.closingDateAndTime / 1000)
                      .local()
                      .format("YYYY-MM-DD HH:mm")}
                  </div>
                  <div className="timeline-badge">
                    <i className="fa fa-genderless text-danger fs-1"></i>
                  </div>
                  <div className="fw-semibold timeline-content">
                    <div className="fw-semibold text-muted ps-3 fs-3">
                      Action : Ticket Completed
                    </div>
                    {ticket.machineServicesDone?.length > 0
                      ?
                      <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                        <div className="mt-2 fv-row">
                          <label className="fs-5 mt-2 mb-0 fw-semibold text-muted">
                            Machine Services Done
                          </label>
                          <div className="separator separator-dashed my-2"></div>
                          {ticket.machineServicesDone?.map((element: any) => {
                            return (
                              <li className="fs-5 text-dark">{element.name}</li>
                            )
                          })}
                          {ticket.machineServiceOtherInput !== "" && (
                            <li className="fs-5 text-dark">
                              {ticket.machineServiceOtherInput}
                            </li>
                          )}
                        </div>
                      </div>
                      : ""}
                    {ticket.operationsDone?.length > 0
                      ?
                      <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                        <label className="fs-5 fw-semibold text-muted mt-2 mb-0">
                          Operations Done
                        </label>
                        <div className="separator separator-dashed my-2"></div>
                        <div className=" mt-2 fv-row">
                          {ticket.operationsDone?.map((element: any) => {
                            return (<li className="fs-5 text-dark">{element.name}</li>)
                          })}

                          {ticket.operationOtherInput !== "" && (
                            <li className="fs-5 text-dark">
                              {ticket.operationOtherInput}
                            </li>
                          )}
                        </div>
                      </div>
                      : ""}
                    {ticket.diagnosisDone?.length > 0
                      ?
                      <div className="text-dark fw-normal ps-3 mt-2 fs-6">
                        <label className="fs-5 mt-2 mb-0 fw-semibold text-muted">
                          Diagnosis Done
                        </label>
                        <div className="separator separator-dashed my-2"></div>
                        <div className="mt-2 fv-row">
                          {ticket.diagnosisDone?.map((element: any) => {
                            return (<li className="fs-5 text-dark">{element.name}</li>)
                          })}
                          {ticket.diagnosisOtherInput !== null &&
                            ticket.diagnosisOtherInput !== "" && (
                              <li className="fs-5 text-dark">
                                {ticket.diagnosisOtherInput}
                              </li>
                            )}
                        </div>
                      </div>
                      : ""}
                    {ticket.isFilterReplaced === true &&
                      <div className='ps-3'>
                        <div className="separator separator-dashed my-2"></div>
                        <div>Filter is replaced</div>
                      </div>
                    }
                    {ticket.isSanitizerReplaced === true &&
                      <div className='ps-3'>
                        <div className="separator separator-dashed my-2"></div>
                        <div>Sanitizer is replaced</div>
                      </div>
                    }
                  </div>
                </div>}
            </div>
          </div>
          </>}
        </Modal>
      </Suspense>
    </div >
  )
}

export default ActivityModal


