import React, { useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import ReactSelect, { components } from "react-select";
import { Suspense } from "react";
import * as _ from "lodash";
import moment from "moment";
import {
  getCustomerSatisfactionSummary,
} from "../../models/_blendlogs";
import MultiSelect from "../../../_metronic/helpers/MySelect";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Legend } from "recharts";
import clsx from "clsx";
import { Option } from '../../../_metronic/helpers/MySelect';
import { Tooltip as Tooltips } from "react-tooltip";
import { LOCATION_TYPES, MACHINE_STATUS } from "../../../_metronic/helpers/enums";
import { CustomerSatisfactionProps } from "./CustomerSatisfactionParentComponent";
const { Option: customOption } = components;

interface colorsObj {
  BB: string;
  GG: string;
  TT: string
}

const colors: colorsObj = {
  'BB': '#D4375C',
  'GG': '#4CA23D',
  'TT': '#FAD266'
};
const accountTypeDB = [
  {
    id: "ALL",
    name: "All",
  },
  {
    id: "CHAIN",
    name: "Chain",
  },
  {
    id: "INDIVIDUAL",
    name: "Individual",
  },
];

const getPath = (x: number, y: number, width: number, height: number) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
    ${x + width / 2}, ${y}
    C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
    Z`;
};

const TriangleBar = (props: any) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

interface FeedbackByFlavorSummary {
  flavor: string;
  averageRating: number;
  totalFeedback: number;
}

const CustomerSatisfactionSummary: React.FC<CustomerSatisfactionProps> = (props) => {
  const endDate = moment().utcOffset(0, true).subtract(1, 'weeks').endOf('isoWeek').toDate()
  const startDate = moment().utcOffset(0, true).subtract(1, 'weeks').startOf('isoWeek').toDate();
  let utcStartDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0)
  let utcEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59)

  const [addLoading, setAddLoading] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState(utcStartDate);
  const [filterToDate, setFilterToDate] = useState(utcEndDate);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterstatus, setFilterStatus] = useState<any>(true);
  const [locationRegionDB, setLocationRegionDB] = useState<any>([]);
  const [locationDB, setLocationDB] = useState<any>([]);
  const [filterRegion, setFilterRegion] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterMarketSegement, setFilterMarketSegment] = useState([]);
  const [locationRegionSelected, setLocationRegionSelected] = useState(null)
  const [locationSelected, setLocationSelected] = useState(null)
  const [machineStatusSelected, setMachineStatusSelected] = useState<any>([{ value: MACHINE_STATUS.DEPLOYED, label: 'DEPLOYED' }]);
  const [locationTypeSelected, setLocationTypeSelected] = useState<any>([{ value: LOCATION_TYPES.COMMERCIAL, label: 'COMMERCIAL' }]);
  const [filterMachineStatus, setFilterMachineStatus] = useState([MACHINE_STATUS.DEPLOYED]);
  const [filterLocationType, setFilterLocationType] = useState([LOCATION_TYPES.COMMERCIAL]);
  const [machineStatusDB, setMachineStatusDB] = useState<any>([])
  const [locationTypeDB, setLocationTypeDB] = useState<any>([])
  const [customerDB, setCustomerDB] = useState<any>([])
  const [filterCustomer, setFilterCustomer] = useState("")
  const [customerSelected, setCustomerSelected] = useState(null)
  // eslint-disable-next-line
  const [machineWiseAvgFeedback, setMachineWiseAvgFeedback] = useState([])
  const [machineWiseFlavorFeedbackCount, setMachineWiseFlavorFeedbackCount] = useState([])
  const [averageRating, setAverageRating] = useState(0)
  const [feedbackByFlavorSummary, setFeedbackByFlavorSummary] = useState<FeedbackByFlavorSummary[]>([])
  const [totalFeedback, setTotalFeedback] = useState(0);
  const [accountTypeSelected, SetAccountTypeSelected] = useState("");
  const [marketSegmentDB, setMarketSegmentDB] = useState<any>([]);
  const [marketSegmentIdSelected, setMarketSegmentIdSelected] = useState([]);
  const statusDB = [
    {
      id: true,
      name: "Active",
    },
    {
      id: false,
      name: "Inactive",
    },
    {
      id: "all",
      name: "All"
    }
  ]


  const customerSatisfactionData = async (filter: any) => {
    try {
      const customerSatisFactionResponse = await getCustomerSatisfactionSummary({ ...filter, fromDate: moment.utc([filterFromDate.getFullYear(), filterFromDate.getMonth(), filterFromDate.getDate(), filterFromDate.getHours(), filterFromDate.getMinutes()]).toDate(), toDate: moment.utc([filterToDate.getFullYear(), filterToDate.getMonth(), filterToDate.getDate(), filterToDate.getHours(), filterToDate.getMinutes()]).toDate() });

      const averageRatingResult: any = customerSatisFactionResponse?.averageRating ?? 0;
      setAverageRating(averageRatingResult)

      setTotalFeedback(customerSatisFactionResponse?.totalFeedback)
      if (customerSatisFactionResponse?.feedbackByFlavorSummary && customerSatisFactionResponse?.feedbackByFlavorSummary.length > 0) {
        setFeedbackByFlavorSummary(customerSatisFactionResponse?.feedbackByFlavorSummary)
      }

      if (customerSatisFactionResponse?.machineWiseAvgFeedback && customerSatisFactionResponse?.machineWiseAvgFeedback.length > 0) {

        setMachineWiseAvgFeedback(customerSatisFactionResponse?.machineWiseAvgFeedback)
      }

      if (customerSatisFactionResponse?.machineWiseFlavorFeedbackCount && customerSatisFactionResponse?.machineWiseFlavorFeedbackCount.length > 0) {

        setMachineWiseFlavorFeedbackCount(customerSatisFactionResponse?.machineWiseFlavorFeedbackCount)
      }
    } catch (error) {
      console.log(error)
    }

  }
  const fetchData = async () => {
    try {
      const {customers,locationTypes,locations,machineStatus,marketSegments,regions} = props
      setLocationDB(locations);
      setMarketSegmentDB(marketSegments);
      setLocationRegionDB(regions);
      setMachineStatusDB(machineStatus)
      setLocationTypeDB(locationTypes)
      setCustomerDB(customers)
    } catch (error) {
      console.log(error)
    }
  };


  const searchData = () => {
    let hasErrors = false;
    if (filterFromDate === undefined) {
      hasErrors = true;
      toast.error("From date is required");
    }
    if (filterToDate === undefined) {
      hasErrors = true;
      toast.error("To date is required");
    }

    if (hasErrors) {
      return false;
    }
    let filter: any = {};
    if (filterRegion) {
      filter["regionId"] = filterRegion;
    }
    if (filterLocation.length > 0) {
      filter["locationId"] = filterLocation
    }
    if (filterCustomer.length > 0) {
      filter["customerId"] = filterCustomer
    }
    if (filterMachineStatus.length > 0) {
      filter["machineStatusId"] = filterMachineStatus
    }
    if (filterLocationType.length > 0) {
      filter["locationTypeId"] = filterLocationType
    }
    if (filterFromDate) {
      filter["fromDate"] = moment.utc([filterFromDate.getFullYear(), filterFromDate.getMonth(), filterFromDate.getDate(), filterFromDate.getHours(), filterFromDate.getMinutes()]).toDate();
    }
    if (filterToDate) {
      filter["toDate"] = moment.utc([filterToDate.getFullYear(), filterToDate.getMonth(), filterToDate.getDate(), filterToDate.getHours(), filterToDate.getMinutes()]).toDate();
    }

    if (filterstatus === 'all') {
      delete filter['isActive']
    } else {
      filter['isActive'] = filterstatus;
    }

    if (filterMarketSegement.length > 0) {
      filter["marketSegmentId"] = filterMarketSegement;
    }

    if (accountTypeSelected && accountTypeSelected !== "ALL") {
      filter['accountType'] = accountTypeSelected;
    }

    setAddLoading(true);
    customerSatisfactionData(filter)
    setAddLoading(false);

  }
  const removeSearch = () => {
    if (filterFromDate === undefined) {
      setFilterFromDate(startDate)
    }
    if (filterToDate === undefined) {
      setFilterToDate(endDate)
    }
    setFilterLoading(true);
    setFilterFromDate(startDate);
    setFilterToDate(endDate);
    setLocationRegionSelected(null);
    setLocationSelected(null);
    setCustomerSelected(null);
    setMachineStatusSelected(null);
    setLocationTypeSelected(null);
    setFilterRegion("");
    setFilterLocation("");
    setFilterCustomer("");
    setFilterMachineStatus([]);
    setFilterLocationType([]);
    customerSatisfactionData({})

    setFilterLoading(false);
  };


  React.useEffect(() => {

    searchData()
    fetchData();
    // eslint-disable-next-line
  }, []);
  ;


  return (
    <>
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Customer satisfaction Summary
              </h1>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#satisfaction-filter"
                  aria-expanded="false"
                  aria-controls="satisfaction-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </button>
              </div>
            </div>
            <div className="collapse" id="satisfaction-filter" aria-labelledby="satisfaction-filter">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      setFilterFromDate(dt);
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      setFilterToDate(dt);
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Region</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setLocationRegionSelected(selected)
                      setFilterRegion(value)
                    }}
                    components={{
                      Option,
                    }}
                    value={locationRegionSelected}
                    options={Array.from(locationRegionDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />

                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customers</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setCustomerSelected(selected)
                      setFilterCustomer(value)
                    }}
                    components={{
                      Option,
                    }}
                    value={customerSelected}
                    options={Array.from(customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Locations</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setLocationSelected(selected)
                      setFilterLocation(value)
                    }}
                    components={{
                      Option,
                    }}
                    value={locationSelected}
                    options={Array.from(locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />

                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Machine status
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setMachineStatusSelected(selected)
                      setFilterMachineStatus(value)
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={machineStatusSelected}
                    options={Array.from(machineStatusDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          description: value.description,
                        };
                      }
                    )}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location Type</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setLocationTypeSelected(selected)
                      setFilterLocationType(value)
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={locationTypeSelected}
                    options={Array.from(locationTypeDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                          description: value.description,
                        };
                      }
                    )}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Site Status</label>
                  <Select
                    defaultValue={{ label: "Active", value: true }}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterStatus"
                    placeholder=""
                    onChange={(event: any) => {

                      setFilterStatus(event.value)
                    }}
                    options={Array.from(statusDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <label className="form-label fs-4 required fw-bold">
                    Market Segment
                  </label>

                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setMarketSegmentIdSelected(selected);
                      setFilterMarketSegment(value);
                    }}
                    selected={marketSegmentIdSelected}
                    options={Array.from(marketSegmentDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />

                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                  <label className="form-label fs-4 d-block  fw-bold ps-1">
                    Account Type
                  </label>
                  {Array.from(accountTypeDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex d-flex mt-1 ms-2" key={'ac-' + id}>
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={id}
                      >
                        <input
                          id="accountType"
                          className="form-check-input"
                          type="radio"
                          name="accountType"
                          value={value.id}
                          checked={accountTypeSelected === value.id}
                          onChange={() =>
                            SetAccountTypeSelected(value.id)
                          }
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}

                </div>
              </div>


              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={() => searchData()}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={() => removeSearch()}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row mt-5">
            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <div
                        className="AverageCustomerSatisfaction text-dark text-hover-success fw-bold fs-3"
                      >
                        Average Customer satisfaction
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </div>
                      <Tooltips anchorSelect=".AverageCustomerSatisfaction" className="tooltip-dashboard">
                        How is the percentage calculated
                      </Tooltips>
                      <span className="text-muted fw-semibold mt-1">
                        Count of Average Customer Satisfaction
                      </span>
                    </div>
                    <span className="symbol symbol-70px">
                      <span className="symbol-label fs-2 fw-bold bg-light-success text-success ms-1">
                        {averageRating}
                      </span>
                    </span>

                    <span className="symbol symbol-70px">
                      <span className="symbol-label fs-2 fw-bold bg-light-success text-success ms-2 ">
                        {(averageRating / 5 * 100).toFixed(2)}%
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <div
                        className="totalFeedback text-dark text-hover-success fw-bold fs-3"
                      >
                        Total Feedback
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </div>
                      <Tooltips anchorSelect=".totalFeedback" className="tooltip-dashboard">
                        The total number of customers who rated their Smoodi during the selected time period
                      </Tooltips>
                      <span className="text-muted fw-semibold mt-1">
                        Count of total feedback
                      </span>
                    </div>

                    <span className="symbol symbol-70px">
                      <span className="symbol-label fs-2 fw-bold bg-light-success text-success">
                        {totalFeedback}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-xl-6 col-12">
              <div className="card">
                <div className="card-body w-100 p-lg-7 p-md-7 p-1">
                  <div className="table-responsive p-0 pb-2 fs-6 border-0 dashboardtable">
                    <div className="col-12">
                      <h3 className="flavorWiseFeedback btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                        <KTSVG
                          path="/media/icons/duotune/graphs/gra011.svg"
                          className="svg-icon-4 me-1 mb-1"
                        />
                        Flavor wise avg customer satisfaction
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </h3>
                      <Tooltips anchorSelect=".flavorWiseFeedback" className="tooltip-dashboard">
                        Average score given to each flavor during the selected time period
                      </Tooltips>
                    </div>
                    <ResponsiveContainer width="100%" height={400}>
                      <BarChart
                        width={500}
                        height={400}
                        data={feedbackByFlavorSummary}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="flavor" label="Flavor" name="Flavor" />
                        <YAxis />
                        <Tooltip />
                        <Legend />

                        <Bar dataKey="averageRating" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                          {feedbackByFlavorSummary.map((entry: FeedbackByFlavorSummary, index) => (
                            <Cell key={`cell-${index}`} fill={colors[entry?.flavor as keyof typeof colors]} />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-20">
              <div className="card">
                <div className="card-body w-100 p-lg-7 p-md-7 p-1">
                  <div className="table-responsive p-0 pb-2 fs-6 border-0 dashboardtable">
                    <div className="col-12">
                      <h3 className="machineWiseFeedback btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                        <KTSVG
                          path="/media/icons/duotune/graphs/gra001.svg"
                          className="svg-icon-4 me-1 mb-1"
                        />
                        Machine wise flavor feedback count
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </h3>
                      <Tooltips anchorSelect=".machineWiseFeedback" className="tooltip-dashboard">
                        Please explain the graff
                      </Tooltips>
                    </div>
                    <ResponsiveContainer width="95%" height={400}>

                      <BarChart
                        width={1000}
                        height={300}
                        data={machineWiseFlavorFeedbackCount}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="machineName" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="totalGG" name="GG" stackId="a" fill={colors['GG']} />
                        <Bar dataKey="totalBB" name="BB" stackId="a" fill={colors['BB']} />
                        <Bar dataKey="totalTT" name="TT" stackId="a" fill={colors['TT']} />
                      </BarChart>
                    </ResponsiveContainer>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </>
  );
}

export default CustomerSatisfactionSummary;