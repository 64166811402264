import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateTubeShieldInput {
  name: string;
  description: string;
}
export async function createTubeShield(input: CreateTubeShieldInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createTubeShield (
          $input:CreateTubeShieldInput!
        ) {
            createTubeShield(
                createTubeShieldInput:$input
          )  
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function updateTubeShield( input: CreateTubeShieldInput, id: string) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTubeShield (
          $id: String!
          $input: CreateTubeShieldInput!
        ) {
          updateTubeShield(
            createTubeShieldInput: $input
            id: $id
          ) 
        }
      `,
      variables: { 
        id, 
        input 
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}


export async function getTubeShieldList() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
          query {
            getTubeShieldList {
                id
                name
                mediaUrl
                description
            }
          }
        `,
        variables: {},
      },
    });
    return data?.data?.data?.getTubeShieldList;
  }

  export async function getTubeShields(
    where: {} = {},
    pagination: {} | undefined = undefined
  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
                  query getTubeShields(
                    $where: TubeShieldResolver_GetTubeShields_FilterInputType,
                    $paginate: PaginatorArgs
                    ) {
                      getTubeShields (where:$where, paginate: $paginate) {
                        data {
                          id
                          name
                          mediaUrl
                          description
                          createdAt
                        }
                        totalPages
                        total
                        perPage
                        page
                      }
                  }
                  `,
        variables: { where, paginate: pagination },
      },
    });
    return data?.data?.data?.getTubeShields;
  }