import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export async function getMachineSerialNoLookups(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getMachineSerialNoLookups(
                  $where:  MachinesResolver_GetMachineSerialNoLookups_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getMachineSerialNoLookups (where:$where, paginate: $paginate) {
                      data {
                        id
                        serialNumber
                        createdAt
                        mechanicalHardwareVersion
                      }
                      totalPages
                      total
                      perPage
                      page
                    }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachineSerialNoLookups;
}
interface CreateMachineSerialNoLookupInput {
  payload: []
}

export async function getMachineSerialNoLookupsForFilter(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getMachineSerialNoLookups(
                  $where:  MachinesResolver_GetMachineSerialNoLookups_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getMachineSerialNoLookups (where:$where, paginate: $paginate) {
                      data {
                        id
                        serialNumber
                      }
                      totalPages
                      total
                      perPage
                      page
                    }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachineSerialNoLookups;
}

export async function createMachineSerialNoLookup( input: CreateMachineSerialNoLookupInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createMachineSerialNoLookup (
            $input: CreateMachineSerialNoLookupInput!
          ){
            createMachineSerialNoLookup(
              createMachineSerialNoLookupInput: $input,
              ){
                 isSuccess
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
