import React, { Component } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import {
  getRoles,
  getUsers,
  updateUser,
  updateUserDeveloperStatus,
  updateUserServiceEngineerStatus,
  updateUserPassword,
  updateUserRegionalManagerStatus,
  updateUserStatus,
  updateUserSalesPersonStatus,
} from "../../models/_user";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-responsive-modal";
import Select from "react-select";

export default class UsersList extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      editOpen: false,
      loading: false,
      usersList: [],
      userListObj: {},
      roleDB: [],
      confirmPwd: "",
      passwords: "",
      id: "",
      firstName: "",
      lastName: "",
      roleId: "",
    };
  }

  async componentWillMount() {}

  async componentDidMount() {
    var _ = this;
    $("body").on("change", ".togBtn", function () {
      const developerStatus = $(this).is(":checked");
      const record = $(this).attr("data-recordId");
      _.developerStatus(record, developerStatus);
      return false;
    });
    $("body").on("change", ".ragBtn", function () {
      const regionalManagerStatus = $(this).is(":checked");
      const record = $(this).attr("data-recordId");
      _.regionalManagerStatus(record, regionalManagerStatus);
      return false;
    });
    $("body").on("change", ".engineerBtn", function () {
      const engineerStatus = $(this).is(":checked");
      const record = $(this).attr("data-recordId");
      _.serviceEngineerStatus(record, engineerStatus);
      return false;
    });
    $("body").on("change", ".statusBtn", function () {
      const userStatus = $(this).is(":checked");
      const record = $(this).attr("data-recordId");
      _.userStatus(record, userStatus);
      return false;
    });
    $("body").on("change", ".salesPersonBtn", function () {
      const salesPersonStatus = $(this).is(":checked");
      const record = $(this).attr("data-recordId");
      _.salesPersonStatus(record, salesPersonStatus);
      return false;
    });
    $("#usersTable").on("click", ".passwordEdit", function () {
      const record = $(this).attr("data-recordId");
      _.passwordOpen(record);
    });
    $("#usersTable").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.editOpen(record);
    });

    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  async componentDidUpdate() {}

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];

    $(document).ready(function () {
      $("#usersTable").DataTable().destroy();

      let table = $("#usersTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "firstName",
            render: function (data, type, row) {
              return row.firstName;
            },
          },
          {
            name: "lastName",
            render: function (data, type, row) {
              return row.lastName;
            },
          },
          {
            name: "userEmail",
            render: function (data, type, row) {
              return row.userEmail;
            },
          },
          {
            name: "slackID",
            render: function (data, type, row) {
              return row.slackID;
            },
          },
          {
            name: "role",
            render: function (data, type, row) {
              return row.role;
            },
          },
          
          {
            name: "isRegionalManager",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                let isChecked =
                  row.isRegionalManager === true ? 'checked="checked"' : "";
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="ragBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.isRegionalManager === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }

              return returnData;
            },
          },
          {
            name: "canHandleServiceTicket",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                let isChecked =
                  row.canHandleServiceTicket === true ? 'checked="checked"' : "";
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="engineerBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.canHandleServiceTicket === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }

              return returnData;
            },
          },
          {
            name: "isActive",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                let isChecked =
                  row.isActive === true ? 'checked="checked"' : "";
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="statusBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.isActive === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }

              return returnData;
            },
          },
          {
            name: "isDeveloper",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                let isChecked =
                  row.isDeveloper === true ? 'checked="checked"' : "";
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="togBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.isDeveloper === true) {
                  returnData += '<div class="text-dark"> True</div>';
                } else {
                  returnData += '<div class="text-dark"> False</div>';
                }
              }
              return returnData;
            },
          },
          {
            name: "isSalesPerson",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                let isChecked =
                  row.isSalesPerson === true ? 'checked="checked"' : "";
                returnData +=
                  '<label class="status-switch"><input type="checkbox" ' +
                  isChecked +
                  "  data-recordId='" +
                  row.recordId +
                  '\' class="salesPersonBtn"><div class="status-slider round"></div></label>';
              } else {
                if (row.isSalesPerson === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }

              return returnData;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-user") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to change password"><button class="btn btn-secondary btn passwordEdit m-1 p-2 px-4" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-key' style='font-size:1rem;'></i></button></div>";

                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit user"><button class="btn btn-secondary btn editBtn m-1 p-2 px-4" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              return returnData;
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        // order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#usersTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      if (permissions?.includes("update-user") === false) {
        table.column(8).visible(false);
      }
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getData = async () => {
    const records: any = await getUsers();

    let counter = 0;
    const rows = [];
    const userListObj: any = {};

    const roleRecord = await getRoles();
    const roleResults: any = roleRecord ? roleRecord : [];
    roleResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    for (const record of records) {
      counter++;
      const tempRecord = {
        id: counter,
        recordId: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        userEmail: record.email,
        slackID: record.slackId,
        role: record.role.name,
        roleId: record.role?.id ?? "",
        isRegionalManager: record.isRegionalManager,
        canHandleServiceTicket:record.canHandleServiceTicket,
        isActive: record.isActive,
        isDeveloper: record.isDeveloper,
        isSalesPerson:record.isSalesPerson,
        record: record,
      };
      rows.push(tempRecord);
      userListObj[record.id] = tempRecord;
    }

    this.setState({
      usersList: rows,
      userListObj: userListObj,
      roleDB: roleResults,
    });
    return rows;
  };
  regionalManagerStatus = async (recordId: any, regionalManagerStatus: boolean) => {
    try {
      await updateUserRegionalManagerStatus(recordId, regionalManagerStatus);
    } catch (e: any) {
      console.log(e);
    }
  };
  serviceEngineerStatus = async (recordId: any, engineerStatus: boolean) => {
    try {
      await updateUserServiceEngineerStatus(recordId, engineerStatus);
    } catch (e: any) {
      console.log(e);
    }
  };
  developerStatus = async (recordId: any, developerStatus: boolean) => {
    try {
      await updateUserDeveloperStatus(recordId, developerStatus);
    } catch (e: any) {
      console.log(e);
    }
  };
  userStatus = async (recordId: any, userStatus: boolean) => {
    try {
      await updateUserStatus(recordId, userStatus);
    } catch (e: any) {
      console.log(e);
    }
  };
  salesPersonStatus = async (recordId: any, salesPersonStatus: boolean) => {
    try {
      await updateUserSalesPersonStatus(recordId, salesPersonStatus);
    } catch (e: any) {
      console.log(e);
    }
  };
  //edit ticket open
  editOpen = (recordId: any) => {
    const record = this.state.userListObj[recordId];
    this.setState({
      id: record.record.id,
      currentlyOpenRecord: record.record,
      firstName: record.firstName,
      lastName: record.lastName,
      roleId: record.roleId,
      role: record.role,
      editOpen: true,
    });
  };
  editClose = () => {
    this.setState({
      editOpen: false,
      currentlyOpenRecord: {},
      firstName: "",
      lastName: "",
      role: "",
      roleId: "",
      id: "",
    });
  };
  submitEdit = async (record: any) => {
    let hasErrors = false;
    if (this.state.firstName === "") {
      hasErrors = true;
      toast.error("First name is required");
    }
    if (this.state.lastName === "") {
      hasErrors = true;
      toast.error("Last name is required");
    }
    if (!this.state.roleId) {
      hasErrors = true;
      toast.error("Role is required");
    }

    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const updateData = {
      id: record.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      roleId: this.state.roleId,
    };
    const result = await updateUser(updateData);
    if (result?.data?.data?.updateUser) {
      this.setState({
        loading: false,
      });
      toast.success("User updated successfully");
      this.editClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };

  //password edit ticket open
  passwordOpen = (recordId: any) => {
    const record = this.state.userListObj[recordId];
    this.setState({
      id: record.record.id,
      currentlyOpenRecord: record.record,
      open: true,
    });
  };
  passwordClose = () => {
    this.setState({
      open: false,
      currentlyOpenRecord: {},
      passwords: "",
      confirmPwd: "",
      id: "",
    });
  };
  submitPassword = async (record: any) => {
    let hasErrors = false;
    if (this.state.passwords === "") {
      hasErrors = true;
      toast.error("Password is required");
    }
    if (this.state.confirmPwd === "") {
      hasErrors = true;
      toast.error("Confirm password is required");
    }

    if (this.state.passwords !== this.state.confirmPwd) {
      hasErrors = true;
      toast.error("Passwords does not match.");
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });
    const result = await updateUserPassword(record.id, this.state.confirmPwd);
    if (result?.data?.data?.updateUserPassword) {
      this.setState({
        loading: false,
      });
      toast.success("Password updated successfully");
      this.passwordClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Users List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Users List</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="usersTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr.No
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          First Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Last Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Email
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          SlackID
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Role
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Regional Manager
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Can Handle Service Ticket?
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Developer Status
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is Sales Person
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.editOpen}
          onClose={this.editClose}
          role="modal"
          center
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">Edit user</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className="form-label fs-4 fw-bold required">
                  First name
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  name="firstName"
                  id="firstName"
                  value={this.state.firstName}
                  onChange={(event: any) => {
                    this.setState({
                      firstName: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-12 mt-2 pt-2 w-100">
                <label className="form-label fs-4 fw-bold required">
                  Last name
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  name="lastName"
                  id="lastName"
                  value={this.state.lastName}
                  onChange={(event: any) => {
                    this.setState({
                      lastName: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-12 mt-2 pt-2 w-100">
                <label className="form-label fs-4 fw-bold required">Role</label>
                <Select
                  className="react-select"
                  classNamePrefix="my-react-select"
                  name="roleId"
                  defaultValue={{
                    label: this.state.role ?? "",
                    value: this.state.roleId,
                  }}
                  placeholder=""
                  onChange={(event: any) => {
                    this.setState({
                      roleId: event.value,
                    });
                  }}
                  options={Array.from(this.state.roleDB).map((value: any) => {
                    return {
                      value: value.id,
                      label: value.name,
                    };
                  })}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer mt-8 p-0 justify-content-start">
            <button
              type="submit"
              onClick={() => {
                return this.submitEdit(this.state.currentlyOpenRecord);
              }}
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              {this.state.loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please Wait..
                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                </span>
              )}
              {!this.state.loading && (
                <span className="indicator-label">UPDATE</span>
              )}
            </button>
          </div>
        </Modal>
        <Modal
          open={this.state.open}
          onClose={this.passwordClose}
          role="modal"
          center
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">Change Password</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12 mt-2 pt-2">
                <label className="form-label fs-4 fw-bold required">
                  New password
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  type="password"
                  name="passwords"
                  id="passwords"
                  value={this.state.passwords}
                  onChange={(event: any) => {
                    this.setState({
                      passwords: event.target.value,
                    });
                  }}
                />
              </div>
              <div className="col-12 mt-2 pt-2">
                <label className="form-label fs-4 fw-bold required">
                  Confirm Password
                </label>
                <input
                  className="form-control"
                  autoComplete="off"
                  type="password"
                  name="confirmPwd"
                  id="confirmPwd"
                  value={this.state.confirmPwd}
                  aria-required="true"
                  onChange={(event: any) => {
                    this.setState({
                      confirmPwd: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer mt-6 p-0 justify-content-start">
            <button
              type="submit"
              onClick={() => {
                return this.submitPassword(this.state.currentlyOpenRecord);
              }}
              className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
            >
              {this.state.loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Please Wait..
                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                </span>
              )}
              {!this.state.loading && (
                <span className="indicator-label">UPDATE</span>
              )}
            </button>
          </div>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}
