import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";

interface FileUploadProps {
  values: any;
  setFieldValue: (field: string, value: any) => void;
  fieldName: string;
  label: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  values,
  setFieldValue,
  fieldName,
  label,
}) => {
  const [files, setFiles] = useState<{ type: string; content: string; name: string }[]>([]);
  const [showCapture, setShowCapture] = useState(false);
  useEffect(() => {
    if (!Array.isArray(values[fieldName])) {
      setFieldValue(fieldName, []);
    }
  }, [values, fieldName, setFieldValue]);

  useEffect(() => {
    setFieldValue(fieldName, files);
  }, [files, fieldName, setFieldValue]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        getBase64(file)
          .then((result) => {
            setFiles((prevFiles) => [
              ...prevFiles,
              { type: file.type, content: result as string, name: file.name },
            ]);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleRemoveFile = (fileToRemove: string) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.content !== fileToRemove));
  };

  const handleCaptureImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      console.log("Captured file:", file); // Debugging log
      getBase64(file)
        .then((result) => {
          console.log("Base64 result:", result); // Debugging log
          setFiles((prevFiles) => [
            ...prevFiles,
            { type: file.type, content: result as string, name: file.name },
          ]);
        })
        .catch((err) => {
          console.error("Error converting file to Base64:", err);
        });
    }
  };

  return (
    <div className="wo-form-section mt-8">
      <div className="border-dotted border-2 border-gray-200 p-8 gap-2 mb-8">
        <div className="upload-section">
          <h3>{label}</h3>
          <div className="upload-box">
            {/* File Input */}
            <input
              type="file"
              className="file-input"
              multiple
              accept="image/png, image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
              onChange={handleFileChange}
            />

            {files.length > 0 ? (
              <div className="preview-container d-flex flex-wrap mt-3">
                {files.map((file, index) => (
                  <div
                    key={index}
                    className="img-box mt-3 ms-2 justify-content-center"
                  >
                    {file.type.startsWith("image/") ? (
                      <img
                        src={file.content}
                        width="150"
                        className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                      />
                    ) : (
                      <video
                        controls
                        src={file.content}
                        width="170"
                        className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                      />
                    )}
                    <button
                      onClick={() => handleRemoveFile(file.content)}
                      className="close-btn img-btn"
                    >
                      <i className="fa fa-close fs-5 p-1"></i>
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <div className="file-label text-center p-3">
                <i className="fa fa-cloud-upload cloud-icon fs-3"></i>
                <span className="d-block mt-2">Browse Files</span>
                <p className="text-muted">Drag and drop files here</p>
              </div>
            )}
          </div>

           {/* Camera Input */}
           <div className="mt-3">
              {!showCapture && <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowCapture(true)}
              >
                Capture Image
              </button>}

              {showCapture && <WebcamCapture setFiles={setFiles} files={files} setShowCapture={setShowCapture} />}
             
            </div>
        </div>
      </div>
    </div>
  );
};

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user"
};

const dataURLtoFile = (dataUrl: string, fileName: string): File => {
  const arr = dataUrl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

const WebcamCapture = ({
  setFiles,
  files,
  setShowCapture
}: {
  setFiles: React.Dispatch<React.SetStateAction<{ type: string; content: string; name: string }[]>>;
  files: { type: string; content: string; name: string }[];
  setShowCapture: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const webcamRef = React.useRef<Webcam>(null);
  const capture = React.useCallback(() => {
    if (!webcamRef.current) return;
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const fileName = `captured_image_${Date.now()}.jpg`;
      const file = dataURLtoFile(imageSrc, fileName);
      console.log("Captured file:", file); // Debugging log
      setFiles((prevFiles) => [
        ...prevFiles,
        { type: file.type, content: imageSrc as string, name: file.name },
      ]);
      setShowCapture(false)
    }
    return false;
  }, [webcamRef]);
  return (
    <>
      <Webcam
        audio={false}
        height={500}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={"100%"}
        videoConstraints={videoConstraints}
      />
      <button onClick={capture}>Capture photo</button>
    </>
  );
};

export default FileUpload;