import { ErrorMessage, FieldArray } from "formik";
import { useTicketDependencies } from "../../../providers/TicketDependenciesProvider";

const Step2 = (props: {
  formValues: any;
  handleChange: any;
}) => {
  const { formValues } = props;
  const { handleChange } = props;
  const { issueReportedDB } = useTicketDependencies();
  
  return (
    <>
       {formValues.ticketType !== 'MARKETING_OTHER' && (
      <div className="row">
        <div className="col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-6 pb-2">
          <div className="form-label required fw-bold fs-4">Issue Reported</div>
          <div className="row">
            <FieldArray name="issuesReported">
              {(arrayHelpers) => {
                return (
                  <>
                    {Array.from(issueReportedDB).map((value: any, index) => {
                      return (
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12" key={`issue_reported`+index}>
                          <label
                            key={value.id}
                            className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                          >
                            <input
                              id="issuesReported"
                              className="form-check-input mb-2 me-1"
                              type="checkbox"
                              name={value.id}
                              value={value.id}
                              checked={formValues.issuesReported.includes(value.id)}
                              onChange={(e) => {
                                if (e.target.checked)
                                  {
                                    arrayHelpers.push(e.target.value);
                                  }
                                else {
                                  let idx = -1;
                                  
                                  arrayHelpers.form.values.issuesReported.forEach(
                                    (e: any, index: number) => {
                                      if(e === value.id) {
                                        idx = index
                                      }
                                    }
                                  );
                                 
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                            <span className="ms-2 mb-1 fs-5 form-check-label">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      );
                    })}
                  </>
                );
              }}
            </FieldArray>
            <div className="text-danger text-align-top-center ms-2 mt-2">
              <ErrorMessage name="issuesReported" />
            </div>
          </div>
          {formValues.issuesReported.includes("other") && (
            <div className="col-xl-4 col-lg-6 col-12 p-2 pe-xl-5 pb-2 mt-3">
              <label className="form-label required fw-bold fs-4">
                Other issue Reported
              </label>
              <input
                className="form-control"
                autoComplete="off"
                id="issueReported_other"
                name="issueReported_other"
                value={formValues.issueReported_other}
                onChange={handleChange}
              />
            </div>
          )}
        </div>
      </div>)}
    </>
  );
};

export default Step2;
