import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateWorkOrderInstallationStep {
  freezerInstalled: string;
  footerInstalled: string;
  sanitizerInstalled: string;
  headerInstalled: string;
  boosterBarInstalled: string;
  drainageInstalled: string;
  internetConnected: string;
  flavorTagsInstalled: string;
  serviceStickerInstalled: string;
  oneTwoThreeStickersInstalled: string;
  palmTree: string;
  filledFreezer: string;
  pressureWithWeighingScale: string;
}

interface CreateWorkOrderTestPerformance {
  blendedFourSmoodies: string;
  sanitizer: string;
  getStatus: string;
  drainTest: string;
  waterPressureAbove3100: string;
}

interface CreateWorkOrderStaffTraining {
  makingSmoodies: string;
  benefitsOfSmoodi: string;
  accessingServiceMenu: string;
  cleaningWipers: string;
  checkingSanitizer: string;
  smoodiStore: string;
  squeezingCupsBeforePuttingInFreezer: string;
}

interface CreateWorkOrderMaterialInstallation {
  boosterPump: string;
  windowDecal : string;
  doorDecal : string;
  palmTree : string;
  neonSign : string;
  smoodiMat : string;
  videoOnTv : string;
  videoOnOutdoorSign : string;
  freezerMagnet : string;
  machineSideSticker : string;
  outdoorFlag : string;
  rollUpBanner : string;
}

interface CreateWorkOrderRequiredTask {
  action: string;
  priority: string;
  dueDate: Date;
}

interface createWorkOrderInput {
  deploymentManagerId: string;
  locationId: string;
  installerId: string | null;
  closeUpMedia: string[];
  freezerDoorOpenMedia: string[];
  storeSetUpMedia: string[];
  drainSetUpMedia: string[];
  serialNumberMedia: string[];
  comment: string;
  machineId1: string;
  isSecondMachineInstalled: Boolean;
  id: string;
  machineId2: string;
  firstName: string;
  lastName: string;
  installerName : string | null;
  date: Date;
  signature: string;
  storeContactName: string;
  storeContactPhoneNumber: string;
  storeContactRole: string;
  storeManagerSignature: string;
  installationSteps: CreateWorkOrderInstallationStep[];
  testPerformance: CreateWorkOrderTestPerformance[];
  staffTraining: CreateWorkOrderStaffTraining[];
  materialInstallation: CreateWorkOrderMaterialInstallation[];
  requiredTask: CreateWorkOrderRequiredTask[];
}

export async function createWorkOrder(input: createWorkOrderInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createWorkOrder (
            $input: CreateWorkOrderInput!
          ) {
            createWorkOrder(
              createWorkOrderInput: $input
            )
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

interface generateSignedUrlInput {
  file_name: string;
  file_type: string;
  folder_name: string;
}

export async function generateSignedUrl(input: generateSignedUrlInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation generateSignedUrl (
          $input: GenerateSignedUrlInput!
        ) {
          generateSignedUrl(
            generateSignedUrlInput: $input
          ) {
            url
          }
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getWorkOrders(where: {} = {}, pagination: {} | undefined = undefined,extraFilter:{} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getWorkOrders(  
              $where: WorkOrderResolver_GetWorkOrders_FilterInputType,
              $paginate:PaginatorArgs,
              $extraFilter: WorkOrderExtraFilterInput
              ) {
                  getWorkOrders(where: $where ,paginate: $paginate,extraFilter:$extraFilter) {
                      data {
                        id
                        deploymentManagerId
                        locationId
                        closeUpMedia
                        freezerDoorOpenMedia
                        storeSetUpMedia
                        drainSetUpMedia
                        serialNumberMedia
                        comment
                        machineId1
                        isSecondMachineInstalled
                        machineId2
                        firstName
                        lastName
                        status
                        date
                        signature
                        storeManagerSignature
                        storeContactName
                        storeContactPhoneNumber
                        storeContactRole
                        createdAt
                        installationSteps{
                        freezerInstalled
                        footerInstalled
                        sanitizerInstalled
                        headerInstalled
                        boosterBarInstalled
                        drainageInstalled
                        internetConnected
                        flavorTagsInstalled
                        serviceStickerInstalled
                        oneTwoThreeStickersInstalled
                        palmTree
                        filledFreezer
                        pressureWithWeighingScale
                        }
                        testPerformance{
                        blendedFourSmoodies
                        sanitizer
                        getStatus
                        drainTest
                        waterPressureAbove3100
                        }
                        staffTraining{
                        makingSmoodies
                        benefitsOfSmoodi
                        accessingServiceMenu
                        cleaningWipers
                        checkingSanitizer
                        smoodiStore
                        squeezingCupsBeforePuttingInFreezer
                        }
                        materialInstallation{
                        boosterPump
                        windowDecal
                        doorDecal
                        palmTree
                        neonSign
                        smoodiMat
                        videoOnTv
                        videoOnOutdoorSign
                        freezerMagnet
                        machineSideSticker
                        outdoorFlag
                        rollUpBanner
                        }
                        requiredTask{
                        action
                        priority
                        dueDate
                        }
                        }
                        totalPages
                        total
                        perPage
                        page
                    }
            }
            `,
      variables: { where, paginate: pagination,extraFilter: extraFilter },
    },
  });
  return data?.data?.data?.getWorkOrders;
}
export async function getWorkOrderById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($id:String!) {
          getWorkOrderById(id:$id) {
            id
            deploymentManagerId
            locationId
            closeUpMedia
            storeSetUpMedia
            freezerDoorOpenMedia
            drainSetUpMedia
            serialNumberMedia
            comment
            machineId1
            isSecondMachineInstalled
            machineId2
            firstName
            status
            lastName
            date
            signature
            storeManagerSignature
            storeContactName
            storeContactPhoneNumber
            storeContactRole
            installationSteps {
              workOrderId
              freezerInstalled
              footerInstalled
              sanitizerInstalled
              headerInstalled
              boosterBarInstalled
              drainageInstalled
              internetConnected
              flavorTagsInstalled
              serviceStickerInstalled
              oneTwoThreeStickersInstalled
              palmTree
              filledFreezer
              pressureWithWeighingScale
              createdAt
            }
            testPerformance {
              workOrderId
              blendedFourSmoodies
              sanitizer
              getStatus
              drainTest
              waterPressureAbove3100
              createdAt
            }
            staffTraining {
              workOrderId
              makingSmoodies
              benefitsOfSmoodi
              accessingServiceMenu
              cleaningWipers
              checkingSanitizer
              smoodiStore
              squeezingCupsBeforePuttingInFreezer
              createdAt
            }
            materialInstallation {
              workOrderId
              boosterPump
              windowDecal
              doorDecal
              palmTree
              neonSign
              smoodiMat
              videoOnTv
              videoOnOutdoorSign
              freezerMagnet
              machineSideSticker
              outdoorFlag
              rollUpBanner
              createdAt
            }
            requiredTask {
              workOrderId
              action
              priority
              dueDate
              createdAt
            }
            createdAt
            installerId
            installerName
          }
        }
      `,
      variables: {
        id,
      },
    },
  });
  return data?.data?.data?.getWorkOrderById;
}

export async function getWorkOrderByIdForCreateWorkOrderForm(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($id:String!) {
          getWorkOrderById(id:$id) {
            id
            status
            deploymentManagerId
            locationId
            machineId1
            firstName
            lastName
          }
        }
      `,
      variables: {
        id,
      },
    },
  });
  return data?.data?.data?.getWorkOrderById;
}