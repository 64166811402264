import React from "react";
import Moment from "moment";
import * as _ from "lodash";
import { Link } from "react-router-dom";

const LocationOverview = (props: { locations: any }) => {
  const { locations } = props;
  return (
    <div>
      <div className="row">
        <h3 className="fw-bolder my-2 ms-xl-9 ms-3 mt-9 card-title align-items-start flex-column">
          <span className="card-label fw-bolder fs-3">Locations</span>
          <div className="text-muted fw-semibold fs-7 mt-2">
            Total locations : {locations.length}
          </div>
        </h3>
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Location ID</th>
                      <th className="fs-5 text-muted">Name</th>
                      <th className="fs-5 text-muted">Address</th>
                      <th className="fs-5 text-muted">Region</th>
                      <th className="fs-5 text-muted">Market segment</th>
                      <th className="fs-5 text-muted">Location Type</th>
                      <th className="fs-5 text-muted">Deployment Manager</th>
                      <th className="fs-5 text-muted">Target Date</th>
                      <th className="fs-5 text-muted">Launch Date</th>
                      <th className="fs-5 text-muted">No.Of Blenders</th>
                      <th className="fs-5 text-muted">No.Of Freezers</th>
                      <th className="fs-5 text-muted">Sampling Time</th>
                      <th className="fs-5 text-muted">Sampling Days</th>
                      <th className="fs-5 text-muted">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.length > 0 ? (
                      Array.from(locations).map((location: any, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Link data-bs-toggle="tooltip" title="Click here to view location"
                                to={`/locations/list/history/${location.id}`}
                              >
                                {location.uuid ?? ""}
                              </Link>
                            </td>
                            <td>{location.name ?? ""}</td>
                            <td>{location.address ?? ""}</td>
                            <td>{location.region?.name ?? ""}</td>
                            <td>{location.marketSegment?.name ?? ""}</td>
                            <td>
                              {_.capitalize(
                                _.lowerCase(location.locationType?.name ?? "")
                              )}
                            </td>
                            <td>
                              {location.deploymentManager?.firstName ?? ""}
                            </td>
                            <td>
                              {Moment.utc(location.targetLaunchDate)
                                .local()
                                .format("YYYY-MM-DD")}
                            </td>
                            <td>
                              {location.launchDate ? Moment.utc(location.launchDate)
                                .local()
                                .format("YYYY-MM-DD"):""}
                            </td>
                            <td>{location.noOfBlenders ?? ""}</td>
                            <td>{location.noOfFreezers ?? ""}</td>
                            <td>{location.samplingRecommendedTime ?? ""}</td>
                            <td>{location.samplingRecommendedDays ?? ""}</td>
                            <td>
                              {Moment.utc(location.createdAt)
                                .local()
                                .format("YYYY-MM-DD HH:mm")}
                            </td>
                          </tr>
                        </>
                      ))
                    ) : (
                      <>
                        <tr>
                          <td
                            align="center"
                            className="text-center align-align-items-center justify-content-center"
                            colSpan={13}
                          >
                            No records found
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationOverview;
