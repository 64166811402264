/**
 * @generated SignedSource<<ec3090e8e52205c55c797f65a6c4334f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SalesInfoGetLocationQuery$variables = {
  id: string;
};
export type SalesInfoGetLocationQuery$data = {
  readonly getLocation: {
    readonly id: string;
    readonly name: string;
    readonly salesLeadId: string | null;
    readonly salesSupport1Id: string | null;
    readonly salesSupport2Id: string | null;
    readonly salesSupport2Persentage: number | null;
    readonly uuid: string | null;
  };
};
export type SalesInfoGetLocationQuery = {
  response: SalesInfoGetLocationQuery$data;
  variables: SalesInfoGetLocationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesLeadId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesSupport1Id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesSupport2Id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salesSupport2Persentage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SalesInfoGetLocationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SalesInfoGetLocationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "32ae6828e1a26dd38fe5e53314c66e1b",
    "id": null,
    "metadata": {},
    "name": "SalesInfoGetLocationQuery",
    "operationKind": "query",
    "text": "query SalesInfoGetLocationQuery(\n  $id: String!\n) {\n  getLocation(id: $id) {\n    id\n    uuid\n    name\n    salesLeadId\n    salesSupport1Id\n    salesSupport2Id\n    salesSupport2Persentage\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc71091bdc1c90c32f7e58af4db1bf9b";

export default node;
