import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateMarketingInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function createMarketing(input: CreateMarketingInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createMarketing (
            $input: CreateMarketingInput!
          ){
            createMarketing(
                createMarketingInput: $input
              ) 
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getMarketingList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query  getAllMarketing($where:MarketingsResolver_GetAllMarketing_FilterInputType){
                    getAllMarketing (where :$where){
                      id
                      name
                      isDefault
                      priority
                    }
                  }
                `,
      variables: { where },
    },
  });
  return data?.data?.data?.getAllMarketing;
}

interface UpdateMarketingInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function updateMarketing(input: UpdateMarketingInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateMarketing (
              $input: UpdateMarketingInput!
            ){
                updateMarketing(
                    updateMarketingInput: $input
                )
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
