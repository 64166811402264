import { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import clsx from "clsx";
import Moment from "moment";
import $ from "jquery";
import * as _ from "lodash";
import { getTickets } from "../../models/_tickets";
import Select, { components } from "react-select";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect from "react-select";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import ReAssignModal from './ReAssignModal'
import PriorityModal from './PriorityModal'
import SignatureModal from './SignatureModal'
import { Toaster } from "react-hot-toast";
import { getModuleDataDisplaySettingByKey, updateModuleDataDisplaySettingByKey } from "../../models/_user";
import ActivityModal from "./ScheduleTickets/ActivityModal";
import { Option } from '../../../_metronic/helpers/MySelect';
import Columns from '../../../_metronic/helpers/Columns';
import { Tooltip } from "react-tooltip";
import moment from "moment";
import {ChangeTicketTypeModal} from './ChangeTicketTypeModal';
const { Option: customOption } = components;

class TicketLogs extends Component<any, any> {
  readonly props: any;
  sigPad: any = {};
  constructor(props: any) {
    super(props);

    // getting current login user name
    const lsValue: any = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
    const currentLogin = JSON.parse(lsValue);
    const currentLoginName = currentLogin?.user?.firstName ?? "";
    const currentLoginId = currentLogin?.user?.id ?? "";
    // query parameter from dashboard
    const query = new URLSearchParams(window.location.search);
    const statusQuery = query.get("status");
    let statusSelected: any = [];
    if (statusQuery != null) {
      statusSelected.push({
        label: _.capitalize(_.lowerCase(statusQuery)),
        value: statusQuery,
      });
    }
    let searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const currentPage = searchParams.get('page');
    const applyDateFilterOn = searchParams.get('applyDate');
    const fromDate = searchParams.get('from_date');
    const toDate = searchParams.get('to_date');
    const serialNumber = searchParams.get('serial_number');
    const ticketId = searchParams.get('ticket_number');
    const ticketType = searchParams.get('ticket_type');
    const ticketTypeArray = ticketType ? ticketType.split(',').map((x) => x) : "";

    const machineId = searchParams.get('machine_id');
    const machineIdArray = machineId ? machineId.split(',').map((x) => x) : "";

    const assignToId = searchParams.get('assign_to_id');
    const assignToIdArray = assignToId ? assignToId.split(',').map((x) => x) : "";

    const reportedById = searchParams.get('reported_by_id');
    const reportedByIdArray = reportedById ? reportedById.split(',').map((x) => x) : "";

    const serviceType = searchParams.get('service_type');
    const serviceTypeArray = serviceType ? serviceType.split(',').map((x) => x) : "";

    const ticketSourceId = searchParams.get('ticket_source_id');
    const ticketSourceIdArray = ticketSourceId ? ticketSourceId.split(',').map((x) => x) : "";

    const priority = searchParams.get('priority');
    const priorityArray = priority ? priority.split(',').map((x) => x) : "";

    const status = searchParams.get('status');
    const statusArray = status ? status.split(',').map((x) => x) : "";


    const machineStatusIds = searchParams.get('machineStatusId');
    const machineStatusArray = machineStatusIds ? machineStatusIds.split(',').map((x) => x) : "";
    const isAppliedDefaultAssignToFilter = Array.from(searchParams.keys()).length < 1 ? true : assignToIdArray?.length > 0 && assignToIdArray.includes(currentLoginId) ? true : false;
    this.state = {
      downloadLoading: false,
      currentLoginName: currentLoginName,
      currentLoginId: currentLoginId,
      resetService: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      open: false,
      addLoading: false,
      filterLoading: false,
      ptOpen: false,
      sigOpen: false,
      assignOpen: false,
      loading: false,
      reAssignModal: false,
      priorityModalOpen: false,
      ticketsListObj: {},
      ticketsList: [],
      ticketTypeChangeModalOpen: false,
      machineDB: [],
      ticketSourceDB: [],
      assignToDB: [],
      machineStatusDB: [],
      ticketTypeSelected: null,
      ticketSourceSelected: null,
      machineSelected: null,
      prioritySelected: null,
      serviceTypeSelected: null,
      machineStatusSelected: null,
      machineStatus: machineStatusArray || "",
      filterSerialNumber: serialNumber || "",
      filterTicketId: ticketId || "",
      assignSelected: isAppliedDefaultAssignToFilter && [
        {
          label: currentLoginName,
          value: currentLoginId,
        },
      ],
      reportedBySelected: [],
      activityModalOpen: false,
      statusFilter: statusQuery,
      statusSelected: statusSelected,
      priority: "",
      currentlyAssignedToName: "",
      currentlyAssignedToId: "",
      assignToId: "",
      signatureModalOpen: false,
      reportedAt: new Date(),
      lastAssigneToComment: "",
      lastPriorityComment: "",
      currentlyOpenRecord: {},
      signaturePad: null,
      signatureName: "",
      signaturePadRef: "",
      currentTicketId: id || "",
      resultImage: [],
      filterMachine: machineIdArray || "",
      filterAssign: isAppliedDefaultAssignToFilter ? [currentLoginId] : assignToIdArray,
      filterReportedBy: reportedByIdArray || "",
      filterTicketSource: ticketSourceIdArray || "",
      filterPriority: priorityArray || "",
      filterStatus: statusArray || "",
      filterServiceType: serviceTypeArray || "",
      filterTicketType:  ticketTypeArray || "",
      filterFromDate: fromDate || "",
      filterToDate:toDate || "",
      applyDateFilterOn:applyDateFilterOn ?? "reported_at",
      finalUrl: "",
      page: currentPage || 0,
      perPage: 50,
      priorities: [
        {
          id: "HIGHEST",
          name: "Highest",
        },
        {
          id: "HIGH",
          name: "High",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "LOW",
          name: "Low",
        },
      ],
      serviceTypes: [
        {
          id: "ON_SITE",
          name: "On site",
        },
        {
          id: "REMOTE",
          name: "Remote",
        },
      ],
      ticketTypes: [
        {
          id: "REACTIVE_MAINTANANCE",
          name: "Reactive Maintenance",
        },
        {
          id: "PREVENTIVE_MAINTANANCE",
          name: "Preventive Maintenance",
        },
        {
          id: "MARKETING_OTHER",
          name: "Marketing",
        },
        {
          id: "LAB",
          name: "Lab"
        }
      ],
      statuses: [
        {
          id: "PENDING",
          name: "Pending",
        },
        {
          id: "ON_HOLD",
          name: "On-hold",
        },
        {
          id: "IN_PROCESS",
          name: "In-Process",
        },
        {
          id: "COMPLETED",
          name: "completed",
        },
        {
          id: "ARCHIVED",
          name: "Archived",
        },
      ],
      moduleKeyName: 'ticket_log',
      selectedColumns: [],
      columnData: [
        { id: 0, label: 'Ticket ID', key: 'index' },
        { id: 1, label: 'Location Name', key: 'locationName' },
        { id: 2, label: 'Ticket Type', key: 'ticketType' },
        { id: 3, label: 'Machine', key: 'machine' },
        { id: 4, label: 'Serial Number', key: 'serialnumber' },
        { id: 5, label: 'Ticket Sources', key: 'ticketSource' },
        { id: 6, label: 'Service Type', key: 'serviceType' },
        { id: 7, label: 'Days Open', key: 'daysOpen' },
        { id: 8, label: 'Comment', key: 'comment' },
        { id: 9, label: 'Chat Description', key: 'chatDescription' },
        { id: 10, label: 'Created By', key: 'ticketCreatedBy' },
        { id: 11, label: 'Priority', key: 'priority' },
        { id: 12, label: 'Reported At', key: 'reportedAt' },
        { id: 14, label: 'End Time', key: 'endTime' },
        { id: 15, label: 'Assign to', key: 'assignTo' },
        { id: 16, label: 'Status', key: 'status' },
        { id: 17, label: 'Action', key: 'action' },
        { id: 18, label: 'Reported By', key: 'reportedBy' }
      ]
    };
  }

  async componentWillMount() { }

  async componentDidUpdate() {
    await this.handleTicketLogFilterSet()
   }

  async componentDidMount() {
    const _ = this;
    $("body").on("click", ".reassignBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        reAssignModal: true,
        currentTicketId: record

      })

    });

    $("body").on("click", ".priorityBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        priorityModalOpen: true,
        currentTicketId: record
      })
    });

    $("body").on("click", ".ticketTypeBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        ticketTypeChangeModalOpen: true,
        currentTicketId: record
      })
    });

    $("body").on("click", ".jobOrderBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        activityModalOpen: true,
        currentTicketId: record
      })
    });


    $("body").on("click", ".signatureBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        signatureModalOpen: true,
        currentTicketId: record
      })
    });

    await this.getFilterRecords();

    this.renderDataTable([]);
  }
  getCustomFilter = () => {
    let customFilter: any = {};

    if (this.state.machineStatus.length > 0) {
      customFilter["machineStatusId"] = this.state.machineStatus;
    }
    if (this.state.filterSerialNumber) {
      customFilter["serial_number"] = this.state.filterSerialNumber
    }

    return customFilter
  }
  getFilter = () => {
    let filter: any = {};

    if (this.state.filterTicketType.length > 0) {
      filter["ticket_type"] = { in: this.state.filterTicketType };
    }
    if (this.state.filterMachine.length > 0) {
      filter["machine_id"] = { in: this.state.filterMachine };
    }
    if (this.state.filterTicketId) {
      const numericValue = this.state.filterTicketId.replace(/\D/g, '');
      //const ticketId = this.state.filterTicketId.replace(/^SMM/, ''); 
      filter["ticket_number"] = { eq: numericValue };
    }
    if (this.state.filterAssign.length > 0) {
      filter["assign_to_id"] = { in: this.state.filterAssign };
    }
    if (this.state.filterReportedBy.length > 0) {
      filter["reported_by_id"] = { in: this.state.filterReportedBy };
    }
    if (this.state.filterServiceType.length > 0) {
      filter["service_type"] = { in: this.state.filterServiceType };
    }
    if (this.state.filterTicketSource.length > 0) {
      filter["ticket_source_id"] = { in: this.state.filterTicketSource };
    }
    if (this.state.filterPriority.length > 0) {
      filter["priority"] = { in: this.state.filterPriority };
    }
    if (this.state.filterStatus.length > 0) {
      filter["status"] = { in: this.state.filterStatus };
    }
    if (this.state.applyDateFilterOn !== "") {
      let dateFilterAppliedOnLabel = "reported_at";
      switch (this.state.applyDateFilterOn) {
        case 'createdAt':
          dateFilterAppliedOnLabel = 'created_at';
          break;
        case 'reportedAt':
          dateFilterAppliedOnLabel = 'reported_at';
          break;
        case 'closedAt':
          dateFilterAppliedOnLabel = 'closing_date_and_time';
          break;
      }

      if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
        filter[dateFilterAppliedOnLabel] = {};
      }
      if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
        filter[dateFilterAppliedOnLabel]["gt"] = new Date(
          this.state.filterFromDate
        ).toISOString();
      } else if (
        this.state.filterFromDate === "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["lt"] = new Date(
          this.state.filterToDate
        ).toISOString();
      } else if (
        this.state.filterFromDate !== "" &&
        this.state.filterToDate !== ""
      ) {
        filter[dateFilterAppliedOnLabel]["between"] = [
          new Date(this.state.filterFromDate).toISOString(),
          new Date(this.state.filterToDate).toISOString(),
        ];
      }
    }
    return filter
  }
  getURL = (baseURL: string, customerListFilter?: any) => {
    const isApplyDateFilter = this.state.applyDateFilterOn;
    const isApplyFromDateFilter = this.state.filterFromDate;
    const isApplyToDateFilter = this.state.filterToDate;
    const isApplyTicketTypeFilter = this.state.filterTicketType;
    const isApplyReportedByFilter = this.state.filterReportedBy;
    const isApplyServiceTypeFilter = this.state.filterServiceType;
    const isApplyStatusFilter = this.state.filterStatus;
    const isApplyPriorityManager = this.state.filterPriority;
    const isApplyTicketSourceFilter = this.state.filterTicketSource;
    const isApplyAssignFilter = this.state.filterAssign;
    const isApplyMachineFilter = this.state.filterMachine;
    const isApplyMachineStatusFilter = this.state.machineStatus;
    const isApplySerialNumberFilter = this.state.filterSerialNumber;
    const isApplyTicketIdFilter = this.state.filterTicketId;
    // if(this.state.page || this.state.page == 0){
    //   baseURL += `?page=${this.state.page}`
    // }
    if (isApplyDateFilter) {
      baseURL += `&applyDate=${isApplyDateFilter}`
    }
    if (isApplyFromDateFilter) {
      let date = moment(isApplyFromDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&from_date=${encodedDate}`
    }
    if (isApplyToDateFilter) {
      let date = moment(isApplyToDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&to_date=${encodedDate}`
    }
    if (isApplyTicketTypeFilter && isApplyTicketTypeFilter.length > 0) {
      const ticketTypeIds = isApplyTicketTypeFilter.join(',');
      baseURL += `&ticket_type=${ticketTypeIds}`
    }
    if (isApplyServiceTypeFilter?.length > 0) {
      const serviceTypeIds = isApplyServiceTypeFilter.join(',');
      baseURL += `&service_type=${serviceTypeIds}`
    }
    if (isApplyPriorityManager?.length > 0) {
      const priorityIds = isApplyPriorityManager.join(',');
      baseURL += `&priority=${priorityIds}`
    }
    if (isApplyTicketSourceFilter?.length > 0) {
      const ticketSourceIds = isApplyTicketSourceFilter.join(',');
      baseURL += `&ticket_source_id=${ticketSourceIds}`
    }
    if (isApplyAssignFilter?.length > 0) {
      const assignIds = isApplyAssignFilter.join(',');
      baseURL += `&assign_to_id=${assignIds}`
    }
    if (isApplyReportedByFilter?.length > 0) {
      const reportedByIds = isApplyReportedByFilter.join(',');
      baseURL += `&reported_by_id=${reportedByIds}`
    }
    if (isApplyMachineFilter?.length > 0) {
      const machineIds = isApplyMachineFilter.join(',');
      baseURL += `&machine_id=${machineIds}`
    }
    if (isApplyStatusFilter?.length > 0) {
      const StatusIds = isApplyStatusFilter.join(',');
      baseURL += `&status=${(StatusIds)}`;
    }
    if (isApplyMachineStatusFilter?.length > 0) {
      const machineStatusIds = isApplyMachineStatusFilter.join(',');
      baseURL += `&machineStatusId=${(machineStatusIds)}`;
    }
    if (isApplySerialNumberFilter) {
      let decodedSerialNumber = decodeURIComponent(isApplySerialNumberFilter); 
      let encodedSerialNumber = encodeURIComponent(decodedSerialNumber); 
      baseURL += `&serial_number=${encodedSerialNumber}`; 
    }
    if (isApplyTicketIdFilter) {
      const ticketId = isApplyTicketIdFilter.replace(/^SMM/, ''); 
      baseURL += `&ticket_id=${ticketId}`; 
    }
    return baseURL;
  }

  // rendering datatable jquery
  renderDataTable = async (rows: any) => {
    const _this = this;
    const filter = this.getFilter()
    let customFilter = this.getCustomFilter()
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    await this.getColumnData();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $("#ticketsTable").DataTable().destroy();

    $(document).ready(function () {
      let table = $("#ticketsTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Bearer " + authToken);
          },
          "data": function (d: any) {
            const gql = ` query getTickets(
              $where: TicketResolver_GetTickets_FilterInputType, 
              $paginate: PaginatorArgs,
              $customFilter:TicketCustomFilterType!
                   ) {
                  getTickets(where: $where, paginate: $paginate,customFilter:$customFilter) {
                      data {
                      id
                      uuid
                      ticketType
                      machineId
                      chatDescription
                      signatureName
                      reportedByUser {
                        id
                        firstName
                        lastName
                      }  
                      reportedAt
                      priority
                      issuesReported {
                        id
                        name
                      }
                      machineServices {
                        id
                        name
                      }
                      machineServicesDone {
                        id
                        name
                      }
                      operationsDone {
                        id
                        name
                      }
                      operations {
                        id
                        name
                      }
                      marketingOrOthers {
                        id
                        name
                      }
                      marketingMaterials {
                        id
                        name
                      }
                      comment
                      lastStatusComment
                      lastAssigneToComment
                      status
                      howTicketClosed
                      closingDateAndTime
                      timeSpentOnTask
                      travelTime
                      customerSatisfaction
                      cleanliness
                      trafficRate
                      staffEnthusiasm
                      createdAt
                      updatedAt
                      machine {
                        id
                        serialNumber
                        name
                        locationId
                        location{
                          name
                          customer{
                            isVip
                          }
                        }
                      }
                      ticketSource {
                        id
                        name
                      }
                      assignTo {
                        id
                        firstName
                        lastName
                      }
                      createdBy {
                        id
                        firstName
                        lastName
                      }
                      subTasks {
                        id
                        uuid
                        priority
                        issueType {
                          id
                          name
                        }
                        comment
                        status
                        createdAt
                        assignTo {
                          id
                          firstName
                          lastName
                        }
                      }
                      }
                      totalPages 
                      total
                      perPage
                      page 
                }
            }`;

            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            const query = {
              "operationName": null,
              "query": gql,
              "variables": {
                "where": filter,
                paginate: {

                  "page": currentPage,
                  "per_page": length

                },
                "customFilter": customFilter
              }
            };

            return JSON.stringify(query);
          },
          "dataSrc": function (json) {

            json.recordsTotal = json.data.getTickets.total;
            json.recordsFiltered = json.data.getTickets.total;

            const rows: any[] = [];
            const records = json.data.getTickets.data;
            for (const record of records) {

              let locationName = "";
              if (record?.machine?.location?.name) {
                locationName = record.machine.location.name;
              }
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let updatedAt = "";
              if (record.updatedAt) {
                updatedAt = Moment.utc(record.updatedAt)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let reportedAt = "";
              if (record.reportedAt) {
                reportedAt = Moment.unix(record.reportedAt / 1000)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              let closingDateAndTime = "";
              if (record.closingDateAndTime) {
                closingDateAndTime = Moment.unix(record.closingDateAndTime / 1000)
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss");
              }
              const howTicketClosed = _.lowerCase(record.howTicketClosed);
              let firstDate = Moment();
              if (record.status === "COMPLETED") {
                firstDate = Moment(closingDateAndTime);
              }

              let daysOpen = firstDate.diff(Moment(reportedAt), "days");
              const tempRecord: any = {
                id: record.uuid,
                locationName: locationName,
                locationId: record.machine?.locationId,
                machineId: record.machineId,
                serialnumber: record.machine?.serialNumber,

                ticketType: _.capitalize(_.lowerCase(record.ticketType)),
                machine: record.machine?.name ?? "",

                ticketSource: record.ticketSource?.name ?? "",
                serviceType: _.capitalize(_.lowerCase(record.serviceType)),
                reportedAt: reportedAt,
                createdAt,
                daysOpen: daysOpen,
                comment: record.comment,
                issueReported: record.issuesReported?.map(
                  (element: any) => element.name).join(","),
                machineServicesNeeded: record.machineServices?.map(
                  (element: any) => element.name).join(","),
                operationsNeeded: record.operations?.map(
                  (element: any) => element.name).join(","),
                marketingPurpose: record.marketingOrOthers?.map(
                  (element: any) => element.name).join(","),
                marketingMaterialsUsed: record.marketingMaterials?.map(
                  (element: any) => element.name).join(","),
                machineServicesDone: record.machineServicesDone?.map(
                  (element: any) => element.name).join(","),
                operationsDone: record.operationsDone?.map(
                  (element: any) => element.name).join(","),
                chatDescription: record.chatDescription,
                ticketCreatedBy: record.createdBy?.firstName ?? "",
                priority: _.capitalize(_.lowerCase(record.priority)),
                assignTo: record.assignTo?.firstName ?? "",
                reportedBy: record.reportedByUser?.firstName ?? "",
                status: _.capitalize(_.lowerCase(record.status)),
                lastAssigneToComment: record.lastAssigneToComment ?? "",
                lastStatusComment: record.lastStatusComment ?? "",
                endTime: closingDateAndTime,
                howTicketClosed: _.capitalize(_.lowerCase(howTicketClosed)),
                timeSpentOnTask: record.timeSpentOnTask ?? "",
                travelTime: record.travelTime ?? "",
                cleanliness: _.capitalize(_.lowerCase(record.cleanliness)),
                customerSatisfaction: _.capitalize(
                  _.lowerCase(record.customerSatisfaction)
                ),
                trafficRate: _.capitalize(_.lowerCase(record.trafficRate)),
                staffEnthusiasm: _.capitalize(_.lowerCase(record.staffEnthusiasm)),
                signatureName: record.signatureName ?? "",
                updatedAt,
                record: record,
                recordId: record.id,
                isVip: record.machine?.location?.customer?.isVip,
                scheduleStartDate: record.scheduleStartDate
              };

              const subRecords = record.subTasks ?? "";
              for (const subRecord of subRecords) {
                subRecord["record"] = { ...subRecord };
              }
              rows.push(tempRecord);
            }
            // this.setState({
            //   ticketsList: rows,
            // });
            return rows;
          }
        },
        "initComplete": function (settings, json) {
          table.page(Number(_this.state.page)).draw(false);

        },
        columns: [
          {
            name: "index",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            className: "details-control ",
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view ticket"><a class="clickable_entry" data-value=\'' + JSON.stringify(row) + '\' href="list/viewticket/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.id +
                "</a>&nbsp;&nbsp;&nbsp</div>";
              return returnsData;
            },
          },
          {
            name: "locationName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('locationName'),
            render: function (data, type, row) {
              let returnData = ""
              if (row.isVip === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                  row.locationId + '?page=' + _this.state.page + _this.state.finalUrl +
                  '"> <i class="fa fa-crown text-warning me-1"></i>' +
                  row.locationName +
                  '</a></div>'
              } else {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                  row.locationId + '?page=' + _this.state.page + _this.state.finalUrl +
                  '"> ' +
                  row.locationName +
                  "</div></a>";
              }
              return returnData;
            },
          },
          {
            name: "ticketType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('ticketType'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view ticket"><a href="list/viewticket/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.ticketType +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "machine",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('machine'),
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view machine"><a class=" text-primary" href="/machines/list/history/' +
                row.machineId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.machine +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "serialnumber",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('serialnumber'),
            render: function (data, type, row) {
              return row.serialnumber;

            },
          },
          {
            name: "ticketSource",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('ticketSource'),
            render: function (data, type, row) {
              return row.ticketSource;
            },
          },
          {
            name: "serviceType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('serviceType'),
            render: function (data, type, row) {
              return row.serviceType;
            },
          },
          {
            name: "daysOpen",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('daysOpen'),
            render: function (data, type, row) {
              return row.daysOpen;
            },
          },
          {
            name: "comment",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('comment'),
            render: function (data, type, row) {
              return row.comment;
            },
          },
          {
            name: "chatDescription",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('chatDescription'),
            render: function (data, type, row) {
              return row.chatDescription;
            },
          },
          {
            name: "ticketCreatedBy",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('ticketCreatedBy'),
            render: function (data, type, row) {
              return row.ticketCreatedBy;
            },
          },
          {
            name: "priority",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('priority'),
            render: function (data, type, row) {
              return row.priority;
            },
          },
          {
            name: "reportedAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('reportedAt'),
            render: function (data, type, row) {
              return row.reportedAt;
            },
          },
          {
            name: "reportedBy",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('reportedBy'),
            render: function (data, type, row) {
              return row.reportedBy;
            },
          },
          {
            name: "endTime",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('endTime'),
            render: function (data, type, row) {
              return row.endTime;
            },
          },
          {
            name: "assignTo",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('assignTo'),
            render: function (data, type, row) {
              return row.assignTo;
            },
          },
          {
            name: "status",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('status'),
            render: function (data, type, row) {
              return row.status;
            },
          },
          {
            name: "action",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('action'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-ticket") === true) {
                if (row.status.toLowerCase() === "completed") {
                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to status change"><a class="btn btn-secondary btn px-4 py-1 m-1" href="list/statuschange/' +
                    row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                    '">Status Change</a></div>';

                  returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to change ticket type"><button class="btn btn-secondary btn ticketTypeBtn px-4 py-1 m-1" data-recordId=\'' +
                  row.recordId +
                  "'>Ticket Type Change</button></div>";
                }
                if (row.status.toLowerCase() !== "completed" && row.status.toLowerCase() !== "archived") {
                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to edit ticket"><a class="btn btn-secondary btn px-4 py-1 m-1" href="edit/' +
                    row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                    '">Edit Ticket</a></div>';

                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to status change"><a class="btn btn-secondary btn px-4 py-1 m-1" href="list/statuschange/' +
                    row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                    '">Status Change</a></div>';

                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to re-assign"><button class="btn btn-secondary btn reassignBtn px-4 py-1 m-1" data-recordId=\'' +
                    row.recordId +
                    "'> Re-Assign </button></div>";

                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to change priority"><button class="btn btn-secondary btn priorityBtn px-4 py-1 m-1" data-recordId=\'' +
                    row.recordId +
                    "'> Priority </button></div>";

                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to change ticket type"><button class="btn btn-secondary btn ticketTypeBtn px-4 py-1 m-1" data-recordId=\'' +
                    row.recordId +
                    "'>Ticket Type Change</button></div>";
                }
              }
              if (permissions?.includes("create-ticket") === true) {
                if (row.status.toLowerCase() !== "completed" && row.status.toLowerCase() !== "archived") {
                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to create task"><a class="btn btn-secondary btn px-4 py-1 m-1" href="list/task/create/' +
                    row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                    '">Create Task</a></div>';
                }
              }
              if (permissions?.includes("update-ticket") === true) {
                if (
                  row.status.toLowerCase() === "completed" &&
                  row.signatureName === ""
                ) {
                  returnData +=
                    '<div data-bs-toggle="tooltip" title="Click here to add signature"><button class="btn btn-primary signatureBtn btn px-4 py-1 m-1" data-recordId=\'' +
                    row.recordId +
                    "'><i class='fa fa-signature' style='font-size:1rem;'></i></button></div>";
                }
              }
              if (row.scheduleStartDate !== null) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view job order activity"><button class="btn btn-primary btn jobOrderBtn px-4 py-1 m-1" data-recordId=\'' +
                  row.recordId +
                  "'> <i class='fa fa-tasks'></i> </button></div>";
              }
              returnData +=
                '<div data-bs-toggle="tooltip" title="Click here to view ticket"><button class="btn btn-primary btn px-4 py-1 m-1"><a href="list/viewticket/' +
                row.recordId + '?page=' + _this.state.page + _this.state.finalUrl +
                '"><i class="fa fa-eye"></i></a></button></div>';
              return returnData;
            },
          },
        ],
        processing: true,
        dom: '<"row mb-2"<"col-md-6 text-left"i><"col-md-6 text-right"p>>rt<"row mb-2"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data: any, dataIndex) {
          if (data?.record?.subTasks?.length === 0) {
            $(row).find("td.details-control").removeClass("details-control");
          }
          const title: any = [];
          $("#ticketsTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      table.on('page', function () {
        const currentPage = table.page.info();

        _this.setState({
          page: currentPage.page
        })

      });
      $(document)[0].oncontextmenu = function () { return false; }

      $('#ticketsTable tbody').mousedown(function (e) {
        if (e.buttons === 1) return true;
        e.preventDefault();
        const top = e.clientY;

        const left = e.clientX;
        const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
        let record;
        if (recordString) {
          record = JSON.parse(recordString)
        }
        if (record) {
          $('#view_record_link').attr('href', `list/viewticket/${record.recordId}?page=${_this.state.page}${_this.state.finalUrl}`)
          if (record.record.scheduleStartDate !== null) {
            $('#activity_record_link').show().attr('data-recordId', record.recordId)
          } else {
            $('#activity_record_link').hide().attr('data-recordId', '')
          }

          if (record.record.status !== "COMPLETED" && record.record.status !== "ARCHIVED") {
            $('#edit_ticket_link').show().attr('href', `edit/${record.recordId}`)
            $('#status_record_link').show().attr('href', `list/statuschange/${record.recordId}`)
            $('#create-task_record_link').show().attr('href', `list/task/create/${record.recordId}`)
            $('#re-assign_record_link').show().attr('data-recordId', record.recordId)
            $('#priority_record_link').show().attr('data-recordId', record.recordId)
            $('#ticket_type_record_link').show().attr('data-recordId', record.recordId)
          }
          else {
            $('#edit_ticket_link').hide().attr('href', ``)
            $('#status_record_link').hide().attr('href', ``)
            $('#create-task_record_link').hide().attr('href', ``)
            $('#re-assign_record_link').hide().attr('data-recordId', '')
            $('#priority_record_link').hide().attr('data-recordId', '')
            $('#ticket_type_record_link').hide().attr('data-recordId', '')
          }
          if (record.record.status !== "ARCHIVED") {
            $('#status_record_link').show().attr('href', `list/statuschange/${record.recordId}`)
          }
          else {
            $('#status_record_link').hide().attr('href', `list/statuschange/${record.recordId}`)
          }
          if (record.record.status === "COMPLETED" && record.record.signatureName === null) {
            $('#signature_record_link').show().attr('data-recordId', record.recordId)
          }

          else {
            $('#signature_record_link').hide().attr('data-recordId', '')
          }
          $("#context-menu").css({
            top: top,
            left: left
          }).show();
        }

        return false;

      })
      $('html').click(function () {
        $("#context-menu").hide();
      })

      $("#context-menu a").click(function (e) {

      });
      function format1(row: any) {
        let tabla = `<table class="sub-row" cellpadding="5" cellspacing="0" style="border-collapse: separate;">
              <thead style="border-top: none !important">
                <tr>
                    <th>
                      Task Id
                    </th>
                    <th>
                      Assign To
                    </th>
                    <th>
                      Issue Type
                    </th>
                    <th>
                      Comments
                    </th>
                    <th>
                      Created At
                    </th>
                    <th>
                      Priority
                    </th>
                    <th>
                      Status
                    </th>
                    <th>
                      Action
                    </th>
                </tr>
              </thead>`;
        if (row?.record?.subTasks?.length > 0) {
          let counter = 0;
          for (const subTask of row.record.subTasks) {
            counter++;
            let returnData = "";
            if (permissions?.includes("update-ticket") === true) {
              if (subTask.status.toLowerCase() !== "completed") {
                returnData +=
                  '<a class="btn btn-secondary btn px-4 py-1 m-1"  href="list/statuschange/' +
                  subTask.id +
                  '">Status Change</a>';
                returnData +=
                  '<button class="btn btn-secondary btn reassignBtn px-4 py-1 m-1" data-recordId=\'' +
                  subTask.id +
                  "'> Re-Assign </button>";
                returnData +=
                  '<button class="btn btn-secondary btn priorityBtn px-4 py-1 m-1" data-recordId=\'' +
                  subTask.id +
                  "'> Priority </button>";
                returnData +=
                  '<button class="btn btn-secondary btn ticketTypeBtn px-4 py-1 m-1" data-recordId=\'' +
                  subTask.id +
                  "'> Ticket Type Change </button>";
              }
            }
            tabla += `<tbody>
                      <tr>
                        <td data-label="Task Id">${row.record.uuid
              } - ${counter}</td>
                        <td data-label="Assign To">${subTask.assignTo?.firstName ?? ""
              }</td>
                        <td data-label="Issue Type">${subTask.issueType?.name ?? ""
              }</td>
                        <td data-label="Comments">${subTask.comment}</td>
                        <td data-label="Created At">${Moment.utc(
                subTask.createdAt
              )
                .local()
                .format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td data-label="Priority">${_.capitalize(
                  _.lowerCase(subTask.priority)
                )}</td>
                        <td data-label="Status">${_.capitalize(
                  _.lowerCase(subTask.status)
                )}</td>
                        <td data-label="Action">
                          ${returnData}
                        </td>
                      </tr>
                      </tbody>`;
          }
        }
        tabla += `</table>`;
        return tabla;
      }
      $("#ticketsTable tbody").on("click", "td.details-control", function () {
        const tr = $(this).parents("tr");
        const rows = table.row(tr);

        if (rows.child.isShown()) {
          rows.child.hide();
          tr.removeClass("shown");
        } else {
          rows.child(format1(rows.data())).show();
          tr.addClass("shown");
        }
      });
      _this.displaySelectedColumns(table);
    });
    $(".dt-buttons").remove();
  };

  getFilterRecords = async () => {
    const {machines,machineStatus,ticketSources,users} = this.props;
    this.setState({
      machineStatusDB: machineStatus,
      machineDB: machines,
      ticketSourceDB: ticketSources,
      assignToDB: users,
    });
  };

  handleDownloadExcel = async () => {
    this.setState({
      downloadLoading: true
    })
    const filter = this.getFilter();

    let customFilter = this.getCustomFilter()

    const result: any = await getTickets(filter, undefined, customFilter);

    const excelRecords = [];
    for (const record of result.data) {

      let locationName = "";
      if (record?.machine?.location?.name) {
        locationName = record.machine.location.name;
      }
      let reportedAt = "";
      if (record.reportedAt) {
        reportedAt = Moment.unix(record.reportedAt / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      let closingDateAndTime = "";
      if (record.closingDateAndTime) {
        closingDateAndTime = Moment.unix(record.closingDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const howTicketClosed = _.lowerCase(record.howTicketClosed);
      let firstDate = Moment();
      if (record.status === "COMPLETED") {
        firstDate = Moment(closingDateAndTime);
      }
      let daysOpen = firstDate.diff(Moment(reportedAt), "days");
      //const allKeys = this.state.columnData.map((col: any) => col.key);
      //const selectedColumns = this.state.selectedColumns?.length > 0 ? this.state.selectedColumns : allKeys;
      const tempRecord = {
        id: record.uuid,
        "Location name": locationName,
        "Ticket type": _.capitalize(_.lowerCase(record.ticketType)),
        "Machine": record.machine?.name ?? "",
        "Serial number": record.machine?.serialNumber,

        "Ticket source": record.ticketSource?.name ?? "",
        "Service type": _.capitalize(_.lowerCase(record.serviceType)),
        "Reported at": Moment.unix(record.reportedAt / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        "Reported by": (record.reportedByType === 'INTERNAL') ? record?.reportedByUser?.firstName + ' ' + (record?.reportedByUser?.lastName ? record?.reportedByUser?.lastName : '') : record?.reportedByName,
        "Reported by (Email)": (record.reportedByType === 'EXTERNAL') ? record?.reportedByEmail : '',
        "Reported by (Phone)": (record.reportedByType === 'EXTERNAL') ? record?.reportedByPhone : '',
        "Days open": daysOpen,
        "Comment": record.comment,
        "Issue reported": record.issuesReported.length > 0 ? record.issuesReported?.map(
          (element: any) => element.name).join(",") : record?.issueReportedOtherInput,
        "Machine services needed": record.machineServices.length > 0 ? record.machineServices?.map(
          (element: any) => element.name).join(",") : record?.machineServiceOtherInput,
        "Operations needed": record.operations.length > 0 ? record.operations?.map(
          (element: any) => element.name).join(",") : record?.operationOtherInput,
        "Marketing purpose": record.marketingOrOthers.length > 0 ? record.marketingOrOthers?.map(
          (element: any) => element.name).join(",") : record?.marketingOtherInput,
        "Marketing materials used": record.marketingMaterials?.map(
          (element: any) => element.name).join(","),
        "Diagnosis done": record.diagnosisDone.length > 0 ? record.diagnosisDone?.map(
          (element: any) => element.name).join(",") : record?.diagnosisOtherInput,
        "Machine services done": record.machineServicesDone.length > 0 ? record.machineServicesDone?.map(
          (element: any) => element.name).join(",") : record?.machineServiceOtherInput,
        "Operations done": record.operationsDone.length > 0 ? record.operationsDone?.map(
          (element: any) => element.name).join(",") : record.operationOtherInput,
          "Last status comment": record.lastStatusComment ?? "",
          "Last assigne comment": record.lastAssigneToComment ?? "",
        "Chat description": record.chatDescription,
        "Created by": record.createdBy?.firstName + ' ' + record.createdBy?.lastName,
        "Priority": _.capitalize(_.lowerCase(record.priority)),
        "Assign to": record.assignTo?.firstName + ' ' + record.assignTo?.lastName,
        "Status": _.capitalize(_.lowerCase(record.status)),
        "End time": Moment.unix(record.closingDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        "How ticket closed": _.capitalize(_.lowerCase(howTicketClosed)),
        "Time spent on task": record.timeSpentOnTask ?? "",
        "Travel time": record.travelTime ?? "",
        "Cleanliness": _.capitalize(_.lowerCase(record.cleanliness)),
        "Customer satisfaction": _.capitalize(
          _.lowerCase(record.customerSatisfaction)
        ),
        "Traffic rate": _.capitalize(_.lowerCase(record.trafficRate)),
        "Staff enthusiasm": _.capitalize(_.lowerCase(record.staffEnthusiasm)),
        "Signature": record.signatureName ?? "",

        "Created at": Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
        "Updated at": Moment.utc(record.updatedAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss"),
      };
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "Tickets_list");
    this.setState({
      downloadLoading: false
    })
  };

  // filter data submit
  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const url = this.getURL(`/tickets/list?page=${this.state.page}`);
    window.history.replaceState({}, '', url);
    this.setState({
      finalUrl: this.getURL('')
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterStatus: [],
      statusFilter: "",
      filterTicketType: "",
      filterMachine: "",
      filterTicketSource: "",
      currentLoginName: "",
      filterServiceType: "",
      machineStatus: "",
      filterSerialNumber: "",
      filterTicketId:"",
      filterPriority: "",
      filterAssign: [this.state.currentLoginId],  
      statusSelected: [],
      filterReportedBy: [],
      reportedBySelected: [],
      machineStatusSelected: null,
      ticketTypeSelected: null,
      machineSelected: null,
      prioritySelected: null,
      serviceTypeSelected: null,
      assignSelected: [],
      ticketSourceSelected: null,
      applyDateFilterOn:"reportedAt",
    });
    window.history.replaceState({}, '', "/tickets/list");
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };


  refreshTable = async () => {
    await this.getFilterRecords();
    this.renderDataTable([]);
  }
  handleCloseReAssign = () => {
    this.setState({
      reAssignModal: false,
      currentTicketId: ""
    })
  }
  handleCloseTicketType = () => {
    this.setState({
      ticketTypeChangeModalOpen: false,
      currentTicketId: ""
    })
  }
  handleClosePriority = () => {
    this.setState({
      priorityModalOpen: false,
      currentTicketId: ""
    })
  }
  handleCloseActivity = () => {
    this.setState({
      activityModalOpen: false,
      currentTicketId: ""
    })
  }
  handleCloseSiganture = () => {
    this.setState({
      signatureModalOpen: false,
      currentTicketId: ""
    })
  }
  // async getColumnData() {
  getColumnData = async () => {
    const _ = this;
    const data = await getModuleDataDisplaySettingByKey(this.state.moduleKeyName);
    if (data) {
      _.setState({ selectedColumns: data.metaValue })
      return data.metaValue;
    }
    return [];
  }

  displaySelectedColumns = async (table: any) => {
    const _this = this;
    if (_this.state.selectedColumns.length > 0) {
      table.settings().init().columns?.map(function (val: any, key: any) {
        if (!_this.state.selectedColumns.includes(val.name)) {
          table.column(key).visible(false);
        }
        return false;
      });
    }
    $('#ticketsTable').show();
    return false;
  };

  handleColumnDataSelect = async (e: any) => {
    const selCol = (e.target.checked)
      ? [...this.state.selectedColumns, e.target.value]
      : this.state.selectedColumns.filter((item: string) => item !== e.target.value);
    this.setState({ selectedColumns: selCol });
  };

  handleColumnDataSubmit = async (e: any) => {
    const selectedColumns = this.state.selectedColumns;
    await updateModuleDataDisplaySettingByKey(this.state.moduleKeyName, selectedColumns);
    this.refreshTable();
  };
  handleSelectAll = () => {
    const { selectedColumns, columnData } = this.state;
    const allKeys = columnData.map((col: any) => col.key);
    if (selectedColumns.length === columnData.length) {
        this.setState({ selectedColumns: [] });
    } else {
        this.setState({ selectedColumns: allKeys });
    }
};
  handleTicketLogFilterSet = async () => {
    if (this.getURL(''))
    {
      if (this.state.filterTicketType) {
        const selectTicketType = this.state.ticketTypes.filter((data: any) => this.state.filterTicketType.includes(data.id))
        const finalSelectedTicketTypeResponse = selectTicketType.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedTicketTypeResponse) !== JSON.stringify(this.state.ticketTypeSelected)) {
          this.setState({
            ticketTypeSelected: finalSelectedTicketTypeResponse,
          });
        }
      }
      if (this.state.filterMachine) {
        const selectMachine = this.state.machineDB.filter((data: any) => this.state.filterMachine.includes(data.id))
        const finalSelectedMachineResponse = selectMachine.map((x: any) => {
          return {
            value: x.id,
            label: `${x.name ? x?.name : (x?.location?.name  ?? "")} ${x?.serialNumber}`,
          }
        })
        if (JSON.stringify(finalSelectedMachineResponse) !== JSON.stringify(this.state.machineSelected)) {
          this.setState({
            machineSelected: finalSelectedMachineResponse,
          });
        }
      }
      if (this.state.filterTicketSource) {
        const selectTicketSource = this.state.ticketSourceDB.filter((data: any) => this.state.filterTicketSource.includes(data.id))
        const finalSelectedTicketSourceResponse = selectTicketSource.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedTicketSourceResponse) !== JSON.stringify(this.state.ticketSourceSelected)) {
          this.setState({
            ticketSourceSelected: finalSelectedTicketSourceResponse,
          });
        }
      }
      if (this.state.filterServiceType) {
        const selectServiceType = this.state.serviceTypes.filter((data: any) => this.state.filterServiceType.includes(data.id))
        const finalSelectedServiceTypeResponse = selectServiceType.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedServiceTypeResponse) !== JSON.stringify(this.state.serviceTypeSelected)) {
          this.setState({
            serviceTypeSelected: finalSelectedServiceTypeResponse,
          });
        }
      }
      if (this.state.filterAssign) {
        const selectAssign = this.state.assignToDB.filter((data: any) => this.state.filterAssign.includes(data.id))
        const finalSelectedAssignResponse = selectAssign.map((x: any) => {
          return {
            value: x.id,
            label: x.firstName
          }
        })
        if (JSON.stringify(finalSelectedAssignResponse) !== JSON.stringify(this.state.assignSelected)) {
          this.setState({
            assignSelected: finalSelectedAssignResponse,
          });
        }
      }
      if (this.state.filterReportedBy) {
        const selectReportedBy = this.state.assignToDB.filter((data: any) => this.state.filterReportedBy.includes(data.id))
        const finalSelectedReportedByResponse = selectReportedBy.map((x: any) => {
          return {
            value: x.id,
            label: x.firstName
          }
        })
        if (JSON.stringify(finalSelectedReportedByResponse) !== JSON.stringify(this.state.reportedBySelected)) {
          this.setState({
            reportedBySelected: finalSelectedReportedByResponse,
          });
        }
      }
      if (this.state.filterPriority) {
        const selectPriority = this.state.priorities.filter((data: any) => this.state.filterPriority.includes(data.id))
        const finalSelectedPriorityResponse = selectPriority.map((x: any) => {
         
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedPriorityResponse) !== JSON.stringify(this.state.prioritySelected)) {
          this.setState({
            prioritySelected: finalSelectedPriorityResponse,
          });
        }
      }
      if (this.state.machineStatus) {
        const selectMachineStatus = this.state.machineStatusDB.filter((data: any) => this.state.machineStatus.includes(data.id))
        const finalSelectedMachineStatusResponse = selectMachineStatus.map((x: any) => {
         
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedMachineStatusResponse) !== JSON.stringify(this.state.machineStatusSelected)) {
          this.setState({
            machineStatusSelected: finalSelectedMachineStatusResponse,
          });
        }
      }
      if (this.state.filterStatus) {
        const selectStatus = this.state.statuses.filter((data: any) => this.state.filterStatus.includes(data.id))
        const finalSelectedStatusResponse = selectStatus.map((x: any) => {
       
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedStatusResponse) !== JSON.stringify(this.state.statusSelected)) {
          this.setState({
            statusSelected: finalSelectedStatusResponse,
          });
        }
      }
    }
  }
  render() {
    return (
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Ticket Logs
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#tickets-filter"
                aria-expanded="false"
                aria-controls="tickets-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Ticket Logs</li>
              </ul>
            </div>
          </div>
        </div>


        <div className="collapse" id="tickets-filter" aria-labelledby="tickets-filter">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Apply Date filter on</label>
                  <br />
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5 mb-1 ms-2 "
                    key="apply_date_1"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value='reportedAt'
                      checked={this.state.applyDateFilterOn === 'reportedAt'}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: 'reportedAt'
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Reported at
                    </span>
                  </label>
                  <label
                    className="form-check form-check-sm form-check-custom form-check-solid align-items-center fs-5  mb-1 ms-2"
                    key="apply_date_3"
                  >
                    <input
                      className="form-check-input"
                      type="radio"
                      name="applyDateFilterOn"
                      value='closedAt'
                      checked={this.state.applyDateFilterOn === 'closedAt'}
                      onChange={() =>
                        this.setState({
                          applyDateFilterOn: 'closedAt'
                        })
                      }
                    />
                    <span className="form-check-label fs-5 mb-0 ms-2">
                      Closed at
                    </span>
                  </label>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold"> From Date</label>
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    ref={this.state.resetFrom}
                    closeOnSelect
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    ref={this.state.resetTo}
                    closeOnSelect
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Ticket Id</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}
                    name="filterTicketId"
                    value={this.state.filterTicketId}
                    onChange={(event: any) => {
                      this.setState({
                        filterTicketId: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Ticket Type</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        ticketTypeSelected: selected,
                        filterTicketType: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.ticketTypeSelected}
                    options={Array.from(this.state.ticketTypes).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Machines</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="machineId"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        machineSelected: selected,
                        filterMachine: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.machineSelected}
                    options={Array.from(this.state.machineDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: `${value.name ? value?.name : (value?.location?.name  ?? "")} ${value?.serialNumber}`,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Ticket Source</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="machineId"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        ticketSourceSelected: selected,
                        filterTicketSource: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.ticketSourceSelected}
                    options={Array.from(this.state.ticketSourceDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Service Type</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        serviceTypeSelected: selected,
                        filterServiceType: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.serviceTypeSelected}
                    options={Array.from(this.state.serviceTypes).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />

                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Assign To</label>
                  <Select
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="status"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={this.state.assignSelected}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        assignSelected: selected,
                        filterAssign: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    options={Array.from(this.state.assignToDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Reported By</label>
                  <Select
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="reportedBy"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={this.state.reportedBySelected}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        reportedBySelected: selected,
                        filterReportedBy: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    options={Array.from(this.state.assignToDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Priority</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        prioritySelected: selected,
                        filterPriority: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.prioritySelected}
                    options={Array.from(this.state.priorities).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Machine status
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        machineStatusSelected: selected,
                        machineStatus: value,
                      });
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={this.state.machineStatusSelected}
                    options={Array.from(this.state.machineStatusDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          description: value.description,
                        };
                      }
                    )}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>
               
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Serial Number</label>
                  <input
                    autoComplete="off"
                    className={clsx("form-control")}

                    name="filterSerialNumber"
                    value={this.state.filterSerialNumber}
                    onChange={(event: any) => {
                      this.setState({
                        filterSerialNumber: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Status</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="status"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    value={this.state.statusSelected}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        statusSelected: selected,
                        filterStatus: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    options={Array.from(this.state.statuses).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Columns
          columnData={this.state.columnData}
          selectedColumns={this.state.selectedColumns}
          handleColumnDataSelect={this.handleColumnDataSelect}
          handleColumnDataSubmit={this.handleColumnDataSubmit}
          handleSelectAll={this.handleSelectAll}
        />
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      {/* <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                        <KTSVG
                          path="/media/icons/duotune/finance/fin003.svg"
                          className="svg-icon-4 me-1 mb-1"
                        />
                        Tickets
                      </h3> */}
                       <button
                        type="button"
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#columnsModal"
                      >
                        Columns
                      </button>
                    </div>
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      <button
                        onClick={this.handleDownloadExcel}
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                      >
                        {this.state.downloadLoading && (
                          <span
                            className="indicator-progress"
                            style={{ display: "block" }}
                          >
                            Please Wait..
                            <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                          </span>
                        )}
                        {!this.state.downloadLoading && (
                          <span className="indicator-label"> <KTSVG
                            path="/media/icons/duotune/files/fil021.svg"
                            className="svg-icon-4 me-2"
                          />
                            Export Excel</span>
                        )}

                      </button>
                    </div>
                  </div>
                  <table
                    id="ticketsTable"
                    className="table align-items-center display responsive-table justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="ticketId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Ticket ID
                          <Tooltip anchorSelect=".ticketId" className="tooltip-list " data-placement="top">
                          Unique Smoodi identifier automatically created by the software
                          </Tooltip>
                        </th>
                        <th className="locationName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Name
                          <Tooltip anchorSelect=".locationName" className="tooltip-list " data-placement="top">
                          Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="ticketType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Ticket Type
                          {/* <Tooltip anchorSelect=".ticketType" className="tooltip-list " data-placement="top">
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="machine text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine
                          <Tooltip anchorSelect=".machine" className="tooltip-list " data-placement="top">
                          Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="serialNumber text-capitalize font-weight-bolder opacity-7 ps-2">
                          Serial Number
                          <Tooltip anchorSelect=".serialNumber" className="tooltip-list " data-placement="top">
                            The machine's serial number, located inside the service door
                          </Tooltip>
                        </th>
                        <th className="ticketSource text-capitalize font-weight-bolder opacity-7 ps-2">
                          Ticket Source
                          {/* <Tooltip anchorSelect=".ticketSource" className="tooltip-list " data-placement="top">
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="serviceType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Service Type
                          <Tooltip anchorSelect=".serviceType" className="tooltip-list " data-placement="top">
                            Service issues that necessitate an in-person visit by a Smoodi representative or someone assigned by Smoodi are marked as 'on-site'.
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Days Open
                          <Tooltip anchorSelect=".daysOpen" className="tooltip-list " data-placement="top">
                            This field shows the amount of time elapsed since the ticket was initially opened
                          </Tooltip>
                        </th>
                        <th className="comment text-capitalize font-weight-bolder opacity-7 ps-2">
                          Comment
                          {/* <Tooltip anchorSelect=".comment" className="tooltip-list " data-placement="top">
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="chatDescription text-capitalize font-weight-bolder opacity-7 ps-2">
                          Chat Description
                          {/* <Tooltip anchorSelect=".chatDescription" className="tooltip-list " data-placement="top">
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="createdBy text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created By
                          <Tooltip anchorSelect=".createdBy" className="tooltip-list " data-placement="top">
                            This field indicates the individual responsible for assigning the ticket, which may differ from the person actually performing the task.
                          </Tooltip>
                        </th>
                        <th className="priority text-capitalize font-weight-bolder opacity-7 ps-2">
                          Priority
                          <Tooltip anchorSelect=".priority" className="tooltip-list " data-placement="top">
                          Priority is assigned based on a combination of the client's importance to the company and the type of service issue
                          </Tooltip>
                        </th>
                        <th className="reportedAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Reported At
                          <Tooltip anchorSelect=".reportedAt" className="tooltip-list " data-placement="top">
                            This field records the time when the issue was initially reported.
                          </Tooltip>
                        </th>
                        <th className="reportedBy text-capitalize font-weight-bolder opacity-7 ps-2">
                          Reported By
                        </th>
                        <th className="endTime text-capitalize font-weight-bolder opacity-7 ps-2">
                          End Time
                          {/* <Tooltip anchorSelect=".endTime" className="tooltip-list " data-placement="top">
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip> */}
                        </th>
                        <th className="assignTo text-capitalize font-weight-bolder opacity-7 ps-2">
                          Assign to
                          <Tooltip anchorSelect=".assignTo" className="tooltip-list " data-placement="top">
                            This field specifies the Smoodi team member assigned to resolve the reported issue and close the ticket.
                          </Tooltip>
                        </th>
                        <th className="status text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                          <Tooltip anchorSelect=".status" className="tooltip-list " data-placement="top">
                            Pending:Indicates the ticket has been created but not yet acknowledged.
                            On-Hold:The ticket is recognized but does not require immediate resolution.
                            In-Process:Shows the ticket has been received and is being handled by the assigned person.
                            Archived: Refers to tickets that have been deleted from active status.
                          </Tooltip>
                        </th>
                        <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                          <Tooltip anchorSelect=".action" className="tooltip-list " data-placement="top">
                          Edit or change the status of the location
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
       
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            id="context-menu"
          >
            <li>
              {/* eslint-disable-next-line */}
              <a
                className="dropdown-item " id="edit_ticket_link"
              >
                <i className="fa fa-pencil fs-6"></i>{" "}
                <span className="ms-2">Edit ticket</span>
              </a>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a
                className="dropdown-item " id="status_record_link"
              >
                <i className="fa fa-exchange fs-6"></i>{" "}
                <span className="ms-2">Status change</span>
              </a>
            </li>

            <li>
              <button className="dropdown-item reassignBtn" id="re-assign_record_link">
                <i className="fa fa-refresh f-6"></i>
                <span className="ms-2"> Re-Assign </span>
              </button>
            </li>
            <li>
              <button
                className="dropdown-item priorityBtn" id="priority_record_link"
              >
                <i className="fa fa-qrcode fs-6"></i>{" "}
                <span className="ms-2">Priority</span>
              </button>
            </li>
            <li>
              <button
                className="dropdown-item ticketTypeBtn" id="ticket_type_record_link"
              >
                <i className="fa fa-retweet fs-6"></i>{" "}
                <span className="ms-2">Ticket type change</span>
              </button>
            </li>
            <li>
              {/* eslint-disable-next-line */}
              <a
                className="dropdown-item" id="create-task_record_link"
              >
                <i className="fa fa-pencil"></i>{" "}
                <span className="ms-2">Create Task</span>
              </a>
            </li>
            <li>
              <button
                className="dropdown-item jobOrderBtn" id="activity_record_link"
              >
                <i className="fa fa-tasks fs-6"></i>
                <span className="ms-2">Activity</span>
              </button>
            </li>
            <li>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" id="view_record_link">
                <i className="fa fa-eye f-6"></i>
                <span className="ms-2"> View</span>
              </a>
            </li>
            <li>
              <button className="dropdown-item signatureBtn" id="signature_record_link">
                <i className="fa fa-signature f-6"></i>
                <span className="ms-2"> Signature </span>
              </button>
            </li>
          </ul>

          <SignatureModal
            currentTicketId={this.state.currentTicketId}
            signatureModalOpen={this.state.signatureModalOpen}
            handleCloseSiganture={this.handleCloseSiganture}
            refreshTable={this.refreshTable}
          />
          <ReAssignModal
            reAssignModal={this.state.reAssignModal}
            currentTicketId={this.state.currentTicketId}
            refreshTable={this.refreshTable}
            handleCloseReAssign={this.handleCloseReAssign}
          />
          <PriorityModal
            priorityModalOpen={this.state.priorityModalOpen}
            currentTicketId={this.state.currentTicketId}
            handleClosePriority={this.handleClosePriority}
            refreshTable={this.refreshTable} />
        
        <ChangeTicketTypeModal
            changeTicketTypeModal={this.state.ticketTypeChangeModalOpen}
            currentTicketId={this.state.currentTicketId}
            handleCloseTicketType={this.handleCloseTicketType}
            refreshTable={this.refreshTable} />
        <ActivityModal
            activityModalOpen={this.state.activityModalOpen}
            currentTicketId={this.state.currentTicketId}
            handleCloseActivity={this.handleCloseActivity} />
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    );
  }
}

export default TicketLogs;
