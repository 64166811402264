import { FC, Suspense, useState, useEffect } from 'react';
import { fetchQuery,  useRelayEnvironment } from 'react-relay';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import { UpdateTicketPriority } from "../../models/_updateTickets";
import { graphql } from 'babel-plugin-relay/macro';
import { useQuery } from 'react-query';

type Props = {
  priorityModalOpen: boolean
  currentTicketId: string
  handleClosePriority: () => void
  refreshTable?: any
}

export const getTicketListById = graphql`
 query PriorityModal_getTicketQuery($id:String!){
  getTicket(id:$id){
    id
    uuid
    priority
  }
}`

const prioritySchema = yup.object({
  priority: yup.string().required("Priority is required"),
})
export const PriorityModal: FC<Props> = ({
  priorityModalOpen,
  handleClosePriority,
  currentTicketId,
  refreshTable
}
) => {
  const environment = useRelayEnvironment();

  const refetchTicket= async (): Promise<void> => {
    try {
      refetch();
      console.log("Successfully updated cache with the latest ticket data.");
    } catch (error) {
      console.error("Error refetching ticket data:", error);
    }
  };

  const { data: ticketRecord, refetch } = useQuery(
      ["getTicketPriorityModalById", currentTicketId],
      () => fetchQuery(environment, getTicketListById, { id: currentTicketId ?? undefined }).toPromise(),
      {
        enabled: !!currentTicketId,
      }
    );

    const ticket: any = ticketRecord && typeof ticketRecord === 'object' && 'getTicket' in ticketRecord ? ticketRecord.getTicket : null;

  const [loading, setLoading] = useState(false);
  const priorities = [
    {
      id: "HIGHEST",
      name: "Highest",
    },
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]

  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <Suspense>
        <Modal
          open={priorityModalOpen}
          onClose={() => handleClosePriority()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
         {ticket &&  <Formik
            enableReinitialize
            initialValues={{
              id: ticket?.id ?? "",
              priority: ticket?.priority ?? "",
              comment: "",
            }}
            validationSchema={prioritySchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setLoading(true);
              const updateData = {
                id: values.id,
                priority: values.priority,
                comment: values.comment,
              };
              const result = await UpdateTicketPriority(
                updateData.id,
                updateData.priority,
                updateData.comment
              );
              if (result?.data?.data?.updateTicketPriority) {
                refetchTicket();
                toast.success("Priority updated successfully");
                setLoading(false)
                handleClosePriority()
                resetForm()
                if (typeof refreshTable !== 'undefined') {
                  refreshTable()
                }
              } else {
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
                setLoading(false)
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) =>
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate>
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Change Priority - {ticket.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-2 pt-2">
                      <label className="form-label required fw-bold d-block fs-4">
                        Priority
                      </label>
                      {Array.from(priorities).map((value: any, id) => (
                        <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="priority"
                              className="form-check-input"
                              type="radio"
                              name="priority"
                              value={value.id}
                              checked={values.priority === value.id}
                              onChange={() =>
                                setFieldValue("priority", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="priority" />
                      </div>
                    </div>

                    <div className="col-12 mt-4 pt-2">
                      <label className="form-label fw-bold d-block fs-4">
                        Comment
                      </label>
                      <div className="form-group">
                        <textarea
                          autoComplete="off"
                          className="form-control"
                          rows={3}
                          name="comment"
                          value={values.comment}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-6 p-0 justify-content-start">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">SUBMIT</span>
                    )}
                  </button>
                </div>
              </form>}
          </Formik>}
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>

    </div>
  )
}

export default PriorityModal


