import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import Select from "react-select";
import Moment from "moment";
import {
  createLocation,
  getMarketSegment,
  getRegion,
  getUsers,
  getFilterProviders,
  getBusinessModelsList,
} from "../../models/_location";
import { getCustomersForFilter, getDistributorsForFilter } from "../../models/_customer";
import {
  locationValidationSchema,
  ICreateLocation,
  inits,
} from "./CreateLocationHelper";
import { CreateAdditionalInfoForm } from "./CreateAdditionalInfoForm";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import * as _ from "lodash";
import { getLocationTypesList } from "../../models/_locationType";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";
import { getGasSupplierList } from "../../models/_gasSupplier";
import { BUSINESS_MODELS, LOCATION_TYPES, MARKET_SEGMENTS } from "../../../_metronic/helpers/enums";

const CreateBasicInfoForm = () => {
  const navigate = useNavigate();
  const resetDistributor = React.useRef<any>(null);
  const resetLocationType = React.useRef<any>(null);
  const resetFilterProvider = React.useRef<any>(null);
  const resetDeploymentManager = React.useRef<any>(null);
  const resetMarketSegment = React.useRef<any>(null);
  const resetBusinessModel = React.useRef<any>(null);
  const resetLocationRegion = React.useRef<any>(null);
  const resetCustomers = React.useRef<any>(null);
  const resetTargetLaunchDate = React.useRef<any>(null);
  const resetSamplingTime = React.useRef<any>(null);
  const resetGasSupplier = React.useRef<any>(null);
  const resetLaunchDate = React.useRef<any>(null);

  const [initValues] = useState<ICreateLocation>(inits);
  const [loading, setLoading] = useState(false);
  const [distributorDB, setDistributorDB] = useState([]);
  const [customerDB, setCustomerDB] = useState([]);
  const [marketSegmentDB, setMarketSegmentDB] = useState([]);
  const [businessModelsDB, setBusinessModelsDB] = useState([]);
  const [managerDB, setManagerDB] = useState([]);
  const [filterProviderDB, setFilterProviderDB] = useState([])
  const [locationRegionDB, setLocationRegionDB] = useState([]);
  const [locationTypeDB, setLocationTypeDB] = useState([]);
  const [contactPhone, setContactPhone] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactPhoneType, setContactPhoneType] = React.useState("");
  const [distributorId, setDistributorId] = useState("");
  const [distributorName, setDistributorName] = useState("");
  const [distributorOther, setDistributor_other] = useState("");
  const [customerResult, setCustomerResult] = useState<any>({});
  const [customerId, setCustomerId] = useState("");
  const [customersData, setCustomersData] = useState<any>([]);
  const [gasSupplierDB, setGasSupplierDB] = useState([]);
  const contactTypeDB = [
    {
      id: "PERSONAL",
      name: "Personal",
    },
    {
      id: "OFFICE",
      name: "Office",
    },
  ];
  const [displayBusinessModel, setDisplayBusinessModel] = useState<any>(null);
  const [isBusinessModelDisabled, setIsBusinessModelDisabled] = useState<boolean>(false);
  const [displayGasSupplier, setDisplayGasSupplier] = useState(false);

  const fetchData = async () => {
    try {
      const customerIdRecord = await getCustomersForFilter();
      setCustomersData(customerIdRecord);
      const customerIdResults: any = customerIdRecord?.data ? customerIdRecord?.data : [];
      customerIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const distributorRecord = await getDistributorsForFilter();
      const distributorIdResults: any = distributorRecord
        ? distributorRecord
        : [];
      distributorIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      distributorIdResults.push({
        id: "other",
        name: "Other",
      });

      const marketSegmentRecord = await getMarketSegment();
      const marketSegmentResults: any = marketSegmentRecord
        ? marketSegmentRecord
        : [];
      marketSegmentResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const locationTypeRecord = await getLocationTypesList();
      const locationTypeResults: any = locationTypeRecord
        ? locationTypeRecord
        : [];
      locationTypeResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const deploymentManagerRecord = await getUsers(true);
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const locationRegionRecord = await getRegion();
      const locationRegionResults: any = locationRegionRecord
        ? locationRegionRecord
        : [];

      locationRegionResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const filterProviderRecord = await getFilterProviders();
      const filterProviderResults: any = filterProviderRecord
        ? filterProviderRecord
        : [];

      filterProviderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const businessModelsRecord = await getBusinessModelsList();
      const businessModelsResults: any = businessModelsRecord
        ? businessModelsRecord
        : [];

      businessModelsResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const gasSuppliersRecord = await getGasSupplierList();
      const gasSuppliersResults: any = gasSuppliersRecord
        ? gasSuppliersRecord
        : [];

        gasSuppliersResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setFilterProviderDB(filterProviderResults)
      setDistributorDB(distributorIdResults);
      setMarketSegmentDB(marketSegmentResults);
      setLocationTypeDB(locationTypeResults);
      setManagerDB(deploymentManagerResults);
      setCustomerDB(customerIdResults);
      setLocationRegionDB(locationRegionResults);
      setBusinessModelsDB(businessModelsResults);
      setGasSupplierDB(gasSuppliersResults);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerChange = async (e: any) => {
    setCustomerId(e.value);
    try {
      const customers: any = customersData?.data ?? []

      const customerRecords = customers.filter(
        (customer: any) => customer?.id === e.value
      );
      const customerRecord: any = customerRecords[0];

      if (customerRecords) {
        setCustomerResult(customerRecord);
        setContactPhone(customerRecord?.customerContactPhoneNumber);
        setContactEmail(customerRecord?.customerContactEmail);
        setContactName(customerRecord?.customerContactName);
        setContactPhoneType(customerRecord?.typeOfContactPhoneNumber);
        setDistributorId(customerRecord?.distributorId);
        setDistributorName(customerRecord?.distributor?.name);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleMarketSegmentChange = async (e : any) => {
    if (e.value === MARKET_SEGMENTS.CONVENIENCE_STORE) {
      setDisplayGasSupplier(true);
    } else {
      setDisplayGasSupplier(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [displayGasSupplier]);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Location
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">
                Create Location
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          validationSchema={locationValidationSchema}
          initialValues={initValues}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            let hasErrors = false;
            if (customerId === "") {
              hasErrors = true;
              toast.error("Customer is required");
            }
            if (customerId !== "") {
              if (contactName === "") {
                hasErrors = true;
                toast.error("Contact name is required");
              }
              if (contactPhone === "") {
                hasErrors = true;
                toast.error("Contact phone is required");
              }
              if (contactEmail === "") {
                hasErrors = true;
                toast.error("Contact email is required");
              }
              if (contactPhoneType === "") {
                hasErrors = true;
                toast.error("Contact phone type is required");
              }
            }
            if (distributorId === "other" && distributorOther === "") {
              hasErrors = true;
              toast.error("Other distributor is required");
            }
            if (!values.targetLaunchDate) {
              hasErrors = true;
              toast.error("Target launch date is required");
            }
            if (hasErrors) {
              return false;
            }
            setLoading(true);
            const businessHourData: any = [];
            const businessHourValues = values?.businessHours.filter((item: any) => item !== undefined);
            ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => {
              const current = businessHourValues.filter((x) => x.dayOfWeek === index);
              if (current && current?.length > 0 && current[0]?.isOpen) {
                businessHourData.push({
                  isOpen: current[0].isOpen,
                  dayOfWeek: current[0].dayOfWeek,
                  openTime: current[0].isOpen ? current[0].openTime : null,
                  closeTime: current[0].isOpen ? current[0].closeTime : null,
                })
              } else {
                businessHourData.push({
                  isOpen: false,
                  dayOfWeek: index,
                  openTime: null,
                  closeTime: null,
                })
              }
              return true;
            });

            const $inputData: any = {
              customerId: customerId,
              name: values.name,
              address: values.address,
              regionId: values.regionId,
              marketSegmentId: values.marketSegmentId,
              deploymentManagerId: values.deploymentManagerId,
              locationTypeId: values.locationTypeId,
              businessModelId: displayBusinessModel ? displayBusinessModel?.id : values?.businessModelId,
              gasSupplierId: null,
              filterProviderId: values.filterProviderId,
              distributorId: distributorId,
              distributorOtherInput: distributorOther,
              targetLaunchDate: Moment(values.targetLaunchDate).format(
                "YYYY-MM-DD"
              ),
              noOfFreezers: values.noOfFreezers,
              noOfBlenders: values.noOfBlenders,

              isActive: values.isActive,
              contactName: contactName,
              contactPhone: contactPhone,
              contactEmail: contactEmail,
              city: null,
              state: null,
              latitude: null,
              longitude: null,
              typeOfContactPhoneNumber: contactPhoneType,
              customerSecondContact: values.customerSecondContact,
              secondContactName: values.customerSecondContactName,
              secondContactPhoneNumber: values.customerSecondContactPhoneNumber,
              secondContactEmail: values.customerSecondContactEmail,
              secondContactNameRole: values.customerSecondContactNameRole,
              customerItContact: values.itContact,
              itContactName: values.itContactName,
              itContactPhoneNumber: values.itContactPhoneNumber,
              itContactEmail: values.itContactEmail,
              itContactNameRole: values.itContactNameRole,

              customerServiceContact: values.serviceContact,
              serviceContactName: values.serviceContactName,
              serviceContactPhoneNumber: values.serviceContactPhoneNumber,
              serviceContactEmail: values.serviceContactEmail,
              serviceContactNameRole: values.serviceContactNameRole,

              storeManagerContact: values.storeManagerContact,
              storeManagerContactName: values.storeManagerContactName,
              storeManagerContactEmail: values.storeManagerContactEmail,
              storeManagerContactPhoneNumber: values.storeManagerContactPhoneNumber,
              businessHours: businessHourData,
            };
            if(values.launchDate){
              $inputData["launchDate"] = Moment(values.launchDate).format("YYYY-MM-DD");
            }
            if (values.marketSegmentId === MARKET_SEGMENTS.CONVENIENCE_STORE && values?.gasSupplierId) {
              $inputData["gasSupplierId"] = values.gasSupplierId;
            }
            if (values.samplingRecommendedTime) {
              $inputData["samplingRecommendedTime"] = Moment.utc(
                values.samplingRecommendedTime
              ).local().format("HH:mm");
            }
            if (values.samplingRecommendedDays) {
              $inputData["samplingRecommendedDays"] = parseFloat(
                values.samplingRecommendedDays
              );
            }
            if (distributorId === "other") {
              $inputData.distributorId = "";
            }
            if (values.secondContactPhoneType) {
              $inputData["typeOfSecondContactPhoneNumber"] =
                values.secondContactPhoneType;
            }
            if (values.itContactPhoneType) {
              $inputData["typeOfItContactPhoneNumber"] =
                values.itContactPhoneType;
            }
            if (values.serviceContactPhoneType) {
              $inputData["typeOfServiceContactPhoneNumber"] =
                values.serviceContactPhoneType;
            }
            if (values.typeOfStoreManagerContactPhoneNumber) {
              $inputData["typeOfStoreManagerContactPhoneNumber"] =
                values.typeOfStoreManagerContactPhoneNumber;
            }
            const result = await createLocation($inputData);
            if (result?.data?.data?.createLocation) {
              toast.success("Location created successfully");
              resetTargetLaunchDate.current.state.inputValue = "";
              resetLaunchDate.current.state.inputValue = "";
              resetSamplingTime.current.state.inputValue = "";
              resetForm();
              resetDistributor.current.setValue("");
              resetLocationType.current.setValue("");
              resetFilterProvider.current.setValue("");
              resetDeploymentManager.current.setValue("");
              resetMarketSegment.current.setValue("");
              // resetBusinessModel.current.setValue("");
              resetLocationRegion.current.setValue("");
              resetCustomers.current.setValue("");
              setContactPhoneType("");
              setDistributor_other("");
              setContactName("");
              setContactPhone("");
              setContactEmail("");
              setDistributorId("");
              setDistributorName("");
              setCustomerId("");
              setCustomerResult("");
              setLoading(false);
              navigate("/locations/list");
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="customer form-label fs-4 required fw-bold">
                      Customers
                    </label>  <i className="customer fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".customer" className="tooltip-dashboard">
                      Associate the location with it's parent company
                    </Tooltip>

                    <Select
                      ref={resetCustomers}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.customerId) && touched.customerId,
                      })}
                      classNamePrefix="my-react-select"
                      name="customerId"
                      defaultValue={{
                        label: customerResult.name,
                        value: customerId,
                      }}
                      placeholder=""
                      options={Array.from(customerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        handleCustomerChange(e);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="customerId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="locationName form-label fs-4 required fw-bold">
                      Location Name
                    </label>  <i className="locationName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".locationName" className="tooltip-dashboard">
                      Enter the name of the location where the machine will be placed. Use a unique location name if available, or the customer name and store number. If neither is available, use the company name and city.
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.name) && touched.name,
                      })}
                      name="name"
                      id="name"
                      value={values.name}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="name" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="locationAddress form-label fs-4 required fw-bold">
                      Location Address
                    </label>  <i className="locationAddress fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".locationAddress" className="tooltip-dashboard">
                      Enter the full physical address of the location where the machine is installed.
                    </Tooltip>
                    <input
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.address) && touched.address,
                      })}
                      name="address"
                      id="address"
                      value={values.address}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="address" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="locationRegion form-label fs-4 required fw-bold">
                      Location Region
                    </label>  <i className="locationRegion fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".locationRegion" className="tooltip-dashboard">
                      Refer to this map: https://shorturl.at/fgoY5 to select the region
                    </Tooltip>
                    <Select
                      ref={resetLocationRegion}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.regionId) && touched.regionId,
                      })}
                      classNamePrefix="my-react-select"
                      name="regionId"
                      placeholder=""
                      options={Array.from(locationRegionDB).map(
                        (value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        }
                      )}
                      onChange={(e: any) => {
                        setFieldValue("regionId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="regionId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="marketSegment form-label fs-4 required fw-bold">
                      Market Segment
                    </label>  <i className="marketSegment fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".marketSegment" className="tooltip-dashboard">
                    Market segment should be on the customer level. Not in the location level
                    </Tooltip>
                    <Select
                      ref={resetMarketSegment}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.marketSegmentId) &&
                          touched.marketSegmentId,
                      })}
                      classNamePrefix="my-react-select"
                      name="marketSegmentId"
                      placeholder=""
                      options={Array.from(marketSegmentDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("marketSegmentId", e.value);
                        handleMarketSegmentChange(e)
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="marketSegmentId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Deployment Manager
                    </label>  <i className="deploymentManager fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".deploymentManager" className="tooltip-dashboard">
                    The person within Smoodi responsible for overseeing this account, typically a regional manager.
                    </Tooltip>
                    <Select
                      ref={resetDeploymentManager}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.deploymentManagerId) &&
                          touched.deploymentManagerId,
                      })}
                      classNamePrefix="my-react-select"
                      placeholder=""
                      name="deploymentManagerId"
                      options={Array.from(managerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("deploymentManagerId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="deploymentManagerId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="locationType form-label fs-4 required fw-bold">
                      Location Type
                    </label>  
                    {/* <i className="locationType fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".locationType" className="tooltip-dashboard">
                      What capacity is the machine performing right now. 
                    </Tooltip> */}
                    <Select
                      ref={resetLocationType}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.locationTypeId) &&
                          touched.locationTypeId,
                      })}
                      classNamePrefix="my-react-select"
                      name="locationTypeId"
                      placeholder=""
                      options={Array.from(locationTypeDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                          description: value.description,
                        };
                      })}
                      formatOptionLabel={({ label, description }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("locationTypeId", e.value);
                        if (e.value === LOCATION_TYPES.INTERNAL || e.value === LOCATION_TYPES.DEMO) {
                          setFieldValue("businessModelId", BUSINESS_MODELS.FREE);
                          const businessModel = businessModelsDB.find((modal: any) => modal.id === BUSINESS_MODELS.FREE);
                          setDisplayBusinessModel(businessModel || null);
                          setIsBusinessModelDisabled(true);
                        } else {
                          setFieldValue("businessModelId", '');
                          setDisplayBusinessModel(null);
                          setIsBusinessModelDisabled(false);
                        }
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="locationTypeId" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className={'form-label fs-4 fw-bold required'}>
                      Business Model
                    </label>
                    <Select
                      ref={resetBusinessModel}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.businessModelId) &&
                          touched.businessModelId,
                      })}
                      value={displayBusinessModel ? {
                        label: displayBusinessModel.name,
                        value: displayBusinessModel.id
                      } : null}
                      
                      classNamePrefix="my-react-select"
                      name="businessModelId"
                      placeholder=""
                      options={Array.from(businessModelsDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          description: value.description,
                        };
                      })}
                      formatOptionLabel={({ label, description }: any, { context }: any) => {
                        return context === 'menu' ? (
                          <div>
                            {label}
                            <div className="text-muted">{description}</div>
                          </div>
                        ) : (
                          label
                        );
                      }}
                      onChange={(e: any) => {
                        setFieldValue("businessModelId", displayBusinessModel?.id ? displayBusinessModel?.id : e.value);
                        setDisplayBusinessModel(
                          businessModelsDB.find((model: any) => model.id === e.value) ||  null
                        );
                      }}
                      isDisabled={isBusinessModelDisabled}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="businessModelId" />
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="filterProvider form-label fs-4 required fw-bold">
                      Filter Provider
                    </label>  <i className="filterProvider fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".filterProvider" className="tooltip-dashboard">
                    Who supplied the filter the is closest to the machine
                    </Tooltip>
                    <Select
                      ref={resetFilterProvider}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.filterProviderId) &&
                          touched.filterProviderId,
                      })}
                      classNamePrefix="my-react-select"
                      name="filterProviderId"
                      placeholder=""
                      options={Array.from(filterProviderDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.title
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("filterProviderId", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="filterProviderId" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="targetLaunchDate form-label fs-4 fw-bold">
                      Target Launch Date
                    </label>  <i className="targetLaunchDate fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".targetLaunchDate" className="tooltip-dashboard">
                    Date when the customer requested to install the machine by
                    </Tooltip>
                    <Datetime
                      ref={resetTargetLaunchDate}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      timeFormat={false}
                      value={values.targetLaunchDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("targetLaunchDate", dt);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className=" form-label fs-4 fw-bold">
                     Launch Date
                    </label>
                    <Datetime
                      ref={resetLaunchDate}
                      dateFormat="YYYY-MM-DD"
                      closeOnSelect
                      timeFormat={false}
                      value={values.launchDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("launchDate", dt);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="noOfBlenders form-label fs-4 fw-bold">
                      No.of Blenders
                    </label>  <i className="noOfBlenders fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".noOfBlenders" className="tooltip-dashboard">
                    Enter the total number of smoodi machines at this location
                    </Tooltip>
                    <input
                      id="noOfBlenders"
                      name="noOfBlenders"
                      type="number"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.noOfBlenders) && touched.noOfBlenders,
                      })}
                      value={values.noOfBlenders}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="noOfBlenders" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="noOfFreezers form-label fs-4 fw-bold">
                      No.of Freezers
                    </label>  <i className="noOfFreezers fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".noOfFreezers" className="tooltip-dashboard">
                    Enter the total number of smoodi freezers at this location
                    </Tooltip>
                    <input
                      id="noOfFreezers"
                      name="noOfFreezers"
                      type="number"
                      className={clsx("form-control", {
                        "is-invalid":
                          Boolean(errors.noOfFreezers) && touched.noOfFreezers,
                      })}
                      value={values.noOfFreezers}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="noOfFreezers" />
                    </div>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="samplingRecommendedTime form-label fs-4 fw-bold">
                      Sampling Recommended Time
                    </label>  <i className="samplingRecommendedTime fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".samplingRecommendedTime" className="tooltip-dashboard">
                    The time suggested by the store management as optimal for conducting sampling events.
                    </Tooltip>
                    <Datetime
                      ref={resetSamplingTime}
                      closeOnSelect
                      dateFormat={false}
                      timeFormat="HH:mm"
                      className="date-control"
                      value={values.samplingRecommendedTime}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("samplingRecommendedTime", dt);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="samplingRecommendedDays form-label fs-4 fw-bold">
                      Sampling Recommended Days
                    </label>  <i className="samplingRecommendedDays fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".samplingRecommendedDays" className="tooltip-dashboard">
                    The specific days of the week recommended by the store management as most suitable for conducting sampling events.
                    </Tooltip>
                    <input
                      type="number"
                      id="samplingRecommendedDays"
                      name="samplingRecommendedDays"
                      className="form-control"
                      value={values.samplingRecommendedDays}
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="wiFiCredentials form-label fs-4 fw-bold">
                      Wi-Fi Credentials
                    </label>  <i className="wiFiCredentials fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".wiFiCredentials" className="tooltip-dashboard">
                    Internet username and password
                    </Tooltip>
                    <input
                      id="credentials"
                      name="credentials"
                      className="text-transform form-control"
                      value={values.credentials}
                      onChange={handleChange}
                    />
                  </div> */}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="distributor form-label fs-4 fw-bold required">
                      Distributor
                    </label>  <i className="distributor fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".distributor" className="tooltip-dashboard">
                    Identify the company responsible for distributing Smoodi consumables to this location
                    </Tooltip>
                    <Select
                      ref={resetDistributor}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="distributorId"
                      placeholder={distributorName ?? ""}
                      options={Array.from(distributorDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setDistributorId(e.value);
                      }}
                    />
                  </div>
                  {distributorId === "other" ? (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className="form-label fs-4 required fw-bold">
                        Other Distributor
                      </label>  <i className="contactPhone fas fa-info-circle fs-4 mb-2 ps-1"></i>
                      <Tooltip anchorSelect=".contactPhone" className="tooltip-dashboard">
                        Enter the phone number of the contact person
                      </Tooltip>
                      <input
                        id="distributor_other"
                        name="distributor_other"
                        value={distributorOther}
                        className="form-control"
                        onChange={(e: any) => {
                          setDistributor_other(e.target.value);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                   {displayGasSupplier && (
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                      <label className={'form-label fs-4 fw-bold'}>
                      Gas Supplier
                      </label>
                      <Select
                        ref={resetGasSupplier}
                        className={clsx("react-select", displayGasSupplier ? {
                          "is-invalid":
                            Boolean(errors.gasSupplierId) &&
                            touched.gasSupplierId,
                        } : '')}
                        classNamePrefix="my-react-select"
                        name="gasSupplierId"
                        placeholder=""
                        options={Array.from(gasSupplierDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("gasSupplierId", e.value);
                        }}
                      />
                      {displayGasSupplier && (
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="gasSupplierId" />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactName form-label fs-4 fw-bold required">
                      Contact Name
                    </label>  <i className="contactName fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactName" className="tooltip-dashboard">
                    Enter the full name of the primary contact person at the customer's location
                    </Tooltip>
                    <input
                      id="contactName"
                      name="contactName"
                      className="form-control"
                      defaultValue={contactName}
                      onChange={(e) => {
                        setContactName(e.target.value);
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactPhone form-label fs-4 fw-bold required">
                      Contact Phone
                    </label>  <i className="contactPhone fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactPhone" className="tooltip-dashboard">
                    Enter the phone number of the primary contact person at the location
                    </Tooltip>
                    <input
                      id="contactPhone"
                      name="contactPhone"
                      className="form-control"
                      defaultValue={contactPhone}
                      onChange={(e) => {
                        setContactPhone(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="contactEmail form-label fs-4 fw-bold required">
                      Contact Email
                    </label>  <i className="contactEmail fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactEmail" className="tooltip-dashboard">
                    Enter the email address of the primary contact person at the location
                    </Tooltip>
                    <input
                      id="contactEmail"
                      name="contactEmail"
                      className="form-control"
                      defaultValue={contactEmail}
                      onChange={(e) => {
                        setContactEmail(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                    <label className="contactPhoneType form-label fs-4 required fw-bold ps-1">
                      Contact Phone Type
                    </label>  <i className="contactPhoneType fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".contactPhoneType" className="tooltip-dashboard">
                    Specify if this phone number is for work or personal use.
                    </Tooltip>
                    {Array.from(contactTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2" key={'con-' + id}>
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="typeOfContactPhoneNumber"
                            className="form-check-input"
                            type="radio"
                            name="typeOfContactPhoneNumber"
                            value={value.id}
                            checked={value.id === contactPhoneType}
                            onChange={(event) =>
                              setContactPhoneType(event.target.value)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="typeOfContactPhoneNumber" />
                    </div>
                  </div>
                </div>

                <CreateAdditionalInfoForm
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />

                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-8">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD LOCATION</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};

export default CreateBasicInfoForm;
