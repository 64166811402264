import React, { Component, createRef, useContext } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import "react-datetime/css/react-datetime.css";
import { KTSVG } from "../../../_metronic/helpers";
import ReactSelect from "react-select";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import { Option } from '../../../_metronic/helpers/MySelect';
import { getLocationByCustomers } from "../../models/_location";

export default class FlavorPriceList extends Component<any, any> {
  readonly props: any;
  constructor(props: any) {
    super(props);
    this.state = {
      resetFrom: createRef(),
      resetTo: createRef(),
      filterLocation: "",
      filterCustomer: "",
      filterFlavor: "",
      addLoading: false,
      filterLoading: false,
      loading: false,
      customerSelected: null,
      locationSelected: null,
      flavorSelected:null,
      locationDB: [],
      customerDB: [],
      flavorPriceList: [],
      customerIds: [],
      flavorDB:props.flavorsDB,
      flavorsKeyValue : props.flavorsDB ? props.flavorsDB.reduce((acc: any, curr: any) => {
        acc[curr.flavor] = curr.name;
        return acc;
      }, {}) : {},
    };
  }

  async componentWillMount() { }

  async componentDidMount() {
    await this.getFilterRecords();
    this.renderDataTable([]);
  }

  async componentDidUpdate() { }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    const _this = this;
    let authToken = "";
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    let extraFilter: any = {};
    if (this.state.filterLocation.length > 0) {
      extraFilter["locationIds"] = this.state.filterLocation
    }
    if (this.state.filterCustomer.length > 0) {
      extraFilter["customerIds"] = this.state.filterCustomer
    }
   
    $(document).ready(function () {
      $("#flavorTable").DataTable().destroy();

      $("#flavorTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
              "Bearer " + authToken);
          },
          "data": function (d: any) {
            const gql = `
        query  getFlavorPriceList(
          $extraFilter: FlavorPricesFilterType
          $paginate: PaginatorArgs,
          ) {
            getFlavorPriceList(extraFilter: $extraFilter, paginate: $paginate) {
              data {
                flavorPrice{
                  flavor
                  price
                }
                id
                name
                customer
                }
                totalPages
                total
                perPage
                page
                  }
        }
        `;

            const currentPage = (d?.start) ? (d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            const query = {
              "operationName": null,
              "query": gql,
              "variables": {
                "extraFilter": extraFilter,
                paginate: {

                  "page": currentPage,
                  "per_page": length

                },
              }
            };
            return JSON.stringify(query);
          },
          "dataSrc": function (json) {

            json.recordsTotal = json.data.getFlavorPriceList.total;
            json.recordsFiltered = json.data.getFlavorPriceList.total;

            const rows: any[] = [];
            const records = json.data.getFlavorPriceList.data;            
            let counter = 0;
            for (const record of records) {
              counter++
              const tempRecord = {
                id: counter,
                recordId: record.id,
                location: record.name,
                customer:record.customer,
                flavorPrice: record.flavorPrice,
                record: record,
              };
            
              const subRecords = record.subTasks ?? "";
              for (const subRecord of subRecords) {
                subRecord["record"] = { ...subRecord };
              }
              rows.push(tempRecord);
            }

            return rows;
          }
        },
        // data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "customerName",
            render: function (data, type, row) {
              return  row.customer
            },
          },
          {
            name: "locationName",
            render: function (data, type, row) {
              return row.location
            },
          },
          {
            name: "flavorPrice",
            render: function (data, type, row) {
              let html = `<div class="flavor"> <table class="price">
              <tr>
                  <td >Flavor</td>
                  <td >Price</td>
              </tr>`;
              // eslint-disable-next-line 
              if (row.flavorPrice && row.flavorPrice.length > 0) {
                row.flavorPrice.filter((item: any) => _this.state.filterFlavor.length === 0 || _this.state.filterFlavor.includes(item.flavor))
                  .forEach((item: any) => {
                    let flavor = item.flavor;
                    let price = item.price;

                    const flavorName = _this.state.flavorsKeyValue[flavor] ??  flavor;
                    

                    html += `
                    <tr>
                      <td>${flavorName}</td>
                      <td>${price}</td>
                    </tr>`;
                  });
              }
              html += ` </table></div> `;
              return html

            },
          },
          
         

        ],
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        // order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          const title: any = [];
          $("#flavorTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  LocationList = async (event: any) => {
    const selectCustomerId = event
    await getLocationByCustomers(selectCustomerId);
    const result = await getLocationByCustomers(selectCustomerId);
    this.setState({
      locationDB: result
    })
    return false;
  }
  getFilterRecords = async () => {
    const {customers,locations} = this.props;
    this.setState({
      customerDB: customers,
      locationDB: locations
    })
  }


  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    await this.getFilterRecords();
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });


    this.setState({

      filterLocation: "",
      filterCustomer: "",
      filterFlavor: "",
      flavorSelected:null,
      customerSelected: null,
      locationSelected: null,
    });
    await this.getFilterRecords();
    this.renderDataTable([]);

    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Flavor Pricing Chart
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flavor-filter"
                aria-expanded="false"
                aria-controls="flavor-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Flavor Pricing Chart</li>
              
              </ul>
            </div>
          </div>
        </div>

        <div className="collapse" id="flavor-filter" aria-labelledby="flavor-filter">
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customer Name</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterCustomer"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        customerSelected: selected,
                        filterCustomer: value,
                      });
                      this.LocationList({
                        customerIds: value
                      })
                    }
                    }
                    components={{
                      Option,
                    }}
                    value={this.state.customerSelected}
                    options={Array.from(this.state.customerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name.toLowerCase(),
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location Name</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterLocation"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Flavor</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    name="filterFlavor"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        flavorSelected: selected,
                        filterFlavor: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.flavorSelected}
                    options={Array.from(this.state.flavorDB).map(
                      (value: any) => {
                        let flavor = value.flavor;
                        const flavorName = this.state.flavorsKeyValue[flavor] ??  flavor;
                        
                        return {
                          value: value.flavor,
                          label:flavorName,
                        };
                      }
                    )}
                  />
                </div>

              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Flavor Pricing Chart
                </h3>
              </div>

            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="flavorTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                        Flavor Pricing Chart
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
