import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function UpdateTicketAssignee(
  id: string,
  assignToId: string,
  comment: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketAssignee (
                  $id: String!,
                  $assignToId: String!,
                  $comment: String!,
              ){
            updateTicketAssignee(
              updateTicketAssignToInput: {
                id: $id
                assignToId: $assignToId
                comment: $comment
              }
            ) 
          }
        `,
      variables: {
        id,
        assignToId,
        comment,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
interface UpdateTicketSignatureInput {
  id: string;
  signatureName: string;
  signatureImage: string[];
}
export async function UpdateTicketSignature(input: UpdateTicketSignatureInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketSignature (
                  $input: UpdateTicketSignatureInput!
              ){
                updateTicketSignature(
                  updateTicketSignatureInput:  $input
            ) 
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
interface UpdateTicketStatusInput {
  id: string;
  status: string;
  comment: string;
  media?: string[];
  machineServices?: string[];
  operations?: string[];
  marketingOrOthers?: string[];
  lastStatusComment?: "";
  howTicketClosed?: "";
  closingDateAndTime?: string;
  timeSpentOnTask: number;
  customerSatisfaction?: string | null;
  cleanliness?: string | null;
  trafficRate?: string | null;
  staffEnthusiasm?: string | null;
  travelTime?: number;
  machineServiceOtherInput?: string;
  operationOtherInput?: string;
  marketingOtherInput?: string;
  marketingMaterials?: string[];
  replaceNewTubeDoors1mmShorter?: boolean,
  blenderId?: string,
  boardId?: string,
  oldMachineShiftToLocationId?: string,
  convertToLabTicket?: boolean
}
export async function UpdateTicketStatus(input: UpdateTicketStatusInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketStatus (
                $input: UpdateTicketStatusInput!
              ){
                updateTicketStatus(
                  updateTicketStatusInput: $input
            ) 
          }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function UpdateTicketPriority(
  id: string,
  priority: string,
  comment: string
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateTicketPriority (
                  $id: String!,
                  $priority: Priority!,
                  $comment: String!,
              ){
            updateTicketPriority(
              updateTicketPriorityInput: {
                id: $id
                priority: $priority
                comment: $comment
              }
            )
          }
        `,
      variables: {
        id,
        priority,
        comment,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function changeTicketType(
  ticketId: string,
  newTicketType: string,
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation changeTicketType (
                  $ticketId: String!,
                  $newTicketType: String!,
              ){
            changeTicketType(
              ticketId: $ticketId,
              newTicketType: $newTicketType
            )
          }
        `,
      variables: {
        ticketId,
        newTicketType,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}