import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import CustomerAccountsList from "./CustomerAccountsList";
import { Location } from "../Machines/MachineParentComponent";

export interface Customer {
  id: string;
  name: string;
}

export interface CustomerRole {
  id: string;
  name: string;
  slug: string;
}

interface CustomerAccountsParentComponentQueryResponse {
  getCustomerList: Customer[];
  getLocationList: Location[];
  getCustomerRoleList: CustomerRole[];
}

type CustomerAccountsParentComponentQuery = OperationType & {
  response: CustomerAccountsParentComponentQueryResponse;
  variables: Record<string, never>;
};

class CustomerAccountsParentComponent extends React.Component {
  render() {
    return (
      <QueryRenderer<CustomerAccountsParentComponentQuery>
        environment={environment}
        query={graphql`
          query CustomerAccountsParentComponentQuery {
            getCustomerList {
              id
              name
            }
            getLocationList {
              id
              uuid
              name
            }
            getCustomerRoleList {
              id
              name
              slug
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>Error loading customer data: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const { getCustomerList, getLocationList, getCustomerRoleList } =
            props;

          return (
            <CustomerAccountsList
              customers={getCustomerList}
              locations={getLocationList}
              customerRoles={getCustomerRoleList}
            />
          );
        }}
      />
    );
  }
}

export default CustomerAccountsParentComponent;
