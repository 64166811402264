import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchQuery } from "react-relay";
import environment from "../../environment";
import { graphql } from "babel-plugin-relay/macro";

export const LocationDataContext = createContext<any>(null);

const getLocationDataQuery = graphql`
  query LocationDataProviderQuery($isRegionalManager: Boolean) {
    getCustomerList {
      id
      name
    }
    getDistributors {
      id
      name
    }
    getMarketSegment {
      id
      name
    }
    getLocationTypes {
      id
      name
      description
      isDefault
    }
    getRegions {
      id
      name
    }
    getFilterProviders {
      id
      title
    }
    getBusinessModelsList {
      id
      name
      description
    }
    getLocationList {
      id
      uuid
      name
    }
    getUsers(isRegionalManager: $isRegionalManager) {
      id
      firstName
      lastName
      isActive
      isRegionalManager
    }
    getGasSupplierList {
        id
        name
        createdById
        isActive
    }
  }
`;

export const LocationDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    fetchQuery(environment, getLocationDataQuery, { isRegionalManager: true })
      .toPromise()
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });
  }, []);

  return (
    <LocationDataContext.Provider value={{ data : data ?? {}, loading, error }}>
      {children}
    </LocationDataContext.Provider>
  );
};

export const useLocationData = () => useContext(LocationDataContext);