import React, { useEffect } from "react";
import { Suspense } from "react";
import { useState } from "react";
import { getAllSettings, updateSetting } from "../../models/_settings";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip } from "react-tooltip";

const getPermissions: any = localStorage.getItem("permissions");
const permissions = getPermissions || [];

function Setting() {
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const getSettings = await getAllSettings();
    for (const setting of getSettings) {
      if (setting.settingKey === "blend_threshold_time") {
        const parsedValue = JSON.parse(setting.settingValue);
        setFrom(parseInt(parsedValue?.from) ?? 0);
        setTo(parseInt(parsedValue?.to) ?? 0);
      }
    }
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (from > to) {
      toast.error("To must be less than to!");
      return;
    }
    const settingValue = {
      from,
      to,
    };
    // blend_threshold_time
    const result = await updateSetting({
      settingKey: "blend_threshold_time",
      settingValue: JSON.stringify(settingValue),
    });

    if (result?.data?.data?.updateSetting) {
      toast.success("Setting updated successfully");
      // setSubmitting(false);
      setLoading(false);
    } else {
      setLoading(false);
      const messages = result?.data?.errors?.map((e: any) => e.message);
      toast.error(messages ? messages.join(",") : "Setting updated failed");
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Suspense>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Settings
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Settings
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-3 col-lg-6 col-12">
                <h4 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-align-items-lg-center card-title text-center">
                  Standard blend Time
                </h4>
              </div>
            </div>
            <form onSubmit={onSubmit}>
              <div className="row mt-6 align-items-center">
                <div className="col-xl-4 col-lg-5 col-md-5 col-12">
                  <label className="formSecond form-label fs-5 fw-bold">
                    From (Seconds)
                  </label>
                  <i className="formSecond fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".formSecond" className="tooltip-dashboard">
                    Sets the time threshold below which an abnormal blend triggers a notification to the regional manager.
                    </Tooltip>
                  <input
                    className="form-control"
                    id="from"
                    type="number"
                    onChange={(e: any) => setFrom(e.target.value)}
                    value={from}
                  />
                </div>
                <div className="col-xl-1 col-lg-2 col-md-2 mt-lg-3 pt-lg-3 mt-md-3 pt-2 text-center my-lg-0 my-md-0 my-4">
                  <div className="form-label mb-0 fs-5 fw-bold">between</div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-5 col-12">
                  <label className="toSecond form-label fs-5  fw-bold">
                    To (Seconds)
                  </label>
                  <i className="toSecond fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".toSecond" className="tooltip-dashboard">
                    Sets the time threshold above which an abnormal blend triggers a notification to the regional manager.
                    </Tooltip>
                  <input
                    className="form-control"
                    id="to"
                    type="number"
                    onChange={(e: any) => setTo(e.target.value)}
                    value={to}
                  />
                </div>
              </div>
              {permissions?.includes("update-settings") === true && (
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">SUBMIT</span>
                    )}
                  </button>
                </div>
              </div>
                )}
            </form>
          </div>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </>
  );
}

export default Setting;
