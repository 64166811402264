import React, { useRef, useState } from "react";
import { GetMachineServiceType } from "../../../models/_tickets";
import { GetAvailableMachinesForReplaceType, GetBlendersType, GetBoardsType, GetDiagnosisType, GetLocationsType } from "../../../models/_publicCall";
import { MACHINE_SERVICES } from "../../../../_metronic/helpers/enums";
import Select from 'react-select';
import * as _ from 'lodash';
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-responsive-modal";
import { SiteVisitationMachine } from "../SiteVisitation";


const TicketsInfo = (props: {
  tickets: any;
  logJob: any;
  diagnosis: GetDiagnosisType[];
  machineServicesDB: GetMachineServiceType[];
  machinesDB: GetAvailableMachinesForReplaceType[],
  locationsDB: GetLocationsType[]
  blendersDB: GetBlendersType[],
  boardsDB: GetBoardsType[],
  replaceNewTubeDoors1mmShorter: { [ticketId: string]: boolean };
  convertTicketToLabTicket: { [ticketId: string]: boolean };
  replacedMachines: { [ticketId: string]: string };
  replacedBlender: { [ticketId: string]: string };
  replacedBoard: { [ticketId: string]: string };
  oldMachineShiftToLocation: { [ticketId: string]: string };
  siteVisitationMachines: { [machineId: string]: SiteVisitationMachine };
  onSelectedDiagnosesChange: (selected: { [ticketId: string]: string[] }) => void;
  onSelectedMachineServicesDoneChange: (selected: { [ticketId: string]: string[] }) => void;
  onMachineServicesDoneOtherChange: (selected: { [ticketId: string]: string }) => void;
  setReplaceNewTubeDoors1mmShorter: (selected: { [ticketId: string]: boolean }) => void;
  setConvertTicketToLabTicket: (selected: { [ticketId: string]: boolean }) => void;
  setReplacedMachines: (selected: { [ticketId: string]: string }) => void;
  setReplacedBlender: (selected: { [ticketId: string]: string }) => void;
  setReplacedBoard: (selected: { [ticketId: string]: string }) => void;
  setOldMachineShiftToLocation: (selected: { [ticketId: string]: string }) => void;
  setSiteVisitationMachines: (value: { [machineId: string]: SiteVisitationMachine }) => void;
}) => {
  const {
    tickets,
    logJob,
    diagnosis,
    machineServicesDB,
    machinesDB,
    blendersDB,
    boardsDB,
    locationsDB,
    replacedMachines,
    replacedBlender,
    replacedBoard,
    convertTicketToLabTicket,
    oldMachineShiftToLocation,
    siteVisitationMachines,
    setOldMachineShiftToLocation,
    setConvertTicketToLabTicket,
    setReplacedBlender,
    setReplacedBoard,
    setReplacedMachines,
    onSelectedDiagnosesChange,
    onSelectedMachineServicesDoneChange,
    onMachineServicesDoneOtherChange,
    setSiteVisitationMachines,
  } = props;
  const [showNewMachineActiveLocationWarningPopup, setShowNewMachineActiveLocationWarningPopup] = useState(false);
  const [selectedDiagnoses, setSelectedDiagnoses] = useState<{ [ticketId: string]: string[] }>({});
  const [selectedMachineServicesDone, setSelectedMachineServicesDone] = useState<{ [ticketId: string]: string[] }>({});
  const [machineServicesDoneOther, setMachineServicesDoneOther] = useState<{ [ticketId: string]: string }>({});
  const [tempReplaceMachineTicketInfo, setTemplReplaceMachineTicketInfo] = useState<{ ticketId?: string, machineId?: string, locationName?: string }>({});
  const [tempOldMachineShiftLocationTicketId, setTempOldMachineShiftLocationTicketId] = useState<string>("");
  const [showOldMachineShiftLocationPopup, setShowOldMachineShiftLocationPopup] = useState(false);
  const machineIdRefs = useRef<{ [ticketId: string]: any }>({}); // Dynamic refs for each ticket

  /** Handle diagnosis checkbox changes */
  const handleCheckboxChange = (ticketId: string, diagnosisName: string) => {
    setSelectedDiagnoses((prev) => {
      const updatedDiagnoses = {
        ...prev,
        [ticketId]: prev[ticketId]?.includes(diagnosisName)
          ? prev[ticketId].filter((name) => name !== diagnosisName) // Remove if already selected
          : [...(prev[ticketId] || []), diagnosisName], // Add if not selected
      };
      onSelectedDiagnosesChange(updatedDiagnoses); // Pass updated state to parent
      return updatedDiagnoses;
    });
  };

  /** Handle machine service checkbox changes */
  const handleMachineServiceChange = (ticketId: string, serviceName: string) => {
    setSelectedMachineServicesDone((prev) => {
      const prevSelected = prev[ticketId] || [];
      const updatedSelected = prevSelected.includes(serviceName)
        ? prevSelected.filter((name) => name !== serviceName) // Remove if already selected
        : [...prevSelected, serviceName]; // Add if not selected

      const updatedState = {
        ...prev,
        [ticketId]: updatedSelected,
      };

      onSelectedMachineServicesDoneChange(updatedState); // Pass updated state to parent
      return updatedState;
    });
  };

  /** Handle "Other" service text input */
  const handleOtherServiceChange = (ticketId: string, value: string) => {
    setMachineServicesDoneOther((prev) => {
      const updated = {
        ...prev,
        [ticketId]: value,
      };

      onMachineServicesDoneOtherChange(updated); // Pass to parent
      return updated;
    });
  };

  const replaceMachine = () => {
    if (!tempReplaceMachineTicketInfo.ticketId || !tempReplaceMachineTicketInfo.machineId) return;

    setReplacedMachines({
      ...replacedMachines,
      [tempReplaceMachineTicketInfo.ticketId]: tempReplaceMachineTicketInfo.machineId,
    });

    setTempOldMachineShiftLocationTicketId(tempReplaceMachineTicketInfo.ticketId)
    setShowNewMachineActiveLocationWarningPopup(false)
    setTemplReplaceMachineTicketInfo({})
    setShowOldMachineShiftLocationPopup(true)
  }

  const handleClose = () => {
    setShowNewMachineActiveLocationWarningPopup(false)
    if (tempReplaceMachineTicketInfo.ticketId) {
      if (machineIdRefs.current[tempReplaceMachineTicketInfo.ticketId]) {
        machineIdRefs.current[tempReplaceMachineTicketInfo.ticketId].clearValue();
      }
    }

    setTemplReplaceMachineTicketInfo({})
  }

  return (
    <>
      <div className="wo-form-section mt-8">
        {tickets?.length > 0 ? (
          tickets.map((ticket: any, index: number) => (
            <div key={ticket.id} className="mb-8 border-dotted border-2 border-gray-200 p-8">
              <h3>Tickets: {ticket.uuid}</h3>
              <div className="ms-8 mt-4">
                {/* Machine Name */}
                <div className="d-flex align-items-center gap-2">
                  <label htmlFor="machineName" className="wo-label mb-0">Machine Name:</label>
                  <span className="mb-0">{ticket.machineName ? ticket.machineName : ticket.locationName}</span>
                </div>

                {/* Serial Number */}
                <div className="d-flex align-items-center gap-2 mt-4">
                  <label htmlFor="serialNumber" className="wo-label mb-0">Serial Number:</label>
                  <span className="mb-0">{ticket.serialNumber}</span>
                </div>

                {/* Issue Reported */}
                <div className="d-flex gap-2 mt-4">
                  <label htmlFor="issueReported" className="wo-label mb-0">Issue Reported:</label>
                  <ul className="mb-0">
                    {ticket.issuesReported.map((issue: any, index: number) => (
                      <li key={index} className="fs-5">{issue.name}</li>
                    ))}
                  </ul>
                </div>

                {/* Diagnosis */}
                <div className="gap-2 mt-4">
                  <label htmlFor="diagnosisId" className="wo-label mb-0">Diagnosis:</label>
                  <div className="ms-8 mt-2">
                    <ul className="list-unstyled">
                      {diagnosis.map((diag: GetDiagnosisType, index: number) => (
                        <label key={index} className="form-check form-check-sm form-check-custom form-check-solid me-5 d-flex align-items-center ms- mt-1">
                          <input
                            id={`diagnosis-${ticket.id}-${index}`}
                            className="form-check-input mb-2 me-1"
                            type="checkbox"
                            checked={selectedDiagnoses[ticket.id]?.includes(diag.id) || false}
                            onChange={() => handleCheckboxChange(ticket.id, diag.id)}
                          />
                          <span className="ms-2 mb-1 fs-5 form-check-label">{diag.name}</span>
                        </label>
                      ))}
                    </ul>
                  </div>
                </div>

                {/* Machine Services Done */}
                <div className=" gap-2 mt-4">
                  <label htmlFor="machineServicesDone" className="wo-label mb-0">Machine Services Done:</label>
                  <div className="ms-8 mt-2">
                    <ul className="list-unstyled">
                      {machineServicesDB.map((service: GetMachineServiceType, index: number) => (
                        <div key={index} className="d-flex align-items-center ms-4 mt-1">
                          <label className="form-check form-check-sm form-check-custom form-check-solid me-5 d-flex align-items-center">
                            <input
                              id={`machineServicesDone-${ticket.id}-${index}`}
                              className="form-check-input mb-2 me-1"
                              type="checkbox"
                              checked={selectedMachineServicesDone[ticket.id]?.includes(service.id) || false}
                              onChange={() => handleMachineServiceChange(ticket.id, service.id)}
                            />
                            <span className="ms-2 mb-1 fs-5 form-check-label">{service.name}</span>
                          </label>

                          {/* Show text input when "Other" is selected */}
                          
                        </div>
                      ))}

                      {selectedMachineServicesDone[ticket.id]?.includes("other") && (
                            <input
                              type="text"
                              className="form-control ms-2"
                              placeholder="Enter service details"
                              value={machineServicesDoneOther[ticket.id] || ""}
                              onChange={(e) => handleOtherServiceChange(ticket.id, e.target.value)}
                            />
                          )}

                      {selectedMachineServicesDone[ticket.id]?.includes(
                        MACHINE_SERVICES.REPLACE_MACHINE
                      ) ? (
                        <>
                        <div className="">
                          <label className="form-label required fs-4 fw-bold">
                            New Machine
                          </label>
                          <Select
                            className="react-select"
                            classNamePrefix="my-react-select"
                            name="machineId"
                            placeholder="Please select new machine"
                            ref={(el) => (machineIdRefs.current[ticket.id] = el)}
                            value={replacedMachines[ticket.id] ? {
                              label: machinesDB.find((value: any) => value.id === replacedMachines[ticket.id])?.machine_name ? machinesDB.find((value: any) => value.id === replacedMachines[ticket.id])?.machine_name : (machinesDB.find((value: any) => value.id === replacedMachines[ticket.id])?.location_name ?? "") + " " + machinesDB.find((value: any) => value.id === replacedMachines[ticket.id])?.serial_number,
                              value: replacedMachines[ticket.id],
                            } : {}}
                            options={Array.from(machinesDB).filter((record) => record.id !== ticket.machineId).map((value: any) => {
                              return {
                                value: value.id,
                                label: `${value.machine_name ? value?.machine_name : (value?.location_name ?? "")} ${value?.serial_number}`,
                              };
                            })}
                            onChange={(e: any) => {
                              if (e.value === ticket.machineId) {
                                toast.error("Please select another machine.");
                                return;
                              }
                              const isDuplicate = Object.values(replacedMachines).some(
                                (machineId) => machineId === e.value && machineId !== replacedMachines[ticket.id]
                              );

                              if (isDuplicate) {
                                toast.error("This machine is already assigned to another ticket.");
                                return;
                              }


                              const machine = machinesDB.find((machine: any) => machine.id === e.value);
                              if (machine?.location_id && machine?.site_status) {
                                setShowNewMachineActiveLocationWarningPopup(true)
                                setTemplReplaceMachineTicketInfo({
                                  ticketId: ticket.id,
                                  machineId: e.value,
                                  locationName: machine.location_name
                                })
                                return;
                              }
                              setReplacedMachines({
                                ...replacedMachines,
                                [ticket.id]: e.value,
                              });
                              setTempOldMachineShiftLocationTicketId(ticket.id)
                              setShowOldMachineShiftLocationPopup(true)
                            }}
                          />

                        </div>
                          {
                            oldMachineShiftToLocation[ticket.id] && 
                            (
                              <div className="form-label mt-5 mb-5">
                                Old machine will be shif to: {locationsDB.find(location => location.id === oldMachineShiftToLocation[ticket.id])?.name} location
                              </div>
                            )
                          }
                        </>
                      ) : (
                        ""
                      )}

                      {selectedMachineServicesDone[ticket.id]?.includes(
                        MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY
                      ) ? (
                        <div className="">
                          <label className="form-label required fs-4 fw-bold">
                            Blender
                          </label>
                          <Select
                            className="react-select"
                            classNamePrefix="my-react-select"
                            name="blenderId"
                            placeholder=""
                            options={Array.from(blendersDB).map((value: any) => {
                              return {
                                value: value.id,
                                label: value.title,
                              };
                            })}
                            value={{
                              label: blendersDB.find((value: any) => value.id === replacedBlender[ticket.id])?.title,
                              value: replacedBlender[ticket.id],
                            }}
                            onChange={(e: any) => {
                              setReplacedBlender({
                                ...replacedBlender,
                                [ticket.id]: e.value,
                              });
                              
                              if(siteVisitationMachines[ticket.machineId]) {
                                setSiteVisitationMachines({
                                  ...siteVisitationMachines,
                                  [ticket.machineId] : {
                                    ...siteVisitationMachines[ticket.machineId],
                                    blenderId: e.value
                                  }
                                })
                              }
                              
                            }}
                          />

                        </div>
                      ) : (
                        ""
                      )}
                      {selectedMachineServicesDone[ticket.id]?.includes(
                        MACHINE_SERVICES.UPGRADE_PCB
                      ) ||
                        selectedMachineServicesDone[ticket.id]?.includes(
                          MACHINE_SERVICES.REPLACE_PCB
                        ) ? (
                        <div className="">
                          <label className="form-label required fs-4 fw-bold">
                            Board
                          </label>
                          <Select
                            className="react-select"
                            classNamePrefix="my-react-select"
                            name="boardId"
                            placeholder=""
                            value={{
                              label: boardsDB.find((value: any) => value.id === replacedBoard[ticket.id])?.title,
                              value: replacedBoard[ticket.id],
                            }}
                            options={Array.from(boardsDB).map((value: any) => {
                              return {
                                value: value.id,
                                label: value.title,
                              };
                            })}

                            onChange={(e: any) => {
                              setReplacedBoard({
                                ...replacedBoard,
                                [ticket.id]: e.value,
                              });

                              
                            }}
                          />

                        </div>
                      ) : (
                        ""
                      )}

                      {(selectedMachineServicesDone[ticket.id]?.includes(
                        MACHINE_SERVICES.REPLACE_MACHINE
                      )) ?
                        <>
                          <div className="row">
                            <div className="">
                              <label className="form-check form-check-custom form-check-solid me-5 d-flex align-items-center">
                                <input
                                  id="convertToLabTicket"
                                  type="checkbox"
                                  name="convertToLabTicket"
                                  className="form-check-input"
                                  checked={convertTicketToLabTicket[ticket.id] || false}
                                  onChange={(e) => {
                                    setConvertTicketToLabTicket({ ...convertTicketToLabTicket, [ticket.id]: e.target.checked });
                                  }}
                                />
                                <span
                                  className="form-label fw-bold mt-2 ms-3 fs-3"
                                  style={{ cursor: "pointer" }}
                                >
                                  Do you want to convert this ticket to lab ticket?
                                </span>
                              </label>
                            </div>
                          </div>
                        </>
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No Data</p>
        )}
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
      <Modal
        open={showNewMachineActiveLocationWarningPopup}
        onClose={() => setShowNewMachineActiveLocationWarningPopup(false)}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <div className='modal-title'></div>
        <div className="modal-body p-2">
          <h4 className=" fs-4 mb-8 mt-4">This machine is already activated at {tempReplaceMachineTicketInfo.locationName} do you want to replace machine</h4>
        </div>
        <div className="modal-footer justify-content-center p-2">
          <button className="btn btn-primary me-4 ml-4 col-3 mt-4" onClick={() => replaceMachine()}>Yes </button>
          <button className="btn btn-secondary col-3 mt-4" onClick={() => handleClose()}>No </button>
        </div>
      </Modal>

      <Modal open={showOldMachineShiftLocationPopup} onClose={() => {
        setShowOldMachineShiftLocationPopup(false)
        setTempOldMachineShiftLocationTicketId("")
      }} center classNames={{ root: "content-wrapper" }}>
        <div className="modal-title"></div>
        <div className="modal-body p-2">
          <div className="card">
          <label className="form-label fs-4 fw-bold">
            Where you want to shift old machine ?
          </label>
          <Select
            className="react-select"
            classNamePrefix="my-react-select"
            name="oldMachineShiftToLocationId"
            placeholder=""

            options={Array.from(locationsDB).map((value: any) => {
              return {
                value: value.id,
                label: value.name,
              };
            })}
            onChange={(e: any) => {
              const selectedId = e.value;
              setOldMachineShiftToLocation({
                ...oldMachineShiftToLocation,
                [tempOldMachineShiftLocationTicketId]: selectedId,
              });
            }}
          />
          </div>
        </div>
        <div className="modal-footer justify-content-center p-2">
          <button className="btn btn-secondary col-3 mt-4" onClick={() => {setShowOldMachineShiftLocationPopup(false)
            setTempOldMachineShiftLocationTicketId("")}
          }>Close </button>
        </div>


      </Modal>
    </>
  );
};

export default TicketsInfo;
