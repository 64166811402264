import { FC, useEffect,  useState } from "react";
import { Pagination } from "react-bootstrap";
import {
  getLocationCustomerSuccessNotes,
  createCustomerSuccessNote,
  deleteCustomerSuccessNote,
  updateCustomerSuccessNote,
} from "../../../../models/_location";
import clsx from "clsx";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import Moment from "moment";

interface LocationCustomerSuccessSpace {
  id: string;
  title: string;
  createdBy: {
    firstName: string;
    lastName: string | null
  }
  createdAt: string
}

type Props = {
  locations: any;
};

const validationSchema = yup.object({
  name: yup.string().required("Location Break name is required")
});

const LocationCustomerSuccessSpace: FC<Props> = ({ locations }) => {
  const [customerSuccessNotes, setCustomerSuccessData] = useState<
  LocationCustomerSuccessSpace[]
  >([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [loading, setLoading] = useState(false);
  const [editingCustomerSuccessNote, setEditingCustomerSuccessNote] =
    useState<LocationCustomerSuccessSpace | null>(null);

  const fetchLocationCustomerSuccessNotes = async () => {
    const locationId = localStorage.getItem("currentLocationId");
    let filter:any = {}
    if(locationId){
      filter["location_id"] = { eq: locationId }
    }
    const response = await getLocationCustomerSuccessNotes(
     filter
    ,{
      page,
      per_page: perPage,
    });

    if (response?.data) {
      setCustomerSuccessData(response.data);
    }
    setTotalPages(response?.totalPages ?? 0);
  };

  const handleDelete = async (id: string) => {
    await deleteCustomerSuccessNote(id);
    toast.success("Note deleted successfully");
    fetchLocationCustomerSuccessNotes();
  };

  const handleEdit = (customerSuccessNote: LocationCustomerSuccessSpace) => {
    setEditingCustomerSuccessNote(customerSuccessNote);
  };

  useEffect(() => {
      fetchLocationCustomerSuccessNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="mb-4">
                <h5>
                  {editingCustomerSuccessNote
                    ? "Edit Note"
                    : "Add Note"}
                </h5>
                <Formik
                  initialValues={{
                    name: editingCustomerSuccessNote?.title || ""
                  }}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={async (values, { resetForm, setSubmitting }) => {
                    setLoading(true);
                    let hasErrors = false;

                    if (hasErrors) {
                      setLoading(false);
                      return false;
                    }

                    const $inputData: any = {
                      title: values.name,
                    };

                    if (editingCustomerSuccessNote) {
                      $inputData.id = editingCustomerSuccessNote.id;
                      const result = await updateCustomerSuccessNote($inputData);
                      if (result?.data?.data?.updateCustomerSuccessSpace) {
                        toast.success("Note updated successfully");
                        setEditingCustomerSuccessNote(null);
                      } else {
                        const messages = result?.data?.errors.map(
                          (e: any) => e.message
                        );
                        toast.error(messages.join(","));
                      }
                    } else {
                      if (locations?.id) {
                        $inputData.locationId = locations.id;
                      }
                      const result = await createCustomerSuccessNote($inputData);
                      if (result?.data?.data?.createCustomerSuccessSpace) {
                        toast.success("Note created successfully");
                      } else {
                        const messages = result?.data?.errors.map(
                          (e: any) => e.message
                        );
                        toast.error(messages.join(","));
                      }
                    }

                    setLoading(false);
                    resetForm();
                    fetchLocationCustomerSuccessNotes();
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                            <label className=" form-label fs-4 required fw-bold">
                              Note
                            </label>
                            <input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary me-4 fs-4 w-100 mt-5"
                            >
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!loading && (
                                <span className="indicator-label">
                                  {editingCustomerSuccessNote
                                    ? "Update"
                                    : "Save"}
                                </span>
                              )}
                            </button>

                            
                          </div>
                          {
                            editingCustomerSuccessNote && (
                              <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                            <button
                              type="button"
                              className="btn btn-danger me-4 fs-4 w-100 mt-5"
                              onClick={() => setEditingCustomerSuccessNote(null)}
                            >
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!loading && (
                                <span className="indicator-label">
                                  Cancel
                                </span>
                              )}
                            </button>

                            
                          </div>)
                          }
                          
                         
                        </div>

                      </form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Sr No</th>
                      <th className="fs-5 text-muted">Note</th>
                      <th className="fs-5 text-muted">Created By</th>
                      <th className="fs-5 text-muted">Created At</th>
                      <th className="fs-5 text-muted">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerSuccessNotes?.length > 0 ? (
                      customerSuccessNotes.map((noteData, index) => (
                        <tr key={noteData.id}>
                          <td>{(page - 1) * perPage + index + 1}</td>
                          <td>{noteData.title}</td>
                          <td>{noteData.createdBy.firstName} {noteData.createdBy.lastName}</td>
                          <td>{Moment.utc(noteData.createdAt)
                                .local()
                                .format("YYYY-MM-DD HH:mm")}</td>
                          <td>
                            <button
                              className="btn btn-primary me-2"
                              onClick={() => handleEdit(noteData)}
                              disabled={noteData.id === editingCustomerSuccessNote?.id}
                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(noteData.id)}
                            >
                              Delete
                            </button>
                          </td>{" "}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={6}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {customerSuccessNotes?.length > 0 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  >
                    First
                  </Pagination.First>
                  <Pagination.Prev
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === page}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </Pagination.Next>
                  <Pagination.Last
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                  >
                    Last
                  </Pagination.Last>
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </div>
  );
};

export default LocationCustomerSuccessSpace;
