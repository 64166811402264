import React from "react";
import { GetBlendersType, GetTubeShieldsType } from "../../../models/_publicCall";
import Select from "react-select";
import { SiteVisitationMachine } from "../SiteVisitation";

const MachineInfo = (props: {
  values: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  logJob: any;
  tubeShieldsDB: GetTubeShieldsType[];
  blendersDB: GetBlendersType[];
  siteVisitationMachines: { [machineId: string]: SiteVisitationMachine };
  setSiteVisitationMachines: (value: { [machineId: string]: SiteVisitationMachine }) => void;
}) => {
  const { blendersDB, tubeShieldsDB, siteVisitationMachines, setSiteVisitationMachines } = props;
  return (
    <div className="wo-form-section mt-8">
      {siteVisitationMachines && Object.keys(siteVisitationMachines).length > 0 ? (
        Object.values(siteVisitationMachines).map((machine: any, index: number) => {
          
          const currentBlender = blendersDB.find((b: any) => String(b.id) === String(machine.blenderId))
          const currentTubeShield = tubeShieldsDB.find((b: any) => String(b.id) === String(machine.tubeDoorId))
          const blenderDefaultValue = currentBlender && machine.blenderId
          ? { label: currentBlender.title, value: machine.blenderId }
          : {}

          return (
            <div key={index} className="border-dotted border-2 border-gray-200 p-8 gap-2 mb-8 ">
              <h3 className="mb-3">Machine {index + 1}</h3>
              <div className="ms-12 ">
                {/* Machine Name & Serial Number */}
                <div className="d-flex align-items-center gap-2">
                  <label className="wo-label mb-0">Machine Name:</label>
                  <span className="mb-0">{machine?.name}</span>
                </div>
                <div className="d-flex align-items-center gap-2 mt-4">
                  <label className="wo-label mb-0">Serial Number:</label>
                  <span className="mb-0">{machine?.serialNumber}</span>
                </div>

                {/* Toggle Switches - Right Aligned */}
                <div className="mt-4">
                  {[
                    { label: "Machine has Polyfuses", name: "hasPolyfuses" },
                    { label: "Made 4 smoothies", name: "made4Smoothies" },
                    {
                      label:
                        "Machine Surface, Blender Bell Wiper, Bottom Wiper are clean",
                      name: "cleanlinessCheck",
                    },
                    {
                      label: "Machine Passed Getstatus",
                      name: "getStatusPassed",
                    },
                    { label: "Flowmeter Activated", name: "flowmeterActivated" },
                    {
                      label: "Water level is calibrated with scale",
                      name: "waterLevelCalibratedWithScale",
                    },
                    {
                      label: "Replace filter",
                      name: "replaceFilter",
                    },
                  ].map((item, idx) => (
                    <div
                      key={idx}
                      className="d-flex justify-content-between align-items-center mb-3"
                    >
                      <label className="wo-label">
                        {item.label}:
                      </label>
                      <div className="form-check form-switch form-check-custom">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={machine[item.name]}
                          onChange={(event) =>
                            setSiteVisitationMachines({
                              ...siteVisitationMachines,
                              [machine.id]: {
                                ...machine,
                                [item.name]: event.target.checked,
                              },
                            })
                          }
                          name={item.name}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="wo-form-section mt-8">
                <div className="d-flex align-items-center justify-content-between">
                      <label className="wo-label">
                      Sanitizer Level:
                      </label>
                      <div>
                        <button
                          type="button"
                          onClick={() =>
                            setSiteVisitationMachines({
                              ...siteVisitationMachines,
                              [machine.id]: {
                                ...machine,
                                sanitizerLevel: "LOW",
                              },
                            })} // ✅ Store in values
                          className={`btn me-2 ${siteVisitationMachines[machine.id] && siteVisitationMachines[machine.id].sanitizerLevel === "LOW"
                              ? "btn-primary"
                              : "btn-light text-dark"
                            }`}
                        >
                          Low
                        </button>

                        <button
                          type="button"
                          onClick={() => setSiteVisitationMachines({
                            ...siteVisitationMachines,
                            [machine.id]: {
                              ...machine,
                              sanitizerLevel: "MEDIUM",
                            },
                          })} // ✅ Store in values
                          className={`btn me-2 ${siteVisitationMachines[machine.id] && siteVisitationMachines[machine.id].sanitizerLevel === "MEDIUM"
                              ? "btn-primary"
                              : "btn-light text-dark"
                            }`}
                        >
                          Medium
                        </button>

                        <button
                          type="button"
                          onClick={() => setSiteVisitationMachines({
                            ...siteVisitationMachines,
                            [machine.id]: {
                              ...machine,
                              sanitizerLevel: "HIGH",
                            },
                          })} // ✅ Store in values
                          className={`btn ${siteVisitationMachines[machine.id] && siteVisitationMachines[machine.id].sanitizerLevel === "HIGH"
                              ? "btn-primary"
                              : "btn-light text-dark"
                            }`}
                        >
                          High
                        </button>
                      </div>
                    </div>
                </div>
                <div className="col-6 mb-3">
                  <label className="form-label fs-4 fw-bold">Blender</label>
                  <Select
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="blender"
                    value={blenderDefaultValue}
                    placeholder=""
                    onChange={(e: any) => {
                      setSiteVisitationMachines({
                        ...siteVisitationMachines,
                        [machine.id]: {
                          ...machine,
                          blenderId: e.value,
                        },
                      });
                    }}
                    options={blendersDB.map((value: any) => {
                      return {
                        value: value.id,
                        label: value.title,
                      };

                    })}
                  />
                </div>
                <div className="col-6 mb-3">
                  <label className="form-label fs-4 fw-bold">Tube Door Shield</label>
                  <Select
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="tubeDoorId"
                    value={
                      currentTubeShield
                        ? { label: currentTubeShield.name, value: currentTubeShield.id }
                        : null
                    }
                    placeholder=""
                    onChange={(e: any) => {
                      setSiteVisitationMachines({
                        ...siteVisitationMachines,
                        [machine.id]: {
                          ...machine,
                          tubeDoorId: e.value,
                        },
                      });
                    }}
                    options={tubeShieldsDB.map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };

                    })}
                  />
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <p className="text-muted">No machine data available.</p>
      )}
    </div>
  );
};

export default MachineInfo;
