import React, { Suspense } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Moment from "moment";
import * as _ from "lodash";
import { TicketActivity } from "./TicketActivity";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import { graphql } from 'babel-plugin-relay/macro';
import { fetchQuery,  useRelayEnvironment } from 'react-relay';
import ReAssignModal from './ReAssignModal'
import PriorityModal from './PriorityModal'
import SignatureModal from './SignatureModal'
import { Toaster } from 'react-hot-toast'
import ChangeTicketTypeModal from "./ChangeTicketTypeModal";
import { useQuery } from "react-query";
export const getTicketListById = graphql`
 query ViewTicket_getTicketQuery($id:String!){
  getTicket(id:$id){
    id
    uuid
    ticketType
    machineId
    parentId
    ticketSourceId
    issueReportedOtherInput
    machineServiceOtherInput
    operationOtherInput
    marketingOtherInput
    reportedAt
    assignToId
    issueTypeId
    priority
    reportedByType
    reportedById
    reportedByName
    reportedByPhone
    reportedByEmail
    blenderId
    boardId
    scheduleStartDate
    scheduleEndDate
    travelingStartTime
    travelingEndTime
    workStartTime
    board {
      title
      id
    }
    blender {
      title
      id
    }
    replaceNewTubeDoors1mmShorter
    reportedByUser {
      firstName
      lastName
    }
    issueType {
      id
      name
    }
    serviceType
    issuesReported {
      id
      name
    }
    machineServices {
      id
      name
    }
    operations {
      id
      name
    }
    machineServicesDone {
      id
      name
    }
    operationsDone {
      id
      name
    }
    marketingOrOthers {
      id
      name
    }
    marketingMaterials {
      id
      name
    }
    diagnosisDone {
      id
      name
    }
    diagnosisOtherInput
    comment
    chatDescription
    lastStatusComment
    lastAssigneToComment
    status
    howTicketClosed
    closingDateAndTime
    timeSpentOnTask
    travelTime
    customerSatisfaction
    cleanliness
    trafficRate
    staffEnthusiasm
    createdById
    createdAt
    updatedById
    updatedAt
    media{
      url
      type
    }
    signatureName
    signatureImage {
      url
      type
    }
    machine {
      id
      uuid
      serialNumber
      name
      locationId
      location{
        latitude
        longitude
      }
    }
    ticketSource {
      id
      name
    }
    assignTo {
      id
      firstName
      lastName
    }
    createdBy {
      id
      firstName
      lastName
    }
    updatedBy {
      id
      firstName
      lastName
    }
    history{
      action
      difference
      createdAt
    }
    subTasks {
      id
      uuid
      ticketType
      machineId
      parentId
      ticketSourceId
      issueReportedOtherInput
      machineServiceOtherInput
      operationOtherInput
      marketingOtherInput
      reportedAt
      assignToId
      issueTypeId
      priority
      issueType {
        id
        name
      }
      serviceType
      machineServices {
        id
        name
      }
      operations {
        id
        name
      }
      marketingOrOthers {
        id
        name
      }
      machineServicesDone {
        id
        name
      }
      operationsDone {
        id
        name
      }
      comment
      lastStatusComment
      lastAssigneToComment
      status
      howTicketClosed
      closingDateAndTime
      timeSpentOnTask
      travelTime
      customerSatisfaction
      cleanliness
      trafficRate
      staffEnthusiasm
      createdById
      createdAt
      updatedById
      updatedAt
      media{
        url
        type
      }
      machine {
        id
        uuid
        serialNumber
        name
        locationId
      }
      ticketSource {
        id
        name
      }
      assignTo {
        id
        firstName
        lastName
      }
      createdBy {
        id
        firstName
        lastName
      }
      updatedBy {
        id
        firstName
        lastName
      }
    }
  }
}
`

export const ViewTicket = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const environment = useRelayEnvironment();
  
  const [ticket, setTicket] = React.useState<any>({});
  const [subTasks, setSubTasks] = React.useState<any>([]);
  const [reAssignModal, setReAssignModal] = React.useState(false)
  const [priorityModalOpen, setPriorityModalOpen] = React.useState(false)
  const [signatureModalOpen, setSignatureModalOpen] = React.useState(false)
  const [changeTicketTypeModalOpen, setChangeTicketTypeModalOpen] = React.useState(false)

  const splittedRoute = location.pathname.split("/").reverse()[0];
  console.log("splittedRoute", splittedRoute)
  if (!splittedRoute) {
    console.log("splittedRoute failed")
    navigate("/tickets/list");
  }
  
  const { data: ticketRecord, refetch, isFetched } = useQuery(
        ["getTicketViewTicketById", splittedRoute],
        () => fetchQuery(environment, getTicketListById, { id: splittedRoute ?? undefined }).toPromise(),
        {
          enabled: !!splittedRoute,
        }
      );
  
      console.log("ticketRecord", ticketRecord)

  const refresh = React.useCallback(() => {
    refetch()
  }, []);

  const handleCloseReAssign = () => {
    setReAssignModal(false)
    refresh()
  }
  const handleClosePriority = () => {
    setPriorityModalOpen(false)
    refresh()
  }
  const handleCloseTicketType = () => {
    setChangeTicketTypeModalOpen(false)
    refresh()
  }
  const handleCloseSiganture = () => {
    setSignatureModalOpen(false)
    refresh()
  }

  React.useEffect(() => {
    if(!isFetched) return;
    const ticketObj: any = ticketRecord && typeof ticketRecord === 'object' && 'getTicket' in ticketRecord ? ticketRecord.getTicket : null;

    if (ticketObj == null) {
      console.log("ticketObj failed", isFetched, ticketRecord)

      navigate("/tickets/list");
    } else {
      setTicket(ticketObj);
      const subTaskCurrent = ticketObj.subTasks ?? [];
      setSubTasks(subTaskCurrent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, ticketRecord]);

  return (
    <Suspense>
      
      {(isFetched && ticket) && (
        <>
        <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap"></div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item text-muted">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                  <a href="/tickets/list" className="text-muted ps-2 fs-5">
                    Ticket Logs
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item fs-5 text-dark">Ticket Detail</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="mx-xl-4 mt-9">
          <div className="card">
            <div className="card-header cursor-pointer border-bottom-0 py-4">
              <div className="card-title m-0">
                <h3 className="fw-semibold m-0">
                  Ticket Details - {ticket.uuid}
                </h3>
              </div>
              <div>
                {ticket.status === "COMPLETED" && (
                  <Link
                    to={`/tickets/list/statuschange/${ticket.id}`}
                    className="btn align-self-center p-0 mt-lg-0 mt-md-0 mt-3"
                  >
                    <button data-bs-toggle="tooltip" title="Click here to status change" className="btn btn-secondary btn px-5 py-3 m-1">
                      Status Change
                    </button>
                  </Link>
                )}
                {ticket.status !== "COMPLETED" && ticket.status !== "ARCHIVED" && (
                  <>
                    <Link
                      to={`/tickets/list/statuschange/${ticket.id}`}
                      className="btn align-self-center p-0 mt-lg-0 mt-md-0 mt-3"
                    >
                      <button data-bs-toggle="tooltip" title="Click here to status change" className="btn btn-secondary btn px-5 py-3 m-1">
                        Status Change
                      </button>
                    </Link>
                    <Link
                      to={`/tickets/list/task/create/${ticket.id}`}
                      className="btn align-self-center p-0 mt-lg-0 mt-md-0 mt-3"
                    >
                      <button data-bs-toggle="tooltip" title="Click here to create task" className="btn btn-secondary btn px-5 py-3 m-1">
                        Create Task
                      </button>
                    </Link>
                    <button data-bs-toggle="tooltip" title="Click here to re-assign" onClick={() => { setReAssignModal(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                      Re-assign
                    </button>
                    <button data-bs-toggle="tooltip" title="Click here to change priority" onClick={() => { setPriorityModalOpen(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                      Priority
                    </button>
                    <button data-bs-toggle="tooltip" title="Click here to change ticket type" onClick={() => { setChangeTicketTypeModalOpen(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                      Ticket Type Change
                    </button>
                  </>
                )}
                {ticket.status === "COMPLETED" && ticket.signatureName === null && (
                  <button data-bs-toggle="tooltip" title="Click here to add signature" onClick={() => { setSignatureModalOpen(true) }} className="btn btn-secondary btn px-5 py-3 m-1">
                    <i className="fa fa-signature fs-4" ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
           <ReAssignModal reAssignModal={reAssignModal}
              handleCloseReAssign={handleCloseReAssign}
              currentTicketId={splittedRoute}
            />
           <PriorityModal
              priorityModalOpen={priorityModalOpen}
              handleClosePriority={handleClosePriority}
              currentTicketId={splittedRoute}
            />
            <SignatureModal
              signatureModalOpen={signatureModalOpen}
              handleCloseSiganture={handleCloseSiganture}
              currentTicketId={splittedRoute}
            />
          <ChangeTicketTypeModal
                changeTicketTypeModal={changeTicketTypeModalOpen}
                currentTicketId={splittedRoute}
                handleCloseTicketType={handleCloseTicketType}
              />
          <div className="mt-1 row g-6  g-xl-9">
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-8 pb-8 ">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Ticket Type
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.ticketType))}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Machine Name
                    </label>
                    <div className="col-lg-6">
                      <Link
                        to={`/machines/list/history/${ticket.machineId}`}
                        className="text-primary"
                      >
                        <div className="fs-4 text-primary">
                          {ticket.machine?.name ?? ""}
                          <i className="fa fa-external-link ms-3"></i>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Serial Number
                    </label>
                    <div className="col-lg-6">
                      <Link
                        to={`/machines/list/history/${ticket.machineId}`}
                        className="text-primary"
                      >
                      <div className="fs-4 text-primary">
                        {ticket.machine?.serialNumber ?? ""}
                      </div>
                      </Link>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Ticket Source
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.ticketSource?.name ?? ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fw-semibold text-muted fs-4">
                      Service Type
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.serviceType))}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Assign To
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.assignTo?.firstName ?? ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Comment
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">{ticket.comment}</span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Chat Description
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.chatDescription}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Priority
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.priority))}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fw-semibold fs-4 text-muted">
                      Reported At
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {Moment.unix(ticket.reportedAt / 1000)
                          .local()
                          .format("YYYY-MM-DD HH:mm:ss")}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fw-semibold fs-4 text-muted">
                      Reported By
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {(ticket.reportedByType === 'INTERNAL') ? (ticket?.reportedByUser ?(ticket.reportedByUser?.firstName + ' ' + ticket.reportedByUser?.lastName):"") : ticket?.reportedByName}
                        {(ticket.reportedByType === 'EXTERNAL') ? (<p>{ticket?.reportedByEmail}</p>) : ''}
                        {(ticket.reportedByType === 'EXTERNAL') ? (<p>{ticket?.reportedByPhone}</p>) : ''}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fw-semibold text-muted fs-4">
                      Status
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.status))}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Created By
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.createdBy?.firstName ?? ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Assign Remark
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.lastAssigneToComment}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Status Remark
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.lastStatusComment}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Closing Date & Time
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.closingDateAndTime
                          ? Moment.unix(ticket.closingDateAndTime / 1000)
                            .local()
                            .format("YYYY-MM-DD HH:mm:ss")
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Blender
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket?.blender?.title}
                      </span>
                    </div>
                  </div>

                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Board
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket?.board?.title}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Replace tube door -1mm shorter
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {ticket.replaceNewTubeDoors1mmShorter}
                        {ticket.replaceNewTubeDoors1mmShorter === true ? (
                          <span className="badge badge-light-success fw-bolder me-auto px-4 py-2">
                            Yes
                          </span>
                        ) : ''}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted mb-3">
                    Location Address
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  {ticket.machine?.location?.latitude !== "" &&
                    ticket.machine?.location?.longitude !== "" ? (
                    <div className="responsive-map-container">
                      <iframe
                        allowFullScreen
                        title="location-address"
                        src={`//maps.google.com/maps?q=${ticket.machine?.location?.latitude},${ticket.machine?.location?.longitude}&z=15&output=embed`}
                      ></iframe>
                    </div>
                  ) : (
                    <div>
                      <span className="fs-6">No location detail found</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Closing service type
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.howTicketClosed))}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Time Spent On Task (Minutes)
                    </label>
                    <div className="col-lg-6  fv-row">
                      <span className="text-dark fs-4">
                        {ticket.timeSpentOnTask}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Travel Time (Minutes)
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">{ticket.travelTime}</span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6  fs-4 fw-semibold text-muted">
                      Customer Satisfaction
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.customerSatisfaction)) ??
                          ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Staff Enthusiasm
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.staffEnthusiasm)) ?? ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fs-4 fw-semibold text-muted">
                      Traffic Rate
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.trafficRate)) ?? ""}
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    <label className="col-lg-6 fw-semibold text-muted fs-4">
                      Cleanliness
                    </label>
                    <div className="col-lg-6 fv-row">
                      <span className="text-dark fs-4">
                        {_.capitalize(_.lowerCase(ticket.cleanliness)) ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                      <label className="card-title fs-4 fw-semibold text-muted">
                        Machine Services Needed
                      </label>
                      <div className="separator separator-dashed my-3"></div>
                      <div className="mt-2 fv-row">
                        {ticket.machineServices?.length > 0
                          ? ticket.machineServices?.map((element: any) => {
                            return (
                              <li className="fs-5 text-dark">{element.name}</li>
                            );
                          })
                          : ""}
                        {ticket.machineServiceOtherInput !== "" && (
                          <li className="fs-5 text-dark">
                            {ticket.machineServiceOtherInput}
                          </li>
                        )}
                        {ticket.machineServices?.length === 0 &&
                          ticket.machineServiceOtherInput === "" && (
                            <>
                              <div>
                                <span className="fs-6">No records found</span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                      <label className="card-title fs-4 fw-semibold text-muted">
                        Machine Services Done
                      </label>
                      <div className="separator separator-dashed my-3"></div>
                      <div className="mt-2 fv-row">
                        {ticket.machineServicesDone?.length > 0
                          ? ticket.machineServicesDone?.map((element: any) => {
                            return (
                              <li className="fs-5 text-dark">{element.name}</li>
                            );
                          })
                          : ""}
                        {ticket.machineServiceOtherInput !== "" && (
                          <li className="fs-5 text-dark">
                            {ticket.machineServiceOtherInput}
                          </li>
                        )}
                        {ticket.machineServicesDone?.length === 0 &&
                          ticket.machineServiceOtherInput === "" && (
                            <>
                              <div>
                                <span className="fs-6">No records found</span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="row mb-5">
                <div className="col-12">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                      <label className=" fs-4 fw-semibold text-muted">
                        Operations Needed
                      </label>
                      <div className="separator separator-dashed my-3"></div>
                      <div className=" mt-2 fv-row">
                        {ticket.operations?.length > 0
                          ? ticket.operations?.map((element: any) => {
                            return (
                              <li className="fs-5 text-dark">{element.name}</li>
                            );
                          })
                          : ""}
                        {ticket.operationOtherInput !== "" && (
                          <li className="fs-5 text-dark">
                            {ticket.operationOtherInput}
                          </li>
                        )}
                        {ticket.operations?.length === 0 &&
                          ticket.operationOtherInput === "" && (
                            <>
                              <div>
                                <span className="fs-6">No records found</span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-12">
                  <div className="card h-100">
                    <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                      <label className=" fs-4 fw-semibold text-muted">
                        Operations Done
                      </label>
                      <div className="separator separator-dashed my-3"></div>
                      <div className=" mt-2 fv-row">
                        {ticket.operationsDone?.length > 0
                          ? ticket.operationsDone?.map((element: any) => {
                            return (
                              <li className="fs-5 text-dark">{element.name}</li>
                            );
                          })
                          : ""}
                        {ticket.operationOtherInput !== "" && (
                          <li className="fs-5 text-dark">
                            {ticket.operationOtherInput}
                          </li>
                        )}
                        {ticket.operationsDone?.length === 0 &&
                          ticket.operationOtherInput === "" && (
                            <>
                              <div>
                                <span className="fs-6">No records found</span>
                              </div>
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-6 g-xl-9 mt-4">
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className=" fs-4 fw-semibold text-muted">
                    Issue Reported
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  <div className=" mt-2 fv-row">
                    {ticket.issuesReported?.length > 0
                      ? ticket.issuesReported?.map((element: any) => {
                        return (
                          <li className="fs-5 text-dark">{element.name}</li>
                        );
                      })
                      : ""}
                    {ticket.issueReportedOtherInput !== "" && (
                      <li className="fs-5 text-dark">
                        {ticket.issueReportedOtherInput}
                      </li>
                    )}
                    {ticket.issuesReported?.length === 0 &&
                      ticket.issueReportedOtherInput === "" && (
                        <>
                          <div>
                            <span className="fs-6">No records found</span>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className=" fs-4 fw-semibold text-muted">
                    Marketing Purpose
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  <div className=" mt-2 fv-row">
                    {ticket.marketingOrOthers?.length > 0
                      ? ticket.marketingOrOthers?.map((element: any) => {
                        return (
                          <li className="fs-5 text-dark">{element.name}</li>
                        );
                      })
                      : ""}
                    {ticket.marketingOtherInput !== "" && (
                      <li className="fs-5 text-dark">
                        {ticket.marketingOtherInput}
                      </li>
                    )}
                    {ticket.marketingOrOthers?.length === 0 &&
                      ticket.marketingOtherInput === "" && (
                        <>
                          <div>
                            <span className="fs-6">No records found</span>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted">
                    Diagnosis Done
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  <div className="mt-2 fv-row">
                    {ticket.diagnosisDone?.length > 0
                      ? ticket.diagnosisDone?.map((element: any) => {
                        return (
                          <li className="fs-5 text-dark">{element.name}</li>
                        );
                      })
                      : ""}
                    {ticket.diagnosisOtherInput !== null && 
                    ticket.diagnosisOtherInput !== "" && (
                      <li className="fs-5 text-dark">
                        {ticket.diagnosisOtherInput}
                      </li>
                    )}
                    {ticket.diagnosisDone?.length === 0 &&
                      ticket.diagnosisOtherInput === null &&
                      ticket.diagnosisOtherInput === "" && (
                        <>
                          <div>
                            <span className="fs-6">No records found</span>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="card h-100">
                <div className="card-body d-flex flex-column px-9 pt-6 pb-8">
                  <label className="card-title fs-4 fw-semibold text-muted">
                    Marketing Materials Used
                  </label>
                  <div className="separator separator-dashed my-3"></div>
                  <div className="mt-2 fv-row">
                    {ticket.marketingMaterials?.length > 0 ? (
                      ticket.marketingMaterials?.map((element: any) => {
                        return <li className="fs-5 text-dark">{element.name}</li>;
                      })
                    ) : (
                      <>
                        <div>
                          <span className="fs-6">No records found</span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {ticket.subTasks?.length > 0 && (
            <>
              <div className="card mt-11">
                <div className="card-header border-0 pt-5">
                  <div className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0">
                    Tasks
                  </div>
                </div>
                <div className="card-body mt-0 pt-0">
                  <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bold text-muted">
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Assign To
                          </th>
                          <th className="min-w-200px fs-5 mt-0 pt-0">
                            Machine Services
                          </th>
                          <th className="min-w-200px fs-5 mt-0 pt-0">
                            Operations
                          </th>
                          <th className="min-w-200px fs-5 mt-0 pt-0">
                            Marketing Purpose
                          </th>
                          <th className="min-w-200px  fs-5 mt-0 pt-0">Comment</th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Created At
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Created By
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0 ">
                            Priority
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">Status</th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Assign Remark
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Status Remark
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Closing Date & Time
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0 ">
                            Closing service type
                          </th>
                          <th className=" min-w-150px fs-5 mt-0 pt-0">
                            Time Spent On Task (Minutes)
                          </th>
                          <th className="min-w-150px fs-5 mt-0 pt-0">
                            Travel Time (Minutes)
                          </th>
                          <th className="min-w-100px fs-5 mt-0 pt-0">
                            Customer Satisfaction
                          </th>
                          <th className=" min-w-100px fs-5 mt-0 pt-0">
                            Staff Enthusiasm
                          </th>
                          <th className="min-w-100px fs-5 mt-0 pt-0">
                            Traffic Rate
                          </th>
                          <th className="min-w-100px fs-5 mt-0 pt-0 ">
                            Cleanliness
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {subTasks.map((subTask: any) => (
                          <>
                            <tr>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.assignTo?.firstName ?? ""}
                                </div>
                              </td>
                              <td>
                                <ul>
                                  {subTask.machineServices?.map(
                                    (element: any) => {
                                      return (
                                        <li className="fs-5 text-dark">
                                          {element.name}
                                        </li>
                                      );
                                    }
                                  )}
                                  {subTask.machineServiceOtherInput !== "" ? (
                                    <li className="fs-5 text-dark">
                                      {subTask.machineServiceOtherInput}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  {subTask.operations?.map((element: any) => {
                                    return (
                                      <li className="fs-5 text-dark">
                                        {element.name}
                                      </li>
                                    );
                                  })}
                                  {subTask.operationOtherInput !== "" ? (
                                    <li className="fs-5 text-dark">
                                      {subTask.operationOtherInput}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </td>
                              <td>
                                <ul>
                                  {subTask.marketingOrOthers?.map(
                                    (element: any) => {
                                      return (
                                        <li className="fs-5 text-dark">
                                          {element.name}
                                        </li>
                                      );
                                    }
                                  )}
                                  {subTask.marketingOtherInput !== "" ? (
                                    <li className="fs-5 text-dark">
                                      {subTask.marketingOtherInput}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.comment}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {Moment.utc(subTask.createdAt)
                                    .local()
                                    .format("YYYY-MM-DD HH:mm:ss")}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.createdBy?.firstName ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(_.lowerCase(subTask.priority)) ??
                                    ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(_.lowerCase(subTask.status)) ??
                                    ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.lastAssigneToComment ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.lastStatusComment ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.closingDateAndTime
                                    ? Moment.unix(
                                      subTask.closingDateAndTime / 1000
                                    )
                                      .local()
                                      .format("YYYY-MM-DD HH:mm:ss") ?? ""
                                    : ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(
                                    _.lowerCase(subTask.howTicketClosed)
                                  ) ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.timeSpentOnTask ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {subTask.travelTime ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(
                                    _.lowerCase(subTask.customerSatisfaction)
                                  ) ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(
                                    _.lowerCase(subTask.staffEnthusiasm)
                                  ) ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(
                                    _.lowerCase(subTask.trafficRate)
                                  ) ?? ""}
                                </div>
                              </td>
                              <td>
                                <div className="text-dark d-block fs-5">
                                  {_.capitalize(
                                    _.lowerCase(subTask.cleanliness)
                                  ) ?? ""}
                                </div>
                              </td>
                              <td></td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="row align-align-items-center justify-content-center mt-9 mx-xl-1">
          <div className="col-xl-6 col-lg-12 col-md-12 col-12 mt-2 pt-2">
            <div className="card">
              <div className="card-body">
                <TicketActivity ticket={ticket} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-12 mt-2 pt-2">
            {ticket.media?.length > 0 && (
              <>
                <div className="card mb-9">
                  <div className="card-body">
                    <span className="btn-bg-light btn fs-2 fw-semibold text-start mb-4 border-0">
                      Ticket Images
                    </span>
                    <div className="rounded-1 h-100 shadow-none">
                      <div className="row align-items-center">
                        {ticket.media?.map((image: any, index: any) => {
                          return (
                            <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-4 pt-2">
                              {image["type"] === "image/png" ||
                                image["type"] === "image/jpeg" ||
                                image["type"] === "image/webp" ||
                                image["type"] === "image/jpg" ? (
                                <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
                                  <img
                                    src={image["url"]}
                                    key={image}
                                    width="250"
                                    alt="Images"
                                    className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light mt-2"
                                  />
                                </SlideshowLightbox>
                              ) : (
                                <video
                                  controls
                                  src={image["url"]}
                                  className="img-view img-fluid position-relative overflow-hidden rounded-1 p-2 bg-light mt-2"
                                  width="250"
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {ticket.signatureName !== null && (
              <>
                <div className="card">
                  <div className="card-body">
                    <div className="btn-bg-light btn fs-2 fw-semibold text-start mb-6 border-0">
                      Signature
                    </div>
                    <div className="text-muted mt-1 fw-semibold fs-4 mt-2">
                      Signature By : {ticket.signatureName}
                    </div>
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-3 mt-6 ">
                      <div className="shadow-none align-items-center justify-content-center">
                        {ticket.signatureImage && (
                          <div className="rounded-1 shadow-sm bg-light">
                            <img
                              src={ticket.signatureImage["url"]}
                              width="250"
                              alt="Upload"
                              className="img-fluid overflow-hidden m-2 d-flex position-relative rounded-1"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
      </>
      )}


    </Suspense>
  );
};
