/**
 * @generated SignedSource<<7f6d74897e6ceaeec4be78bdb58c95f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConsumptionSummaryParentComponentQuery$variables = {};
export type ConsumptionSummaryParentComponentQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getMachineList: ReadonlyArray<{
    readonly id: string;
    readonly locationId: string | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly uuid: string | null;
  }>;
};
export type ConsumptionSummaryParentComponentQuery = {
  response: ConsumptionSummaryParentComponentQuery$data;
  variables: ConsumptionSummaryParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getMachineList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConsumptionSummaryParentComponentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConsumptionSummaryParentComponentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "61b89491f5e1ec43a4f0fc237f0f3ef3",
    "id": null,
    "metadata": {},
    "name": "ConsumptionSummaryParentComponentQuery",
    "operationKind": "query",
    "text": "query ConsumptionSummaryParentComponentQuery {\n  getMachineList {\n    id\n    uuid\n    serialNumber\n    name\n    locationId\n  }\n  getCustomerList {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "99f3f4ab327074884f697823261c1663";

export default node;
