import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateCustomerInput {
  name: string;
  customerCompanyName: string;
  customerContactName: string;
  customerContactPhoneNumber: string;
  customerContactEmail: string;
  customerContactNameRole: string;
  accountType: string;
  distributorId: string;
  distributorOtherInput?: string;
  customerSecondContact?: boolean;
  customerSecondContactName?: string;
  customerSecondContactPhoneNumber?: string;
  customerSecondContactEmail?: string;
  customerSecondContactNameRole?: string;
  typeOfContactPhoneNumber?: string;
  typeOfSecondContactPhoneNumber?: string;
  isActive: boolean;
}

export async function createCustomer(input: CreateCustomerInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation createCustomer (
        $input: CreateCustomerInput!
        ){
        createCustomer(
            createCustomerInput: $input
          ) 
      }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getCustomers(where: {} = {},pagination: {} | undefined = undefined) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getCustomers(  
              $where: CustomersResolver_GetCustomers_FilterInputType
              $paginate:PaginatorArgs
              ) {
                  getCustomers(where: $where ,paginate: $paginate) {
                      data {
                        id
                        uuid
                        name
                        customerCompanyName
                        customerContactName
                        customerContactPhoneNumber
                        customerContactEmail
                        customerContactNameRole
                        accountType
                        distributorId
                        customerSecondContact
                        customerSecondContactName
                        customerSecondContactPhoneNumber
                        customerSecondContactEmail
                        customerSecondContactNameRole
                        createdById
                        createdAt
                        updatedById
                        updatedAt
                        isActive
                        typeOfContactPhoneNumber
                        typeOfSecondContactPhoneNumber
                        isVip
                        locations {
                          id,
                          name
                        }
                        distributor{
                          id
                          name
                        }
                        createdBy{
                          id
                          firstName
                         lastName
                         }
                         updatedBy{
                          id
                          firstName
                         }
                      }
                      totalPages
                      total
                      perPage
                      page 
                    }
            }
            `,
      variables: { where,paginate: pagination },
    },
  });
  return data?.data?.data?.getCustomers;
}

export async function getCustomersForFilter(where: {} = {},pagination: {} | undefined = undefined) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getCustomers(  
              $where: CustomersResolver_GetCustomers_FilterInputType
              $paginate:PaginatorArgs
              ) {
                  getCustomers(where: $where ,paginate: $paginate) {
                      data {
                        id
                        name
                      }
                    }
            }
            `,
      variables: { where,paginate: pagination },
    },
  });
  return data?.data?.data?.getCustomers;
}

export async function getCustomerList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                  getCustomerList{
                    id
                    name
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getCustomerList;
}

export async function updateCustomer(id: string, input: CreateCustomerInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updateCustomer (
              $id: String!
              $input: CreateCustomerInput!
        ){
         updateCustomer(
              id: $id
            createCustomerInput: $input
          ) 
      }
      `,
      variables: {
        id,
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateCustomerStatus(id: string, isActive: boolean,confirmedToActivateLocationsAndMachines: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ,$confirmedToActivateLocationsAndMachines: Boolean!) {
              updateCustomerStatus(
                updateCustomerStatusInput: { id: $id, isActive: $isActive, confirmedToActivateLocationsAndMachines: $confirmedToActivateLocationsAndMachines }
              )
            }
            `,
      variables: {
        id,
        isActive,
        confirmedToActivateLocationsAndMachines
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getDistributorsForFilter() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
            getDistributors {
                id
                name
            }
          }
        `,
      variables: {},
    },
  });
  return data?.data?.data?.getDistributors;
}

export async function getCustomerRevisions(
  inputs: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getCustomerRevisions(
              $inputs: GetCustomersRevisionInputs!
        ){
          getCustomerRevisions(inputs: $inputs) {
            customerRevision
        }
      }`,
      variables: { inputs },
    },
  });
  return data?.data?.data?.getCustomerRevisions?.customerRevision;
}