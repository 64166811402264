import { FC, useEffect, useState } from "react";
import Moment from "moment";
import { Pagination, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { getUsers } from "../../../../models/_user";
import { getCustomerRevisions } from "../../../../models/_customer";

export const getFieldName = (field: string) => {
  const fieldNames: { [key: string]: string } = {
    updated_at: "Updated At",
    created_at: "Created At",
    name: "Customer Name",
    is_active: "Customer Status",
    account_type:"Account Type",
    is_vip:"Is VIP",
    customer_company_name:"Customer Company Name",
    customer_contact_phone_number:"Customer Contact Phone Number",
    customer_contact_name:"Customer Contact Name",
    type_of_contact_phone_number:"Type of Contact Phone Number",
    customer_contact_name_role:"Customer Contact Name Role",
    customer_contact_email:"Customer Contact Email",
    deleted_by_id: "Deleted By",
    updated_by_id: "Updated By",
    distributor_id: "Distributor Name",
  };
  return fieldNames[field] || field;
};
type Props = {
  customerHistory: any;
  page: any;
  perPage: any;
  setPage: (page: any) => void;
};

const CustomerRevision: FC<Props> = ({
  customerHistory,
  page,
  perPage,
  setPage,
}) => {
  const [selectedRevisions, setSelectedRevisions] = useState<any[]>([]);
  const [customerHistoryRevisions, setCustomerHistoryRevisions] = useState<any>([]);
  const [customerHistoryData, setCustomerHistoryData] = useState<any>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [userList, setUserList] = useState([]);

  const handleCheckboxChange = (revision: any, isChecked: boolean) => {
    setSelectedRevisions((prev) => {
      if (isChecked) {
        if (prev.length < 2) {
          return [...prev, revision];
        } else {
          toast.error("You can only select 2 revisions");
          return prev;
        }
      } else {
        return prev.filter((r) => r.id !== revision.id);
      }
    });
  };

  const handleGetCustomerRevisions = async (customerHistoryIds: string[]) => {
    const response = await getCustomerRevisions({ ids: customerHistoryIds });
    setCustomerHistoryRevisions(response);
  };

  useEffect(() => {
    if (selectedRevisions.length === 2) {
      const ids = selectedRevisions.map((revision: any) => revision.id);
      handleGetCustomerRevisions(ids);
    } else {
      setCustomerHistoryRevisions([]);
    }
  }, [selectedRevisions, setSelectedRevisions]);

  useEffect(() => {
    setCustomerHistoryData(customerHistory?.data);
    setTotalPages(customerHistory?.totalPages ?? 0);
  }, [customerHistoryData, customerHistory]);

  const handleGetUserList = async () => {
    const response = await getUsers();
    if (response.length > 0) {
      setUserList(response);
    }
  };

  useEffect(() => {
    handleGetUserList();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-7">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Select Version</th>
                      <th className="fs-5 text-muted">Versions</th>
                      <th className="fs-5 text-muted">Created At</th>
                      <th className="fs-5 text-muted">Updated By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerHistoryData?.length > 0 ? (
                      customerHistoryData.map(
                        (revision: any, index: number) => (
                          <tr key={index}>
                            <td>
                              <label className="form-check form-check-sm form-check-custom form-check-solid align-items-center">
                                <input
                                  className="form-check-input ms-2"
                                  type="checkbox"
                                  checked={selectedRevisions.some(
                                    (r) => r.id === revision.id
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      revision,
                                      e.target.checked
                                    )
                                  }
                                />
                              </label>
                            </td>
                            <td>{(page - 1) * perPage + index + 1}</td>
                            <td>
                              {Moment(revision.operationExecutedAt)
                                .local()
                                .format("YYYY-MM-DD HH:mm")}
                            </td>
                            <td>
                              {userList
                                ?.filter(
                                  (user: any) =>
                                    user.id ===
                                    revision.updated_by_id
                                )
                                ?.map((user: any) => user.firstName)}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={3}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {customerHistoryData?.length > 0 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  >
                    First
                  </Pagination.First>
                  <Pagination.Prev
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === page}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </Pagination.Next>
                  <Pagination.Last
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                  >
                    Last
                  </Pagination.Last>
                </Pagination>
              </div>
            )}
          </div>
        </div>
        {customerHistoryRevisions?.length > 0 && (
          <div className="col-7">
            <div className="card mt-9 mx-xl-4">
              <div className="mt-3 col-md-12">
                <Table bordered hover responsive>
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Field</th>
                      <th style={{ width: "40%" }}>Old Customer Value</th>
                      <th style={{ width: "40%" }}>New Customer Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {customerHistoryRevisions.map(
                      (change: any, i: any) => (
                        <tr key={i}>
                          <td>{getFieldName(change.path)}</td>
                          <td className="text-danger">
                            <i className="me-2 text-danger bi bi-dash-circle"></i>
                            {change.oldVal === true
                              ? "Yes"
                              : change.oldVal === false
                              ? "No"
                              : change.oldVal ? (Moment(change.oldVal.toString(),"", true).isValid()
                              ? Moment.utc(change.oldVal)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm")
                              : change.oldVal) : "N/A"}
                          </td>
                          <td className="text-success">
                            <i className="me-2 text-success bi bi-plus-circle"></i>
                            {change.newVal === true
                              ? "Yes"
                              : change.newVal === false
                              ? "No"
                              : change.newVal ? (Moment(change.newVal.toString(),"", true).isValid()
                              ? Moment.utc(change.newVal)
                                  .local()
                                  .format("YYYY-MM-DD HH:mm")
                              : change.newVal) : "N/A"}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerRevision;