import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateBusinessModelInput {
  name: string;
  description: string;
  isActive: boolean;
}
export async function createBusinessModel(input: CreateBusinessModelInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createBusinessModel (
          $input:CreateBusinessModelInput!
        ) {
          createBusinessModel(
            createBusinessModelInput:$input
          )
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateBusinessModel(id: string, input: CreateBusinessModelInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateBusinessModel (
          $id: String!
          $input: CreateBusinessModelInput!
        ) {
          updateBusinessModel(
            id: $id
            createBusinessModelInput: $input
          )
        }
      `,
      variables: { id, input },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateBusinessModelStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation (
          $id: String!, 
          $isActive: Boolean! 
        ) {
          updateBusinessModelStatus(
            updateBusinessModelStatus: { 
              id: $id, 
              isActive: $isActive 
            }
          )
        }
      `,
      variables: { id, isActive },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}