/**
 * @generated SignedSource<<808cefc1ddd3806e83a83f0889cba388>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomerSatisfactionParentComponentQuery$variables = {};
export type CustomerSatisfactionParentComponentQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getLocationList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
  readonly getLocationTypes: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly isDefault: boolean | null;
    readonly name: string;
  }>;
  readonly getMachineStatus: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly getMarketSegment: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type CustomerSatisfactionParentComponentQuery = {
  response: CustomerSatisfactionParentComponentQuery$data;
  variables: CustomerSatisfactionParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionObjectType",
    "kind": "LinkedField",
    "name": "getRegions",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineStatusObjectType",
    "kind": "LinkedField",
    "name": "getMachineStatus",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationTypeObjectType",
    "kind": "LinkedField",
    "name": "getLocationTypes",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MarketSegmentObjectType",
    "kind": "LinkedField",
    "name": "getMarketSegment",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerSatisfactionParentComponentQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerSatisfactionParentComponentQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b3f52d9b84fff80e0940bb0ab9682273",
    "id": null,
    "metadata": {},
    "name": "CustomerSatisfactionParentComponentQuery",
    "operationKind": "query",
    "text": "query CustomerSatisfactionParentComponentQuery {\n  getRegions {\n    id\n    name\n  }\n  getLocationList {\n    id\n    uuid\n    name\n  }\n  getMachineStatus {\n    id\n    name\n    description\n  }\n  getLocationTypes {\n    id\n    name\n    description\n    isDefault\n  }\n  getCustomerList {\n    id\n    name\n  }\n  getMarketSegment {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd08ab3f42065241e38a5c109ee19361";

export default node;
