/**
 * @generated SignedSource<<6f9725f0b8c5584d941db5dae8b91d70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Cleanliness = "BAD" | "GREAT" | "MEDIUM" | "%future added value";
export type CustomerSatisfaction = "HIGH" | "LOW" | "MEDIUM" | "%future added value";
export type JobTypes = "INSTALLATION" | "INSTALLATION_FOLLOW_UP" | "SAMPLE_EVENT" | "SITE_VISIT" | "%future added value";
export type StaffEnthusiasm = "HIGH" | "LOW" | "MEDIUM" | "%future added value";
export type Status = "COMPLETED" | "IN_PROCESS" | "ON_HOLD" | "PENDING" | "%future added value";
export type StatusOfSanitizer = "HIGH" | "LOW" | "MEDIUM" | "%future added value";
export type TrafficRate = "HIGH" | "LOW" | "MEDIUM" | "%future added value";
export type ViewJob_getLogJobQuery$variables = {
  id: string;
};
export type ViewJob_getLogJobQuery$data = {
  readonly getLogJob: {
    readonly additionalComments: string | null;
    readonly attachments: ReadonlyArray<{
      readonly type: string | null;
      readonly url: string | null;
    }> | null;
    readonly cleanliness: Cleanliness | null;
    readonly closingDateAndTime: string | null;
    readonly createdBy: {
      readonly firstName: string;
      readonly id: string;
    } | null;
    readonly customerSatisfaction: CustomerSatisfaction | null;
    readonly dateAndTime: string;
    readonly id: string;
    readonly jobType: JobTypes;
    readonly locationId: string;
    readonly machineChecks: ReadonlyArray<{
      readonly checks_detail: any | null;
      readonly location_id: string | null;
      readonly log_job_id: string;
      readonly machine_id: string | null;
    }> | null;
    readonly machineName: string | null;
    readonly machineSerialNumber: string | null;
    readonly marketingMaterials: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly operations: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly sanitizerStatus: StatusOfSanitizer | null;
    readonly staffEnthusiasm: StaffEnthusiasm | null;
    readonly status: Status;
    readonly tickets: ReadonlyArray<{
      readonly diagnosisDone: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null;
      readonly id: string;
      readonly issuesReported: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }> | null;
    }> | null;
    readonly trafficRate: TrafficRate | null;
    readonly travelTime: number | null;
    readonly uploadImagesOrVideos: ReadonlyArray<{
      readonly type: string | null;
      readonly url: string | null;
    }> | null;
    readonly uuid: string | null;
  };
};
export type ViewJob_getLogJobQuery = {
  response: ViewJob_getLogJobQuery$data;
  variables: ViewJob_getLogJobQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  }
],
v3 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LogJobsObjectType",
    "kind": "LinkedField",
    "name": "getLogJob",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jobType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateAndTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MediaDto",
        "kind": "LinkedField",
        "name": "attachments",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LogJobMachineCheckObjectType",
        "kind": "LinkedField",
        "name": "machineChecks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "log_job_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "location_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "machine_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checks_detail",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TicketObjectType",
        "kind": "LinkedField",
        "name": "tickets",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "IssueReportedObjectType",
            "kind": "LinkedField",
            "name": "issuesReported",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "DiagnosisObjectType",
            "kind": "LinkedField",
            "name": "diagnosisDone",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperationObjectType",
        "kind": "LinkedField",
        "name": "operations",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MediaDto",
        "kind": "LinkedField",
        "name": "uploadImagesOrVideos",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MarketingMaterialsObjectType",
        "kind": "LinkedField",
        "name": "marketingMaterials",
        "plural": true,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sanitizerStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineSerialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalComments",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerSatisfaction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cleanliness",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trafficRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "staffEnthusiasm",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "closingDateAndTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "travelTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserObjectType",
        "kind": "LinkedField",
        "name": "createdBy",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewJob_getLogJobQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewJob_getLogJobQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "74746fb0c20652a03f6ab41be21c2577",
    "id": null,
    "metadata": {},
    "name": "ViewJob_getLogJobQuery",
    "operationKind": "query",
    "text": "query ViewJob_getLogJobQuery(\n  $id: String!\n) {\n  getLogJob(id: $id) {\n    id\n    uuid\n    jobType\n    locationId\n    dateAndTime\n    attachments {\n      url\n      type\n    }\n    machineChecks {\n      log_job_id\n      location_id\n      machine_id\n      checks_detail\n    }\n    tickets {\n      id\n      issuesReported {\n        id\n        name\n      }\n      diagnosisDone {\n        id\n        name\n      }\n    }\n    operations {\n      id\n      name\n    }\n    uploadImagesOrVideos {\n      url\n      type\n    }\n    marketingMaterials {\n      id\n      name\n    }\n    sanitizerStatus\n    machineSerialNumber\n    machineName\n    additionalComments\n    customerSatisfaction\n    cleanliness\n    trafficRate\n    staffEnthusiasm\n    status\n    closingDateAndTime\n    travelTime\n    createdBy {\n      id\n      firstName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8b7449c1ee2487918b9b7e8f89148e5";

export default node;
