const LocationChecks = (props:{values: any;setFieldValue: any}) => {
    const {values, setFieldValue}   = props;
    return (
        <div>
           <div className="mt-4">
                  {[
                    { label: "Has a smoodi router?", name: "hasSmoodiRouter" },
                    { label: "Has Wifi extender?", name: "hasWifiExtender" },
                    {
                      label:
                        "Has pressure pump?",
                      name: "hasPressurePump",
                    },
                    {
                      label: "Has pressure tank?",
                      name: "hasPressureTank",
                    },
                  ].map((item, idx) => (
                    <div
                      key={idx}
                      className="d-flex justify-content-between align-items-center mb-3"
                    >
                      <label className="wo-label">
                        {item.label}
                      </label>
                      <div className="form-check form-switch form-check-custom">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={values[item.name]}
                          onChange={(event) =>
                            setFieldValue(item.name, event.target.checked)
                          }
                          name={item.name}
                        />
                      </div>
                    </div>
                  ))}
                </div>
        </div>
    )
}

export default LocationChecks;