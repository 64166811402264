import "jquery/dist/jquery.min.js";
import "react-datetime/css/react-datetime.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import clsx from "clsx";
import Moment from "moment";
import Select from "react-select";
import Datetime from "react-datetime";
import { Toaster } from "react-hot-toast";
import { Component, createRef } from "react";
import { KTSVG } from "../../../../_metronic/helpers";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../../app/modules/auth";
import { EditBusinessModel } from "./EditBusinessModel";
import { updateBusinessModelStatus } from "../../../models/_businessModel";

export default class BusinessModelList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      addLoading: false,
      filterLoading: false,
      filterFromDate: "",
      resetFromDate: createRef(),
      filterToDate: "",
      resetToDate: createRef(),
      filterName: "",
      filterStatus: true,
      resetStatus: createRef(),
      statusDB: [ 
        { id: true, name: "Active" },
        { id: false, name: "Inactive" },
        { id: "all", name:"All" }
      ],
      businessModelEditModal: false,
      currentBusinessModelId: "",
    };
  }

  async componentWillMount() {}
  async componentDidUpdate() {}

  businessModelStatus = async (businessModelId: any, businessModelStatus: boolean) => {
    try {
      await updateBusinessModelStatus(businessModelId, businessModelStatus);
    } catch (e: any) {
      console.log(e);
    }
  };

  async componentDidMount() {
    var _ = this;
    $("body").off("change", ".togBtn").on("change", ".togBtn", function () {
      let businessModelStatus;
      if ($(this).is(":checked")) {
        businessModelStatus = true;
      } else {
        businessModelStatus = false;
      }
      const businessModelId = $(this).attr("data-recordId");
      _.businessModelStatus(businessModelId, businessModelStatus);
      return false;
    });

    $("body").off("click", ".editBtn").on("click", ".editBtn", function () {
      const businessModelId = $(this).attr("data-recordId");
      if(!businessModelId) return;
      _.setState({
        businessModelEditModal: true,
        currentBusinessModelId: businessModelId,
      })
      return;
    });
    await this.getFilter();
    this.renderDataTable([]);
  }

  getFilter = () => {
    let filter: any = {};
    // filter name
    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }
    // filter status
    if (this.state.filterStatus === true) {
      filter["is_active"] = { eq: true };
    } else if (this.state.filterStatus === false) {
      filter["is_active"] = { eq: false };
    }
    if(this.state.filterStatus === "all"){
      delete filter["is_active"];
    }
    // filter date
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    } 
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(this.state.filterFromDate).toISOString();
    } else if (this.state.filterFromDate === "" && this.state.filterToDate !== "" ) {
      filter["created_at"]["lt"] = new Date(this.state.filterToDate).toISOString();
    } else if (this.state.filterFromDate !== "" && this.state.filterToDate !== "") {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter;
  };

  renderDataTable = (rows: any) => {
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }
    $(document).ready(function () {
      $("#businessModelTable").DataTable().destroy();
      $("#businessModelTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: 50,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization', "Bearer " + authToken);
          },
          "data": function (d: any) {
            var gql = `
              query getBusinessModels(
                $where:BusinessModelsResolver_GetBusinessModels_FilterInputType
                $paginate:PaginatorArgs
              ){
                getBusinessModels(where: $where, ,paginate: $paginate) {
                  data {
                    id
                    name
                    description
                    createdAt
                    isActive
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
            `;
            const currentPage = (d?.start) ?(d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              "operationName":null,
              "query":gql,
              "variables": {
                "where": filter,
                paginate: {
                  "page": currentPage,
                  "per_page": length
                }                   
              }
            };
            return JSON.stringify(query);
          },
          "dataSrc": function (json) {
            json.recordsTotal = json.data.getBusinessModels.total;
            json.recordsFiltered = json.data.getBusinessModels.total;        
            const rows: any[] = [];
            const records = json.data.getBusinessModels.data;
            let counter = 0;
            for (const record of records) {
              counter++;
              let createdAt = "";
              if (record.createdAt) {
                createdAt = Moment.utc(record.createdAt).local().format("YYYY-MM-DD HH:mm:ss");
              }
              const tempRecord: any = {
                id: counter,
                recordId: record.id,
                description: record.description,
                name: record.name,
                createdAt,
                isActive: record.isActive,
                record: record,
              };
              rows.push(tempRecord);
            }
            return rows;
          }
        },
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "name",
            render: function (data, type, row) {
              return row.name;
            },
          },
          {
            name: "description",
            render: function (data, type, row) {
              return row.description;
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "isActive",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-business-model") === true) {
                let isChecked = row.isActive === true ? `checked="checked"` : "";
                returnData +=
                  `<label class="status-switch">
                    <input type="checkbox" ${isChecked} data-recordId=${row.recordId} class="togBtn">
                    <div class="status-slider round"></div>
                  </label>`;
              } else {
                if (row.isActive === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }
              return returnData;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-business-model") === true) {
                returnData +=
                  `<div data-bs-toggle="tooltip" title="Click here to edit business model">
                    <button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId="${row.recordId}">
                      <i class='fa fa-pencil' style='font-size:1rem;'></i>
                    </button>
                  </div>`;
              }
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
      });
    });
  };

  handleBusinessModelClose = () => {
    this.setState({
      businessModelEditModal: false,
      currentBusinessModelId: ""
    })
  }

  refreshTable = async () => {
    this.renderDataTable([]);
  }

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    this.renderDataTable([]);
    this.setState({
      addLoading: false,
    });
    return false;
  };

  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    this.state.resetStatus.current.setValue("");
    // eslint-disable-next-line
    this.state.resetFromDate.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetToDate.current.state.inputValue = "";
    this.setState({
      filterFromDate: "",
      filterToDate: "",
      filterName:"",
      filterStatus: "",
    });
    await this.getFilter();
    this.renderDataTable([]);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  render() {
    return (
      <div>
        
        {/* breadcrumbs */}
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Business Model List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Business Model List
                </li>
              </ul>
            </div>
          </div>
        </div>

        
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">

              <div className="row align-items-center justify-content-between">
                <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                  <button
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#business-model-filter"
                    aria-expanded="false"
                    aria-controls="business-model-filter"
                    className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                    <KTSVG
                      path="/media/icons/duotune/general/gen031.svg"
                      className="svg-icon-4 me-1"
                    />
                      Filter
                  </button>
                </div>
              </div>

              <div className="collapse" id="business-model-filter" aria-labelledby="business-model-filter">

                <div className="row mt-6">

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                    <label className="form-label fs-4 fw-bold">Created At (From Date)</label>
                    <Datetime
                      ref={this.state.resetFromDate}
                      closeOnSelect
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm"
                      initialValue={this.state.filterFromDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        this.setState({
                          filterFromDate: dt,
                        });
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fs-4 fw-bold">Created At (To Date)</label>
                    <Datetime
                      ref={this.state.resetToDate}
                      closeOnSelect
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm"
                      initialValue={this.state.filterToDate}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        this.setState({
                          filterToDate: dt,
                        });
                      }}
                    />
                  </div>
                  
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fs-4 fw-bold">Name</label>
                    <input
                      autoComplete="off"
                      className={clsx("form-control")}
                      name="filterName"
                      value={this.state.filterName}
                      onChange={(event: any) => {
                        this.setState({
                          filterName: event.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fw-bold fs-4">Status</label>
                    <Select
                      ref={this.state.resetStatus}
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="filterStatus"
                      defaultValue={{ label: "Active", value: true }}
                      placeholder=""
                      onChange={(event: any) => {
                        this.setState({
                          filterStatus: event.value,
                        });
                      }}
                      options={Array.from(this.state.statusDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                    />
                  </div>

                </div>

                <div className="row">

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <button
                      type="submit"
                      onClick={this.searchData}
                      className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                    >
                      {this.state.addLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!this.state.addLoading && (
                        <span className="indicator-label">ADD FILTER</span>
                      )}
                    </button>
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <button
                      type="submit"
                      onClick={this.removeSearch}
                      className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                    >
                      {this.state.filterLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!this.state.filterLoading && (
                        <span className="indicator-label">RESET FILTER</span>
                      )}
                    </button>
                  </div>

                </div>

              </div>  
              
            </div>
          </div>

        <div className="card mt-9">

          {/* table */}
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="businessModelTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2"> 
                          Description
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {this.state.businessModelEditModal === true && (
            <EditBusinessModel 
              currentBusinessModelId = {this.state.currentBusinessModelId} 
              businessModelEditModal = {this.state.businessModelEditModal}
              handleBusinessModelClose = {this.handleBusinessModelClose} 
              refreshTable = {this.refreshTable}
            />
          )}

          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>

      </div>
    );
  }
}
