/**
 * @generated SignedSource<<8a5beb243d53402462ffcd2970bed97d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActivityModal_getTicketQuery$variables = {
  id: string;
};
export type ActivityModal_getTicketQuery$data = {
  readonly getTicket: {
    readonly closingDateAndTime: string | null;
    readonly diagnosisDone: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly diagnosisOtherInput: string | null;
    readonly id: string;
    readonly isFilterReplaced: boolean | null;
    readonly isSanitizerReplaced: boolean | null;
    readonly machineServiceOtherInput: string | null;
    readonly machineServicesDone: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly operationOtherInput: string | null;
    readonly operationsDone: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }> | null;
    readonly scheduleEndDate: any | null;
    readonly scheduleStartDate: any | null;
    readonly travelingEndTime: any | null;
    readonly travelingStartTime: any | null;
    readonly uuid: string | null;
  };
};
export type ActivityModal_getTicketQuery = {
  response: ActivityModal_getTicketQuery$data;
  variables: ActivityModal_getTicketQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "TicketObjectType",
    "kind": "LinkedField",
    "name": "getTicket",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineServiceOtherInput",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "operationOtherInput",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduleStartDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scheduleEndDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "travelingStartTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "travelingEndTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MachineServiceObjectType",
        "kind": "LinkedField",
        "name": "machineServicesDone",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OperationObjectType",
        "kind": "LinkedField",
        "name": "operationsDone",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DiagnosisObjectType",
        "kind": "LinkedField",
        "name": "diagnosisDone",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "diagnosisOtherInput",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "closingDateAndTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isFilterReplaced",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSanitizerReplaced",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityModal_getTicketQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActivityModal_getTicketQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ec4005548edac488ef1377f304d03e36",
    "id": null,
    "metadata": {},
    "name": "ActivityModal_getTicketQuery",
    "operationKind": "query",
    "text": "query ActivityModal_getTicketQuery(\n  $id: String!\n) {\n  getTicket(id: $id) {\n    id\n    uuid\n    machineServiceOtherInput\n    operationOtherInput\n    scheduleStartDate\n    scheduleEndDate\n    travelingStartTime\n    travelingEndTime\n    machineServicesDone {\n      id\n      name\n    }\n    operationsDone {\n      id\n      name\n    }\n    diagnosisDone {\n      id\n      name\n    }\n    diagnosisOtherInput\n    closingDateAndTime\n    isFilterReplaced\n    isSanitizerReplaced\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8b08cca9e3a4eb0d0ba4549020fd317";

export default node;
