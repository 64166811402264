import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateMarketSegment {
  name: string;
}
export async function createMarketSegment(input: CreateMarketSegment) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createMarketSegment (
            $input:CreateMarketSegment!
          ){
            createMarketSegment(
                createMarketSegmentInput:$input
              ) 
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getMarketSegmentList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                    getMarketSegment{
                    id
                    name
                    createdAt
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getMarketSegment;
}

interface UpdateMarketSegment {
  name: string;
}
export async function updateMarketSegment(input: UpdateMarketSegment) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateMarketSegment (
              $input: UpdateMarketSegment!
            ){
                updateMarketSegment(
                    updateMarketSegmentInput: $input
                )
              }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}