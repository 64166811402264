import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateMachineStatusInput {
  name?: string;
  description?: string;
  isActive?: boolean;
}

export async function createMachineStatus(input: CreateMachineStatusInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createMachineStatus (
            $input: CreateMachineStatusInput!
          ){
            createMachineStatus(
              createMachineStatusInput: $input
              )  
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getMachineStatus(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getMachineStatusListWithPagination(
                  $where: MachineStatusResolver_GetMachineStatusListWithPagination_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getMachineStatusListWithPagination (where:$where, paginate: $paginate) {
                      data {
                        id
                        name
                        description
                        isActive
                        createdAt
                      }
                      totalPages
                      total
                      perPage
                      page
                    
                  }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getMachineStatusListWithPagination;
}

export async function updateMachineStatus(input: CreateMachineStatusInput, id?: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateMachineStatus (
              $id: String!
              $input: CreateMachineStatusInput!
            ){
              updateMachineStatus(
                updateMachineStatusInput: $input
                id: $id
                )
            }
            `,
      variables: {
        input,
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateOnlyMachineStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateOnlyMachineStatus(
          updateOnlyMachineStatusInput: { id: $id, isActive: $isActive }
         )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}