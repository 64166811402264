import React, { useContext } from "react";
import { useLocationData } from "../../providers/LocationDataProvider";
import LocationsList from "./LocationsList";
import { FlavorContext } from "../../providers/flavorProvider";

const LocationParentComponent: React.FC = () => {
  const { data, loading, error } = useLocationData();
  const {flavorsDB} = useContext(FlavorContext)
  if (loading) {
    return <div className="loading-indicator">Loading...</div>;
  }

  if (error) {
    return <div className="error">Error: {error.message}</div>;
  }

  const {
    getCustomerList,
    getDistributors,
    getMarketSegment,
    getLocationTypes,
    getRegions,
    getFilterProviders,
    getBusinessModelsList,
    getLocationList,
    getUsers,
  } = data || {};

  return (
    <LocationsList
      customers={getCustomerList}
      distributors={getDistributors}
      marketSegments={getMarketSegment}
      locationTypes={getLocationTypes}
      regions={getRegions}
      filterProviders={getFilterProviders}
      businessModels={getBusinessModelsList}
      locations={getLocationList}
      users={getUsers}
      flavorsDB={flavorsDB}
    />
  );
};

export default LocationParentComponent;