import React, { useState } from 'react'
import { GetOperationType } from '../../../models/_tickets';

const Operation = (props:{
    operation:any;
    onSelectOperationChange: (selected: string[]) => void;
})=> {
    const {operation,onSelectOperationChange} = props;
     const [selectedOperation, setSelectedOperation] = useState<
        string[]
      >([]);
    
      const handleCheckboxChange = (marketingName: string) => {
        setSelectedOperation((prev) => {
          const updatedSelection = prev.includes(marketingName)
            ? prev.filter((name) => name !== marketingName) // Remove if already selected
            : [...prev, marketingName]; // Add if not selected
    
            onSelectOperationChange(updatedSelection); // Update parent state
          return updatedSelection;
        });
      };
      
  return (
    <div className="wo-form-section mt-8">
        <div className="border-dotted border-2 border-gray-200 p-8 gap-2 mb-8">

                                <h3 className="mb-0">Operation:</h3>
                                <ul className="list-unstyled ms-8 mt-4">
                                  {operation.map(
                                    (operation: GetOperationType, index: number) => (
                                      <label
                                        key={index}
                                        className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-4 mt-1"
                                      >
                                        <input
                                          id={`operation_${index}`}
                                          className="form-check-input mb-2 me-1"
                                          type="checkbox"
                                          checked={selectedOperation.includes(operation.id)}
                                          onChange={() =>
                                            handleCheckboxChange(operation.id)
                                          }
                                        />
                                        <span className="ms-2 mb-1 fs-5 form-check-label">
                                          {operation.name}
                                        </span>
                                      </label>
                                    )
                                  )}
                                </ul>
                              </div>
            </div>
  )
}

export default Operation