import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import TicketLogs from "./TicketLog";
import { Machine, MachineStatus, User } from "../Machines/MachineParentComponent";

export interface TicketSource {
  id: string;
  name: string;
}

interface TicketLogParentComponentQueryResponse {
  getMachineList: Machine[];
  getTicketSources: TicketSource[];
  getMachineStatus: MachineStatus[];
  getUsers: User[];
}

type TicketLogParentComponentQuery = OperationType & {
  response: TicketLogParentComponentQueryResponse;
  variables: Record<string, never>;
};

class TicketLogParentComponent extends React.Component<any, any> {
  render() {
    return (
      <QueryRenderer<TicketLogParentComponentQuery>
        environment={environment}
        query={graphql`
          query TicketLogParentComponentQuery {
            getMachineList {
              id
              uuid
              serialNumber
              location {
                name
              }
              name
              locationId
            }
            getUsers {
              id
              firstName
              lastName
              isActive
              isRegionalManager
            }
            getTicketSources {
              id
              name
            }
            getMachineStatus {
              id
              name
              description
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>Error loading ticket data: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const { getMachineList,getMachineStatus,getTicketSources,getUsers } = props;

          return <TicketLogs machines={getMachineList} machineStatus={getMachineStatus} ticketSources={getTicketSources} users={getUsers} />;
        }}
      />
    );
  }
}

export default TicketLogParentComponent;
