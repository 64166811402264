import { FC, useState, Suspense, useContext } from "react";
import Modal from "react-responsive-modal";
import { Formik } from "formik";
import { fetchQuery,  useRelayEnvironment } from "react-relay";
import toast, { Toaster } from "react-hot-toast";
import "react-datetime/css/react-datetime.css";
import {  updateLocationFlavorPrice } from "../../models/_location";
import { graphql } from "babel-plugin-relay/macro";
import { FlavorContext } from "../../providers/flavorProvider";
import { Flavor } from "../../models/_flavor";
import { useQuery } from "react-query";

type Props = {
  currentLocationId: string;
  handleLocationFlavorPriceClose: () => void;
  locationUpdatePriceModal: boolean;
  refreshTable?: any;
};

export const getLocationById = graphql`
          query UpdatePriceLocation_getLocationQuery($id: String!) {
             getLocation(id: $id) {
                id
                uuid
                flavorPrices {
                  flavor
                  price
                }
            }
    }
`;

export const UpdatePriceLocation: FC<Props> = ({
  currentLocationId,
  locationUpdatePriceModal,
  handleLocationFlavorPriceClose,
  refreshTable,
}) => {
  const environment = useRelayEnvironment();
  const {flavorsDB} = useContext(FlavorContext);
  
  const refetchLocationPrice = async (): Promise<void> => {
    try {
      refetch()
      console.log("Successfully updated cache with the latest location price data.");
    } catch (error) {
      console.error("Error refetching location price data:", error);
    }
  };

  const { data: locationRecord, refetch } = useQuery(
    ["getLocation", currentLocationId],
    () => fetchQuery(environment, getLocationById, { id: currentLocationId ?? undefined }).toPromise(),
    {
      enabled: !!currentLocationId,
    }
  );
  const locations: any = locationRecord && typeof locationRecord === 'object' && 'getLocation' in locationRecord ? locationRecord.getLocation : [];
    
  const [loading, setLoading] = useState(false);
  const flavorPrices = locations.flavorPrices ?? [];
  const initialPrices: { [key: string]: number } = {};

  flavorsDB.forEach((flavor: Flavor) => {
    const flavorPrice = flavorPrices.find((fp: any) => fp.flavor === flavor.flavor);
    initialPrices[`${flavor.flavor.toLowerCase()}_price`] = flavorPrice ? flavorPrice.price : 0;
  });

  return (
    <Suspense>
      <Modal
        open={locationUpdatePriceModal && !!currentLocationId && !!locations}
        onClose={() => handleLocationFlavorPriceClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={initialPrices}
          onSubmit={async (values, { resetForm }) => {
            setLoading(true);
            
            const updateData: any = {
              id: locations.id,
              flavors: flavorsDB.map((flavor: any) => ({
              flavor: flavor.flavor,
              price: values[`${flavor.flavor.toLowerCase()}_price`]
              }))
            };

            const result = await updateLocationFlavorPrice(updateData);
            if (result?.data?.data?.updateLocationFlavorPrice) {
              refetchLocationPrice();
              toast.success("Location flavor price updated successfully");
              setLoading(false);
              handleLocationFlavorPriceClose();
              resetForm();
              if (typeof refreshTable !== "undefined") {
                refreshTable();
              }
            } else {
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
          }) => (
            <form
              id="kt_modal_update_location_price_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1 mt-4">
                  Update location price- {locations?.uuid ?? ""}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                      Flavors
                    </label>

                    <table className="table table-boarderd">
                      <thead>
                        <tr>
                          <th className="fw-bold mb-0">Flavor</th>
                          <th className="fw-bold mb-0">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {flavorsDB.map((flavor: any) => (
                          <tr key={flavor.flavor}>
                            <td>{flavor.name}</td>
                            <td>
                              <input
                                type="number"
                                name={`${flavor.flavor.toLowerCase()}_price`}
                                id={`${flavor.flavor.toLowerCase()}_price`}
                                className="form-control"
                                value={values[`${flavor.flavor.toLowerCase()}_price`]}
                                onChange={handleChange}
                              />
                            </td>
                          </tr>
                        ))}
                       
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && <span className="indicator-label">UPDATE</span>}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
