import React, { FC, useEffect, useState } from "react";
import { WithChildren } from "../../_metronic/helpers";
import { Flavor } from "../models/_flavor";
import { useLazyLoadQuery } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { flavorProvider_getFlavorsQuery } from './__generated__/flavorProvider_getFlavorsQuery.graphql';


export interface FlavorContextProps {
    flavorsDB: Flavor[];
    loading: boolean;
    error: any;
}

const FlavorContext = React.createContext<FlavorContextProps>({
    flavorsDB: [],
    loading: true,
    error: null
});

const getFlavorsQuery = graphql`
  query flavorProvider_getFlavorsQuery {
    getFlavors {
              id
              name
              flavor
            }
  }
`;

const FlavorProvider: FC<WithChildren> = ({ children }) => {
    const data = useLazyLoadQuery<flavorProvider_getFlavorsQuery>(getFlavorsQuery, {}, {
        fetchPolicy: 'store-or-network',});
    const loading = !data;
    const error = data ? null : 'Error loading flavors';
    const [flavors, setFlavors] = useState<Flavor[]>([]);
    useEffect(() => {
        if (data) {
            setFlavors([...data.getFlavors]);
        }
    }, [data]);

    return (
        <FlavorContext.Provider value={{ flavorsDB: flavors, loading, error }}>
            {children}
        </FlavorContext.Provider>
    );
};

export { FlavorProvider, FlavorContext };
