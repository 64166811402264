import { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";

import Moment from "moment";
import { getLogJobs } from "../../models/_logjob";
import { Toaster } from "react-hot-toast";
import * as _ from "lodash";
import $ from "jquery";

import "react-responsive-modal/styles.css";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect, { components } from "react-select";
import EditJobModal from "./EditJobModal";
import { Option } from '../../../_metronic/helpers/MySelect';
import { Tooltip } from "react-tooltip";
const { Option: customOption } = components;

class LogJobList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      resetFrom: createRef(),
      resetTo: createRef(),
      isReRander: false,
      open: false,
      addLoading: false,
      filterLoading: false,
      logJobList: [],
      logJobListObj: {},
      locationId: "",
      userDB: [],
      editJobModal: false,
      locationDB: [],
      locationTypeDB: [],
      materialDB: [],
      currentJobId: "",
      jobId: "",
      managerDB: [],
      machineDB: [],
      filterJobTypes: "",
      filterLocation: "",
      filterCreatedBy: "",
      filterToDate: "",
      filterFromDate: "",
      filterMachine: "",
      filterStatus: "",
      filterType: "",
      rowRecord: {},
      locationTypeSelected: null,
      jobTypeSelected: null,
      machineSelected: null,
      locationSelected: null,
      createdBySelected: null,
      statusSelected: null,
      statuses: [
        {
          id: "PENDING",
          name: "Pending",
        },
        {
          id: "ON_HOLD",
          name: "On-hold",
        },
        {
          id: "IN_PROCESS",
          name: "In-Process",
        },
        {
          id: "COMPLETED",
          name: "Completed",
        },
      ],
      cleanlinessRate: [
        {
          id: "GREAT",
          name: "Great",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "BAD",
          name: "Bad",
        },
      ],
      trafficRates: [
        {
          id: "HIGH",
          name: "High",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "LOW",
          name: "Low",
        },
      ],
      enthusiasmRate: [
        {
          id: "HIGH",
          name: "High",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "LOW",
          name: "Low",
        },
      ],
      satisfactionRate: [
        {
          id: "HIGH",
          name: "High",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "LOW",
          name: "Low",
        },
      ],
      statusSanitizers: [
        {
          id: "HIGH",
          name: "High",
        },
        {
          id: "MEDIUM",
          name: "Medium",
        },
        {
          id: "LOW",
          name: "Low",
        },
      ],
      jobTypes: [
        {
          id: "SAMPLE_EVENT",
          name: "Sample event",
        },
        {
          id: "SITE_VISIT",
          name: "Site visit",
        },
        {
          id: "INSTALLATION_FOLLOW_UP",
          name: "Installation Follow-up",
        },
        {
          id: "INSTALLATION",
          name: "Installation",
        },
      ],
    };
  }

  async componentWillMount() { }
  async componentDidUpdate() { }
  async componentDidMount() {
    const _ = this;
    $("body").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        editJobModal: true,
        currentJobId: record,
      });
    });
    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    $(document).ready(function () {


      $("#jobsTable").DataTable().destroy();

      $("#jobsTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view job"><a class="clickable_entry" data-value=\'' + JSON.stringify(row) + '\' href="/job/list/viewjob/' +
                row.recordId +
                '"> ' +
                row.id +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "jobType",
            className: "clickable",
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view job"><a href="/job/list/viewjob/' +
                row.recordId +
                '"> ' +
                row.jobType +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "locationName",
            render: function (data, type, row) {
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view location"><a href="/locations/list/history/' +
                row.locationId +
                '"> ' +
                row.locationName +
                "</div></a>";
              return returnsData;
            },
          },
          {
            name: "status",
            render: function (data, type, row) {
              return row.status;
            },
          },
          {
            name: "startTime",
            render: function (data, type, row) {
              return row.startTime;
            },
          },

          {
            name: "machineName",

            render: function (data, type, row) {
              return row.machineName;
            },
          },
          {
            name: "machineSerialNumber",

            render: function (data, type, row) {
              return row.machineSerialNumber;
            },
          },
          {
            name: "createdBy",

            render: function (data, type, row) {
              return row.createdBy;
            },
          },
          {
            name: "createdAt",

            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "endTime",
            render: function (data, type, row) {
              return row.endTime;
            },
          },
          {
            name: "comments",
            render: function (data, type, row) {
              return row.comments;
            },
          },
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              const getPermissions: any = localStorage.getItem("permissions");
              const permissions = getPermissions || [];
              if (permissions?.includes("update-logJob") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit job"><button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              returnData +=
                '<div data-bs-toggle="tooltip" title="Click here to view job"><a class="btn btn-primary btn p-2 px-4 m-1" href="/job/list/viewjob/' +
                row.recordId +
                '"> <i class="fa fa-eye"></i> </a></div>';
              return returnData;
            },
          },
        ],

        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: '<"row mb-2"<"col-md-6 text-left"i><"col-md-6 text-right"p>>rt<"row mb-2"<"col-md-6 text-left"i><"col-md-6 text-right"p>>',
        destroy: true,
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],

        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],

        createdRow: function (row, data, rowIndex) {
          const title: any = [];
          $("#jobsTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },

      });


      $(document)[0].oncontextmenu = function () { return false; }

      $('#jobsTable td').mousedown('contextmenu', function (e) {
        if (e.buttons === 1) return true;
        e.preventDefault();
        const top = e.clientY


        const left = e.clientX
        const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
        let record;
        if (recordString) {
          record = JSON.parse(recordString)
        }
        if (record) {
          $('#view_record_link').attr('href', `/job/list/viewjob/${record.recordId}`)
          $('#edit_record_link').attr('data-recordId', record.recordId)
          $("#context-menu").css({
            top: top,
            left: left
          }).show();
        }

        return false;

      })
      $('html').click(function () {
        $("#context-menu").hide();
      })
      $("#context-menu a").click(function (e) {
        // eslint-disable-next-line
      });
    });


    $(".dt-buttons").remove();
  };

  async getFilterRecords() {
    const {machines,locations,users,locationTypes,marketingMaterials} = this.props

    this.setState({
      locationTypeDB:locationTypes ,
      locationDB: locations,
      machineDB: machines,
      managerDB: users,
      materialDB: marketingMaterials,
    });
  }

  // fetch data into datatable
  getData = async () => {
    let filter: any = {};
    let locationTypeId: any = [];
    if (this.state.filterType.length > 0) {
      locationTypeId = this.state.filterType;
    }
    if (this.state.filterJobTypes.length > 0) {
      filter["job_type"] = { in: this.state.filterJobTypes };
    }
    if (this.state.filterLocation.length > 0) {
      filter["location_id"] = { in: this.state.filterLocation };
    }
    if (this.state.filterCreatedBy.length > 0) {
      filter["created_by_id"] = { in: this.state.filterCreatedBy };
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["date_and_time"] = {};
    }
    if (this.state.filterMachine.length > 0) {
      filter["machine_name"] = { in: this.state.filterMachine };
    }
    if (this.state.filterStatus.length > 0) {
      filter["status"] = { in: this.state.filterStatus };
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["date_and_time"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["date_and_time"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["date_and_time"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    const records = await getLogJobs(filter, locationTypeId);

    const logJobListObj: any = {};
    const rows = [];
    for (const record of records) {
      let createdAt = "";
      if (record.createdAt) {
        createdAt = Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }

      let dateAndTime = "";
      if (record.dateAndTime) {
        dateAndTime = Moment.unix(record.dateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      let closingDateAndTime = "";
      if (record.closingDateAndTime) {
        closingDateAndTime = Moment.unix(record.closingDateAndTime / 1000)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      let locationName = "";
      for (const locationObj of this.state.locationDB) {
        if (locationObj.id === record.locationId) {
          locationName = locationObj.name;
        }
      }
      this.setState({
        locationName: locationName,
      });

      const tempRecord = {
        id: record.uuid,
        jobType: _.capitalize(_.lowerCase(record.jobType)),
        locationName: locationName,
        locationId: record.locationId,
        status: _.capitalize(_.lowerCase(record.status)),
        comments: record.additionalComments,
        createdBy: record.createdBy?.firstName ?? "",
        startTime: dateAndTime,
        createdAt,
        machineName: record.machineName ?? "",
        machineSerialNumber: record.machineSerialNumber ?? "",
        marketingMaterialsUsed: record.marketingMaterials
          ?.map((element: any) => element.name)
          .join(","),
        endTime: closingDateAndTime,
        cleanliness: _.capitalize(_.lowerCase(record.cleanliness)),
        customerSatisfaction: _.capitalize(
          _.lowerCase(record.customerSatisfaction)
        ),
        trafficRate: _.capitalize(_.lowerCase(record.trafficRate)),
        staffEnthusiasm: _.capitalize(_.lowerCase(record.staffEnthusiasm)),
        sanitizerStatus: _.capitalize(_.lowerCase(record.sanitizerStatus)),
        travelTime: record.travelTime ?? "",
        recordId: record.id,
        record: record,
      };
      rows.push(tempRecord);
      logJobListObj[record.id] = tempRecord;
    }

    this.setState({
      logJobListObj: logJobListObj,
      logJobList: rows,
    });
    return rows;
  };

  handleDownloadExcel = async () => {
    const { logJobList } = this.state;

    const excelRecords = [];
    for (const record of logJobList) {
      const tempRecord = { ...record };
      delete tempRecord.recordId;
      delete tempRecord.record;
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "Jobs_list");
  };

  // filter data submit
  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterMachine: "",
      filterJobTypes: "",
      filterLocation: "",
      filterCreatedBy: "",
      filterStatus: "",
      filterType: "",
      locationTypeSelected: null,
      jobTypeSelected: null,
      machineSelected: null,
      locationSelected: null,
      createdBySelected: null,
      statusSelected: null,
    });

    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      filterLoading: false,
    });
    return false;
  };

  handleCloseJob = () => {
    this.setState({
      editJobModal: false,
      currentJobId: "",
    });
  };
  refreshTable = async () => {
    const rows = await this.getData();
    this.renderDataTable(rows);
  };
  showModal = async (record: any) => {
    const rows = await this.getData();

    for (const record of rows) {
      this.setState({ editJobModal: true, currentJobId: record.record?.id });
    }
  };

  render() {
    return (
      <>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Jobs List
              </h1>
            </div>


            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#jobs-filter"
                aria-expanded="false"
                aria-controls="jobs-filter"
                className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
              >
                <KTSVG
                  path="/media/icons/duotune/general/gen031.svg"
                  className="svg-icon-4 me-1"
                />
                Filter
              </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Jobs List</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className="collapse"
          id="jobs-filter"
          aria-labelledby="jobs-filter"
        >
          <div className="card mt-9">
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    ref={this.state.resetFrom}
                    closeOnSelect
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    ref={this.state.resetTo}
                    closeOnSelect
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Job Type</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        jobTypeSelected: selected,
                        filterJobTypes: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.jobTypeSelected}
                    options={Array.from(this.state.jobTypes).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Location</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationSelected: selected,
                        filterLocation: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.locationSelected}
                    options={Array.from(this.state.locationDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Created By</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        createdBySelected: selected,
                        filterCreatedBy: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.createdBySelected}
                    options={Array.from(this.state.managerDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Status</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        statusSelected: selected,
                        filterStatus: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.statusSelected}
                    options={Array.from(this.state.statuses).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Machines
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        machineSelected: selected,
                        filterMachine: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.machineSelected}
                    options={Array.from(this.state.machineDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: `${value.name ? value?.name : (value?.location?.name  ?? "")} ${value?.serialNumber}`,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Location Type
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    isMulti
                    placeholder=""
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        locationTypeSelected: selected,
                        filterType: value,
                      });
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={this.state.locationTypeSelected}
                    options={Array.from(this.state.locationTypeDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                          description: value.description,
                        };
                      }
                    )}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/finance/fin006.svg"
                    className="svg-icon-4 me-1"
                  />
                  Jobs
                </h3>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  onClick={this.handleDownloadExcel}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                >
                  <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-4 me-2"
                  />
                  Export Excel
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="jobsTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="jobId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Job ID
                          <Tooltip anchorSelect=".jobId" className="tooltip-list " data-placement="top">
                          This field displays the unique internal identifier assigned to each ticket for tracking and reference purposes.
                          </Tooltip>
                        </th>
                        <th className="jobType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Job Type
                          <Tooltip anchorSelect=".jobType" className="tooltip-list " data-placement="top">
                          This field records visits to locations for purposes other than resolving reactive or preventive maintenance issues
                          </Tooltip>
                        </th>
                        <th className="location text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location
                          <Tooltip anchorSelect=".location" className="tooltip-list " data-placement="top">
                          Select the name of the location from the available list
                          </Tooltip>
                        </th>
                        <th className="status text-capitalize font-weight-bolder opacity-7 ps-2">
                          Status
                          <Tooltip anchorSelect=".status" className="tooltip-list " data-placement="top">
                          Select the current status of the ticket from the available options.
                          </Tooltip>
                        </th>
                        <th className="startTime text-capitalize font-weight-bolder opacity-7 ps-2">
                          Start Time
                          <Tooltip anchorSelect=".startTime" className="tooltip-list " data-placement="top">
                          Enter the time when the job is scheduled to begin.
                          </Tooltip>
                        </th>
                        <th className="machineName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine Name
                          <Tooltip anchorSelect=".machineName" className="tooltip-list " data-placement="top">
                          Enter the name of the location, followed by dash followed by  the city in which it is situated.
                          </Tooltip>
                        </th>
                        <th className="machineSerialNumber text-capitalize font-weight-bolder opacity-7 ps-2">
                          Machine Serial Number
                          <Tooltip anchorSelect=".machineSerialNumber" className="tooltip-list " data-placement="top">
                          The machine's serial number, located inside the service door
                          </Tooltip>
                        </th>
                        <th className="jobCreatedBy text-capitalize font-weight-bolder opacity-7 ps-2">
                          Job Created By
                          <Tooltip anchorSelect=".jobCreatedBy" className="tooltip-list " data-placement="top">
                          This field indicates the individual responsible for assigning the job, which may differ from the person actually performing the task.
                          </Tooltip>
                        </th>
                        <th className="createdAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                          <Tooltip anchorSelect=".createdAt" className="tooltip-list " data-placement="top">
                          This field displays the date and time when the ticket was initially created
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          End Time
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Comments
                        </th>
                        <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                          <Tooltip anchorSelect=".action" className="tooltip-list " data-placement="top">
                          Edit or change the status of the location
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>



       
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            id="context-menu"
          >
            <li>
              <button
                className="dropdown-item editBtn" id="edit_record_link"
              >
                <i className="fa fa-pencil fs-6"></i>{" "}
                <span className="ms-2">Edit</span>
              </button>
            </li>

            <li>
              {/* eslint-disable-next-line */}
              <a className="dropdown-item" id="view_record_link">
                <i className="fa fa-eye f-6"></i>
                <span className="ms-2"> View </span>
              </a>
            </li>
          </ul>

        {this.state.editJobModal === true && (
          <EditJobModal
            editJobModal={this.state.editJobModal}
            currentJobId={this.state.currentJobId}
            handleCloseJob={this.handleCloseJob}
            refreshTable={this.refreshTable}
          />
        )}

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </>
    );
  }
}

export default LogJobList;
