import { FC, useEffect, useRef, useState } from "react";
import Moment from "moment";
import { Pagination } from "react-bootstrap";
import {
  getLocationBreaks,
  createLocationBreak,
  deleteLocationBreak,
  updateLocationBreaks,
} from "../../../../models/_location";
import Datetime from "react-datetime";
import clsx from "clsx";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";

interface LocationBreakProps {
  id: string;
  title: string;
  breakStartDateTime: string;
  breakEndDateTime: string;
  createdBy: {
    firstName: string;
    lastName: string;
  }
  createdAt: string
}

type Props = {
  locations: any;
};

const validationSchema = yup.object({
  name: yup.string().required("Location Break name is required"),
  startDateTime: yup
    .date()
    .required("Location Break start date time is required"),
  endDateTime: yup.date().required("Location Break end date time is required"),
});

const LocationBreak: FC<Props> = ({ locations }) => {
  const [locationBreakData, setLocationBreakData] = useState<
    LocationBreakProps[]
  >([]);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const resetStartDateEndTimeDate = useRef<any>(null);
  const resetEndDateEndTimeDate = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const [editingLocationBreak, setEditingLocationBreak] =
    useState<LocationBreakProps | null>(null);

  const fetchLocationBreakData = async () => {
    const locationId = localStorage.getItem("currentLocationId");
    let filter:any = {}
    if(locationId){
      filter["location_id"] = { eq: locationId }
    }
    const response = await getLocationBreaks(
     filter
    ,{
      page,
      per_page: perPage,
    });

    if (response?.data) {
      setLocationBreakData(response.data);
    }
    setTotalPages(response?.totalPages ?? 0);
  };

  const handleDelete = async (id: string) => {
    await deleteLocationBreak(id);
    toast.success("Location break deleted successfully");
    fetchLocationBreakData();
  };

  const handleEdit = (locationBreak: LocationBreakProps) => {
    setEditingLocationBreak(locationBreak);
    resetStartDateEndTimeDate.current.state.inputValue =
      locationBreak.breakStartDateTime;
    resetEndDateEndTimeDate.current.state.inputValue =
      locationBreak.breakEndDateTime;
  };

  useEffect(() => {
      fetchLocationBreakData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage]);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="mb-4">
                <h5>
                  {editingLocationBreak
                    ? "Edit Location Break"
                    : "Add Location Break"}
                </h5>
                <Formik
                  initialValues={{
                    name: editingLocationBreak?.title || "",
                    startDateTime:
                      editingLocationBreak?.breakStartDateTime || "",
                    endDateTime: editingLocationBreak?.breakEndDateTime || "",
                  }}
                  validationSchema={validationSchema}
                  enableReinitialize
                  onSubmit={async (values, { resetForm, setSubmitting }) => {
                    setLoading(true);
                    let hasErrors = false;

                    if (hasErrors) {
                      setLoading(false);
                      return false;
                    }

                    const $inputData: any = {
                      title: values.name,
                      startDateTime: Moment.utc(values.startDateTime)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                      endDateTime: Moment.utc(values.endDateTime)
                        .local()
                        .format("YYYY-MM-DD HH:mm:ss"),
                    };

                    if (editingLocationBreak) {
                      $inputData.id = editingLocationBreak.id;
                      const result = await updateLocationBreaks($inputData);
                      if (result?.data?.data?.updateLocationBreaks) {
                        toast.success("Location break updated successfully");
                        setEditingLocationBreak(null);
                        resetStartDateEndTimeDate.current.state.inputValue = '';
                        resetEndDateEndTimeDate.current.state.inputValue = '';
                      } else {
                        const messages = result?.data?.errors.map(
                          (e: any) => e.message
                        );
                        toast.error(messages.join(","));
                      }
                    } else {
                      if (locations?.id) {
                        $inputData.locationId = locations.id;
                      }
                      const result = await createLocationBreak($inputData);
                      if (result?.data?.data?.createLocationBreak) {
                        toast.success("Location break created successfully");
                      } else {
                        const messages = result?.data?.errors.map(
                          (e: any) => e.message
                        );
                        toast.error(messages.join(","));
                      }
                    }

                    resetStartDateEndTimeDate.current.state.inputValue = "";
                    resetEndDateEndTimeDate.current.state.inputValue = "";
                    setLoading(false);
                    resetForm();
                    fetchLocationBreakData();
                  }}
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                    setFieldValue,
                  }) => (
                    <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                      <form onSubmit={handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                            <label className=" form-label fs-4 required fw-bold">
                              Name
                            </label>
                            <input
                              className={clsx("form-control", {
                                "is-invalid":
                                  Boolean(errors.name) && touched.name,
                              })}
                              name="name"
                              id="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="name" />
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                            <label
                              className={`startDateTime form-label fs-4  fw-bold required`}
                            >
                              Start Date Time
                            </label>
                            <Datetime
                              ref={resetStartDateEndTimeDate}
                              dateFormat="YYYY-MM-DD HH:mm:ss"
                              closeOnSelect
                              className={clsx("date-control", {
                                "is-invalid":
                                  Boolean(errors.startDateTime) &&
                                  touched.startDateTime,
                              })}
                              value={values.startDateTime}
                              onChange={(e: any) => {
                                const dt = e["_d"];
                                setFieldValue("startDateTime", dt);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="startDateTime" />
                            </div>
                          </div>

                          <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                            <label
                              className={`endDateTime form-label fs-4  fw-bold required`}
                            >
                              End Date Time
                            </label>
                            <Datetime
                              ref={resetEndDateEndTimeDate}
                              dateFormat="YYYY-MM-DD HH:mm:ss"
                              closeOnSelect
                              className={clsx("date-control", {
                                "is-invalid":
                                  Boolean(errors.endDateTime) &&
                                  touched.endDateTime,
                              })}
                              value={values.endDateTime}
                              onChange={(e: any) => {
                                const dt = e["_d"];
                                setFieldValue("endDateTime", dt);
                              }}
                            />
                            <div className="text-danger text-align-top-center mt-2">
                              <ErrorMessage name="endDateTime" />
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary me-4 fs-4 w-100"
                            >
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!loading && (
                                <span className="indicator-label">
                                  {editingLocationBreak
                                    ? "UPDATE LOCATION BREAK"
                                    : "ADD LOCATION BREAK"}
                                </span>
                              )}
                            </button>
                          </div>

                          {
                            editingLocationBreak && (
                              <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                            <button
                              type="button"
                              className="btn btn-danger me-4 fs-4 w-100"
                              onClick={() => {setEditingLocationBreak(null)
                              
                                resetStartDateEndTimeDate.current.state.inputValue =
                                '';
                              resetEndDateEndTimeDate.current.state.inputValue =
                                '';
                              }
                              }
                            >
                              {loading && (
                                <span
                                  className="indicator-progress"
                                  style={{ display: "block" }}
                                >
                                  Please Wait..
                                  <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                                </span>
                              )}
                              {!loading && (
                                <span className="indicator-label">
                                  Cancel
                                </span>
                              )}
                            </button>

                            
                          </div>)
                          }
                        </div>
                      </form>
                    </div>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="card mt-9 mx-xl-4">
            <div className="card-body w-100 p-5">
              <div className="table-responsive p-0 pb-2 fs-6 border-0 p-5">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fs-5 fw-bold">
                      <th className="fs-5 text-muted">Sr No</th>
                      <th className="fs-5 text-muted">Title</th>
                      <th className="fs-5 text-muted">Location Name</th>
                      <th className="fs-5 text-muted">Start Date Time</th>
                      <th className="fs-5 text-muted">End Date Time</th>
                      <th className="fs-5 text-muted">Created By</th>
                      <th className="fs-5 text-muted">Created At</th>
                      <th className="fs-5 text-muted">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {locationBreakData?.length > 0 ? (
                      locationBreakData.map((locationBreak, index) => (
                        <tr key={locationBreak.id}>
                          <td>{(page - 1) * perPage + index + 1}</td>
                          <td>{locationBreak.title}</td>
                          <td>{locations ? locations?.name : ""}</td>
                          <td>
                            {Moment.utc(locationBreak.breakStartDateTime)
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>
                            {Moment.utc(locationBreak.breakEndDateTime)
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>{locationBreak.createdBy.firstName} {locationBreak.createdBy.lastName}</td>
                          <td>
                            {Moment(locationBreak.createdAt)
                              .local()
                              .format("YYYY-MM-DD HH:mm")}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary me-2"
                              onClick={() => handleEdit(locationBreak)}
                              disabled={locationBreak.id === editingLocationBreak?.id}

                            >
                              Edit
                            </button>
                            <button
                              className="btn btn-danger"
                              onClick={() => handleDelete(locationBreak.id)}
                            >
                              Delete
                            </button>
                          </td>{" "}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          align="center"
                          className="text-center align-align-items-center justify-content-center"
                          colSpan={6}
                        >
                          No records found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {locationBreakData?.length > 0 && (
              <div className="card-footer d-flex justify-content-end">
                <Pagination>
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  >
                    First
                  </Pagination.First>
                  <Pagination.Prev
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1}
                  >
                    Previous
                  </Pagination.Prev>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === page}
                      onClick={() => setPage(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => setPage(page + 1)}
                    disabled={page === totalPages}
                  >
                    Next
                  </Pagination.Next>
                  <Pagination.Last
                    onClick={() => setPage(totalPages)}
                    disabled={page === totalPages}
                  >
                    Last
                  </Pagination.Last>
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </div>
  );
};

export default LocationBreak;
