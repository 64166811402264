/* eslint-disable jsx-a11y/alt-text */
import React, {useState} from "react";
import { GetMarketingType } from "../../../models/_tickets";

const MarketingMaterials = (props: {
  materialDB: any;
  onSelectMarketingMaterialsChange: (selected: string[]) => void;
}) => {
  const {onSelectMarketingMaterialsChange, materialDB} =
    props;


  const [selectedMarketingMaterials, setSelectedMarketingMaterials] = useState<
    string[]
  >([]);

  const handleCheckboxChange = (marketingName: string) => {
    setSelectedMarketingMaterials((prev) => {
      const updatedSelection = prev.includes(marketingName)
        ? prev.filter((name) => name !== marketingName) // Remove if already selected
        : [...prev, marketingName]; // Add if not selected

      onSelectMarketingMaterialsChange(updatedSelection); // Update parent state
      return updatedSelection;
    });
  };
  
  return (
    <>
      <div className="wo-form-section mt-8">
        <div className="border-dotted border-2 border-gray-200 p-8 gap-2 mb-8">
          <h3 className="mb-0">Marketing Materials:</h3>
          <ul className="list-unstyled ms-8 mt-4">
            {materialDB.map((material: GetMarketingType, index: number) => (
              <label
                key={index}
                className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-4 mt-1"
              >
                <input
                  className="form-check-input mb-2 me-1"
                  type="checkbox"
                  checked={selectedMarketingMaterials.includes(material.id)}
                  onChange={() => handleCheckboxChange(material.id)}
                />
                <span className="ms-2 mb-1 fs-5 form-check-label">
                  {material.name}
                </span>
              </label>
            ))}
          </ul>
        </div>

        
       

        
      </div>
    </>
  );
};

export default MarketingMaterials;
