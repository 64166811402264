/**
 * @generated SignedSource<<5ed2db84354c55505e58075fd428ad96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EditBusinessModel_getBusinessModelByIdQuery$variables = {
  id: string;
};
export type EditBusinessModel_getBusinessModelByIdQuery$data = {
  readonly getBusinessModelById: {
    readonly description: string | null;
    readonly id: string;
    readonly isActive: boolean;
    readonly name: string;
  };
};
export type EditBusinessModel_getBusinessModelByIdQuery = {
  response: EditBusinessModel_getBusinessModelByIdQuery$data;
  variables: EditBusinessModel_getBusinessModelByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "BusinessModelsObjectType",
    "kind": "LinkedField",
    "name": "getBusinessModelById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditBusinessModel_getBusinessModelByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditBusinessModel_getBusinessModelByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0ccf06423aa4d372edfddae161c03604",
    "id": null,
    "metadata": {},
    "name": "EditBusinessModel_getBusinessModelByIdQuery",
    "operationKind": "query",
    "text": "query EditBusinessModel_getBusinessModelByIdQuery(\n  $id: String!\n) {\n  getBusinessModelById(id: $id) {\n    id\n    name\n    description\n    isActive\n  }\n}\n"
  }
};
})();

(node as any).hash = "4fcf1ccc7e7684fb0a2b1d1139272695";

export default node;
