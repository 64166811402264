/**
 * @generated SignedSource<<67676367e89b4b1522504aa0eafa92fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorkOrderParentComponentQuery$variables = {
  isRegionalManager?: boolean | null;
};
export type WorkOrderParentComponentQuery$data = {
  readonly getLocationList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
  readonly getMachineList: ReadonlyArray<{
    readonly id: string;
    readonly locationId: string | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly uuid: string | null;
  }>;
  readonly getUsers: ReadonlyArray<{
    readonly firstName: string;
    readonly id: string;
    readonly isActive: boolean;
    readonly isRegionalManager: boolean;
    readonly lastName: string | null;
  }>;
};
export type WorkOrderParentComponentQuery = {
  response: WorkOrderParentComponentQuery$data;
  variables: WorkOrderParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isRegionalManager"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getMachineList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "isRegionalManager",
        "variableName": "isRegionalManager"
      }
    ],
    "concreteType": "UserObjectType",
    "kind": "LinkedField",
    "name": "getUsers",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isRegionalManager",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationList",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkOrderParentComponentQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkOrderParentComponentQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "b6d3d3e4badfd7e3e207a98bb1efd849",
    "id": null,
    "metadata": {},
    "name": "WorkOrderParentComponentQuery",
    "operationKind": "query",
    "text": "query WorkOrderParentComponentQuery(\n  $isRegionalManager: Boolean\n) {\n  getMachineList {\n    id\n    uuid\n    serialNumber\n    name\n    locationId\n  }\n  getUsers(isRegionalManager: $isRegionalManager) {\n    id\n    firstName\n    lastName\n    isActive\n    isRegionalManager\n  }\n  getLocationList {\n    id\n    uuid\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "86edbbcdbb26bc59618036ae94ad543f";

export default node;
