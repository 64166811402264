import React, { FC, Suspense, useRef } from "react";
import Modal from "react-responsive-modal";
import Barcode from "react-barcode";
import { KTSVG } from "../../../_metronic/helpers";
import html2canvas from "html2canvas";

type Props = {
  barCodeModal: boolean;
  handleCloseBarCode: () => void;
  machineName: any;
  machineSerialNumber: any;
};

export const BarCodeModal: FC<Props> = ({
  barCodeModal,
  handleCloseBarCode,
  machineName,
  machineSerialNumber,
}) => {
  const barcodeRef = useRef<any>(null);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  // const [barcodeObject, setBarcodeObject] = React.useState<any>({
  //   machineName,
  //   machineSerialNumber,
  // });

  const downloadBarcode = async () => {
   setDownloadLoading(true);
   try {
     const canvas = await html2canvas(barcodeRef.current);
     if (!canvas) throw new Error("<canvas> not found in DOM");
     const img = canvas
       .toDataURL("image/jpg")
       .replace("image/jpg", "image/octet-stream");
     const link = document.createElement("a");
     link.href = img;
     link.download = "barcode.jpg";
     link.click();
     link.remove();
   } catch (error) {
     throw new Error("Failed to download barcode");
   } finally{
     setDownloadLoading(false);
   }
  };
  return (
    <div>
      <Suspense>
        <Modal
          open={barCodeModal}
          onClose={() => handleCloseBarCode()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">BAR CODE</h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12 col-lg-6 col-md-6 col-12 mt-2 pt-2">
                <div
                  className="d-flex justify-content-center"
                  style={{ width: "400px" }}
                  ref={barcodeRef}
                >
                  <Barcode
                    displayValue={true}
                    value={machineSerialNumber}
                  />
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-lg-12 col-md-12 col-12 mt-2 pt-2">
                <button
                  className="btn btn-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3  text-lg-center text-md-center text-xxl-center text-xl-center"
                  onClick={() => downloadBarcode()}
                >
                  {downloadLoading ? (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  ) : (
                    <span className="indicator-label">
                      <KTSVG
                        path="/media/icons/duotune/files/fil021.svg"
                        className="svg-icon-4 me-2"
                      />
                      Download Bar Code
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </Suspense>
    </div>
  );
};

export default BarCodeModal;
