import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateDistributor {
  name: string;
}
export async function createDistributor(input: CreateDistributor) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createDistributor (
            $input:CreateDistributor!
          ){
            createDistributor(
                createDistributorInput:$input
              )
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getDistributorsList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getAllDistibutors(
                  $where:  DistributorsResolver_GetAllDistibutors_FilterInputType
                  )
                  {
                    getAllDistibutors (where: $where){
                    id
                    name
                  }
                }
                `,
      variables: { where },
    },
  });
  return data?.data?.data?.getAllDistibutors;
}

interface UpdateDistributor {
  name: string;
}
export async function updateDistributor(input: UpdateDistributor) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateDistributor (
              $input: UpdateDistributor!
            ){
                updateDistributor(
                    updateDistributorInput: $input
                )
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
