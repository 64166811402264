/**
 * @generated SignedSource<<12b782f8865f0e91a553732b9d1f5e43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BlendParentComponentQuery$variables = {};
export type BlendParentComponentQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getDistributors: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getLocationList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
  readonly getLocationTypes: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly isDefault: boolean | null;
    readonly name: string;
  }>;
  readonly getMachineList: ReadonlyArray<{
    readonly id: string;
    readonly location: {
      readonly name: string;
    } | null;
    readonly locationId: string | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly uuid: string | null;
  }>;
  readonly getMachineStatus: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly getMarketSegment: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getRegions: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type BlendParentComponentQuery = {
  response: BlendParentComponentQuery$data;
  variables: BlendParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RegionObjectType",
    "kind": "LinkedField",
    "name": "getRegions",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineStatusObjectType",
    "kind": "LinkedField",
    "name": "getMachineStatus",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationTypeObjectType",
    "kind": "LinkedField",
    "name": "getLocationTypes",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MarketSegmentObjectType",
    "kind": "LinkedField",
    "name": "getMarketSegment",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "DistributorObjectType",
    "kind": "LinkedField",
    "name": "getDistributors",
    "plural": true,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getMachineList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BlendParentComponentQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BlendParentComponentQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "0e0622a58c2c8d7fc6a0e0ce9e378b9f",
    "id": null,
    "metadata": {},
    "name": "BlendParentComponentQuery",
    "operationKind": "query",
    "text": "query BlendParentComponentQuery {\n  getRegions {\n    id\n    name\n  }\n  getLocationList {\n    id\n    uuid\n    name\n  }\n  getMachineStatus {\n    id\n    name\n    description\n  }\n  getLocationTypes {\n    id\n    name\n    description\n    isDefault\n  }\n  getCustomerList {\n    id\n    name\n  }\n  getMarketSegment {\n    id\n    name\n  }\n  getDistributors {\n    id\n    name\n  }\n  getMachineList {\n    id\n    uuid\n    serialNumber\n    name\n    locationId\n    location {\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a692e14e762519d7ae52eded401c94e8";

export default node;
