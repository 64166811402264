import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateLocationTypeInput  {
  name: string;
  description: string;
}
export async function createLocationType(input: CreateLocationTypeInput ) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createLocationType (
            $input: CreateLocationTypeInput !
          ){
            createLocationType(
              createLocationTypeInput :$input
              ) 
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getLocationTypesList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                    getLocationTypes{
                      id
                      name
                      description
                      isDefault
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getLocationTypes;
}

export async function getAllLocationTypes(where:{} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getAllLocationTypes($where : LocationTypeResolver_GetAllLocationTypes_FilterInputType){
                  getAllLocationTypes (where :$where) {
                    id
                   }
                }
                `,
      variables: {where},
    },
  });
  return data?.data?.data?.getAllLocationTypes;
}

interface UpdateLocationTypeInput {
  name: string;
}
export async function updateLocationType(input: UpdateLocationTypeInput ) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateLocationType (
              $input: UpdateLocationTypeInput!
            ){
                updateLocationType(
                  updateLocationTypeInput : $input
                ) 
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
