import React, { useEffect } from "react";

const FlavorPrices = (props: {
  values: any;
  setFieldValue: any;
  flavors: any;
  logJob: any
}) => {
  const { values, setFieldValue, flavors, logJob } = props;

  // Handle Flavor Price Change
  const handleFlavorPriceChange = (flavorKey: string, value: string) => {
    const newPrices = (values.flavorPrices || []).map((item: any) =>
      item.flavor === flavorKey ? { ...item, price: Number(value) > 0 ? Number(value) : '' } : item
    );
    setFieldValue("flavorPrices", newPrices);
  };

  useEffect(() => { 
    if (flavors && flavors.length > 0) {
      const newFlavorPrices = flavors.map((flavor: any) => ({
        flavor: flavor.flavor,
        price: logJob.flavorPrices?.find(
          (item: any) => item.flavor === flavor.flavor
        )?.price ?? 0,
      }));
      setFieldValue("flavorPrices", newFlavorPrices);
    }
  }
  , [flavors, setFieldValue]);
  return (
    <div className="wo-form-section mt-8">
      <div className="border-dotted border-2 border-gray-200 p-8 gap-2 mb-8">
        <div className="align-items-center justify-content-between ">
          <h3 className="mb-0">Flavor Price:</h3>
          <table className="table table-bordered mt-4 ms-8">
            <thead>
              <tr>
                <th className="fw-bold mb-0">Flavor</th>
                <th className="fw-bold mb-0">Price</th>
              </tr>
            </thead>
            <tbody>
              {values.flavorPrices?.map((flavor: any) => {
                const flavorKey = flavor.flavor; // Use 'name' as key
                const currentFlavor = flavors?.find(
                  (item: any) => item.flavor === flavorKey
                );

                return (
                  <tr key={flavorKey}>
                    <td className="align-middle">{currentFlavor.name}</td>
                    <td className="align-middle">
                      <input
                        type="number"
                        name={`flavorPrices.${flavorKey}`}
                        id={`flavorPrices_${flavorKey}`}
                        className="form-control"
                        value={flavor?.price ?? ""}
                        onChange={(e) =>
                          handleFlavorPriceChange(flavorKey, e.target.value)
                        }
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default FlavorPrices;
