/**
 * @generated SignedSource<<ecc0a2c68fe9de969ac04f974150e024>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FlavorPriceListParentComponentQuery$variables = {};
export type FlavorPriceListParentComponentQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getLocationByCustomers: ReadonlyArray<{
    readonly customerId: string;
    readonly id: string;
    readonly name: string;
  }>;
};
export type FlavorPriceListParentComponentQuery = {
  response: FlavorPriceListParentComponentQuery$data;
  variables: FlavorPriceListParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationByCustomers",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerId",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FlavorPriceListParentComponentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FlavorPriceListParentComponentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "15c1abd20ef1f8ad02971ed986864e60",
    "id": null,
    "metadata": {},
    "name": "FlavorPriceListParentComponentQuery",
    "operationKind": "query",
    "text": "query FlavorPriceListParentComponentQuery {\n  getLocationByCustomers {\n    id\n    customerId\n    name\n  }\n  getCustomerList {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6d17dfbade46bae40d885d03de85423";

export default node;
