import React, { useEffect, useState } from "react";
import mainLogo from "../../../images/smoodi_Logo.png";
import { Formik } from "formik";
import "../WorkOrders/WorkOrder.css";
import { useSiteVisitation } from "../../providers/SiteVisitationProvider";
import { useParams } from "react-router-dom";
import TicketsInfo from "./Tabs/TicketsInfo";
import MachineInfo from "./Tabs/MachineInfo";
import MarketingMaterials from "./Tabs/MarketingMaterials";
import { updateJob, updateJobStatus } from '../../models/_publicCall';
import moment from "moment";
import SanitizerLevel from "./Tabs/SanitizerLevel";
import FlavorPrices from "./Tabs/FlavorPrices";
import SmoothieImgUpload from "./Tabs/SmoothieImgUpload";
import MarketingMaterialsUpload from "./Tabs/MarketingMaterialsUpload";
import Operation from "./Tabs/Operation";
import Moment from 'moment';
import toast, { Toaster } from "react-hot-toast";
import { MACHINE_SERVICES } from "../../../_metronic/helpers/enums";
import LocationChecks from "./Tabs/LocationChecks";

export interface SiteVisitationMachine {
  id: string;
  blenderId: string | null;
  tubeDoorId: string | null;
  name: string;
  serialNumber: string;
  hasPolyfuses: boolean;
  made4Smoothies: boolean;
  cleanlinessCheck: boolean;
  getStatusPassed: boolean;
  flowmeterActivated: boolean;
  waterLevelCalibratedWithScale: boolean;
  sanitizerLevel: string;
  replaceFilter: boolean;
}
export const SiteVisitation: React.FC = () => {
  const {
    loading,
    machineServicesDB,
    operationDB,
    materialDB,
    machinesDB,
    blendersDB,
    tubeShieldsDB,
    locationsDB,
    boardsDB,
    logJob,
    diagnosis,
    flavors,
    fetchSiteVisitJobOrder,
  } = useSiteVisitation();
  const { id } = useParams();
  const [travelTime, setTravelTime] = useState<any>(0);
  const [running, setRunning] = useState(false);
  const [workTime, setWorkTime] = useState<any>(0);
  const [workRunning, setWorkRunning] = useState(false);
  const [onWay, setOnWay] = useState(false)
  const [startWork, setStartWork] = useState(false)
  const [showJobCompleted, setShowJobCompleted] = useState(false);

  const [selectedDiagnoses, setSelectedDiagnoses] = useState<{
    [ticketId: string]: string[];
  }>({});
  const [selectedMarketingMaterials, setSelectedMarketingMaterials] = useState<
    string[]
  >([]);
  const [selectedMachineServicesDone, setSelectedMachineServicesDone] =
    useState<{ [ticketId: string]: string[] }>({});

  const [siteVisitationMachines, setSiteVisitationMachines] =
    useState<{ [machineId: string]: SiteVisitationMachine }>({}); 
  const [replaceNewTubeDoors1mmShorter, setReplaceNewTubeDoors1mmShorter] =
    useState<{ [ticketId: string]: boolean }>({});
    const [convertTicketToLabTicket, setConvertTicketToLabTicket] =
    useState<{ [ticketId: string]: boolean }>({});
  const [replacedMachines, setReplacedMachines] =
    useState<{ [ticketId: string]: string }>({});
  const [oldMachineShiftToLocation, setOldMachineShiftToLocation] =
    useState<{ [ticketId: string]: string }>({});
  const [replacedBoard, setReplacedBoard] =
    useState<{ [ticketId: string]: string }>({});
  const [replacedBlender, setReplacedBlender] =
    useState<{ [ticketId: string]: string }>({});
  const [machineServicesDoneOther, setMachineServicesDoneOther] = useState<{
    [ticketId: string]: string;
  }>({});
  const [selectedOperation, setSelectedOperation] = useState<string[]>([]);
  const [showLoaderForSave, setShowLoaderForSave] = useState(false);
  
  // // Effect to update travel timer
  useEffect(() => {
    if (!logJob || !logJob.job) {
      return;
    }
    
    const jobResult = logJob.job;

    if(jobResult.status as string === 'Completed') {
      setShowJobCompleted(true);
      return;
    }

    if(logJob.machines) {
      const newSiteVisitationMachines: { [machineId: string]: SiteVisitationMachine } = {};
      logJob.machines.forEach((machine: any) => {
        newSiteVisitationMachines[machine.id] = {
          id: machine.id,
          name: machine.name,
          serialNumber: machine.serialNumber,
          blenderId: machine.blenderId,
          tubeDoorId: machine.tubeShieldId,
          hasPolyfuses: machine.isPolyFuses,
          made4Smoothies: false,
          cleanlinessCheck: false,
          getStatusPassed: false,
          flowmeterActivated: false,
          waterLevelCalibratedWithScale: false,
          replaceFilter: false,
          sanitizerLevel: "",
        };
      });
      setSiteVisitationMachines(newSiteVisitationMachines);
    }
    if (jobResult.travelingStartTime) {
      let travelEndTime = Moment()
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      let travelStartTime = Moment.utc(jobResult.travelingStartTime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
      if (jobResult.travelingEndTime !== null) {
        travelEndTime = Moment.utc(jobResult.travelingEndTime)
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      }
      let diff = Moment(travelEndTime).diff(Moment(travelStartTime), "seconds");
      setTravelTime(diff)
      if (jobResult.travelingEndTime === null) {
        setRunning(true)
        setOnWay(true)
      }
    }

    if (jobResult.workStartTime) {
      let endWork = Moment()
        .local()
        .format("YYYY-MM-DD HH:mm:ss");
      let startWork = Moment.utc(jobResult.workStartTime)
        .local()
        .format("YYYY-MM-DD HH:mm:ss")
      if (jobResult.closingDateAndTime !== null) {
        endWork = Moment(jobResult.closingDateAndTime)
          .local()
          .format("YYYY-MM-DD HH:mm:ss")
      }
      let diff = Moment(endWork).diff(Moment(startWork), "seconds");
      setWorkTime(diff)
      if (!jobResult.closingDateAndTime) {
        setOnWay(true)
        setWorkRunning(true)
        setStartWork(true)
      }
    }
  }, [logJob]);

  useEffect(() => {
    let interval: any;
    if (running) {
      interval = setInterval(() => {
        setTravelTime((prevTime: any) => prevTime + 1);
      }, 1000);
    } else if (!running) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [running]);

  useEffect(() => {
    let interval: any;
    if (workRunning) {
      interval = setInterval(() => {
        setWorkTime((prevTime: any) => prevTime + 1);
      }, 1000);
    } else if (!workRunning) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [workRunning]);

  const travelTimer = () => {
    const getSeconds = `0${(travelTime % 60)}`.slice(-2)
    const minutes: any = `${Math.floor(travelTime / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(travelTime / 3600)}`.slice(-2)
    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }
  const workTimer = () => {
    const getSeconds = `0${(workTime % 60)}`.slice(-2)
    const minutes: any = `${Math.floor(workTime / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(workTime / 3600)}`.slice(-2)
    return `${getHours} : ${getMinutes} : ${getSeconds}`
  }
  // // Handle "On My Way" button click
  // const handleUpdateStatus = () => {
  //   setStatus("ON_MY_WAY");
  //   setTravelStartTime(Date.now()); // Start travel timer
  // };

  // // Handle "Start Work" button click
  // const handleStartWork = () => {
  //   setWorkStarted(true);
  //   setWorkStartTime(Date.now()); // Start work timer
  // };
  const handleUpdateStatus = async () => {
    if (!logJob) return;

    const date = moment.utc().format(); // Get current UTC time
    const result = await updateJobStatus(logJob.job.id, "ON_MY_WAY", date);

    if (result?.isSuccess) {
      //setStatus("ON_MY_WAY");
      setOnWay(true)
      setRunning(true)
    } else {
      console.error("Failed to update status:", result?.message);
    }
  };

  // Handle "Start Work" Button Click
  const handleStartWork = async () => {
    if (!logJob) return;


    const date = moment.utc().format(); // Get current UTC time
    const result = await updateJobStatus(logJob.job.id, "START_WORK", date);

    if (result?.isSuccess) {
      //setStatus("ON_MY_WAY");
      setWorkTime(1)
      setStartWork(true)
      setWorkRunning(true)
      setRunning(false)
    } else {
      console.error("Failed to start work:", result?.message);
    }
  };
  useEffect(() => {
    if (!id) return;
    fetchSiteVisitJobOrder(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <>
      <div className="wo-body">
        <div className="wo-logo-container">
          <img src={mainLogo} alt="Smoody Logo" className="wo-logo" />
        </div>

        <div className="wo-form-wrapper">
          <div className="wo-header-container">
            <h2 className="wo-header-title">Site Visitation</h2>
          </div>
        </div>

        {(!loading && logJob && logJob.job && !showJobCompleted) && (
          <>
            <div className="wo-form-wrapper">
              <div className="wo-form-container">
                <div className="row">
                    <Formik
                    initialValues={{
                      locationId: logJob?.location?.id,
                      address: logJob?.location?.address,
                      tickets: [],
                      blenderId: "",
                      tubeDoorId: "",
                      flowmeterActivated: "",
                      hasPolyfuses: "",
                      made4Smoothies: "",
                      cleanlinessCheck: "",
                      getStatusPassed: "",
                      waterLevelCalibratedWithScale: "",
                      operations: [],
                      marketingMaterials: [],
                      flavorPrices: flavors?.map((flavor: any) => {
                      const existingPrice = logJob.flavorPrices?.find(
                        (flavorPrice: any) => flavorPrice.flavor === flavor.flavor
                      );
                      return {
                        flavor: flavor.flavor,
                        price: existingPrice ? existingPrice.price : 0,
                      };
                      }) || [],
                      smoothieSetupMedia: [],
                      marketingMaterialPresent: [],
                      hasWifiExtender: logJob.location.hasWifiExtender ?? false,
                      hasSmoodiRouter: logJob.location.hasSmoodiRouter ?? false,
                      hasPressurePump: logJob.location.hasPressurePump ?? false,
                      hasPressureTank: logJob.location.hasPressureTank ?? false,
                    }}
                    onSubmit={async (values, { resetForm, setSubmitting }) => {
                      if (!logJob) return;
                      const errorMessages : string[] = [];

                      Object.values(siteVisitationMachines).forEach((machine) => {
                      
                      if(!machine.sanitizerLevel) {
                        errorMessages.push(`Please select sanitizer level for Machine: ${machine.name}`);
                      }

                      if(!machine.blenderId) {
                        errorMessages.push(`Please select blender for Machine: ${machine.name}`);
                      }
                      if(!machine.tubeDoorId) {
                        errorMessages.push(`Please select tube door for Machine: ${machine.name}`);
                      }
                      });
                      const $inputData: any = {
                      locationId: logJob?.location?.id,
                      address: logJob?.location?.address,
                      tickets: logJob.tickets.map((ticket: any, index) => {
                        if(!selectedDiagnoses[ticket.id] || selectedDiagnoses[ticket.id].length === 0) return;
                        if (selectedMachineServicesDone[ticket.id].includes('other')) {
                        if (!machineServicesDoneOther[ticket.id]) {
                          errorMessages.push(`Please provide other machine service done for Ticket: ${ticket.uuid}`);
                          return;
                        }
                        }
                        if (selectedDiagnoses[ticket.id].includes('other')) {
                        if (!diagnosis[ticket.id]) {
                          errorMessages.push(`Please provide other diagnosis for Ticket: ${ticket.uuid}`);
                          return;
                        }
                        }

                        if (
                        selectedMachineServicesDone[ticket.id]?.includes(
                          MACHINE_SERVICES.REPLACE_BLENDER_ASSEMBLY
                        ) &&
                        !replacedBlender[ticket.id]
                        ) {
                        errorMessages.push(`Please provide new blender for Ticket: ${ticket.uuid}`);
                        return
                        }

                        if (
                        (selectedMachineServicesDone[ticket.id]?.includes(
                          MACHINE_SERVICES.UPGRADE_PCB
                        ) ||
                        selectedMachineServicesDone[ticket.id]?.includes(
                          MACHINE_SERVICES.REPLACE_PCB
                          )) &&
                        !replacedBoard[ticket.id]
                        ) {
                        errorMessages.push(`Please provide new board for Ticket: ${ticket.uuid}`);
                        return
                        }
                        if (
                        selectedMachineServicesDone[ticket.id]?.includes(
                          MACHINE_SERVICES.REPLACE_MACHINE
                        ) &&
                        !replacedMachines[ticket.id]
                        ) {
                        errorMessages.push("Please provide new machine for all tickets");
                        return
                        }

                        return {
                          id: ticket.id,
                          issuesReported: ticket.issuesReported.map(
                          (issue: any) => issue.name
                          ),
                          diagnosis: (selectedDiagnoses[ticket.id] || []).filter(
                            (item) => item !== "other"
                          ),
                          machineServicesDone:
                          (selectedMachineServicesDone[ticket.id] || []).filter(
                            (service) => service !== "other"
                          ),
                          machineServicesDoneOther:
                          machineServicesDoneOther[ticket.id] || "",
                          replaceNewTubeDoors1mmShorter: replaceNewTubeDoors1mmShorter[ticket.id] || false,
                          newBlenderId: replacedBlender[ticket.id] || "",
                          newBoardId: replacedBoard[ticket.id] || "",
                          newMachineId: replacedMachines[ticket.id] || "",
                          convertToLabTicket: convertTicketToLabTicket[ticket.id] || false,
                        }
                      }).filter(Boolean),
                      operations: selectedOperation,
                      machines: Object.values(siteVisitationMachines),
                      marketingMaterials: selectedMarketingMaterials,
                      flavorPrices: values.flavorPrices,
                      smoothieSetupMedia: values.smoothieSetupMedia,
                      marketingMaterialPresent:
                        values.marketingMaterialPresent,
                        hasSmoodiRouter: values.hasSmoodiRouter,
                        hasWifiExtender: values.hasWifiExtender,
                        hasPressurePump: values.hasPressurePump,
                        hasPressureTank: values.hasPressureTank,
                      };

                      if(errorMessages.length > 0) {
                      toast.error(errorMessages.join(", "));
                      return;
                      }
                      setShowLoaderForSave(true);
                      const result = await updateJob(logJob.job.id, $inputData);
                      if(result.isSuccess) {
                      toast.success("Job updated successfully");
                      setShowJobCompleted(true);
                      }
                      else {
                      toast.error(result.message);
                      }
                    }}
                    >
                    {({
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      values,
                      errors,
                      touched,
                      setFieldValue,
                    }) => {

                      return (
                      <>
                        <form onSubmit={handleSubmit} autoComplete="off">
                        <div>
                          <div className="col-6">
                          <div className="d-flex align-items-center gap-2">
                            <label
                            htmlFor="locationId"
                            className="wo-label fs-4 mb-0"
                            >
                            Location Name:
                            </label>
                            <span className="mb-0 fs-5">
                            {logJob?.location?.name}
                            </span>
                          </div>

                          <div className="d-flex align-items-center gap-2 mt-8">
                            <label
                            htmlFor="address"
                            className="wo-label fs-4 mb-0"
                            >
                            Address:
                            </label>
                            <span className="mb-0 fs-5">
                            {logJob?.location?.address}
                            </span>
                          </div>
                          <div className="mt-8">
                            {onWay ? (
                            <>
                              <div className="btn btn-light text-dark me-3">
                              <div>Traveling Time</div>
                              <div>{travelTimer() || "Updating..."}</div>
                              </div>
                              {startWork ? (
                              <div className="btn btn-light text-dark me-3">
                                <div>Work Time</div>
                                <div>{workTimer()}</div>
                              </div>
                              ) : (
                              <button
                                className="btn btn-light text-dark me-2"
                                onClick={handleStartWork}
                                type="button"
                              >
                                Start Work
                              </button>
                              )}
                            </>
                            ) : (
                            <button
                              type="button"
                              onClick={handleUpdateStatus}
                              className="btn btn-light text-dark me-2"
                            >
                              On My Way
                            </button>
                            )}
                          </div>
                          </div>
                          <TicketsInfo
                          tickets={logJob?.tickets}
                          logJob={logJob}
                          diagnosis={diagnosis}
                          machinesDB={machinesDB}
                          blendersDB={blendersDB}
                          boardsDB={boardsDB}
                          machineServicesDB={machineServicesDB}
                          locationsDB={locationsDB}
                          onSelectedDiagnosesChange={setSelectedDiagnoses}
                          setReplaceNewTubeDoors1mmShorter={setReplaceNewTubeDoors1mmShorter}
                          replaceNewTubeDoors1mmShorter={replaceNewTubeDoors1mmShorter}
                          setReplacedMachines={setReplacedMachines}
                          replacedMachines={replacedMachines}
                          setReplacedBoard={setReplacedBoard}
                          replacedBoard={replacedBoard}
                          setReplacedBlender={setReplacedBlender}
                          setConvertTicketToLabTicket={setConvertTicketToLabTicket}
                          convertTicketToLabTicket={convertTicketToLabTicket}
                          replacedBlender={replacedBlender}
                          setOldMachineShiftToLocation={setOldMachineShiftToLocation}
                          oldMachineShiftToLocation={oldMachineShiftToLocation}
                          siteVisitationMachines={siteVisitationMachines}
                          onSelectedMachineServicesDoneChange={
                            setSelectedMachineServicesDone
                          }
                          onMachineServicesDoneOtherChange={
                            setMachineServicesDoneOther
                          }
                          setSiteVisitationMachines={
                            setSiteVisitationMachines
                          }
                          />
                          <Operation
                          operation={operationDB}
                          onSelectOperationChange={setSelectedOperation}
                          />

                          <MarketingMaterials
                          materialDB={materialDB}
                          onSelectMarketingMaterialsChange={
                            setSelectedMarketingMaterials
                          }
                          />
                          
                          <FlavorPrices
                          values={values}
                          setFieldValue={setFieldValue}
                          flavors={flavors}
                          logJob={logJob}
                          />
                          <MachineInfo
                          values={values}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          tubeShieldsDB={tubeShieldsDB}
                          blendersDB={blendersDB}
                          logJob={logJob}
                          siteVisitationMachines={siteVisitationMachines}
                          setSiteVisitationMachines={setSiteVisitationMachines}
                          />
                          <LocationChecks 
                          values={values}
                          setFieldValue={setFieldValue}
                          />

                          <SmoothieImgUpload
                          values={values}
                          setFieldValue={setFieldValue}
                          />
                          <MarketingMaterialsUpload
                          values={values}
                          setFieldValue={setFieldValue}
                          />

                          <div
                          style={{
                            borderTop: "1px solid #dbdce4",
                            marginTop: "3rem",
                            marginBottom: "3rem",
                          }}
                          ></div>
                          <div className="button-container">
                          <button
                            type="submit"
                            className="btn btn-primary me-4 fs-4"
                            onClick={(e) => {
                            // setSubmitClick(true);
                            }}
                            disabled={!workRunning || showLoaderForSave}
                          >
                            {showLoaderForSave ? (
                            <span
                              className="indicator-progress"
                              style={{ display: "block" }}
                            >
                              Please Wait..
                              <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                            </span>
                            ) : (
                            <span className="indicator-label">
                              Submit
                            </span>
                            )}
                          </button>
                          </div>
                        </div>
                        </form>
                      </>
                      );
                    }}
                    </Formik>
                </div>
              </div>
            </div>
          </>
        )}

        {
          loading && (
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {
          showJobCompleted && (
            <div className="card">  
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <h3>Job Completed</h3>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </>
  );
};
