import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import { Location, Machine } from "../Machines/MachineParentComponent";
import { Customer } from "../Customers/CustomerParentComponent";
import CustomerDashboard from "./CustomerDashboard";

interface ConsumptionSummaryParentComponentQueryResponse {
  getCustomerList: Customer[];
  getMachineList: Machine[];
}

export interface ConsumptionSummaryProps {
  customers: Customer[];
  machines: Location[];
}

type ConsumptionSummaryParentComponentQuery = OperationType & {
  response: ConsumptionSummaryParentComponentQueryResponse;
  variables: Record<string, never>;
};

class ConsumptionSummaryParentComponent extends React.Component<any, any> {
  render() {
    return (
      <QueryRenderer<ConsumptionSummaryParentComponentQuery>
        environment={environment}
        query={graphql`
          query ConsumptionSummaryParentComponentQuery {
            getMachineList {
              id
              uuid
              serialNumber
              name
              locationId
            }

            getCustomerList {
              id
              name
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return (
              <div>
                Error loading customer satisfaction data: {error.message}
              </div>
            );
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const { getCustomerList, getMachineList } = props;

          return (
            <CustomerDashboard
              customers={getCustomerList}
              machines={getMachineList}
            />
          );
        }}
      />
    );
  }
}

export default ConsumptionSummaryParentComponent;
