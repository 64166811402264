/**
 * @generated SignedSource<<e223083a99fb6227045d6b0bf776151e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ViewStatusModel_getMachineDeviceStatusLogQuery$variables = {
  machineId: string;
};
export type ViewStatusModel_getMachineDeviceStatusLogQuery$data = {
  readonly getMachineDeviceStatusLog: ReadonlyArray<{
    readonly blenderActBottomPos: number | null;
    readonly blenderActMaxCur: number | null;
    readonly blenderActTopPos: number | null;
    readonly blenderMotorCur: number | null;
    readonly blenderMotorCurRatio: number | null;
    readonly cleanValveCur: number | null;
    readonly cupSensorBlkdVal: number | null;
    readonly cupSensorCupDiffVal: number | null;
    readonly cupSensorUnBlkVal: number | null;
    readonly doorActBottomPos: number | null;
    readonly doorActMaxCur: number | null;
    readonly doorActTopPos: number | null;
    readonly fillValveCur: number | null;
    readonly id: string;
    readonly machine: {
      readonly id: string;
      readonly location: {
        readonly name: string;
      } | null;
      readonly serialNumber: string;
    } | null;
    readonly misc12v: number | null;
    readonly misc5v: number | null;
    readonly miscTemp: number | null;
    readonly peelSealBlenderLower: number | null;
    readonly peelSealOpenDoor: number | null;
    readonly pumpCur: number | null;
  }>;
};
export type ViewStatusModel_getMachineDeviceStatusLogQuery = {
  response: ViewStatusModel_getMachineDeviceStatusLogQuery$data;
  variables: ViewStatusModel_getMachineDeviceStatusLogQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "machineId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "machineId",
        "variableName": "machineId"
      }
    ],
    "concreteType": "MachineDeviceStatusObjectType",
    "kind": "LinkedField",
    "name": "getMachineDeviceStatusLog",
    "plural": true,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MachineObjectType",
        "kind": "LinkedField",
        "name": "machine",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serialNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationObjectType",
            "kind": "LinkedField",
            "name": "location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActBottomPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActMaxCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderActTopPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cleanValveCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorBlkdVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorCupDiffVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupSensorUnBlkVal",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActBottomPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActMaxCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "doorActTopPos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fillValveCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc12v",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "misc5v",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "miscTemp",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealBlenderLower",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "peelSealOpenDoor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pumpCur",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderMotorCurRatio",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0dc6146b1c657fe11628d50114a808c7",
    "id": null,
    "metadata": {},
    "name": "ViewStatusModel_getMachineDeviceStatusLogQuery",
    "operationKind": "query",
    "text": "query ViewStatusModel_getMachineDeviceStatusLogQuery(\n  $machineId: String!\n) {\n  getMachineDeviceStatusLog(machineId: $machineId) {\n    id\n    machine {\n      id\n      serialNumber\n      location {\n        name\n      }\n    }\n    blenderActBottomPos\n    blenderActMaxCur\n    blenderActTopPos\n    blenderMotorCur\n    cleanValveCur\n    cupSensorBlkdVal\n    cupSensorCupDiffVal\n    cupSensorUnBlkVal\n    doorActBottomPos\n    doorActMaxCur\n    doorActTopPos\n    fillValveCur\n    misc12v\n    misc5v\n    miscTemp\n    peelSealBlenderLower\n    peelSealOpenDoor\n    pumpCur\n    blenderMotorCurRatio\n  }\n}\n"
  }
};
})();

(node as any).hash = "f4c00d41fe392ccdeacd3eba5c582b23";

export default node;
