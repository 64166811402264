/**
 * @generated SignedSource<<0208029df8c9319a9124d8840571e936>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ChangeLocationModal_getmachineQuery$variables = {
  id: string;
};
export type ChangeLocationModal_getmachineQuery$data = {
  readonly getmachine: {
    readonly id: string;
    readonly location: {
      readonly id: string;
      readonly name: string;
      readonly uuid: string | null;
    } | null;
    readonly name: string | null;
    readonly serialNumber: string;
    readonly uuid: string | null;
  };
};
export type ChangeLocationModal_getmachineQuery = {
  response: ChangeLocationModal_getmachineQuery$data;
  variables: ChangeLocationModal_getmachineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getmachine",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeLocationModal_getmachineQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeLocationModal_getmachineQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "1733d27dd72a52915190db4d9e3d3b83",
    "id": null,
    "metadata": {},
    "name": "ChangeLocationModal_getmachineQuery",
    "operationKind": "query",
    "text": "query ChangeLocationModal_getmachineQuery(\n  $id: String!\n) {\n  getmachine(id: $id) {\n    id\n    uuid\n    serialNumber\n    name\n    location {\n      id\n      uuid\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da91339a0606174aba2927c989d0cf12";

export default node;
