/**
 * @generated SignedSource<<ffdc7355d4786649b4c9908f62c08dda>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UpdateLocationBusinessHours_getLocationQuery$variables = {
  id: string;
};
export type UpdateLocationBusinessHours_getLocationQuery$data = {
  readonly getLocation: {
    readonly businessHours: ReadonlyArray<{
      readonly closeTime: string | null;
      readonly createdAt: any | null;
      readonly dayOfWeek: number;
      readonly id: string;
      readonly isOpen: boolean | null;
      readonly locationId: string;
      readonly openTime: string | null;
      readonly updatedAt: any | null;
    }> | null;
    readonly id: string;
    readonly uuid: string | null;
  };
};
export type UpdateLocationBusinessHours_getLocationQuery = {
  response: UpdateLocationBusinessHours_getLocationQuery$data;
  variables: UpdateLocationBusinessHours_getLocationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocation",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationBusinessHourObjectType",
        "kind": "LinkedField",
        "name": "businessHours",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "locationId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dayOfWeek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "openTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closeTime",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOpen",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLocationBusinessHours_getLocationQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateLocationBusinessHours_getLocationQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "989c3786e99dc1f352e6f2e546efcbd4",
    "id": null,
    "metadata": {},
    "name": "UpdateLocationBusinessHours_getLocationQuery",
    "operationKind": "query",
    "text": "query UpdateLocationBusinessHours_getLocationQuery(\n  $id: String!\n) {\n  getLocation(id: $id) {\n    id\n    uuid\n    businessHours {\n      id\n      locationId\n      dayOfWeek\n      openTime\n      closeTime\n      isOpen\n      createdAt\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f395eed246f91a5d80e47ad47c03ef17";

export default node;
