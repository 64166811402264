import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import { Location, LocationType, Machine, User } from "../Machines/MachineParentComponent";
import LogJobList from "./LogJobList";

export interface MarketingMaterials {
  id: string;
  name: string;
  priority: number;
}

interface LogJobsParentComponentQueryResponse {
  getLocationList: Location[];
  getMachineList: Machine[];
  getUsers: User[];
  getMarketingMaterials: MarketingMaterials[];
  getLocationTypes: LocationType[];
}

export type LogJobsParentComponentQuery = OperationType & {
  response: LogJobsParentComponentQueryResponse;
  variables: {
    isRegionalManager: boolean;
  };
};

class LogJobsParentComponent extends React.Component {
  render() {
    return (
      <QueryRenderer<LogJobsParentComponentQuery>
        environment={environment}
        query={graphql`
          query LogJobsParentComponentQuery($isRegionalManager: Boolean) {
            getLocationList {
              id
              uuid
              name
            }
            getMachineList {
              id
              uuid
              serialNumber
              name
              locationId
            }
            getUsers(isRegionalManager: $isRegionalManager) {
              id
              firstName
              lastName
              isActive
              isRegionalManager
            }
            getMarketingMaterials {
              id
              name
              priority
            }
            getLocationTypes {
              id
              name
              description
              isDefault
            }
          }
        `}
        variables={{ isRegionalManager: true }}
        render={({ error, props }) => {
          if (error) {
            return (
              <div className="error-container">
                Error loading log jobs data: {error.message}
              </div>
            );
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }

          const {
            getLocationList,
            getMachineList,
            getUsers,
            getMarketingMaterials,
            getLocationTypes
          } = props;

          return (
            <LogJobList
              machines={getMachineList}
              locations={getLocationList}
              users={getUsers}
              locationTypes ={getLocationTypes}
              marketingMaterials={getMarketingMaterials}
            />
          );
        }}
      />
    );
  }
}

export default LogJobsParentComponent;
