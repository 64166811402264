import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;



export async function getBlendLogs(where: {} = {}, pagination: {} | undefined = undefined ,customFilter:{}={}) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendLogs(
                $where: BlendLogResolver_GetBlendLogs_FilterInputType,
                $paginate: PaginatorArgs
                $customFilter:BlendLogCustomFilterType!
                ) {
                    getBlendLogs(where: $where, paginate: $paginate ,customFilter:$customFilter) {
                        data{
                          id
                        machineId
                        locationId
                        blendDate
                        blendDateUtc
                        thickness
                        blendDuration
                        flavor
                        feedback
                        machine {
                          id
                          name
                       }
                        location {
                          id
                          name
                        }
                      }
                      totalPages
                      total
                      perPage
                      page
                      }
              }
              `,
        variables: { where, paginate: pagination ,customFilter },
      },
    });
    return data?.data?.data?.getBlendLogs;
  }
  export async function getBlendSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendSummary(
                $filter :BlendSummaryInput!
                ) {
                  getBlendSummary(filter: $filter) {
                      averageBlend
                      averageUpTime
                      machines {
                        machineId
                        locationId
                        machineStatusName
                        machineName
                        locationName
                        regionId
                        regionName
                        blendCount
                        hasHeartbeat
                        deploymentManagerId
                        deploymentManagerName
                        distributorId
                        distributorName
                        tickets
                        machineUpTimeInMinutes
                        totalDownTimeThroughTickets
                        lastHeartbeatAt
                        kpi
                        upTimePercentage
                        downTimePercentage
                      }
                      }
              }
              `,
        variables: { filter},
      },
    });
    return data?.data?.data?.getBlendSummary;
  }

  export async function getAllTimeBlendSummary(filter:{} = {} ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getAllTimeBlendSummary(
                $filter : AllTimeBlendSummaryInput!
                ) {
                  getAllTimeBlendSummary(filter: $filter) {
                    averageBlend
                    totalMachines
                    allMachinesTotalBlend
                  }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.getAllTimeBlendSummary;
  }
  export async function getCustomerSatisfactionSummary(filter:{fromDate:"" , toDate:""}  ) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getBlendCustomerSatisfactionSummary(
                $filter :BlendSummaryInput!
                ) {
                  getBlendCustomerSatisfactionSummary(filter: $filter) {
                    averageRating
                    totalFeedback
                    feedbackByFlavorSummary {
                      flavor
                      averageRating
                      totalFeedback
                    }
                  flavorFeedbackCount {
                    flavor
                    count
                    feedback
                  }
                  machineWiseAvgFeedback {
                    machineName
                    averageRating
                  }
                  machineWiseFlavorFeedbackCount {
                    totalGG
                    totalBB
                    totalTT
                    machineName
                    machineId
                  }
                      }
              }
              `,
        variables: { filter },
      },
    });
    return data?.data?.data?.getBlendCustomerSatisfactionSummary;
  }

  interface CreateOfflineBlendLogInput {
    fromDate: string;
    toDate: string;
    customerIds?: string[];
    locationIds?: string[];
    machineIds?: string[];
  }
  export async function requestForOfflineBlendLogData(input: CreateOfflineBlendLogInput) {
    let data;
    data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
            mutation requestForOfflineBlendLogData (
              $input: OfflineBlendLogInput!
            ){
              requestForOfflineBlendLogData(
                createOfflineBlendLogInput: $input
              )
            }
            `,
        variables: {
          input,
        },
      },
    }).catch((e: any) => (data = e.response));
    return data;
  }


  export async function getOfflineBlendLogs(pagination: {} | undefined = undefined) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query  getOfflineBlendLogs(
                $paginate: PaginatorArgs
                ) {
                    getOfflineBlendLogs(paginate: $paginate) {
                      data{
                        id
                        fromDate
                        toDate
                        filePath
                        machineIds
                        customerIds
                        locationIds
                        status
                        createdById
                        requestedAt
                        completedAt
                        createdAt
                        updatedAt
                      }
                      totalPages
                      total
                      perPage
                      page
                      }
              }
              `,
        variables: { paginate: pagination},
      },
    });
    return data?.data?.data?.getOfflineBlendLogs;
  }

  export async function getOfflineBlendLogFile(key: string) {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
              query offlineBlendLogFileDownload(
                $key: String!
                ) {
                    offlineBlendLogFileDownload(key: $key) {
                      message
                      fileData
                  }
              }
              `,
        variables: { key},
      },
    });
    return data?.data?.data?.offlineBlendLogFileDownload;
  }