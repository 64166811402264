/**
 * @generated SignedSource<<15b669c6760709a768301644a7ebdd76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EditCustomerAccount_getCustomerAccountByIdQuery$variables = {
  id: string;
};
export type EditCustomerAccount_getCustomerAccountByIdQuery$data = {
  readonly getCustomerAccountById: {
    readonly customer: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly customerRole: {
      readonly id: string;
      readonly name: string;
      readonly slug: string;
    } | null;
    readonly email: string | null;
    readonly id: string;
    readonly location: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly name: string | null;
  };
};
export type EditCustomerAccount_getCustomerAccountByIdQuery = {
  response: EditCustomerAccount_getCustomerAccountByIdQuery$data;
  variables: EditCustomerAccount_getCustomerAccountByIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "CustomerAccountObjectType",
    "kind": "LinkedField",
    "name": "getCustomerAccountById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationObjectType",
        "kind": "LinkedField",
        "name": "location",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerObjectType",
        "kind": "LinkedField",
        "name": "customer",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerRoleObjectType",
        "kind": "LinkedField",
        "name": "customerRole",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCustomerAccount_getCustomerAccountByIdQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCustomerAccount_getCustomerAccountByIdQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "5b8845e64c76bf74490f2a4bf1f88c32",
    "id": null,
    "metadata": {},
    "name": "EditCustomerAccount_getCustomerAccountByIdQuery",
    "operationKind": "query",
    "text": "query EditCustomerAccount_getCustomerAccountByIdQuery(\n  $id: String!\n) {\n  getCustomerAccountById(id: $id) {\n    id\n    name\n    email\n    location {\n      id\n      name\n    }\n    customer {\n      id\n      name\n    }\n    customerRole {\n      id\n      slug\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "068f1719c1fb2678584444ec6fe30be5";

export default node;
