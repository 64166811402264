
import { FC, useState, Suspense, useEffect } from "react";
import Modal from "react-responsive-modal";
import { Formik } from "formik";
import { fetchQuery,  useRelayEnvironment } from "react-relay";
import toast, { Toaster } from "react-hot-toast";
import "react-datetime/css/react-datetime.css";
import { updateLocationFreezerDetail } from "../../models/_location";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "react-query";

type Props = {
  currentLocationId: string;
  handleFreezerClose: () => void;
  freezerInformationModal: boolean;
  refreshTable?: any;
};


export const getLocationById = graphql`
          query FreezerInformation_getLocationQuery($id: String!) {
             getLocation(id: $id) {
              id
              uuid
              name
              freezerCredentials {
                apiKey
                serialNumber
              }             
        }
    }
`;

export const FreezerInformation: FC<Props> = ({
  currentLocationId,
  freezerInformationModal,
  handleFreezerClose,
  refreshTable,
}) => {
  
  const environment = useRelayEnvironment();

  const refetchFreezerInformation = async (): Promise<void> => {
    try {
      await refetch();
      console.log("Successfully updated cache with the latest freezer information.");
    } catch (error) {
      console.error("Error refetching freezer information:", error);
    }
  };
  
  const { data: locationRecord, refetch } = useQuery(
      ["getLocation", currentLocationId],
      () => fetchQuery(environment, getLocationById, { id: currentLocationId ?? undefined }).toPromise(),
      {
        enabled: !!currentLocationId,
      }
    );
    const locations: any = locationRecord && typeof locationRecord === 'object' && 'getLocation' in locationRecord ? locationRecord.getLocation : [];
      
  const [loading, setLoading] = useState(false);
 

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <Suspense>
      <Modal
        open={freezerInformationModal}
        onClose={() => handleFreezerClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{  
            serial_number: locations?.freezerCredentials?.serialNumber ?? "",
            api_key: locations?.freezerCredentials?.apiKey ?? "",
          }}
          onSubmit={async (values, { resetForm }) => {
            try {
            setLoading(true);
            
            if(!values.api_key) {
              toast.error("Please enter api key");
              return;
            }

            if(!values.serial_number) {
              toast.error("Please enter freezer serial number");
              return;
            }

            const updateData: any = {
              locationId: locations.id,
              serialNumber: values.serial_number,
              apiKey: values.api_key
            };

            const result = await updateLocationFreezerDetail(updateData);
            if (result?.data?.data?.updateLocationFreezerDetails) {
              refetchFreezerInformation();
              toast.success("Location freezer information updated successfully");
              handleFreezerClose();
              resetForm();
              if (typeof refreshTable !== "undefined") {
                refreshTable();
              }
            } else {
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          } catch (error) {
            console.log("Error in updating freezer information",error)
          } finally {
            setLoading(false);
          }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
          }) => (
            <form
              id="kt_modal_update_location_price_form"
              className="form"
              onSubmit={handleSubmit}
              autoComplete="off"
              noValidate
            >
              <div className="modal-header p-2 pb-4 mb-4">
                <h4 className="modal-title fs-1 mt-4">
                  Freezer Information- {locations?.uuid ?? ""}
                </h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-12 mt-2 pt-2">
                    <label className="form-label fs-4 fw-bold required">
                    Freezer Information
                    </label>

                    <table className="table table-boarderd">
                      <thead>
                        
                      </thead>
                      <tbody>
                        <tr>
                          <td>Serial Number</td>
                          <td>
                            <input
                              type="text"
                              name="serial_number"
                              id="serial_number"
                              className="form-control"
                               value={values.serial_number}
                              onChange={handleChange}
                            />
                          </td>
                          </tr>
                          <tr>
                          <td>Api key</td>
                          <td>
                            <input
                              type="text"
                              name="api_key"
                              id="api_key"
                              className="form-control"
                              value={values.api_key}
                              onChange={handleChange}
                            />
                          </td>
                          </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer mt-6 p-0 justify-content-start">
                <button
                  type="submit"
                  className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                >
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!loading && <span className="indicator-label">Submit</span>}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
