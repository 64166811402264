import React, { useContext } from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import { Customer } from "../Customers/CustomerParentComponent";
import FlavorPriceList from "./FlavorPriceList";
import { FlavorContext } from "../../providers/flavorProvider";

export interface LocationByCustomer {
  id: string;
  name: string;
  customerId: string;
}

interface QueryResponse {
  getCustomerList: Customer[];
  getLocationByCustomers: LocationByCustomer[];
}

export type FlavorPriceListParentComponentQuery = OperationType & {
  response: QueryResponse;
  variables: {};
};

const FlavorPriceListParentComponent: React.FC = () => {
      const {flavorsDB} = useContext(FlavorContext);
  return (
    <QueryRenderer<FlavorPriceListParentComponentQuery>
      environment={environment}
      query={graphql`
        query FlavorPriceListParentComponentQuery {
          getLocationByCustomers {
            id
            customerId
            name
          }
          getCustomerList {
            id
            name
          }
        }
      `}
      variables={{ isRegionalManager: true }}
      render={({ error, props }) => {
        if (error) {
          return <div className="error">Error: {error.message}</div>;
        }

        if (!props) {
          return <div className="loading-indicator">Loading...</div>;
        }

        const { getCustomerList, getLocationByCustomers } = props;

        return (
          <FlavorPriceList
            customers={getCustomerList}
            locations={getLocationByCustomers}
            flavorsDB={flavorsDB}
          />
        );
      }}
    />
  );
};

export default FlavorPriceListParentComponent;
