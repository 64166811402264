import { ErrorMessage } from "formik";
import clsx from "clsx";
import Select from "react-select";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from 'moment';
import { Tooltip } from "react-tooltip";
import { useTicketDependencies } from "../../../providers/TicketDependenciesProvider";

const Step1 = (props: {
  formValues: any;
  formError: any;
  formTouched: any;
  setFieldValue: any;
  resetMachine: any;
  resetSource: any;
  resetAssignTo: any;
  resetReportedById: any;
  resetReportedAt: any;
}) => {

  const { resetAssignTo, resetReportedById, resetReportedAt, resetMachine, resetSource, formTouched, setFieldValue, formError,formValues } = props;
  
  const { assignToDB, ticketSourceDB, reportedByDB, machineDB, loading: isLoading, serviceTypeDB, reporterType, priorityDB, ticketTypes,  } = useTicketDependencies();

  const selectedMachine  = machineDB.find((value: { id: string; name: string; location?: { name: string }; serialNumber: string }) => value.id === formValues.machineId);

  return (
    <>
      {!isLoading && <><div className="row">
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
          <label className="selectTicketType form-label required fw-bold fs-4">
            Select Ticket Type
          </label>
          <i className="selectTicketType fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".selectTicketType" className="tooltip-dashboard">
            Reactive Ticket: For urgent service issues that stop the machine's operation.
            Preventive Ticket: For non-urgent issues needing attention, not affecting current sales.
            Marketing:For requests related to delivering marketing materials.
            Lab: For service issues of machines not currently in the field.
          </Tooltip>
          {ticketTypes.map((value, index) => (
            <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2" key={`ticket_type`+index}>
              <label className="fs-5 mb-0 ms-2" >
                <input
                  id="ticketType"
                  className="form-check-input"
                  type="radio"
                  name="ticketType"
                  value={value.id}
                  checked={formValues.ticketType === value.id}
                  onChange={() => setFieldValue("ticketType", value.id)}
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
          <div className="text-danger text-align-top-center ms-2 mt-2">
            <ErrorMessage name="ticketType" />
          </div>
        </div>
      </div>
      <div className="row mt-9">
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="form-label required fw-bold fs-4">
            Machine or serial number
          </label>
            <Select
            ref={resetMachine}
            className={clsx("react-select", {
              "is-invalid":
              Boolean(formError.machineId) && formTouched.machineId,
            })}
            classNamePrefix="my-react-select"
            name="machineId"
            placeholder=""
            defaultValue={selectedMachine ? {
              label: `${selectedMachine && selectedMachine.name ? selectedMachine?.name : (selectedMachine?.location?.name  ?? "")} ${selectedMachine?.serialNumber}`,
              value: formValues.machineId
            } : {}}
            options={Array.from(machineDB).map((value: { id: string; name: string; location?: { name: string }; serialNumber: string }) => {
              return { 
              value: value.id,                
              label: `${value.name ? value?.name : (value?.location?.name  ?? "")} ${value?.serialNumber}`
              };
            })}
            onChange={(e: any) => {
              setFieldValue("machineId", e.value);
            }}
          />
          <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="machineId" />
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="ticketSource form-label required fw-bold fs-4">
            Ticket Source
          </label>
          <i className="ticketSource fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".ticketSource" className="tooltip-dashboard">
            This field indicates the method or source through which the service issue was identified.
          </Tooltip>
          <Select
            ref={resetSource}
            className={clsx("react-select", {
              "is-invalid":
                Boolean(formError.ticketSourceId) && formTouched.ticketSourceId,
            })}
            classNamePrefix="my-react-select"
            name="ticketSourceId"
            placeholder=""
            defaultValue={formValues.ticketSourceId ? {
              label: ticketSourceDB.find((value: any) => value.id === formValues.ticketSourceId)?.name,
              value: formValues.ticketSourceId
            } : {}}
            options={Array.from(ticketSourceDB).map((value: any) => {
              return { value: value.id, label: value.name };
            })}
            onChange={(e: any) => {
              setFieldValue("ticketSourceId", e.value);
            }}
          />
          <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="ticketSourceId" />
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="reportedAt form-label required fw-bold fs-4">
            Reported At
          </label>
          <i className="reportedAt fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".reportedAt" className="tooltip-dashboard">
            This field records the date when the issue was first identified
          </Tooltip>
          <Datetime
            dateFormat="YYYY-MM-DD"
            timeFormat="HH:mm"
            ref={resetReportedAt}
            closeOnSelect
            value={formValues.reportedAt}
            isValidDate={(current) => {
              let customDate = Moment().format("YYYY-MM-DD");
              return (
                current &&
                current <= Moment(customDate, "YYYY-MM-DD")
              );
            }}
            onChange={(e: any) => {
              const dt = e["_d"];
              setFieldValue("reportedAt", dt);
            }}
          />
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="assignTo form-label fw-bold fs-4"> Assign To</label>
          <i className="assignTo fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".assignTo" className="tooltip-dashboard">
            This field specifies the Smoodi team member assigned to resolve the reported issue and close the ticket.
          </Tooltip>
          <Select
            ref={resetAssignTo}
            className={clsx("react-select", {
              "is-invalid":
                Boolean(formError.assignToId) && formTouched.assignToId,
            })}
            classNamePrefix="my-react-select"
            name="assignToId"
            placeholder=""
            options={Array.from(assignToDB).map((value: any) => {
              return { value: value.id, label: value.firstName };
            })}
            defaultValue={formValues.assignToId ? {
              label: assignToDB.find((value: any) => value.id === formValues.assignToId)?.firstName,
              value: formValues.assignToId
            }: {}}
            onChange={(e: any) => {
              setFieldValue("assignToId", e.value);
            }}
          />
          <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="assignToId" />
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="priority form-label fw-bold required fs-4">Priority</label>
          <i className="priority fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".priority" className="tooltip-dashboard">
            Priority is assigned based on a combination of the client's importance to the company and the type of service issue
          </Tooltip>
          {Array.from(priorityDB).map((value: any, id) => (
            <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2" key={`priority`+id}>
              <label className="ms-2 fs-5" >
                <input
                  id="priority"
                  className="form-check-input"
                  type="radio"
                  name="priority"
                  value={value.id}
                  defaultChecked={value.id === (formValues.priority ?? "HIGH")}
                  onChange={() => setFieldValue("priority", value.id)}
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
        </div>
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="serviceType form-label required fw-bold fs-4">
            Service Type
          </label>
          <i className="serviceType fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".serviceType" className="tooltip-dashboard">
            Service issues that necessitate an in-person visit by a Smoodi representative or someone assigned by Smoodi are marked as 'on-site'.
          </Tooltip>
          {Array.from(serviceTypeDB).map((value: any, index) => (
            <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2" key={`service_type`+index}>
              <label className="form-check-label fs-5 ms-2" >
                <input
                  id="servicetype"
                  className="form-check-input"
                  type="radio"
                  name="serviceType"
                  value={value.id}
                  checked={formValues.serviceType === value.id}
                  onChange={() => setFieldValue("serviceType", value.id)}
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
          <div className="text-danger text-align-top-center ms-2 mt-1">
            <ErrorMessage name="serviceType" />
          </div>
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
          <label className="reportedBy form-label required fw-bold fs-4">
            Reported By
          </label>
          <i className="reportedBy fas fa-info-circle fs-4 mb-2 ps-1"></i>
          <Tooltip anchorSelect=".reportedBy" className="tooltip-dashboard">
            This field specifies the Smoodi team member assigned to resolve the reported issue and close the ticket.
          </Tooltip>
          {Array.from(reporterType).map((value: any, index) => (
            <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2" key={`reporter-type`+index}>
              <label className="form-check-label fs-5 ms-2" >
                <input
                  id="reporterType"
                  className="form-check-input"
                  type="radio"
                  name="reporterType"
                  value={value.id}
                  checked={formValues.reporterType === value.id}
                  onChange={() => setFieldValue("reporterType", value.id)}
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
          <div className="text-danger text-align-top-center ms-2 mt-1">
            <ErrorMessage name="reporterType" />
          </div>
        </div>
          
        <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
         { formValues.reporterType === 'INTERNAL' &&  <div>
          <label className="form-label required fw-bold fs-4"> Reported By</label>
          <Select
            ref={resetReportedById}
            className={clsx("react-select", {
              "is-invalid":
                Boolean(formError.reportedById) && formTouched.reportedById,
            })}
            classNamePrefix="my-react-select"
            name="reportedById"
            placeholder=""
            options={Array.from(reportedByDB).map((value: any) => {
              return { value: value.id, label: value.firstName };
            })}
            defaultValue={formValues.reportedById ? {
              label: reportedByDB.find((value: any) => value.id === formValues.reportedById)?.firstName,
              value: formValues.reportedById
            } : {}}
            onChange={(e: any) => {
              setFieldValue("reportedById", e.value);
            }}
          />
          <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="reportedById" />
          </div>
          </div> }
          { formValues.reporterType === 'EXTERNAL' &&  <div>
          <label className="form-label required fw-bold fs-4"> Reported By</label>
          <input
          placeholder='Name'
          type='text'
          autoComplete='off'
          value={formValues.reporterName}
          onChange={(value) => {
            setFieldValue("reporterName", value.target.value)
          }}
          className={clsx(
            'form-control',
            {
              'is-invalid': Boolean(formError.reporterName) && formTouched.reporterName,
            },
            {
              'is-valid': formTouched.reporterName && !Boolean(formError.reporterName),
            }
          )}
        />
        <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="reporterName" />
          </div>
           <input
           style={{marginTop: '10px'}}
          placeholder='Email'
          type='text'
          autoComplete='off'
          value={formValues.reporterEmail}
          onChange={(value) => setFieldValue("reporterEmail", value.target.value)}
          className={clsx(
            'form-control',
            {
              'is-invalid': Boolean(formError.reporterEmail) && formTouched.reporterEmail,
            },
            {
              'is-valid': formTouched.reporterEmail && !Boolean(formError.reporterEmail),
            }
          )}
        />
        <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="reporterEmail" />
          </div>
        <input
        style={{marginTop: '10px'}}
          placeholder='Phone'
          type='text'
          autoComplete='off'
          value={formValues.reporterPhone}
          onChange={(value) => setFieldValue("reporterPhone", value.target.value)}
          className={clsx(
            'form-control',
            {
              'is-invalid': Boolean(formError.reporterPhone) && formTouched.reporterPhone,
            },
            {
              'is-valid': formTouched.reporterPhone && !Boolean(formError.reporterPhone),
            }
          )}
        />
          <div className="text-danger text-align-top-center mt-2">
            <ErrorMessage name="reporterPhone" />
          </div>
          </div> }
        </div>
      </div></>}
    </>
  );
};

export { Step1 };
