/**
 * @generated SignedSource<<0554034f86a1054186a2ea2c5a982d81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CustomerAccountsParentComponentQuery$variables = {};
export type CustomerAccountsParentComponentQuery$data = {
  readonly getCustomerList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly getCustomerRoleList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly slug: string;
  }>;
  readonly getLocationList: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly uuid: string | null;
  }>;
};
export type CustomerAccountsParentComponentQuery = {
  response: CustomerAccountsParentComponentQuery$data;
  variables: CustomerAccountsParentComponentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerObjectType",
    "kind": "LinkedField",
    "name": "getCustomerList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocationList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerRoleObjectType",
    "kind": "LinkedField",
    "name": "getCustomerRoleList",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerAccountsParentComponentQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CustomerAccountsParentComponentQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d1e1b63c58a10517b67a79d23e11e891",
    "id": null,
    "metadata": {},
    "name": "CustomerAccountsParentComponentQuery",
    "operationKind": "query",
    "text": "query CustomerAccountsParentComponentQuery {\n  getCustomerList {\n    id\n    name\n  }\n  getLocationList {\n    id\n    uuid\n    name\n  }\n  getCustomerRoleList {\n    id\n    name\n    slug\n  }\n}\n"
  }
};
})();

(node as any).hash = "2847fbb2b110de73154ae28b49e83c6b";

export default node;
