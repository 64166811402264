const API_URL = process.env.REACT_APP_FETCH_API_URL;

const requestOptions: any = {
    method: 'GET',
    redirect: 'follow'
};
const requestPostOptions: any = {
    method: 'POST',
    redirect: 'follow'
};

export interface GetBlendersType {
  id: string;
  title: string;
  version_no: string;
}
export const getBlenders = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-blenders`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export interface GetBoardsType {
  id: string;
  title: string;
  version_no: string;
}
export const getBoards = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-boards`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export interface GetDiagnosisType {
    id: string;
    name: string;  
  }

export const getDiagnosis = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-diagnosis`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export const getMachineServices = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-machine-service`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export const getOperations = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-operations`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export const getMarketings = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-marketings`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export const getMarketingMaterials = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-marketing-materials`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}
export const getIssueReported = async () => {   
    const result = await fetch(`${API_URL}/api/tickets/get-issue-reported`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export interface GetFlavorsType {
    name: string;
    flavor: string;
}
export const getFlavors = async () => {   
    const result = await fetch(`${API_URL}/api/log-jobs/flavors`, requestOptions)
    const data = await result.json();
    return data.data ?? [];
}

export interface GetTubeShieldsType {
    id: string; 
    name: string;
}
export const getTubeShields = async () => {   
  const result = await fetch(`${API_URL}/api/log-jobs/tube-shields`, requestOptions)
  const data = await result.json();
  return data.data ?? [];
}

// Define the StatusOfSanitizer type
type StatusOfSanitizer = "LOW" | "MEDIUM" | "HIGH";


// Define the CustomerSatisfaction type
type CustomerSatisfaction = "EXCELLENT" | "GOOD" | "AVERAGE" | "POOR";

// Define the Cleanliness type
type Cleanliness = "CLEAN" | "MODERATE" | "DIRTY";

// Define the TrafficRate type
type TrafficRate = "HIGH" | "MEDIUM" | "LOW";

// Define the StaffEnthusiasm type
type StaffEnthusiasm = "HIGH" | "MEDIUM" | "LOW";

// Define the Status type
export type LogJobStatus = "PENDING" | "IN_PROGRESS" | "COMPLETED" | "CANCELLED";

// Define the MarketingMaterialsObjectType interface
interface MarketingMaterialsObjectType {
  id: string;
  name: string;
  quantity: number;
}

// Define the LogJobsObjectType interface
interface LogJobsObjectType {
  id: string;
  uuid?: string;
  jobType: string;
  locationId: string;
  dateAndTime: string;
  uploadImagesOrVideos?: Media[];
  machineSerialNumber?: string;
  machineName?: string;
  additionalComments: string;
  createdById: string;
  createdAt: Date;
  updatedById?: string;
  updatedAt?: Date;
  customerSatisfaction?: CustomerSatisfaction;
  cleanliness?: Cleanliness;
  trafficRate?: TrafficRate;
  staffEnthusiasm?: StaffEnthusiasm;
  status: LogJobStatus;
  closingDateAndTime?: string;
  travelingStartTime?: string;
  travelingEndTime?: string;
  workStartTime?: string;
  travelTime?: number;
  sanitizerStatus?: StatusOfSanitizer;
  marketingMaterials?: MarketingMaterialsObjectType[];
}

// Define the Ticket interface
interface Ticket {
  id: number;
  hashId: string;
  uuid: string;
  status: string;
  ticketType: string;
  locationName: string;
  address: string;
  issuesReported: any[];
  issueReportedOtherInput: string;
  machineServices: any[];
  machineServiceOtherInput: string;
  operations: any[];
  operationOtherInput: string;
  travelingStartTime: Date | null;
  travelingEndTime: Date | null;
  workStartTime: Date | null;
  workEndTime: Date | null;
}

// Define the Location interface
interface Location {
  name: string;
  id: number;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  latitude: number;
  longitude: number;
  hasWifiExtender: boolean;
  hasSmoodiRouter: boolean;
  hasPressurePump: boolean;
  hasPressureTank: boolean;
}

// Define the Machine interface
interface Machine {
  id: number;
  name: string;
  serialNumber: string;
  isPolyFuses: boolean;
  blenderId: string;
  boardId: string;
  cupHolderId: string;
  tubeShieldId: string;
  machineStatusId: string;
  softwareVersion: string;
  macAddress: string;
  hardwareVersion: string;
}

// Define the FlavorPrice interface
interface FlavorPrice {
  price: number;
  flavor: string;
}

// Define the GetLogJobByIdResponse interface
export interface GetLogJobByIdResponseType {
    job: LogJobsObjectType;
    tickets: Ticket[];
    location: Location;
    machines: Machine[];
    flavorPrices: FlavorPrice[];
  };
export const getLogJobById = async (id: string) => {    
    const result = await fetch(`${API_URL}/api/log-jobs/${id}`, requestOptions)
    const data = await result.json();
    return data.data ?? null;
}

// Define the Media type
interface Media {
  name: string;
  content: string;
}

// Define the UpdateSiteVisitationTicketInput type
interface UpdateSiteVisitationTicketInput {
  id: string;
  diagnosis: string[];
  diagnosisOther?: string;
  machineServicesDone: string[];
  machineServicesDoneOther?: string;
  newBlenderId: string;
  newBoardId: string;
  newMachineId: string;
  oldMachineShiftToLocationId?: string;
  replaceFilter?: boolean;
  replaceSanitizer?: boolean;
  replaceNewTubeDoors1mmShorter?: boolean;
}

// Define the UpdateSiteVisitationInput type
interface UpdateSiteVisitationInput {
  tickets?: UpdateSiteVisitationTicketInput[];
  operations: string[];
  machines: {
    id: string;
    blenderId: string;
    tubeDoorId: string;
    hasPolyfuses: boolean;
    made4Smoothies: boolean;
    cleanlinessCheck: boolean;
    getStatusPassed: boolean;
    flowmeterActivated: boolean;
    waterLevelCalibratedWithScale: boolean;
  }[];
  marketingMaterials: string[];
  sanitizerLevel: StatusOfSanitizer;
  flavorPrices: {
    flavor: string;
    price: number;
  }[];
  smoothieSetupMedia: Media[];
  marketingMaterialPresent: Media[];
}
export const updateJob = async (id: string, jobData: UpdateSiteVisitationInput ) => {    
    const result = await fetch(`${API_URL}/api/log-jobs/${id}`, {...requestPostOptions, body: JSON.stringify(jobData)})
    const data = await result.json();
    return data;
}
export const updateJobStatus = async (id: string, jobType: string, date : string ) => {    
    const result = await fetch(`${API_URL}/api/log-jobs/${id}/updateStatus`, {...requestPostOptions, body: JSON.stringify({jobType, date})})
    const data = await result.json();
    return data;
}


export interface GetAvailableMachinesForReplaceType {
    id: string;
    location_name: string;
    serial_number: string;
    machine_name: string;
    location_id: string;
    site_status: boolean;
}
export const getAvailableMachinesForReplace = async () => {   
const result = await fetch(`${API_URL}/api/tickets/get-replace-machines`, requestOptions)
const data = await result.json();
  return data.data ?? [];;
}

export interface GetLocationsType {
    name: string;
    id: string;
}
export const getLocations = async () => {
  const result = await fetch(`${API_URL}/api/tickets/get-locations`, requestOptions)
  const data = await result.json();
  return data.data ?? [];  
}