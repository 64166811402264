import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateSubTaskInput {
    parentId: string,
    assignToId: string,
    priority: string,
    issueTypeId: string,
    comment: string,
    // reportedAt?: string;
    machineServices?: string[];
    marketingOrOthers?: string[];
    operations?: string[];
    machineServiceOtherInput?: string;
    operationOtherInput?: string;
    marketingOtherInput?: string;
}

export async function createSubTask(input: CreateSubTaskInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createSubTask (
          $input: CreateSubTaskInput!
        ){
          createSubTask(
            createSubTaskInput: $input
            )
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getIssueType() {
    const data = await axios({
      url: API_URL,
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        query: `
                query {
                    getIssueTypes {
                      id
                      name
                    }
                  }
                `,
        variables: {},
      },
    });
    return data?.data?.data?.getIssueTypes;
  }