import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateBoardInput {
  id?: string;
  title?: string;
  description?: string;
  versionNo?: string;
  isActive?: boolean;
}
export async function createBoard(input: CreateBoardInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createBoard (
            $input: CreateBoardInput!
          ){
            createBoard(
              createBoardInput: $input
              )
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getBoards(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getBoards(
                  $where: BoardsResolver_GetBoards_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getBoards (where:$where, paginate: $paginate) {
                      data {
                          id
                          versionNo
                          title
                          mediaUrl
                          launchDateAndTime
                          createdAt
                          description
                          isActive
                        }
                        totalPages
                        total
                        perPage
                        page
                    
                  }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getBoards;
}
export async function getBoardsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                  getBoardsList{
                    id
                    mediaUrl
                    description
                    title
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getBoardsList;
}

export async function updateBoard(input: CreateBoardInput, id?: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateBoard (
              $id: String!
              $input: CreateBoardInput!
            ){
              updateBoard(
                createBoardInput: $input
                id: $id
                )
            }
            `,
      variables: {
        input,
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function updateBoardStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateBoardStatus(
          updateBoardStatusInput: { id: $id, isActive: $isActive }
              )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
