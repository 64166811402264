import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import {
  Location,
  LocationType,
  Machine,
  MachineStatus,
  MarketSegment,
  Region,
} from "../Machines/MachineParentComponent";
import BlendSummary from "./BlendSummary";
import { Customer, Distributor } from "../Customers/CustomerParentComponent";
import Blendlogs from "./BlendLogs";
import BlendLogsDownload from "./BlendLogsDownload";

interface BlendParentComponentQueryResponse {
  getCustomerList: Customer[];
  getLocationList: Location[];
  getRegions: Region[];
  getMachineStatus: MachineStatus[];
  getLocationTypes: LocationType[];
  getMarketSegment: MarketSegment[];
  getDistributors: Distributor[];
  getMachineList: Machine[];
}

export interface BlendSummaryProps {
  customers: Customer[];
  locations: Location[];
  distributors: Distributor[];
  locationTypes: LocationType[];
  machineStatus: MachineStatus[];
  machines: Machine[];
  marketSegments: MarketSegment[];
  regions: Region[];
}

type BlendParentComponentQuery = OperationType & {
  response: BlendParentComponentQueryResponse;
  variables: Record<string, never>;
};

class BlendParentComponent extends React.Component <any, any>  {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: {} | Readonly<{}>) {
    super(props); 
  }
  render() {
    return (
      <QueryRenderer<BlendParentComponentQuery>
        environment={environment}
        query={graphql`
          query BlendParentComponentQuery {
            getRegions {
              id
              name
            }
            getLocationList {
              id
              uuid
              name
            }
            getMachineStatus {
              id
              name
              description
            }
            getLocationTypes {
              id
              name
              description
              isDefault
            }
            getCustomerList {
              id
              name
            }
            getMarketSegment {
              id
              name
            }
            getDistributors {
              id
              name
            }
            getMachineList {
              id
              uuid
              serialNumber
              name
              locationId
              location {
                name
              }
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return <div>Error loading blend summary data: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const {
            getCustomerList,
            getLocationList,
            getDistributors,
            getLocationTypes,
            getMachineStatus,
            getMachineList,
            getMarketSegment,
            getRegions,
          } = props;

          const {componentName} = this.props;

          if(componentName === "blendSummary") {
            return (
              <BlendSummary
                customers={getCustomerList}
                locations={getLocationList}
                distributors={getDistributors}
                locationTypes={getLocationTypes}
                machineStatus={getMachineStatus}
                machines={getMachineList}
                marketSegments={getMarketSegment}
                regions={getRegions}
              />
            );
          }

          if(componentName === "blendLogs") {
            return (
              <Blendlogs
                customers={getCustomerList}
                locations={getLocationList}
                machines={getMachineList}
              />
            );
          }

          if(componentName === "blendLogDownload") {
            return (
              <BlendLogsDownload
                customers={getCustomerList}
                locations={getLocationList}
                machines={getMachineList}
              />
            );
          }
          
        }}
      />
    );
  }
}

export default BlendParentComponent;
