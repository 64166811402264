import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import CustomersList from "./CustomersList";

export interface Customer {
  id: string;
  name: string;
}

export interface Distributor {
  id: string;
  name: string;
}

interface QueryResponse extends OperationType {
  response: {
    getCustomerList: Customer[];
    getDistributors: Distributor[];
  };
  variables: Record<string, never>;
}

class CustomerParentComponent extends React.Component {
  render() {
    return (
      <QueryRenderer<QueryResponse>
        environment={environment}
        query={graphql`
          query CustomerParentComponentQuery {
            getCustomerList {
              id
              name
            }
            getDistributors {
              id
              name
            }
          }
        `}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            return (
              <div className="error-message">
                Error loading data: {error.message}
              </div>
            );
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }

          const { getCustomerList, getDistributors } = props;

          return (
            <CustomersList
              customers={getCustomerList}
              distributors={getDistributors}
            />
          );
        }}
      />
    );
  }
}

export default CustomerParentComponent;