/**
 * @generated SignedSource<<14ee61932430ce85efe9b3ba9c5b4653>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UpdatePriceLocation_getLocationQuery$variables = {
  id: string;
};
export type UpdatePriceLocation_getLocationQuery$data = {
  readonly getLocation: {
    readonly flavorPrices: ReadonlyArray<{
      readonly flavor: string;
      readonly price: number;
    }> | null;
    readonly id: string;
    readonly uuid: string | null;
  };
};
export type UpdatePriceLocation_getLocationQuery = {
  response: UpdatePriceLocation_getLocationQuery$data;
  variables: UpdatePriceLocation_getLocationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "LocationObjectType",
    "kind": "LinkedField",
    "name": "getLocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "LocationFlavorPricesObjectType",
        "kind": "LinkedField",
        "name": "flavorPrices",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flavor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePriceLocation_getLocationQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePriceLocation_getLocationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "78613951ae21f815245d0a73a1db26a2",
    "id": null,
    "metadata": {},
    "name": "UpdatePriceLocation_getLocationQuery",
    "operationKind": "query",
    "text": "query UpdatePriceLocation_getLocationQuery(\n  $id: String!\n) {\n  getLocation(id: $id) {\n    id\n    uuid\n    flavorPrices {\n      flavor\n      price\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41d8ec01002e8643a7820d0811dd97e8";

export default node;
