import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateIssueReportedInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function createIssueReported(input: CreateIssueReportedInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createIssueReported (
            $input: CreateIssueReportedInput!
          ){
            createIssueReported(
                createIssueReportedInput: $input
              )
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getIssueReportedList(where: {} = {}) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getAllIssueReported(
                  $where:IssueReportedResolver_GetAllIssueReported_FilterInputType
                ){
               
                  getAllIssueReported (where: $where){
                    id
                    name
                    isDefault
                    priority
                  }
                  }
                `,
      variables: { where },
    },
  });
  return data?.data?.data?.getAllIssueReported;
}

interface UpdateIssueReportedInput {
  name: string;
  isDefault: boolean;
  priority: number;
}
export async function updateIssueReported(input: UpdateIssueReportedInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateIssueReported (
              $input: UpdateIssueReportedInput!
            ){
                updateIssueReported(
                    updateIssueReportedInput: $input
                )
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
