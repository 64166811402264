/**
 * @generated SignedSource<<fe4743498c4af3384d3f0bee186b1583>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EditMachine_getmachineQuery$variables = {
  id: string;
};
export type EditMachine_getmachineQuery$data = {
  readonly getmachine: {
    readonly blender: {
      readonly title: string;
    } | null;
    readonly blenderId: string | null;
    readonly board: {
      readonly title: string;
    } | null;
    readonly boardId: string | null;
    readonly cupHolder: {
      readonly title: string;
    } | null;
    readonly cupHolderId: string | null;
    readonly deployingPerson: {
      readonly firstName: string;
    } | null;
    readonly deployingPersonId: string | null;
    readonly deploymentDate: string;
    readonly hardwareVersion: string | null;
    readonly id: string;
    readonly isActive: boolean;
    readonly isPilot: boolean;
    readonly isPolyfuses: boolean | null;
    readonly locationId: string | null;
    readonly macAddress: string | null;
    readonly machineStatus: {
      readonly name: string;
    } | null;
    readonly machineStatusId: string | null;
    readonly name: string | null;
    readonly noteRelatedToMechanicalHardwareVersion: string | null;
    readonly remark: string | null;
    readonly serialNumber: string;
    readonly trialEndDate: string | null;
    readonly tubeShield: {
      readonly name: string;
    } | null;
    readonly tubeShieldId: string | null;
    readonly uuid: string | null;
  };
};
export type EditMachine_getmachineQuery = {
  response: EditMachine_getmachineQuery$data;
  variables: EditMachine_getmachineQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "MachineObjectType",
    "kind": "LinkedField",
    "name": "getmachine",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uuid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "serialNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "macAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tubeShieldId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isActive",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPilot",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trialEndDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "remark",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "noteRelatedToMechanicalHardwareVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hardwareVersion",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isPolyfuses",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "machineStatusId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MachineStatusObjectType",
        "kind": "LinkedField",
        "name": "machineStatus",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserObjectType",
        "kind": "LinkedField",
        "name": "deployingPerson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BoardObjectType",
        "kind": "LinkedField",
        "name": "board",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BlenderObjectType",
        "kind": "LinkedField",
        "name": "blender",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CupHolderObjectType",
        "kind": "LinkedField",
        "name": "cupHolder",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TubeShieldObjectType",
        "kind": "LinkedField",
        "name": "tubeShield",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cupHolderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deploymentDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deployingPersonId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "blenderId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "boardId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditMachine_getmachineQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditMachine_getmachineQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "fdaa4470a394429fca4ca7ac7d09c627",
    "id": null,
    "metadata": {},
    "name": "EditMachine_getmachineQuery",
    "operationKind": "query",
    "text": "query EditMachine_getmachineQuery(\n  $id: String!\n) {\n  getmachine(id: $id) {\n    id\n    name\n    uuid\n    serialNumber\n    macAddress\n    tubeShieldId\n    isActive\n    isPilot\n    trialEndDate\n    remark\n    noteRelatedToMechanicalHardwareVersion\n    hardwareVersion\n    isPolyfuses\n    machineStatusId\n    machineStatus {\n      name\n    }\n    deployingPerson {\n      firstName\n    }\n    board {\n      title\n    }\n    blender {\n      title\n    }\n    cupHolder {\n      title\n    }\n    tubeShield {\n      name\n    }\n    locationId\n    cupHolderId\n    deploymentDate\n    deployingPersonId\n    blenderId\n    boardId\n  }\n}\n"
  }
};
})();

(node as any).hash = "a69c43dcdd5d275a33e895139c92c9af";

export default node;
