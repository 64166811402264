import React,{useCallback ,Suspense} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { KTSVG } from "../../../../_metronic/helpers";
import clsx from "clsx";
import  { Toaster } from "react-hot-toast";
import CustomerOverview from "./Tabs/CustomerOverview";
import LocationOverview from "./Tabs/LocationOverview";
import MachineOverview from "./Tabs/MachineOverview";
import JobsOverview from "./Tabs/JobsOverview";
import TicketsOverview from "./Tabs/TicketsOverview";
import { graphql } from 'babel-plugin-relay/macro';
import { useLazyLoadQuery } from 'react-relay';
import { CustomerHistory_getCustomerByIdQuery } from './__generated__/CustomerHistory_getCustomerByIdQuery.graphql';
import {EditCustomer} from '../EditCustomer'
import { getHistory } from "../../../models/_machine";
import CustomerRevision from "./Tabs/CustomerRevision";

export const getCustomerListById = graphql`
query CustomerHistory_getCustomerByIdQuery($id: String!) {
     getCustomerById(id: $id) {
         id
         uuid
         name
         distributorId
         customerCompanyName
         customerContactName
         customerContactPhoneNumber
         customerContactEmail
         customerContactNameRole
         accountType
         customerSecondContact
         customerSecondContactName
         customerSecondContactPhoneNumber
         customerSecondContactEmail
         customerSecondContactNameRole
         typeOfContactPhoneNumber
         typeOfSecondContactPhoneNumber
         createdAt
         isActive
         isVip
         distributor {
           id
           name
         }
         createdBy {
           id
           firstName
         }
         locations {
           id
           uuid
           name
           address
           regionId
           contactName
           contactPhone
           targetLaunchDate
           launchDate
           noOfBlenders
           noOfFreezers
           logJobs {
             id
             uuid
             jobType
             locationId
             dateAndTime
             machineSerialNumber
             machineName
             additionalComments
             createdBy{
               id
               firstName
             }
             createdAt
             customerSatisfaction
             cleanliness
             trafficRate
             status
             closingDateAndTime
           }
           machines{
             id
             uuid
             serialNumber
             name
             deploymentDate
             connectionStatus
             softwareVersion
             hardwareVersion
             createdAt
             remark
             retiredAt
             isRetired
             retiredBy {
               id
               firstName
             }
             createdBy {
               id
               firstName
             }
             deployingPerson {
               id
               firstName
             }
             machineStatus {
               id
               name
             }
             tickets{
               id
               uuid
               machine{
                 id
                 name
               }
               ticketType
               serviceType
               comment
               priority
               ticketSource{
                 id
                 name
               }
               createdBy{
                 id
                 firstName
               }
               reportedAt
               closingDateAndTime
               assignTo{
                 id
                 firstName
               }
               status
             }
           }
           region{
             id
             name
           }
           marketSegment{
             id
             name
           }
           locationType{
             id
             name
           }
           deploymentManager{
             id
             firstName
           }
           samplingRecommendedTime
           samplingRecommendedDays
           createdAt
           isActive
           city
           state
           latitude
           longitude
           machineCount
         }
       }
}`;

export const CustomerHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [machines, setMachines] = React.useState<any>({});
  const [customer, setCustomer] = React.useState<any>({});
  const [locations, setLocations] = React.useState<any>({});
  const [logJobs, setLogJobs] = React.useState<any>([]);
  const [tickets, setTickets] = React.useState<any>([]);
  const [tab, setTab] = React.useState("Overview");
  const [customerEditModal, setCustomerEditModal] = React.useState(false);
  const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState<any>(null);
  const [customerHistory, setCustomerHistory] = React.useState<any>({});
  const [page, setPage] = React.useState(1);
  const [perPage, setPerPage] = React.useState(50);

  const splittedRoute = location.pathname.split("/").reverse()[0];
  if (!splittedRoute) {
    navigate("/customers/list");
  }

  const customerRecord =
  useLazyLoadQuery<CustomerHistory_getCustomerByIdQuery>(
      getCustomerListById,
    {
      id: splittedRoute ?? "",
    },refreshedQueryOptions ?? {}
  );
  const result: any = customerRecord?.getCustomerById ?? [];
  const currentCustomerId = result.id
  
  const fetchData = async () => {
    if (result == null) {
      navigate("/customers/list");
    } else {
      setCustomer(result);
      const totalLocations = result.locations ?? [];
      setLocations(totalLocations);
      let allLogJobs: any = [];
      let allTickets: any = [];
      let totalMachines: any = [];
      for (const location of totalLocations) {
        const machines = location.machines ?? [];
        totalMachines = [...totalMachines, ...machines];
        const logJobRecord = location.logJobs ?? [];
        allLogJobs = [...allLogJobs, ...logJobRecord];
      }
      for (const machine of totalMachines) {
        const ticketsRecord = machine.tickets ?? [];
        allTickets = [...allTickets, ...ticketsRecord];
      }
      setMachines(totalMachines);
      setLogJobs(allLogJobs);
      setTickets(allTickets);
    }
  };

  const refresh = useCallback(() => {
    setRefreshedQueryOptions((prev: any) => ({
      fetchKey: (prev?.fetchKey ?? 0) + 1,
      fetchPolicy: "store-and-network",
    }));
  }, []);
  
 
  
  const handleCustomerClose= () => {
    setCustomerEditModal(false)
    refresh()
  }

  React.useEffect(() => {
    fetchData();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const fetchCustomerHistory = async () => {
    const customerHistoryPayload = {
      inputs:{id: currentCustomerId,type: "Customer"},
      pagination:{page,per_page:perPage}
    }
    const customerHistory = await getHistory(customerHistoryPayload.inputs,customerHistoryPayload.pagination)
    setCustomerHistory(customerHistory);
  }

  React.useEffect(() => {
    fetchCustomerHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page,perPage,result])
  
  return (
  <Suspense>
    <div>
    <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Customer History - {customer.uuid}
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item text-muted">
                <span className="bullet bg-gray-400 w-5px h-2px" />
                <a
                  href="/customers/list"
                  className="text-muted text-hover-primary ps-2 fs-5"
                >
                  Customers List
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item fs-5 text-dark">
                Customer History
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mx-xl-4 mt-9">
        <div className="card">
          <div className="card-header cursor-pointer border-bottom-0 py-4">
            <div className="card-title m-0">
              <h3 className="fw-semibold m-0">Customer Details - {result.uuid}</h3>
            </div>
            <button data-bs-toggle="tooltip" title="Click here to edit customer"
              onClick={() => {setCustomerEditModal(true)}}
              className="btn btn-secondary btn px-5 py-3 m-1">
              <i className="fa fa-pencil fs-4" ></i>
              </button>
             </div>
          </div>
          </div>
        
          {customerEditModal && (
             <EditCustomer  customerEditModal={customerEditModal}  
             handleCustomerClose={handleCustomerClose}
             currentCustomerId={currentCustomerId}
            
             />
          )}
         
       
      <div className="card mt-9 mx-xl-4">
        <div className="card-body pt-9 pb-0">
          <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
            <div className="me-7 mb-4">
              <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                <img
                  src="/media/icons/duotune/communication/com013.svg"
                  alt="Smoodi"
                  className="bg-light"
                />
              </div>
            </div>

            <div className="flex-grow-1 ms-xl-5 ms-lg-3">
              <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                      {customer.isVip === true ? (
                        <i className="fa fa-crown text-warning me-1 fs-2"></i>
                      ) : (
                        ""
                      )}
                      {customer.name}
                    </div>
                    <div>
                      <KTSVG
                        path="/media/icons/duotune/general/gen026.svg"
                        className="svg-icon-1 svg-icon-primary"
                      />
                    </div>
                  </div>

                  <div className="fw-bold fs-6 mb-4 ms-2 pe-2 mt-2">
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 text-hover-primary mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-user me-3 fs-6"></i>
                      Contact Name :
                      <div className="ms-3 text-gray-800">
                        {customer.customerContactName}
                      </div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 text-hover-primary mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-building me-3 fs-6"></i>
                      Company Name :
                      <div className="ms-3 text-gray-800">
                        {customer.customerCompanyName}
                      </div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 text-hover-primary mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-envelope me-3 fs-6"></i>
                      Contact Email :
                      <div className="ms-3 text-gray-800">
                        {customer.customerContactEmail}
                      </div>
                    </div>
                    <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 text-hover-primary mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-phone me-3 fs-6"></i>
                      Contact Phone :
                      <div className="ms-3 text-gray-800">
                        {customer.customerContactPhoneNumber}
                      </div>
                      <span className="badge badge-success ms-3">Verified</span>
                    </div>
                    {customer?.updated_by ? <div className="d-lg-flex d-md-flex d-block align-items-center text-gray-500 mb-3 mt-3 mt-lg-0 mt-md-0">
                      <i className="fa fa-user me-3 fs-6"></i>
                      Last Updated By :
                      <div className="ms-3 text-gray-800">
                        {customer.updated_by.firstName}
                      </div>
                    </div> : ''}
                  </div>
                </div>
                <div className="my-4">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {locations.length}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Locations
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {locations.length > 0
                              ? machines.length
                              : locations.length}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Machines
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-xl-4 mt-3">
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {locations.length > 0
                              ? logJobs.length
                              : locations.length}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">Jobs</div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="border border-hover-primary border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center">
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr066.svg"
                            className="svg-icon-3 svg-icon-success me-2"
                          />
                          <div className="fs-2 fw-bolder">
                            {tickets.length ?? 0}
                          </div>
                        </div>

                        <div className="fw-bold fs-6 text-gray-400">
                          Tickets
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            <ul
              className="nav nav-stretch nav-line-tabs mt-5 border-transparent flex-nowrap fs-5 fw-bolder"
              role="tablist"
            >
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Overview",
                  })}
                  onClick={() => setTab("Overview")}
                  role="tab"
                >
                  Overview
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Location",
                  })}
                  onClick={() => setTab("Location")}
                  role="tab"
                >
                  Locations
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Machine",
                  })}
                  onClick={() => setTab("Machine")}
                  role="tab"
                >
                  Machines
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Jobs",
                  })}
                  onClick={() => setTab("Jobs")}
                  role="tab"
                >
                  Jobs
                </div>
              </li>
              <li className="nav-item me-4">
                <div
                  className={clsx(`nav-link cursor-pointer pb-5`, {
                    active: tab === "Tickets",
                  })}
                  onClick={() => setTab("Tickets")}
                  role="tab"
                >
                  Tickets
                </div>
              </li>
              <li className="nav-item me-4">
                  <div
                    className={clsx(`nav-link cursor-pointer pb-5`, {
                      active: tab === "Revisions",
                    })}
                    onClick={() => setTab("Revisions")}
                    role="tab"
                  >
                    Revisions
                  </div>
                </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="tab-content pt-3">
        <div className={clsx("tab-pane", { active: tab === "Overview" })}>
          <CustomerOverview customer={customer} />
        </div>

        <div className={clsx("tab-pane", { active: tab === "Location" })}>
          <LocationOverview locations={locations} />
        </div>

        <div className={clsx("tab-pane", { active: tab === "Machine" })}>
          <MachineOverview machines={machines} />
        </div>

        <div className={clsx("tab-pane", { active: tab === "Jobs" })}>
          <JobsOverview logJobs={logJobs} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Tickets" })}>
          <TicketsOverview tickets={tickets} />
        </div>
        <div className={clsx("tab-pane", { active: tab === "Revisions" })}>
          <CustomerRevision customerHistory={customerHistory} page = {page} setPage = {setPage} perPage = {perPage}/>
        </div>
      </div>
    </div>
    <Toaster
     position="bottom-left"
     reverseOrder={false}
     gutter={8}
     containerClassName=""
     containerStyle={{}}
     toastOptions={{
       className: "",
       duration: 3000,
     }}
   />
    </Suspense>
  );
};
