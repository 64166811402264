import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateRegionInput {
  name: string;
}
export async function createRegion(input: CreateRegionInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createRegion (
            $input:CreateRegionInput!
          ){
            createRegion(
                createRegionInput:$input
              ) 
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getRegionsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                    getRegions{
                    id
                    name
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getRegions;
}

interface UpdateRegion {
  name: string;
}
export async function updateRegion(input: UpdateRegion) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateRegion (
              $input: UpdateRegion!
            ){
                updateRegion(
                    updateRegionInput: $input
                )
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}