import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik, FieldArray } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import clsx from "clsx";
import { createLogJob } from "../../models/_logjob";
import Moment from "moment";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getMarketingMaterialsForFilter } from "../../models/_marketingMaterial";
import $ from "jquery";
import { getLocationsForFilter } from "../../models/_location";
import { Tooltip } from "react-tooltip";
const validationSchema = yup.object({
  jobType: yup.string().required("JobType is required"),
  machineName: yup.string().when("jobType", {
    is: "INSTALLATION",
    then: yup.string().required("Machine name is required"),
  }),
  machineSerialNumber: yup.string().when("jobType", {
    is: "INSTALLATION",
    then: yup.string().required("Serial Number is required"),
  }),
  location: yup.string().required("Location is required"),
  status: yup.string().required("Status is required"),
  dateAndTime: yup.date().required("Start time is required"),
  travelTime: yup.number().when("status", {
    is: "COMPLETED",
    then: yup.number().required("Travel time is required"),
  }),
  closingDateAndTime: yup.string().when("status", {
    is: "COMPLETED",
    then: yup.string().required("Closing time is required"),
  }),
});

const CreateLogJob = () => {
  const resetLocation = React.useRef<any>(null);
  const resetDate = React.useRef<any>(null);
  const resetClosingDate = React.useRef<any>(null);

  const [loading, setLoading] = useState(false);
  const [resultImages, setResultImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [locationDB, setLocationDB] = useState([]);
  const [materialDB, setMaterialDB] = useState([]);

  const [statusDB] = useState([
    {
      id: "PENDING",
      name: "Pending",
    },
    {
      id: "ON_HOLD",
      name: "On-hold",
    },
    {
      id: "IN_PROCESS",
      name: "In-Process",
    },
    {
      id: "COMPLETED",
      name: "Completed",
    },
  ]);
  const [jobTypeDB] = useState([
    {
      id: "SAMPLE_EVENT",
      name: "Sample event",
    },
    {
      id: "SITE_VISIT",
      name: "Site visit",
    },
    {
      id: "INSTALLATION_FOLLOW_UP",
      name: "Installation Follow-up",
    },
    {
      id: "INSTALLATION",
      name: "Installation",
    },
  ]);
  const [cleanlinessDB] = useState([
    {
      id: "GREAT",
      name: "Great",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "BAD",
      name: "Bad",
    },
  ]);
  const [trafficRateDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [enthusiasmDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [satisfactionDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);
  const [sanitizerDB] = useState([
    {
      id: "HIGH",
      name: "High",
    },
    {
      id: "MEDIUM",
      name: "Medium",
    },
    {
      id: "LOW",
      name: "Low",
    },
  ]);

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        const baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const onSelectFile = (event: any) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray: any = Array.from(selectedFiles);
    for (let i = 0; i < selectedFilesArray.length; i++) {
      const name = selectedFilesArray[i].name;
      const type = selectedFilesArray[i].type;
      getBase64(selectedFilesArray[i])
        .then((result) => {
          const imagesArray: any = [
            { name: name, type: type, content: result },
          ];
          const imageArrays: any = [{ name: name, content: result }];
          setResultImages((previousImages) =>
            previousImages.concat(imageArrays)
          );
          setSelectedImages((previousImages) =>
            previousImages.concat(imagesArray)
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    event.target.value = "";
  };

  function deleteHandler(image: any) {
    setSelectedImages(
      selectedImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
    setResultImages(
      resultImages.filter((e: any) => e.content !== image.content)
    );
    URL.revokeObjectURL(image.content);
  }

  const fetchdata = async () => {
    try {
      const locationRecord = await getLocationsForFilter();
      const locationResults: any = locationRecord?.data ? locationRecord?.data : [];

      locationResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const materialRecord = await getMarketingMaterialsForFilter();
      const materialResult: any = materialRecord ? materialRecord : [];

      setMaterialDB(materialResult);
      setLocationDB(locationResults);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchdata();
  }, []);

  return (
    <>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Job
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Job </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            jobType: "",
            location: "",
            trafficRate: "",
            cleanliness: "",
            dateAndTime: "",
            status: "",
            closingDateAndTime: "",
            travelTime: "",
            uploadImagesOrVideos: [],
            customerSatisfaction: "",
            staffEnthusiasm: "",
            sanitizerStatus: "",
            machineSerialNumber: "",
            machineName: "",
            additionalComments: "",
            marketingMaterial: [],
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            let hasErrors = false;
            if (hasErrors) {
              return false;
            }
            let $inputData: any = {
              jobType: values.jobType,
              locationId: values.location,
              status: values.status,
              dateAndTime: Moment(values.dateAndTime)
                .utc()
                .format("YYYY-MM-DD HH:mm:ss"),
              machineSerialNumber: values.machineSerialNumber,
              machineName: values.machineName,
              additionalComments: values.additionalComments,
              marketingMaterials: values.marketingMaterial,
            };
            if (values.customerSatisfaction) {
              $inputData["customerSatisfaction"] = values.customerSatisfaction;
            }
            if (values.cleanliness) {
              $inputData["cleanliness"] = values.cleanliness;
            }
            if (values.trafficRate) {
              $inputData["trafficRate"] = values.trafficRate;
            }
            if (values.staffEnthusiasm) {
              $inputData["staffEnthusiasm"] = values.staffEnthusiasm;
            }
            if (values.sanitizerStatus) {
              $inputData["sanitizerStatus"] = values.sanitizerStatus;
            }
            if (values.closingDateAndTime) {
              $inputData["closingDateAndTime"] = Moment(
                values.closingDateAndTime
              )
                .utc()
                .format("YYYY-MM-DD HH:mm:ss");
            }
            if (values.travelTime) {
              $inputData["travelTime"] = values.travelTime;
            }
            if (resultImages && resultImages.length > 0) {
              $inputData["uploadImagesOrVideos"] = resultImages;
            }
            setLoading(true);
            const result = await createLogJob($inputData);
            setLoading(false);
            if (result?.data?.data?.createLogJob) {
              toast.success("Job created successfully");
              resetLocation.current.setValue("");
              resetDate.current.state.inputValue = "";
              if (values.closingDateAndTime) {
                resetClosingDate.current.state.inputValue = "";
              }
              resetForm();
              $("input[type=checkbox]").prop("checked", function () {
                $(this).prop("checked", false);
              });
              setSelectedImages([]);
            } else {
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-2">
                    <label className="jobTypes form-label fs-4 required fw-bold ps-1">
                      Job Type
                    </label>
                    <i className="jobTypes fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".jobTypes" className="tooltip-dashboard">
                      This field records visits to locations for any proactive measure take by smoodi, unrelated to resolving an existing customer issue
                    </Tooltip>

                    {Array.from(jobTypeDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={id}
                        >
                          <input
                            id="jobType"
                            className="form-check-input"
                            type="radio"
                            name="jobType"
                            value={value.id}
                            checked={values.jobType === value.id}
                            onChange={() => setFieldValue("jobType", value.id)}
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="jobType" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-2">
                    <label className="status form-label fs-4 required fw-bold ps-1">
                      Status
                    </label>
                    {/* <div className="status form-label fs-4 mb-3 required fw-bold">
                      Status
                    </div> */}
                    <i className="status fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".status" style={{ width: "20%" }}>
                      Pending: Indicates a job that has been created but not yet acknowledged.
                      On-Hold: The job is recognized but does not require immediate resolution.
                      In-Process: Shows the job has been received and is being handled by the assigned person.
                      Archived:  Refers to tickets that have been deleted from active status.
                    </Tooltip>
                    {Array.from(statusDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2">
                        <label className="ms-2 fs-5" key={id}>
                          <input
                            id="status"
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value={value.id}
                            checked={values.status === value.id}
                            onChange={(event: any) => {
                              setFieldValue("status", event.target.value);
                            }}
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="status" />
                    </div>
                  </div>
                  {values.jobType === "INSTALLATION" && (
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label fs-4 required fw-bold">
                          Serial Number
                        </label>
                        <input
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.machineSerialNumber) &&
                              touched.machineSerialNumber,
                          })}
                          name="machineSerialNumber"
                          id="machineSerialNumber"
                          value={values.machineSerialNumber}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="machineSerialNumber" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label fs-4 required fw-bold">
                          Machine Name
                        </label>
                        <input
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.machineName) &&
                              touched.machineName,
                          })}
                          name="machineName"
                          id="machineName"
                          value={values.machineName}
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="machineName" />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="location form-label fs-4 required fw-bold">
                      Location
                    </label>   <i className="location fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".location" className="tooltip-dashboard">
                      Indicate the location where the job is being carried out
                    </Tooltip>
                    <Select
                      ref={resetLocation}
                      className={clsx("react-select", {
                        "is-invalid":
                          Boolean(errors.location) && touched.location,
                      })}
                      classNamePrefix="my-react-select"
                      name="location"
                      placeholder=""
                      options={Array.from(locationDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("location", e.value);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="location" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="startTime form-label fs-4 required fw-bold">
                      Start Time
                    </label>   <i className="startTime fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".startTime" className="tooltip-dashboard">
                      Enter the start time of the job
                    </Tooltip>
                    <Datetime
                      isValidDate={(current) => {
                        let customDate = Moment().format("YYYY-MM-DD");
                        return (
                          current && current <= Moment(customDate, "YYYY-MM-DD")
                        );
                      }}
                      ref={resetDate}
                      closeOnSelect
                      className={clsx("date-control", {
                        "is-invalid":
                          Boolean(errors.dateAndTime) && touched.dateAndTime,
                      })}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="HH:mm"
                      value={values.dateAndTime}
                      onChange={(e: any) => {
                        const dt = e["_d"];
                        setFieldValue("dateAndTime", dt);
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="dateAndTime" />
                    </div>
                  </div>
                  {values.status === "COMPLETED" && (
                    <>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                        <label className="form-label required fs-4 fw-bold">
                          Closing Date & Time
                        </label>
                        <Datetime
                          ref={resetClosingDate}
                          dateFormat="YYYY-MM-DD"
                          timeFormat="HH:mm"
                          closeOnSelect
                          className={clsx("date-control", {
                            "is-invalid":
                              Boolean(errors.closingDateAndTime) &&
                              touched.closingDateAndTime,
                          })}
                          value={values.closingDateAndTime}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("closingDateAndTime", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="closingDateAndTime" />
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-6 pb-2">
                        <label className="form-label fs-4 required fw-bold">
                          Travel Time (Minutes)
                        </label>
                        <input
                          className={clsx("form-control", {
                            "is-invalid":
                              Boolean(errors.travelTime) && touched.travelTime,
                          })}
                          type="number"
                          value={values.travelTime}
                          name="travelTime"
                          id="travelTime"
                          onChange={handleChange}
                        />
                        <div className="text-danger text-align-top-center ms-1 mt-2">
                          <ErrorMessage name="travelTime" />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="additionalComments form-label fs-4 fw-bold">
                      Additional Comments
                    </label>   <i className="additionalComments fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    <Tooltip anchorSelect=".additionalComments" className="tooltip-dashboard">
                      Add any notes or comments regarding this job.
                    </Tooltip>
                    <textarea
                      name="additionalComments"
                      id="additionalComments"
                      className="form-control"
                      value={values.additionalComments}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-xl-5 col-lg-12 col-md-12 col-12 p-2 pe-xl-5 ps-xl-4 mb-3 mt-md-9 mt-lg-8 mt-xl-8 pt-xl-3 order-xl-0 order-md-1">
                    <div className="accordion ">
                      <div className="accordion-item">
                        <h2
                          className="marketingMaterialsUsed accordion-header "
                          data-bs-toggle="tooltip"
                          id="marketing"
                        >
                          <Tooltip anchorSelect=".marketingMaterialsUsed" className="tooltip-dashboard" style={{ fontSize: "10px" }}>
                            List the marketing materials utilized during the job.
                          </Tooltip>
                          <button
                            className=" accordion-button py-5 collapsed form-label cursor-pointer fs-4 fw-bold mb-0 bg-light"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#marketing-purpose"
                            aria-expanded="false"
                            aria-controls="marketing-purpose"
                          >
                            Marketing Materials Used
                            <i className="marketingMaterialsUsed fas fa-info-circle fs-4  ps-1"></i>
                          </button>
                        </h2>
                        <div
                          id="marketing-purpose"
                          className="accordion-collapse collapse"
                          aria-labelledby="marketing"
                        >
                          <div className="accordion-body pb-1 p-3">
                            <div className="row">
                              <FieldArray name="marketingMaterial">
                                {(arrayHelpers) => {
                                  return (
                                    <>
                                      {Array.from(materialDB).map(
                                        (value: any, index) => {
                                          return (
                                            <div className="col-lg-6 col-md-6 col-12">
                                              <label
                                                key={value.id}
                                                className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex ms-2 mt-1"
                                              >
                                                <input
                                                  id="marketingMaterial"
                                                  className="form-check-input mb-2 me-1"
                                                  type="checkbox"
                                                  name={value.id}
                                                  value={value.id}
                                                  onChange={(e) => {
                                                    if (e.target.checked)
                                                      arrayHelpers.push(
                                                        e.target.value
                                                      );
                                                    else {
                                                      const idx =
                                                        values.marketingMaterial.findIndex(
                                                          (e: any) =>
                                                            e.id === value.id
                                                        );
                                                      arrayHelpers.remove(idx);
                                                    }
                                                  }}
                                                />
                                                <span className="ms-2 mb-1 fs-5 form-check-label">
                                                  {value.name}
                                                </span>
                                              </label>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  );
                                }}
                              </FieldArray>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mt-md-9 mt-lg-8 mt-xl-8 pt-xl-3 order-xl-01 order-md-0">
                    <div className="btn btn-bg-light fs-4 w-100 p-3 m-0 border">
                      <label
                        className="form-label fs-4 mb-0 text-dark"
                        style={{ cursor: "pointer" }}
                      >
                        Click here to upload images & videos
                        <input
                          id="upload"
                          type="file"
                          accept="image/png , image/jpeg, image/webp, video/mp4, video/mkv, video/x-m4v, video/*"
                          name="uploadImageVideo"
                          className="form-control d-none"
                          onChange={onSelectFile}
                        />
                      </label>
                    </div>
                    {selectedImages.length > 0 && (
                      <>
                        {selectedImages &&
                          selectedImages.map((image, index) => {
                            return (
                              <div
                                key={image}
                                className="img-box mt-3 ms-2 justify-content-center"
                              >
                                {image["type"] === "image/png" ||
                                  image["type"] === "image/jpeg" ||
                                  image["type"] === "image/webp" ||
                                  image["type"] === "image/jpg" ? (
                                  <img
                                    src={image["content"]}
                                    key={index}
                                    width="150"
                                    alt="Upload"
                                    className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                  />
                                ) : (
                                  <video
                                    controls
                                    src={image["content"]}
                                    className="img-wrapper overflow-hidden m-2 ms-0 d-flex position-relative"
                                    width="170"
                                  />
                                )}
                                <button
                                  onClick={() => deleteHandler(image)}
                                  className="close-btn img-btn text-gray-700 text-hover-gray-800"
                                >
                                  <i className="fa fa-close fs-5 pt-1" />
                                </button>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </div>
                </div>
                <div className="row mt-8">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                    <label className=" form-label d-block fs-4 ps-1 pb-2 fw-bold">
                      <span className="customerSatisfaction">Customer Satisfaction</span>
                      <i className="customerSatisfaction fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    </label>
                    <Tooltip anchorSelect=".customerSatisfaction" className="tooltip-dashboard">
                      This field indicates the degree of satisfaction expressed by management and staff regarding the smoodi program.
                    </Tooltip>
                    {Array.from(satisfactionDB).map((value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="customerSatisfaction"
                            value={value.id}
                            checked={values.customerSatisfaction === value.id}
                            onChange={() =>
                              setFieldValue("customerSatisfaction", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                    <label className="form-label d-block fs-4 ps-1 pb-2 fw-bold">
                      <span className="cleanliness" >Cleanliness</span>
                      <i className="cleanliness fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    </label>
                    <Tooltip anchorSelect=".cleanliness" className="tooltip-dashboard">
                      This field assesses the cleanliness of the area surrounding the machine at the time of the store visit
                    </Tooltip>
                    {Array.from(cleanlinessDB).map((value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="cleanliness"
                            value={value.id}
                            checked={values.cleanliness === value.id}
                            onChange={() =>
                              setFieldValue("cleanliness", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                    <label className=" form-label d-block fs-4 ps-1 pb-2 fw-bold">
                      <span className="trafficRate" > Traffic Rate</span>
                      <i className="trafficRate fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    </label>
                    <Tooltip anchorSelect=".trafficRate" className="tooltip-dashboard">
                      This field evaluates the level of busyness in the store at the time of the visit
                    </Tooltip>
                    {Array.from(trafficRateDB).map((value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="trafficRate"
                            value={value.id}
                            checked={values.trafficRate === value.id}
                            onChange={() =>
                              setFieldValue("trafficRate", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>

                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                    <label className=" form-label d-block fs-4 ps-1 pb-2 fw-bold">
                      <span className="staffEnthusiasm">Staff Enthusiasm</span>  
                      <i className="staffEnthusiasm fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    </label>
                    <Tooltip anchorSelect=".staffEnthusiasm" className="tooltip-dashboard">
                      Assess the level of enthusiasm and engagement shown by the staff towards the smoodi program
                    </Tooltip>
                    {Array.from(enthusiasmDB).map((value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="staffEnthusiasm"
                            value={value.id}
                            checked={values.staffEnthusiasm === value.id}
                            onChange={() =>
                              setFieldValue("staffEnthusiasm", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 mt-lg-0 mt-md-0 mt-3">
                    <label className=" form-label d-block fs-4 ps-1 pb-2 fw-bold">
                      <span className="statusOfSanitizer">Status Of Sanitizer </span> 
                       <i className="statusOfSanitizer fas fa-info-circle fs-4 mb-2 ps-1"></i>
                    </label>
                    <Tooltip anchorSelect=".statusOfSanitizer" className="tooltip-dashboard">
                      This field indicates the current fill level of the sanitizer dispenser.
                    </Tooltip>
                    {Array.from(sanitizerDB).map((value: any, index) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2">
                        <label
                          className="form-check-label fs-5 mb-0 ms-2"
                          key={index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="sanitizerStatus"
                            value={value.id}
                            checked={values.sanitizerStatus === value.id}
                            onChange={() =>
                              setFieldValue("sanitizerStatus", value.id)
                            }
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row mt-8">
                  <div className="col-xl-3 col-lg-3 col-md-4 col-12 px-xl-5 p-2 mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">CREATE JOB</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </>
  );
};
export default CreateLogJob;
