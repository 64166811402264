import {FC, useState, Suspense, useEffect} from "react";
import Modal from "react-responsive-modal";
import {Formik} from "formik";
import {fetchQuery, useRelayEnvironment} from "react-relay";
import toast, {Toaster} from "react-hot-toast";
import "react-datetime/css/react-datetime.css";
import {getSalesPerson, updateLocationSalesInfo} from "../../models/_location";
import {graphql} from "babel-plugin-relay/macro";
import Select from "react-select";
import { useQuery } from "react-query";

type Props = {
  currentLocationId: string;
  handleSalesClose: () => void;
  salesInfoModal: boolean;
  refreshTable?: any;
};

export const getLocationById = graphql`
  query SalesInfoGetLocationQuery($id: String!) {
    getLocation(id: $id) {
      id
      uuid
      name
      salesLeadId
      salesSupport1Id
      salesSupport2Id
      salesSupport2Persentage
    }
  }
`;
type SalesPerson = {
  id: string;
  firstName: string;
  lastName: string;
};
export const SalesInfo: FC<Props> = ({
  currentLocationId,
  salesInfoModal,
  handleSalesClose,
  refreshTable,
}) => {
  const environment = useRelayEnvironment();
  const [salesPersonDB, setSalesPersonDB] = useState<SalesPerson[]>([]);
  const fetchData = async () => {
    try {
      const salesPersonByRecord = await getSalesPerson(true);
      const salesPersonByResult: any = salesPersonByRecord
        ? salesPersonByRecord
        : [];
      salesPersonByResult.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setSalesPersonDB(salesPersonByResult);
    } catch (error) {
      console.log(error);
    }
  };

  
  const refetchSalesInfo = async (): Promise<void> => {
    try {
      refetch();
      console.log("Successfully updated cache with the Sales Information.");
    } catch (error) {
      console.error("Error refetching Sales Information:", error);
    }
  };

  const { data: locationRecord, refetch } = useQuery(
      ["getLocation", currentLocationId],
      () => fetchQuery(environment, getLocationById, { id: currentLocationId ?? undefined }).toPromise(),
      {
        enabled: !!currentLocationId,
      }
    );
    const locations: any = locationRecord && typeof locationRecord === 'object' && 'getLocation' in locationRecord ? locationRecord.getLocation : [];
  
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    fetchData();
  }, []);

  return (
    <Suspense>
      <Modal
        open={(salesInfoModal && !!currentLocationId && !!locations)}
        onClose={() => handleSalesClose()}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            sales_lead_id: locations?.salesLeadId,
            sales_support_1_id: locations?.salesSupport1Id ?? null,
            sales_support_2_id: locations?.salesSupport2Id ?? null,
            sales_support_2_persentage: locations?.salesSupport2Persentage ?? null
          }}
          onSubmit={async (values, {resetForm}) => {
            try {
              setLoading(true);
              const updateData: any = {
                locationId: locations.id,
                salesLeadId: values.sales_lead_id,
                salesSupport1Id: values.sales_support_1_id ? values.sales_support_1_id : null,
                salesSupport2Id: values.sales_support_2_id ? values.sales_support_2_id : null,
                salesSupport2Persentage: parseFloat(values.sales_support_2_persentage),
              };
              if (values.sales_support_2_persentage > 100) {

                toast.error("Percentage cannot be greater than 100");
                return
              }
              const result = await updateLocationSalesInfo(updateData);
              if (result?.data?.data?.updateLocationSalesInfo) {
                refetchSalesInfo();
                toast.success(
                  "Location Sales Information updated successfully"
                );
                handleSalesClose();
                resetForm();
                if (typeof refreshTable !== "undefined") {
                  refreshTable();
                }
              } else {
                const messages = result?.data?.errors.map(
                  (e: any) => e.message
                );
                toast.error(messages.join(","));
              }
            } catch (error) {
              console.log("Error in updating sales information", error);
            } finally {
              setLoading(false);
            }
          }}
        >
          {({handleSubmit, values, setFieldValue}) => {
            const salesPersonOptions = salesPersonDB.map((value) => ({
              value: value.id,
              label: value.firstName+ ' '+(value.lastName && value.lastName !='null' ? value.lastName: ''),
            }));
            return (
              <form
                id="kt_modal_update_location_price_form"
                className="form"
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
              >
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1 mt-4">
                    Sales Info- {locations?.name ?? ""}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 mt-2 pt-2">
                      <table className="table table-boarderd">
                        <thead></thead>
                        <tbody>
                          <tr>
                          <td className="required text-center align-middle">Sales Lead</td>
                            <td>
                              <Select
                                className="react-select"
                                classNamePrefix="my-react-select"
                                name="sales_lead_id"
                                placeholder="Select Sales Lead"
                                options={salesPersonOptions}
                                value={
                                  salesPersonOptions.find(
                                    (opt) => opt.value === values.sales_lead_id
                                  ) || null
                                }
                                onChange={(selected) => {
                                  setFieldValue(
                                    "sales_lead_id",
                                    selected ? selected.value : null
                                  );
                                }}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="text-center align-middle">Sales Support I</td>
                            <td>
                              <Select
                                className="react-select"
                                classNamePrefix="my-react-select"
                                name="sales_support_1_id"
                                placeholder="Select Sales Support I"
                                options={salesPersonOptions.map((opt) => ({
                                  ...opt,
                                  isDisabled:
                                    opt.value === values.sales_lead_id, // Disable if it's the selected sales lead
                                }))}
                                value={
                                  salesPersonOptions.find(
                                    (opt) =>
                                      opt.value === values.sales_support_1_id
                                  ) || null
                                }
                                isClearable={true} 
                                onChange={(selected) => {
                                  setFieldValue(
                                    "sales_support_1_id",
                                    selected ? selected.value : null
                                  );
                                  if(!selected) {
                                    setFieldValue(
                                      "sales_support_2_id",
                                       null
                                    );
                                  } 
                                }}
                              />
                            </td>
                          </tr>

                          <tr>
                            <td className="text-center align-middle">Sales Support II</td>
                            <td>
                              <Select
                                className="react-select"
                                classNamePrefix="my-react-select"
                                name="sales_support_2_id"
                                placeholder="Select Sales Support II"
                                options={salesPersonOptions.map((opt) => ({
                                  ...opt,
                                  isDisabled:
                                    ((values.sales_support_1_id &&
                                      opt.value === values.sales_support_1_id) || (values.sales_lead_id &&
                                        opt.value === values.sales_lead_id)),
                                }))}
                                value={
                                  salesPersonOptions.find(
                                    (opt) =>
                                      opt.value === values.sales_support_2_id
                                  ) || null
                                }
                                isClearable={true} 
                                onChange={(selected) => {
                                  setFieldValue(
                                    "sales_support_2_id",
                                    selected ? selected.value : null
                                  );
                                }}
                                isDisabled={!values.sales_support_1_id} 
                              />
                            </td>
                          </tr>
                          {values.sales_support_2_id && (
  <tr>
    <td className="text-center align-middle">Sales Support II %</td>
    <td>
      <input
        type="number"
        name="sales_support_2_persentage"
        className="form-control"
        placeholder="Enter percentage"
        value={values.sales_support_2_persentage || ""}
        onChange={(e) => setFieldValue("sales_support_2_persentage", e.target.value)}
      />
    </td>
  </tr>
)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-6 p-0 justify-content-start">
                  <button
                    type="submit"
                    className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                  >
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{display: "block"}}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!loading && (
                      <span className="indicator-label">Submit</span>
                    )}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
