import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateEventsInput {
  title: string;
  description: string;
  venue: string;
  surveyId: string;
  formLink: string;
  formPin: string;
  startDate: Date;
  endDate: Date;
  locationId: string[];
}

export async function createEvent(input: CreateEventsInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createEvent (
          $input:CreateEventsInput!
        ) {
          createEvent(
            createEventInput:$input
          )
        }
      `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateEvent(id: string, input: CreateEventsInput) {
  let data: any;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation updateEvent (
          $id: String!
          $input: CreateEventsInput!
        ) {
          updateEvent(
            id: $id
            updateEventInput: $input
          )
        }
      `,
      variables: {id, input},
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getEventsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
          getEventsList {
            id
            title
            description
            venue
            locations {
              id
              name
            }
            survey {
              id
              title
            }
            startDate
            endDate
            surveyId
            formLink
            formPin
            createdAt
          }
        }
      `,
      variables: {},
    },
  });
  return data?.data?.data?.getEventsList;
}

export async function getEventById(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($id:String!) {
          getEventById(id:$id) {
            id
            title
            description
            venue
            locations {
              id
              name
            }
            survey {
              id
              title
              otherFeedbackRequired
              createdAt
            }
            startDate
            endDate
            surveyId
            formLink
            formPin
            createdAt
          }
        }
      `,
      variables: {
        id,
      },
    },
  });
  return data?.data?.data?.getEventById;
}


export async function getTotalBlendsByEventId(event_id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($event_id:String!) {
          getTotalBlendsByEventId(event_id:$event_id) {
            currentBlends
            previousBlends
          }
        }
      `,
      variables: {
        event_id,
      },
    },
  });
  return data?.data?.data?.getTotalBlendsByEventId;
}

export async function getFlavorWiseBlendsByEventId(event_id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($event_id:String!) {
          getFlavorWiseBlendsByEventId(event_id:$event_id) {
            currentPeriod {
              blends
              flavor
              colorCode
            }
            previosPeriod {
              blends
              flavor
              colorCode
            }
          }
        }
      `,
      variables: {
        event_id,
      },
    },
  });
  return data?.data?.data?.getFlavorWiseBlendsByEventId;
}

export async function getLocationWiseBlendsByEventId(event_id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($event_id:String!) {
          getLocationWiseBlendsByEventId(event_id:$event_id) {
            currentPeriod {
              blends
              name
            }
            previosPeriod {
              blends
              name
            }
          }
        }
      `,
      variables: {
        event_id,
      },
    },
  });
  return data?.data?.data?.getLocationWiseBlendsByEventId;
}

export async function getMachineWiseBlendsByEventId(event_id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query($event_id:String!) {
          getMachineWiseBlendsByEventId(event_id:$event_id) {
            currentPeriod {
              blends
              name
            }
            previosPeriod {
              blends
              name
            }
          }
        }
      `,
      variables: {
        event_id,
      },
    },
  });
  return data?.data?.data?.getMachineWiseBlendsByEventId;
}
