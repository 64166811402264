import React from "react";
import FileUpload from "../../Common/FileUpload";

const MarketingMaterialsUpload = (props: { values: any; setFieldValue: any }) => {
  const { values, setFieldValue } = props;

  return (
    <FileUpload
      values={values}
      setFieldValue={setFieldValue}
      fieldName="marketingMaterialPresent"
      label="Add picture of marketing materials present (attachments)"
    />
  );
};

export default MarketingMaterialsUpload;