import React from "react";
import environment from "../../../environment";
import { graphql } from "babel-plugin-relay/macro";
import { QueryRenderer } from "react-relay";
import { OperationType } from "relay-runtime";
import {
  Location,
  Machine,
  User,
} from "../Machines/MachineParentComponent";
import WorkOrderList from "./WorkOrderList";

interface WorkOrderParentComponentQueryResponse {
  getMachineList: Machine[];
  getUsers: User[];
  getLocationList: Location[];
}

type WorkOrderParentComponentQuery = OperationType & {
  response: WorkOrderParentComponentQueryResponse;
  variables: {
    isRegionalManager: boolean;
  };
};

class WorkOrderParentComponent extends React.Component<any, any> {
  render() {
    return (
      <QueryRenderer<WorkOrderParentComponentQuery>
        environment={environment}
        query={graphql`
          query WorkOrderParentComponentQuery($isRegionalManager: Boolean) {
            getMachineList {
              id
              uuid
              serialNumber
              name
              locationId
            }
            getUsers(isRegionalManager: $isRegionalManager) {
              id
              firstName
              lastName
              isActive
              isRegionalManager
            }
            getLocationList {
              id
              uuid
              name
            }
          }
        `}
        variables={{ isRegionalManager: true }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error loading work order data: {error.message}</div>;
          }

          if (!props) {
            return <div className="loading-indicator">Loading...</div>;
          }
          const {
            getMachineList,
            getUsers,
            getLocationList
          } = props;

          return (
            <WorkOrderList
              machines={getMachineList}
              users={getUsers}
              locations={getLocationList}
            />
          );
        }}
      />
    );
  }
}

export default WorkOrderParentComponent;
