import  { FC, Suspense, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchQuery, useLazyLoadQuery, useRelayEnvironment } from "react-relay";
import Modal from "react-responsive-modal";
import Select from "react-select";
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import * as _ from "lodash";
import {
  updatemachine,
  getMachineStatusList,
} from "../../models/_machine";
import clsx from "clsx";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import Moment from "moment";
import { getUsers } from "../../models/_location";
import * as yup from "yup";
import { getBoardsList } from "../../models/_board";
import { getBlendersList } from "../../models/_blenders";
import { getCupHoldersList } from "../../models/_cupHolders";
import { getTubeShieldList } from "../../models/_tubeShield";
import { graphql } from "babel-plugin-relay/macro";
import { EditMachine_getmachineQuery } from "./__generated__/EditMachine_getmachineQuery.graphql";

type Props = {
  currentMachineId: string;
  machineEditModal: boolean;
  handleCloseMachine: () => void;
  refreshTable?: any;
};
const editMachineSchema = yup.object({
  serialNumber: yup.string().required("Serial number is required"),
  
  machineStatusId: yup.string().required("Machine Status is required"),
});

export const getMachineById = graphql`
  query EditMachine_getmachineQuery($id:String!){
    getmachine(id:$id){
      id
      name
      uuid
      serialNumber
      macAddress
      tubeShieldId
      isActive
      isPilot
      trialEndDate
      remark
      noteRelatedToMechanicalHardwareVersion
      hardwareVersion
      isPolyfuses
      machineStatusId
      machineStatus {
        name
      }
      deployingPerson {
        firstName
      }
      board {
        title
      }
      blender {
        title
      }
      cupHolder {
        title
      }
      tubeShield {
        name
      }
      locationId
      cupHolderId
      deploymentDate
      deployingPersonId
      blenderId
      boardId
    }
  }
`

export const EditMachine: FC<Props> = ({
  currentMachineId,
  machineEditModal,
  handleCloseMachine,
  refreshTable,
}: any) => {
  const navigate = useNavigate();
  const environment = useRelayEnvironment();

  if (!currentMachineId) {
    navigate("/machines/list");
  }

  const refetchMachine = async (): Promise<void> => {
    try {
      await fetchQuery(environment, getMachineById, { id: currentMachineId }).toPromise();
      console.log("Successfully updated cache with the latest machine data.");
    } catch (error) {
      console.error("Error refetching machine data:", error);
    }
  };

  const machineRecord = useLazyLoadQuery<EditMachine_getmachineQuery>(
    getMachineById,
    {
      id: currentMachineId,
    },
    {
      fetchPolicy: "store-or-network",
    }
  );

  const machines: any = machineRecord?.getmachine ?? [];

  if (machines == null) {
    navigate("/machines/list");
  }

  const [loading, setLoading] = useState(false);
  const [managerDB, setManagerDB] = useState([]);
  const [blenderDB, setBlenderDB] = useState<any>([]);
  const [boardDB, setBoardDB] = useState<any>([]);
  const [cupHolderDB, setCupHolderDB] = useState<any>([]);
  const [tubeShieldDB, setTubeShieldDB] = useState<any>([])
  const [machineStatusDB, setMachineStatusDB] = useState<any>([]);
  const statusDB = [
    {
      id: true,
      name: "Active",
    },
    {
      id: false,
      name: "Inactive",
    },
  ];
  const fetchData = async () => {
    try {
      const deploymentManagerRecord = await getUsers();
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const blenderRecord = await getBlendersList();
      const blenderResults: any = blenderRecord ? blenderRecord : [];

      blenderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const boardRecord = await getBoardsList();
      const boardResults: any = boardRecord ? boardRecord : [];

      boardResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const cupHolderRecord = await getCupHoldersList();
      const cupHolderResults: any = cupHolderRecord
        ? cupHolderRecord
        : [];


      cupHolderResults.sort(function (a: any, b: any) {
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const machineStatusRecord = await getMachineStatusList();
      const tubeShieldRecord = await getTubeShieldList();
      const tubeShieldResults: any = tubeShieldRecord
        ? tubeShieldRecord
        : [];
      setMachineStatusDB(machineStatusRecord)
      setBlenderDB(blenderResults);
      setBoardDB(boardResults);
      setManagerDB(deploymentManagerResults);
      setCupHolderDB(cupHolderResults)
      setTubeShieldDB(tubeShieldResults);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Suspense>
        <Modal
          open={machineEditModal}
          onClose={() => handleCloseMachine()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{
              id: machines?.id,
              macAddress: machines?.macAddress ?? "",
              locationId: machines?.locationId ?? "",
              name: machines?.name ?? "",
              serialNumber: machines?.serialNumber ?? "",
              deploymentDate: Moment.unix(machines.deploymentDate / 1000)
                .local()
                .format("YYYY-MM-DD"),
              deployingPersonId: machines?.deployingPersonId ?? null,
              blenderId: machines?.blenderId ?? "",
              boardId: machines?.boardId ?? "",
              cupHolderId: machines?.cupHolderId ?? "",
              tubeShieldId:machines?.tubeShieldId?? "",
              isActive: machines?.isActive,
              isPilot: machines?.isPilot,
              trialEndDate: machines?.trialEndDate,
              machineRemark: machines?.remark ?? "",
              deployingPerson: machines?.deployingPerson?.firstName ?? "",
              board: machines?.board?.title ?? "",
              cupHolder: machines?.cupHolder?.title ?? null,
              tubeShield: machines?.tubeShield?.name ?? null,
              blender: machines?.blender?.title ?? "",
              noteRelatedToMechanicalHardwareVersion:
                machines?.noteRelatedToMechanicalHardwareVersion ?? "",
              hardwareVersion: machines?.hardwareVersion ?? "",
              isPolyfuses: machines?.isPolyfuses,
              machineStatusId: machines?.machineStatusId ?? "",
              machineStatus: machines?.machineStatus?.name ??"",
            }}
            validationSchema={editMachineSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              if (!values.deploymentDate) {
                toast.error("Deployment date is required!");
                return;
              }
              if (!values.blenderId) {
                toast.error("Blender is required!");
                return;
              }
              if (!values.machineStatusId) {
                toast.error("Machine Status is required!");
                return;
              }
              if (!values.boardId) {
                toast.error("Board is required!");
                return;
              }
              setLoading(true);

              const updateData: any = {
                id: values.id,
                name: values.name,
                macAddress: values.macAddress,
                deploymentDate: values.deploymentDate,
                deployingPersonId: values.deployingPersonId,
                blenderId: values.blenderId,
                boardId: values.boardId,
                machineStatusId: values.machineStatusId,
                cupHolderId:values.cupHolderId !== null ? values.cupHolderId : null,
                tubeShieldId:values.tubeShieldId !==null ? values.tubeShieldId: null,
                serialNumber: values.serialNumber,
                remark: values.machineRemark,
                isPilot: values.isPilot,
                trialEndDate: values.trialEndDate,
                isPolyfuses: values.isPolyfuses,
                noteRelatedToMechanicalHardwareVersion:
                  values.noteRelatedToMechanicalHardwareVersion,
                isActive: values.isActive,
              };
            
              if (values.trialEndDate) {
                updateData["trialEndDate"] = Moment.utc(values.trialEndDate)
                  .local()
                  .format("YYYY-MM-DD");
              }
              const result = await updatemachine(updateData);
              if (result?.data?.data?.updatemachine) {
                refetchMachine();
                toast.success("Machine updated successfully");
                setLoading(false);
                handleCloseMachine();
                resetForm();
                if (typeof refreshTable !== "undefined") {
                  refreshTable();
                }
              } else {
                setLoading(false);
                const messages = result?.data?.errors.map(
                  (e: any) => e.message
                );
                toast.error(messages.join(","));
              }
            }}
          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit}
                autoComplete="off"
                noValidate
              >
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1">
                    Edit Machine -{machines?.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Serial Number
                      </label>
                      <input
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.serialNumber) &&
                            touched.serialNumber,
                        })}
                        name="serialNumber"
                        id="serialNumber"
                        value={values.serialNumber}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="serialNumber" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Mac Address
                      </label>
                      <input
                        className={clsx("form-control", {
                          "is-invalid":
                            Boolean(errors.macAddress) && touched.macAddress,
                        })}
                        name="macAddress"
                        id="macAddress"
                        value={values.macAddress}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="macAddress" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        Machine Name
                      </label>
                      <input
                        className={clsx("form-control name", {
                          "is-invalid": Boolean(errors.name) && touched.name,
                        })}
                        name="name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="name" />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Deployment Date
                      </label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect
                        timeFormat={false}
                        className={clsx("date-control", {
                          "is-invalid":
                            Boolean(errors.deploymentDate) &&
                            touched.deploymentDate,
                        })}
                        value={values.deploymentDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("deploymentDate", dt);
                        }}
                      />
                    </div>

                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        Person Deploying
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="deployingPersonId"
                        defaultValue={{
                          label: values.deployingPerson,
                          value: values.deployingPersonId,
                        }}
                        options={Array.from(managerDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.firstName,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("deployingPersonId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">Remark</label>
                      <textarea
                        name="machineRemark"
                        id="machineRemark"
                        className="form-control"
                        value={values.machineRemark}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                        Blender
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="blenderId"
                        defaultValue={{
                          label: _.capitalize(_.lowerCase(values.blender)),
                          value: values.blenderId,
                        }}
                        options={Array.from(blenderDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.title,
                            description: value.description,
                            mediaUrl: value.mediaUrl,
                          };
                        })}
                        formatOptionLabel={({ label, description, mediaUrl }: any, { context }: any) => {
                          return context === 'menu' ? (
                            <div>
                              {label}
                              {description && <div className="mt-2">{description}</div>}
                              {mediaUrl && (
                                <div className="mt-2">
                                  <img 
                                    src={mediaUrl} 
                                    alt={label} 
                                    style={{ height:'80px',objectFit: 'cover' }} 
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            label
                          );
                        }}
                        onChange={(e: any) => {
                          setFieldValue("blenderId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                        Board
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="boardId"
                        defaultValue={{
                          label: _.capitalize(_.lowerCase(values.board)),
                          value: values.boardId,
                        }}
                        options={Array.from(boardDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.title,
                            description: value.description,
                            mediaUrl: value.mediaUrl,
                          };
                        })}
                        formatOptionLabel={({ label, description, mediaUrl }: any, { context }: any) => {
                          return context === 'menu' ? (
                            <div>
                              {label}
                              {description && <div className="mt-2">{description}</div>}
                              {mediaUrl && (
                                <div className="mt-2">
                                  <img 
                                    src={mediaUrl} 
                                    alt={label} 
                                    style={{ height:'80px',objectFit: 'cover' }} 
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            label
                          );
                        }}
                        onChange={(e: any) => {
                          setFieldValue("boardId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label required fs-4 fw-bold">
                      Machine Status
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="machineStatusId"
                        defaultValue={{
                          label: _.capitalize(_.lowerCase(values.machineStatus)),
                          value: values.machineStatusId,
                        }}
                        options={Array.from(machineStatusDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        })}
                        onChange={(e: any) => {
                          setFieldValue("machineStatusId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        CupHolder
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="cupHolderId"
                        defaultValue={{
                          label: _.capitalize(_.lowerCase(values.cupHolder)),
                          value: values.cupHolderId,
                        }}
                        options={Array.from(cupHolderDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.title,
                            description: value.description,
                            mediaUrl: value.mediaUrl,
                          };
                        })}
                        formatOptionLabel={({ label, description, mediaUrl }: any, { context }: any) => {
                          return context === 'menu' ? (
                            <div>
                              {label}
                              {description && <div className="mt-2">{description}</div>}
                              {mediaUrl && (
                                <div className="mt-2">
                                  <img 
                                    src={mediaUrl} 
                                    alt={label} 
                                    style={{ height:'80px',objectFit: 'cover' }} 
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            label
                          );
                        }}
                        onChange={(e: any) => {
                          setFieldValue("cupHolderId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        Tube shield 
                      </label>
                      <Select
                        className="react-select"
                        classNamePrefix="my-react-select"
                        name="tubeShieldId"
                        defaultValue={{
                          label: _.capitalize(_.lowerCase(values.tubeShield)),
                          value: values.tubeShieldId,
                        }}
                        options={Array.from(tubeShieldDB).map((value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                            mediaUrl: value.mediaUrl,
                            description: value.description,
                          };
                        })}
                        formatOptionLabel={({ label, description, mediaUrl }: any, { context }: any) => {
                          return context === 'menu' ? (
                            <div>
                              {label}
                              {description && <div className="mt-2">{description}</div>}
                              {mediaUrl && (
                                <div className="mt-2">
                                  <img 
                                    src={mediaUrl} 
                                    alt={label} 
                                    style={{ height:'80px',objectFit: 'cover' }} 
                                  />
                                </div>
                              )}
                            </div>
                          ) : (
                            label
                          );
                        }}
                        onChange={(e: any) => {
                          setFieldValue("tubeShieldId", e.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                      <label className="form-label fs-4 fw-bold">
                        Note related to mechanical hardware version
                      </label>
                      <textarea
                        name="noteRelatedToMechanicalHardwareVersion"
                        id="noteRelatedToMechanicalHardwareVersion"
                        className="form-control"
                        value={values.noteRelatedToMechanicalHardwareVersion}
                        onChange={handleChange}
                      />
                    </div>
                    {values.locationId !== "" ? (
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 fw-bold d-block">
                          Is Pilot?
                        </label>
                        <div className="form-check form-switch form-check-custom form-check-solid w-100">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={values.isPilot === true}
                            onChange={(event) => {
                              setFieldValue("isPilot", event.target.checked);
                              setFieldValue("trialEndDate", "");
                            }}
                            name="isPilot"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    {values.isPilot ? (
                      <div className="col-lg-6 col-md-12 col-12 mt-2 pt-2">
                        <label className="form-label fs-4 required fw-bold">
                          Trial End Date
                        </label>
                        <Datetime
                          dateFormat="YYYY-MM-DD"
                          closeOnSelect
                          timeFormat={false}
                          className={clsx("date-control", {
                            "is-invalid":
                              Boolean(errors.trialEndDate) &&
                              touched.trialEndDate,
                          })}
                          value={values.trialEndDate}
                          onChange={(e: any) => {
                            const dt = e["_d"];
                            setFieldValue("trialEndDate", dt);
                          }}
                        />
                        <div className="text-danger text-align-top-center mt-2">
                          <ErrorMessage name="trialEndDate" />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 mt-5 pt-2">
                      <label className="form-label fs-4 fw-bold d-block">
                        Is Polyfuses?
                      </label>
                      <div className="form-check form-switch form-check-custom form-check-solid w-100">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={values.isPolyfuses === true}
                          onChange={(event) => {
                            setFieldValue("isPolyfuses", event.target.checked);
                          }}
                          name="isPolyfuses"
                        />
                      </div>
                    </div>
                   
                    <div className="col-lg-6 col-md-12 col-12 mt-5 pt-2">
                      <label className="form-label fs-4 d-block fw-bold ps-1">
                        Site Status
                      </label>
                      {Array.from(statusDB).map((value: any, id) => (
                        <div
                          className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2"
                          key={"status-u-" + id}
                        >
                          <label
                            className="form-check-label fs-5 mb-0 ms-2"
                            key={id}
                          >
                            <input
                              id="isActive"
                              className="form-check-input"
                              type="radio"
                              name="isActive"
                              value={value.id}
                              checked={values.isActive === value.id}
                              onChange={() =>
                                setFieldValue("isActive", value.id)
                              }
                            />
                            <span className="form-check-label fs-5 mb-0 ms-2">
                              {value.name}
                            </span>
                          </label>
                        </div>
                      ))}
                    </div>
                    
                   
                  </div>

                  <div className="modal-footer mt-6 p-0 justify-content-start">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">UPDATE</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </>
  );
};

export default EditMachine;
