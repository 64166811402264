import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface BusinessHour {
  isOpen:boolean;
  dayOfWeek:number;
  openTime: string | null;
  closeTime: string | null;
}

interface CreateLocationInput {
  customerId: string;
  name: string;
  address: string;
  regionId: string;
  marketSegmentId: string;
  deploymentManagerId: string;
  locationTypeId: string;
  distributorId: string;
  filterProviderId: string;
  launchDate?: string | null;
  distributorOtherInput?: string;
  samplingRecommendedTime?: string;
  samplingRecommendedDays?: number;
  targetLaunchDate?: string;
  noOfFreezers?: number;
  noOfBlenders?: number;
  isActive: boolean;
  contactName?: string;
  contactPhone?: string;
  latitude?: string | null;
  longitude?: string | null;
  city?: string | null;
  state?: string | null;
  wifiCredentials?: string;

  typeOfContactPhoneNumber?: string;
  customerSecondContact?: boolean;
  secondContactName?: string | null;
  secondContactPhoneNumber?: string | null;
  secondContactEmail?: string | null;
  secondContactNameRole?: string | null;
  typeOfSecondContactPhoneNumber?: string | null;

  customerItContact?: boolean;
  itContactName?: string | null;
  itContactPhoneNumber?: string | null;
  itContactEmail?: string | null;
  itContactNameRole?: string | null;
  typeOfItContactPhoneNumber?: string | null;

  customerServiceContact?: boolean;
  serviceContactName?: string | null;
  serviceContactPhoneNumber?: string | null;
  serviceContactEmail?: string | null;
  serviceContactNameRole?: string | null;
  typeOfServiceContactPhoneNumber?: string | null;

  storeManagerContact?: boolean;
  storeManagerContactName?: string | null;
  storeManagerContactPhoneNumber?: string | null;
  storeManagerContactEmail?: string | null;
  typeOfStoreManagerContactPhoneNumber?: string | null;
  businessHours?:BusinessHour[]
}

export async function createLocation(input: CreateLocationInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation createLocation (
              $input: CreateLocationInput!
            ){
                createLocation(
                    createLocationInput:  $input
                ) 
            }
             `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getLocations(
  where: {} = {},
  pagination: {} | undefined = undefined,
  extraFilter: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getLocations(
                $where: LocationsResolver_GetLocations_FilterInputType
                $paginate: PaginatorArgs
                $extraFilter: LocationExtraFilterInputs
              ){
                getLocations(where: $where, paginate: $paginate, extraFilter: $extraFilter) {
                  data {
                    id
                    uuid
                    customerId
                    salesLeadId
                    salesSupport1Id
                    salesSupport2Id
                    salesSupport2Persentage
                    name
                    address
                    regionId
                    contactName
                    contactPhone
                    contactEmail
                    gasSupplierId
                    marketSegmentId
                    deploymentManagerId
                    locationTypeId
                    targetLaunchDate
                    launchDate
                    noOfBlenders
                    noOfFreezers
                    distributorId
                    samplingRecommendedTime
                    samplingRecommendedDays
                    createdById
                    createdAt
                    updatedById
                    updatedAt
                    isActive
                    state
                    city
                    zipCode
                    waterPressure
                    latitude
                    longitude
                    machineCount
                    customerSecondContact
                    secondContactName
                    secondContactPhoneNumber
                    secondContactEmail
                    secondContactNameRole
                    customerItContact
                    itContactName
                    itContactPhoneNumber
                    itContactEmail
                    itContactNameRole
                    customerServiceContact
                    serviceContactName
                    serviceContactPhoneNumber
                    serviceContactEmail
                    serviceContactNameRole
                    typeOfContactPhoneNumber
                    typeOfSecondContactPhoneNumber
                    typeOfItContactPhoneNumber
                    typeOfServiceContactPhoneNumber
                    storeManagerContact
                    storeManagerContactName
                    storeManagerContactPhoneNumber
                    storeManagerContactEmail
                    typeOfStoreManagerContactPhoneNumber
                    gasSupplier{
                      id
                      name
                    }
                    businessModel {
                      id
                      name
                    }
                    createdBy {
                      id
                      firstName
                    }
                    updatedBy {
                      id
                      firstName
                    }
                    marketSegment {
                      id
                      name
                    }
                    deploymentManager {
                      id
                      firstName
                    }
                    locationType {
                      id
                      name
                    }
                    region {
                      id
                      name
                    }
                    distributor {
                      id
                      name
                      createdAt
                      updatedAt
                    }
                    flavorPrices {
                      flavor
                      price
                    }
                    filterProvider {
                      id
                      title
                    }
                    customer {
                      id
                      name
                      customerCompanyName
                      customerContactName
                      customerContactPhoneNumber
                      customerContactEmail
                      customerContactNameRole
                      accountType
                      distributorId
                      customerSecondContact
                      customerSecondContactName
                      customerSecondContactPhoneNumber
                      customerSecondContactEmail
                      customerSecondContactNameRole
                      isActive
                    }
                    businessHours {
                      id
                      locationId
                      dayOfWeek
                      openTime
                      closeTime
                      isOpen
                      createdAt
                    }
                      hasSmoodiRouter
                      hasWifiExtender
                      hasPressurePump
                      hasPressureTank
                  }

                  totalPages
                  total
                  perPage
                  page
                }
              }
              `,
      variables: { where, paginate: pagination, extraFilter },
    },
  });
  return data?.data?.data?.getLocations;
}

export async function getLocationsForReplaceManager(
  where: {} = {},
  pagination: {} | undefined = undefined,
  extraFilter: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getLocations(
                $where: LocationsResolver_GetLocations_FilterInputType
                $paginate: PaginatorArgs
                $extraFilter: LocationExtraFilterInputs
              ){
                getLocations(where: $where, paginate: $paginate, extraFilter: $extraFilter) {
                  data {
                    id
                    uuid
                    name
                    address
                    customer{
                      name
                    }
                    region{
                      name
                    }
                    marketSegment{
                      name
                    }
                    deploymentManager{
                      firstName
                    }
                    locationType{
                      name
                    }
                    distributor{
                      name
                    }
                    targetLaunchDate
                    contactName
                    contactPhone
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
              `,
      variables: { where, paginate: pagination, extraFilter },
    },
  });
  return data?.data?.data?.getLocations;
}

export async function getLocationsForFilter(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getLocations(
                $where: LocationsResolver_GetLocations_FilterInputType
                $paginate: PaginatorArgs
              ){
                getLocations(where: $where, paginate: $paginate) {
                  data {
                    id
                    uuid
                    name
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
              `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getLocations;
}

interface UpdateLocationInput {
  id?: string;
  name: string;
  address: string;
  regionId: string;
  launchDate?: string | null;
  marketSegmentId: string;
  deploymentManagerId: string;
  locationTypeId: string;
  filterProviderId: string;
  distributorId: string;
  distributorOtherInput?: string;
  samplingRecommendedTime?: string;
  samplingRecommendedDays?: number;
  targetLaunchDate?: string;
  noOfFreezers?: number;
  noOfBlenders?: number;
  isActive: boolean;
  latitude?: string;
  longitude?: string;
  city?: string;
  state?: string;
  contactName?: string;
  contactPhone?: string;
  typeOfContactPhoneNumber?: string;
  itContactEmail?: string;
  typeOfItContactPhoneNumber?: string;
  itContactName?: string;
  itContactNameRole?: string;
  secondContactName?: string;
  secondContactEmail?: string;
  secondContactNameRole?: string;
  secondContactPhoneNumber?: string;
  itContactPhoneNumber?: string;
  typeOfSecondContactPhoneNumber?: string;
  typeOfServiceContactPhoneNumber?: string;
  serviceContactName?: string;
  serviceContactEmail?: string;
  serviceContactNameRole?: string;
  serviceContactPhoneNumber?: string;
  customerItContact?: boolean;
  customerSecondContact?: boolean;
  customerServiceContact?: boolean;
  wifiCredentials?: string;
  storeManagerContact?: boolean;
  storeManagerContactName?: string;
  storeManagerContactPhoneNumber?: string;
  storeManagerContactEmail?: string;
  typeOfStoreManagerContactPhoneNumber?: string;
  businessHours?:BusinessHour[];
}

interface FlavorPriceInput {
  flavor: string;
  price: number;
}
interface UpdateLocationFlavorPriceInput {
  id: string;
  flavors: FlavorPriceInput[];
}

interface UpdateLocationFreezerDetailInput {
  locationId: string;
  serialNumber: string;
  apiKey: string;
}
interface UpdateLocationSalesInfoInput {
  locationId: string;
  salesLeadId: string;
  salesSupport1Id: string;
  salesSupport2Id: string;
}
export async function updateLocation(input: UpdateLocationInput) {
  let params = { ...input };
  delete params["id"];
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updateLocation (
        $id: String!
        $input: UpdateLocationInput!           
      ){
        updateLocation(
          id: $id
          updateLocationInput: $input
          )
      }
      `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function updateLocationStatus(id: string, isActive: boolean ,confirmedToActivateMachines : boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean!, $confirmedToActivateMachines: Boolean! ) {
          updateLocationStatus(
                updateLocationStatusInput: { id: $id, isActive: $isActive,confirmedToActivateMachines: $confirmedToActivateMachines }
              )
            }
            `,
      variables: {
        id,
        isActive,
        confirmedToActivateMachines
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateLocationFlavorPrice(
  input: UpdateLocationFlavorPriceInput
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateLocationFlavorPrice($input: UpdateLocationFlavorPriceInput! ) {
        updateLocationFlavorPrice(
          updateLocationFlavorPriceInput: $input
              )
            } 
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateLocationFreezerDetail(
  input: UpdateLocationFreezerDetailInput
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateLocationFreezerDetails($input: UpdateLocationFreezerDetailInput! ) {
        updateLocationFreezerDetails(
          updateLocationFreezerDetailInput: $input
              )
            } 
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function updateLocationSalesInfo(
  input: UpdateLocationSalesInfoInput
) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
  headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateLocationSalesInfo($input: UpdateLocationSalesInfoInput! ) {
        updateLocationSalesInfo(
          updateLocationSalesInfo: $input
              )
            } 
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getMarketSegment() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getMarketSegment {
              id
              name
            }
          }
          `,
      variables: {},
    },
  });
  return data?.data?.data?.getMarketSegment;
}

export async function getDeploymentType() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          query {
            getDeploymentTypes {
              id
              name
            }
          }
          `,
      variables: {},
    },
  });
  return data?.data?.data?.getDeploymentTypes;
}

export interface GetUsersType {
  id: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  isRegionalManager: boolean;
}

export async function getUsers(isRegionalManager?: boolean): Promise<GetUsersType[]> {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getUsers (
              $isRegionalManager: Boolean
            ) {
                getUsers(isRegionalManager: $isRegionalManager) {
                  id
                  firstName
                  lastName
                  isActive
                  isRegionalManager
                }
              }
            `,
      variables: {isRegionalManager},
    },
  });
  return data?.data?.data?.getUsers;
}

export async function getRegion() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getRegions {
                id
                name
              }
            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getRegions;
}
export async function getFilterProviders() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getFilterProviders {
                id
                title
              }
            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getFilterProviders;
}

export async function getBusinessModelsList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getBusinessModelsList {
                id
                name
                description
              }
            }
            
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getBusinessModelsList;
}

export async function getLocationByCustomers(
  locationByCustomer: [] = []
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query getLocationByCustomers($locationByCustomer: LocationByCustomer){
              getLocationByCustomers(locationByCustomer: $locationByCustomer) {
                    id
                    customerId
                    name  
                }
              }
            `,
      variables: { locationByCustomer },
    },
  });
  return data?.data?.data?.getLocationByCustomers;
}

interface UpdateLocationBusinessHoursInput {
  locationId: string;
  businessHours?: BusinessHour[];
}

export async function updateLocationBusinessHours(input: UpdateLocationBusinessHoursInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
      mutation updateLocationBusinessHours(
        $input: UpdateLocationBusinessHoursInput!           
      ){
        updateLocationBusinessHours(
          input: $input
          ) 
      }
      `,
      variables: {
        input
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getLocationList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        query {
            getLocationList {
                 id
                 uuid
                 name
            }
          }
        `,
      variables: {},
    },
  });
  return data?.data?.data?.getLocationList;
}

export async function getLocationRevisions(
  inputs: {} = {}
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getLocationRevisions(
              $inputs: GetLocationsRevisionInputs!
        ){
          getLocationRevisions(inputs: $inputs) {
            locationRevision
        }
      }`,
      variables: { inputs },
    },
  });
  return data?.data?.data?.getLocationRevisions?.locationRevision;
}
interface CreateLocationBreakInput {
  title: string;
  startDateTime: string;
  endDateTime: string;
  locationId: string;
}

export async function createLocationBreak(input: CreateLocationBreakInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation createLocationBreak (
              $input: CreateLocationBreakInput!
            ){
                createLocationBreak(
                    createLocationBreakInput: $input
                ) 
            }
             `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getLocationBreaks(
  where: {} = {},
  pagination: {} | undefined = undefined,
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getLocationBreaks(
                $where: LocationsResolver_GetLocationBreaks_FilterInputType
                $paginate: PaginatorArgs
              ){
                getLocationBreaks(where: $where,paginate: $paginate) {
                  data {
                    id
                    title
                    breakStartDateTime
                    breakEndDateTime
                    createdById
                    updatedById
                    updatedAt
                    createdAt
                    createdBy {
                      firstName
                      lastName
                    }
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
              `,
      variables: {where,paginate: pagination },
    },
  });
  return data?.data?.data?.getLocationBreaks;
}

interface UpdateLocationBreakInput {
  title?: string;
  startDateTime?: string;
  endDateTime?: string;
  id?: string;
}

export async function updateLocationBreaks(input: UpdateLocationBreakInput) {
  let params = { ...input };
  delete params["id"];
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateLocationBreaks($id: String!, $input: UpdateLocationBreakInput! ) {
          updateLocationBreaks(
                id: $id
                updateLocationBreakInput:$input 
              )
            }
            `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function deleteLocationBreak(id: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation deleteLocationBreak($id:String!) {
          deleteLocationBreak(id: $id)     
       }`,
      variables: {
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
export async function getSalesPerson(isSalesPerson?: boolean) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query  getSalesPerson (
              $isSalesPerson: Boolean
            ) {
                getSalesPerson(isSalesPerson: $isSalesPerson) {
                  id
                  firstName
                  lastName
                  isActive
                  isRegionalManager
                  isSalesPerson
                }
              }
            `,
      variables: {isSalesPerson},
    },
  });
  return data?.data?.data?.getSalesPerson;
}


interface CreateLocationCustomerSuccessSpaceInput {
  title: string;
  locationId: string;
}

export async function createCustomerSuccessNote(input: CreateLocationCustomerSuccessSpaceInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation createCustomerSuccessSpace (
              $input: CreateCustomerSuccessSpaceInput!
            ){
              createCustomerSuccessSpace(
                createCustomerSuccessSpaceInput: $input
                ) 
            }
             `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getLocationCustomerSuccessNotes(
  where: {} = {},
  pagination: {} | undefined = undefined,
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query getCustomerSuccessSpace(
                $where: LocationsResolver_GetCustomerSuccessSpace_FilterInputType
                $paginate: PaginatorArgs
              ){
                getCustomerSuccessSpace(where: $where,paginate: $paginate) {
                  data {
                    id
                    title
                    createdById
                    updatedById
                    updatedAt
                    createdAt
                    createdBy {
                      firstName
                      lastName
                    }
                  }
                  totalPages
                  total
                  perPage
                  page
                }
              }
              `,
      variables: {where,paginate: pagination },
    },
  });
  return data?.data?.data?.getCustomerSuccessSpace;
}

interface UpdateLocationCustomerSuccessSpaceInput {
  title: string;
  id?: string;
}

export async function updateCustomerSuccessNote(input: UpdateLocationCustomerSuccessSpaceInput) {
  let params = { ...input };
  delete params["id"];
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation updateCustomerSuccessSpace($id: String!, $input: UpdateCustomerSuccessSpaceInput! ) {
        updateCustomerSuccessSpace(
                id: $id
                updateCustomerSuccessSpaceInput:$input 
              )
            }
            `,
      variables: {
        input: params,
        id: input.id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function deleteCustomerSuccessNote(id: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation deleteCustomerSuccessSpace($id:String!) {
        deleteCustomerSuccessSpace(id: $id)     
       }`,
      variables: {
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}