import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getMachinesNameAndSerials, GetMachinesNameAndSerialsMachineType } from '../models/_machine';
import { getIssueReported, GetIssueReportedType, getTicketSources, GetTicketSourcesType, getMachineService,
    getMarketing,
    getOperations,
    GetMachineServiceType,
    GetOperationType,
    GetMarketingType, } from '../models/_tickets';
import { getServiceEngineersForFilter, GetServiceEngineersForFilterType } from '../models/_user';
import { getUsers, GetUsersType } from '../models/_location';
import { GetMarketingMaterialForFilterType, getMarketingMaterialsForFilter } from '../models/_marketingMaterial';

interface ServiceType {
    id: string;
    name: string;
}

interface ReporterType {
    id: string;
    name: string;
}

interface Priority {
    id: string;
    name: string;
}

interface TicketType {
    id: string;
    name: string;
}

interface TicketDependenciesContextProps {
    machineDB: GetMachinesNameAndSerialsMachineType[];
    ticketSourceDB: GetTicketSourcesType[];
    assignToDB: GetServiceEngineersForFilterType[];
    reportedByDB: GetUsersType[];
    serviceTypeDB: ServiceType[];
    reporterType: ReporterType[];
    priorityDB: Priority[];
    ticketTypes: TicketType[];
    issueReportedDB: GetIssueReportedType[];
    machineServicesDB: GetMachineServiceType[];
    operationDB: GetOperationType[];
    marketingDB: GetMarketingType[];
    materialDB: GetMarketingMaterialForFilterType[];
    cleanlinessDB:Cleanliness[];
    satisfactionDB: Satisfaction[];
    closedServiceTypeDB: ClosedServiceType[];
    enthusiasmDB: Enthusiasm[];
    trafficRateDB: TrafficRate[];
    loading: boolean;
}

const TicketDependenciesContext = createContext<TicketDependenciesContextProps>({
    machineDB: [],
    ticketSourceDB: [],
    assignToDB: [],
    reportedByDB: [],
    serviceTypeDB: [],
    reporterType: [],
    priorityDB: [],
    ticketTypes: [],
    issueReportedDB: [],
    machineServicesDB: [],
    operationDB: [],
    marketingDB: [],
    materialDB: [],
    cleanlinessDB:[],
    satisfactionDB: [],
    closedServiceTypeDB: [],
    enthusiasmDB: [],
    trafficRateDB: [],
    loading: false,
});

interface TicketDependenciesProviderProps {
    children: ReactNode;
}

interface Cleanliness {
    id: string;
    name: string;
}

interface TrafficRate {
    id: string;
    name: string;
}

interface Enthusiasm {
    id: string;
    name: string;
}

interface Satisfaction {
    id: string;
    name: string;
}

interface ClosedServiceType {
    id: string;
    name: string;
}

export const TicketDependenciesProvider: React.FC<TicketDependenciesProviderProps> = ({ children }) => {
    const [machineDB, setMachineDB] = useState<GetMachinesNameAndSerialsMachineType[]>([]);
    const [ticketSourceDB, setTicketSourceDB] = useState<GetTicketSourcesType[]>([]);
    const [assignToDB, setAssignToDB] = useState<GetServiceEngineersForFilterType[]>([]);
    const [reportedByDB, setReportedByDB] = useState<GetUsersType[]>([]);
    const [issueReportedDB, setIssueReportedDB] = useState<GetIssueReportedType[]>([]);
    const [machineServicesDB, setMachineServicesDB] = useState<GetMachineServiceType[]>([]);
    const [operationDB, setOperationDB] = useState<GetOperationType[]>([]);
    const [marketingDB, setMarketingDB] = useState<GetMarketingType[]>([]);
    const [materialDB, setMaterialDB] = useState<GetMarketingMaterialForFilterType[]>([]);

    const [loading, setLoading] = useState(false);

    const serviceTypeDB: ServiceType[] = [
        { id: "ON_SITE", name: "On site" },
        { id: "REMOTE", name: "Remote" },
    ];

    const reporterType: ReporterType[] = [
        { id: "INTERNAL", name: "Internal" },
        { id: "EXTERNAL", name: "External" },
    ];

    const priorityDB: Priority[] = [
        { id: "HIGHEST", name: "Highest" },
        { id: "HIGH", name: "High" },
        { id: "MEDIUM", name: "Medium" },
        { id: "LOW", name: "Low" },
    ];

    const ticketTypes: TicketType[] = [
        { id: "REACTIVE_MAINTANANCE", name: "Reactive Maintenance" },
        { id: "PREVENTIVE_MAINTANANCE", name: "Preventive Maintenance" },
        { id: "MARKETING_OTHER", name: "Marketing" },
        { id: "LAB", name: "Lab" },
    ];

    const cleanlinessDB: Cleanliness[] = [
        { id: "GREAT", name: "Great" },
        { id: "MEDIUM", name: "Medium" },
        { id: "BAD", name: "Bad" },
    ];

    const trafficRateDB: TrafficRate[] = [
        { id: "HIGH", name: "High" },
        { id: "MEDIUM", name: "Medium" },
        { id: "LOW", name: "Low" },
    ];

    const enthusiasmDB: Enthusiasm[] = [
        { id: "HIGH", name: "High" },
        { id: "MEDIUM", name: "Medium" },
        { id: "LOW", name: "Low" },
    ];

    const satisfactionDB: Satisfaction[] = [
        { id: "HIGH", name: "High" },
        { id: "MEDIUM", name: "Medium" },
        { id: "LOW", name: "Low" },
    ];

    const closedServiceTypeDB: ClosedServiceType[] = [
        { id: "ON_SITE", name: "On site" },
        { id: "REMOTE", name: "Remote" },
    ];
    

    const fetchData = async () => {
        try {
            setLoading(true);
            const machineRecord = await getMachinesNameAndSerials();
            const machineResult = machineRecord?.data ? machineRecord?.data : [];
            machineResult.sort((a: GetMachinesNameAndSerialsMachineType, b: GetMachinesNameAndSerialsMachineType) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            const ticketSourceRecord = await getTicketSources();
            const ticketSourceResult = ticketSourceRecord ? ticketSourceRecord : [];
            ticketSourceResult.sort((a: GetTicketSourcesType, b: GetTicketSourcesType) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

            const assignRecord = await getServiceEngineersForFilter();
            const assignResult = assignRecord ? assignRecord : [];
            assignResult.sort((a: GetServiceEngineersForFilterType, b: GetServiceEngineersForFilterType) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));

            const reportedByRecord = await getUsers();
            const reportedByResult = reportedByRecord ? reportedByRecord : [];
            reportedByResult.sort((a: GetUsersType, b: GetUsersType) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));

            const issueReportedRecord = await getIssueReported();
            const issueReportedResult: GetIssueReportedType[] = issueReportedRecord
            ? issueReportedRecord
            : [];
            issueReportedResult.push({
            id: "other",
            name: "Other",
            });
            setIssueReportedDB(issueReportedResult);
            setMachineDB(machineResult);
            setTicketSourceDB(ticketSourceResult);
            setAssignToDB(assignResult);
            setReportedByDB(reportedByResult);

            const machineServiceRecord = await getMachineService();
            const machineServiceResults: any = machineServiceRecord
            ? machineServiceRecord
            : [];
            machineServiceResults.push({
            id: "other",
            name: "Other",
            });
    
            const operationRecord = await getOperations();
            const operationResults: any = operationRecord ? operationRecord : [];
            operationResults.push({
            id: "other",
            name: "Other",
            });
    
            const marketingRecord = await getMarketing();
            const marketingResults: any = marketingRecord ? marketingRecord : [];
            marketingResults.push({
            id: "other",
            name: "Other",
            });
            const materialRecord = await getMarketingMaterialsForFilter();
            const materialResult: any = materialRecord ? materialRecord : [];
    
            setMachineServicesDB(machineServiceResults);
            setOperationDB(operationResults);
            setMarketingDB(marketingResults);
            setMaterialDB(materialResult);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <TicketDependenciesContext.Provider value={{ machineDB, ticketSourceDB, assignToDB, reportedByDB, serviceTypeDB, reporterType, priorityDB, ticketTypes,issueReportedDB, loading, machineServicesDB, operationDB, marketingDB, materialDB, cleanlinessDB, satisfactionDB, closedServiceTypeDB, enthusiasmDB, trafficRateDB }}>
            {children}
        </TicketDependenciesContext.Provider>
    );
};

export const useTicketDependencies = () => useContext(TicketDependenciesContext);
