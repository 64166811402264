import React, { useContext, useRef, useState } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { getLocationsForFilter } from "../../models/_location";
import ReactSelect, { components } from "react-select";
import { Suspense } from "react";
import * as _ from "lodash";
import clsx from "clsx";
import { DownloadExcel } from "../ExcelHelper";
import MultiSelect from "../../../_metronic/helpers/MySelect";
import moment from "moment";
import {
  getBlendSummary,
  getAllTimeBlendSummary,
} from "../../models/_blendlogs";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import { Option } from '../../../_metronic/helpers/MySelect';
import { Tooltip } from "react-tooltip";
import useBlendSummaryHistory from "./BlendSummaryManageHistory";
import { LOCATION_TYPES, MACHINE_STATUS } from "../../../_metronic/helpers/enums";
import { BlendSummaryProps } from "./BlendParentComponent";
import { FlavorContext } from "../../providers/flavorProvider";
import { Flavor } from "../../models/_flavor";
const { Option: customOption } = components;

const BlendSummary: React.FC<BlendSummaryProps> = (props) => {
  const {flavorsDB} = useContext(FlavorContext);
  const endDate = moment()
    .utcOffset(0, true)
    .subtract(1, "weeks")
    .endOf("isoWeek")
    .toDate();
  const startDate = moment()
    .utcOffset(0, true)
    .subtract(1, "weeks")
    .startOf("isoWeek")
    .toDate();
  let utcStartDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
    0,
    0,
    0
  );
  let utcEndDate = new Date(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
    23,
    59,
    59
  );
  const accountTypeDB = [
    {
      id: "ALL",
      name: "All",
    },
    {
      id: "CHAIN",
      name: "Chain",
    },
    {
      id: "INDIVIDUAL",
      name: "Individual",
    },
  ];
  const resetIsVip = useRef<any>(null);
  const [addLoading, setAddLoading] = useState(false);
  const [manageHistory,setManageHistory] = useBlendSummaryHistory();
  const fromDate = manageHistory.fromDate ? new Date(manageHistory.fromDate) : null;
  const toDate = manageHistory.toDate ? new Date(manageHistory.toDate) : null;
  const onlyVipCustomerStatus = manageHistory.onlyVipCustomerStatus === 'all' ? 'all' : manageHistory.onlyVipCustomerStatus ===  'true' ? true : manageHistory.onlyVipCustomerStatus === 'false' ? false : undefined;
  const locationType = manageHistory.locationType !== null || undefined ? manageHistory.locationType?.toUpperCase() : null ;
  const customerType = manageHistory.customerType !== null || undefined ? manageHistory.customerType?.toUpperCase() : null ;
  const machineType = manageHistory.machineType !== null || undefined ? manageHistory.machineType?.toUpperCase() : null ;
  const accountType = manageHistory.accountType !== null || undefined ? manageHistory.accountType?.toUpperCase() : null ;
  const customers = manageHistory.customers;
  const locations = manageHistory.locations;
  const machines = manageHistory.machines;
  const marketSegments = manageHistory.marketSegments;
  const flavors = manageHistory.flavors?.length > 0 ? manageHistory.flavors?.map((x:any)=>x.toUpperCase()) : null ;
  const regions = manageHistory.regions;
  const machineStatus = manageHistory.machineStatus;
  const locationTypes = manageHistory.locationTypes;
  const currentStatus = manageHistory.currentStatus === 'all' ? 'all' : manageHistory.currentStatus ===  'true' ? true : manageHistory.currentStatus === 'false' ? false : true;

  const [filterFromDate, setFilterFromDate] = useState(fromDate ||utcStartDate);
  const [filterToDate, setFilterToDate] = useState(toDate || utcEndDate);
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterstatus, setFilterStatus] = useState<any>(currentStatus);
  const [locationRegionDB, setLocationRegionDB] = useState<any>([]);
  const [locationDB, setLocationDB] = useState<any>([]);
  const [filterRegion, setFilterRegion] = useState(regions || []);
  const [filterCustomer, setFilterCustomer] = useState(customers ||[]);
  const [filterLocation, setFilterLocation] = useState(locations ||[]);
  const [locationRegionSelected, setLocationRegionSelected] = useState<any>(null);
  const [locationSelected, setLocationSelected] = useState<any>([]);
  const [machineStatusSelected, setMachineStatusSelected] = useState<any>([{ value: MACHINE_STATUS.DEPLOYED, label: 'DEPLOYED' }]);
  const [locationTypeSelected, setLocationTypeSelected] = useState<any>([{ value: LOCATION_TYPES.COMMERCIAL, label: 'COMMERCIAL' }]);
  const [customerSelected, setCustomerSelected] = useState<any>([]);
  const [machineSelected, setmachineSelected] = useState<any>([]);
  const [filtermachine, setFilterMachine] = useState(machines || []);
  const [filterMarketSegement, setFilterMarketSegment] = useState(marketSegments ||[]);
  const [filterFlavor, setFilterFlavor] = useState(flavors || []);
  const [filterMachineStatus, setFilterMachineStatus] = useState(machineStatus || [MACHINE_STATUS.DEPLOYED]);
  const [filterLocationType, setFilterLocationType] = useState(locationTypes || [LOCATION_TYPES.COMMERCIAL]);
  const [machineStatusDB, setMachineStatusDB] = useState<any>([]);
  const [locationTypeDB, setLocationTypeDB] = useState<any>([]);
  const [totalMachines, setTotalMachines] = useState<any>("");
  const [allTimeAverage, setAllTimeAverage] = useState("");
  const [blendSummary, setBlendSummary] = useState([]);
  const [sortedBlendSummary, setSortedBlendSummary] = useState([]);
  const [totalBlends, setTotalBlends] = useState(0);
  const [averageBlend, setAverageBlend] = useState(0);
  const [averageUpTime, setAverageUpTime] = useState(0);
  const [machineDB, setMachineDB] = useState<any>([]);
  const [customerDB, setCustomerDB] = useState<any>([]);
  const [allTimeMachineBlend, setAllTimeMachineBlend] = useState("");
  const [allTotalMachines, setAllTotalMachines] = useState("");
  const [customerFilterType, setCustomerFilterType] = useState<any>(customerType || "INCLUDE");
  const [locationFilterType, setLocationFilterType] = useState<any>(locationType || "INCLUDE");
  const [machineFilterType, setMachineFilterType] = useState<any>(machineType || "INCLUDE");
  const [callAPIToLoadData = 1, setCallAPIToLoadData] = useState<number>();
  const [populateFilterData = 1, setPopulateFilterData] = useState<number>();
  const [sortedField, setSortedField] = React.useState<string>('kpi');
  const [accountTypeSelected, SetAccountTypeSelected] = useState(accountType || "ALL");
  const [marketSegmentDB, setMarketSegmentDB] = useState<any>([]);
  const [marketSegmentIdSelected, setMarketSegmentIdSelected] = useState<any>([]);
  const [flavorIdSelected, setFlavorIdSelected] = useState([]);
  const [filterIsVip, setFilterIsVip] = useState<any>(onlyVipCustomerStatus)
  const [distributorSelected, setDistributorSelected] = useState<any>([]);
  const [filterDistributor, setFilterDistributor] = useState([]);
  const [distributorDB, setDistributorDB] = useState<any>([]);
  const [blendSummaryQueryParams, setBlendSummaryQueryParams] = useState('')
  const isVipDB = [
    {
      id: true,
      name: "Yes",
    },
    {
      id: false,
      name: "No",
    },
    {
      id: "all",
      name: "All",
    },
  ];

  const [sortOrder, setSortOrder] = React.useState<any>({
    machineName: 'ASC',
    locationName: 'ASC',
    regionName: 'ASC',
    deploymentMangerName: 'ASC',
    machineStatusName: 'ASC',
    lastHeartbeatAt: 'ASC',
    blendCount: 'ASC',
    downTimePercentage: 'ASC',
    upTimePercentage: 'ASC',
    kpi: 'ASC'
  });

  const statusDB = [
    {
      id: true,
      name: "Active",
    },
    {
      id: false,
      name: "Inactive",
    },
    {
      id: "all",
      name: "All",
    },
  ];

  const recordFilterTypeDB = [
    {
      id: "INCLUDE",
      name: "Include",
    },
    {
      id: "EXCLUDE",
      name: "Exclude",
    },
  ];

  const navigate = useNavigate()
  const currentStatusInputRef = useRef<any>(null);

  const allTimeBlend = async () => {
    try {
      let filter: any = {};

      if (filterRegion.length > 0) {
        filter["regionId"] = filterRegion;
      }
      if (filterCustomer.length > 0) {
        filter["customerId"] = filterCustomer;
        filter["customerIdFilterType"] = customerFilterType;
      }
      if (filtermachine.length > 0) {
        filter["machineId"] = filtermachine;
        filter["machineIdFilterType"] = machineFilterType;
      }

      if (filterLocation.length > 0) {
        filter["locationId"] = filterLocation;
        filter["locationIdFilterType"] = locationFilterType;
      }

      if (filterMarketSegement.length > 0) {
        filter["marketSegmentId"] = filterMarketSegement;
      }


      if (filterFlavor.length > 0) {
        filter["flavor"] = filterFlavor;
      }

      if (filterIsVip === "all") {
        delete filter["is_vip"];
      }


      if (accountTypeSelected && accountTypeSelected !== "ALL") {
        filter['accountType'] = accountTypeSelected;
      }

      if (filterMachineStatus.length > 0) {
        filter["machineStatusId"] = filterMachineStatus;
      }

      if (filterLocationType.length > 0) {
        filter["locationTypeId"] = filterLocationType;
      }

      if (filterDistributor.length > 0) {
        filter["distributorId"] = filterDistributor;
      }
      const allBlendTimeSummary = await getAllTimeBlendSummary(filter);
      const allTotalMachines = allBlendTimeSummary?.totalMachines;
      setAllTotalMachines(allTotalMachines);

      const totalTimeAverage = allBlendTimeSummary?.averageBlend;
      setAllTimeAverage(totalTimeAverage);
      const allMachinesTotalBlend = allBlendTimeSummary?.allMachinesTotalBlend;
      setAllTimeMachineBlend(allMachinesTotalBlend);

    } catch (error) {
      console.log(error);
    }
  };

  const blendData = async (filter: any) => {
    try {
      const blendSummaryRecords = await getBlendSummary({
        ...filter,
        fromDate: moment
          .utc([
            filterFromDate.getFullYear(),
            filterFromDate.getMonth(),
            filterFromDate.getDate(),
            filterFromDate.getHours(),
            filterFromDate.getMinutes(),
          ])
          .toDate(),
        toDate: moment
          .utc([
            filterToDate.getFullYear(),
            filterToDate.getMonth(),
            filterToDate.getDate(),
            filterToDate.getHours(),
            filterToDate.getMinutes(),
          ])
          .toDate(),
      });

      let blendSummaryResult = blendSummaryRecords?.machines ?? [];
      blendSummaryResult = _.orderBy(
        blendSummaryResult,
        ["blendCount"],
        ["desc"]
      );
      const totalBlends = _.sumBy(blendSummaryResult, function (o: any) {
        return o?.blendCount ?? 0;
      });

      setTotalMachines(blendSummaryResult.length);
      setTotalBlends(totalBlends);
      setBlendSummary(blendSummaryResult);
      const averageBlendResult: any = blendSummaryRecords?.averageBlend ?? 0;
      setAverageBlend(averageBlendResult);
      setAverageUpTime(blendSummaryRecords?.averageUpTime ?? 0)

    } catch (error) {
      console.log(error);
    }
  };
  // const machineName = blendSummary.map((e: any) => e.machineName) ?? [];
  // const blendCount = blendSummary.map((e: any) => e.blendCount);

  const fetchData = async () => {
    setPopulateFilterData(2)
    try {
      let {customers,distributors,locations,machineStatus,locationTypes,machines,marketSegments,regions} = props

      if(filterRegion.length > 0){
        const response = regions.filter((region:any) => filterRegion.includes(region.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setLocationRegionSelected(finalResponse)
      }

      if(filterLocation.length > 0){
        const response = locations.filter((location:any) => filterLocation.includes(location.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setLocationSelected(finalResponse)
      }

      if(filterMachineStatus.length > 0){
        const response = machineStatus.filter((machineStatus:any) => filterMachineStatus.includes(machineStatus.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setMachineStatusSelected(finalResponse)
      }
     
      if(filterLocationType.length > 0){
        const response = locationTypes.filter((locationType:any) => filterLocationType.includes(locationType.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setLocationTypeSelected(finalResponse)
      }
     
      if(filterCustomer.length > 0){
        const response = customers.filter((customer:any) => filterCustomer.includes(customer.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setCustomerSelected(finalResponse)
      }

      if(filtermachine.length > 0){
        const response = machines.filter((machine:any) => filtermachine.includes(machine.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:`${x.location?.name ?? ""} (${x?.serialNumber ?? "" })`}})
        const filterMachineResponse = response.map((x:any)=> x.id);
        
        setmachineSelected(finalResponse)
        setFilterMachine(filterMachineResponse)
      }

      if(filterMarketSegement.length > 0){
        const response = marketSegments.filter((marketsegement:any) => filterMarketSegement.includes(marketsegement.id));
        const finalResponse = response.map((x:any) => {return {value:x.id,label:x.name}})
        setMarketSegmentIdSelected(finalResponse)
      }

      if(filterIsVip && (filterIsVip !== null || filterIsVip !== undefined || filterIsVip === false)){
        const selectedIsVipCustomer = isVipDB.filter((isVip:any) => filterIsVip === isVip.id);
        resetIsVip.current.setValue({ label: selectedIsVipCustomer[0].name , value: selectedIsVipCustomer[0].id });
      }

      if(filterstatus && (filterstatus !== null || filterstatus !== undefined || filterstatus === false)){
        const selectedIsCurrentStatus = statusDB.filter((isCurrentStatus:any) => filterstatus.toString()?.includes(isCurrentStatus.id));
        currentStatusInputRef.current.setValue({ label: selectedIsCurrentStatus[0].name , value: selectedIsCurrentStatus[0].id });
      }

      if(filterFlavor?.length > 0){
        const selectedFlavor:any = flavorsDB.filter((flavor:Flavor) => filterFlavor.includes(flavor.id));
        const finalResponse = selectedFlavor.map((x:any) => {return {value:x.id,label:x.name}})
        setFlavorIdSelected(finalResponse);
        setFilterFlavor(filterFlavor);
      }

      setMachineDB(machines);
      setCustomerDB(customers);
      setLocationDB(locations);
      setLocationRegionDB(regions);
      setDistributorDB(distributors)
      setMarketSegmentDB(marketSegments);
      setMachineStatusDB(machineStatus);
      setLocationTypeDB(locationTypes)
    } catch (error) {
      console.log(error);
    }
  };

  const handleRegionChange = async (e: any, selected: any) => {
    const value = selected?.map((e: any) => e.value);
    setLocationRegionSelected(selected);
    setFilterRegion(value);

    try {
      let filter: any = {};
      if (value.length > 0) {
        filter["region_id"] = { in: value };
      }

      const locationRecord = await getLocationsForFilter(filter);
      const locationResults: any =
        locationRecord.data ?? locationRecord.data ?? [];
      locationResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setLocationDB(locationResults);
    } catch (e) {
      console.log(e);
    }
  };

  const handleMachineStatusChange = async (e: any, selected: any) => {
    const value = selected?.map((e: any) => e.value);
    setMachineStatusSelected(selected);
    setFilterMachineStatus(value);
  };

  const handleLocationTypeChange = async (e: any, selected: any) => {
    const value = selected?.map((e: any) => e.value);
    setLocationTypeSelected(selected);
    setFilterLocationType(value);
  };

  const handleDistributorChange = async (e: any, selected: any) => {
    const value = selected?.map((e: any) => e.value);

    setDistributorSelected(selected);
    setFilterDistributor(value);

    try {
      let filter: any = {};
      if (value.length > 0) {
        filter["distributor_id"] = { in: value };
      }

      const locationRecord = await getLocationsForFilter(filter);
      const locationResults: any =
        locationRecord.data ?? locationRecord.data ?? [];
      locationResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      setLocationDB(locationResults);
    } catch (e) {
      console.log(e);
    }
  };

  const searchData = async (callMangeHistory?:boolean) => {
    setCallAPIToLoadData(2);
    let hasErrors = false;

    if (filterFromDate === undefined) {
      hasErrors = true;
      toast.error("From date is required");
    }
    if (filterToDate === undefined) {
      hasErrors = true;
      toast.error("To date is required");
    }

    if (hasErrors) {
      return false;
    }
    let filter: any = {};

    if (filterRegion.length > 0) {
      filter["regionId"] = filterRegion;
    }

    if (filterCustomer.length > 0) {
      filter["customerId"] = filterCustomer;
      filter["customerIdFilterType"] = customerFilterType;
    }
    if (filtermachine.length > 0) {
      filter["machineId"] = filtermachine;
      filter["machineIdFilterType"] = machineFilterType;
    }

    if (filterLocation.length > 0) {
      filter["locationId"] = filterLocation;
      filter["locationIdFilterType"] = locationFilterType;
    }

    if (filterMachineStatus.length > 0) {
      filter["machineStatusId"] = filterMachineStatus;
    }

    if (filterLocationType.length > 0) {
      filter["locationTypeId"] = filterLocationType;
    }

    if (filterDistributor.length > 0) {
      filter["distributorId"] = filterDistributor;
    }

    if (filterMarketSegement.length > 0) {
      filter["marketSegmentId"] = filterMarketSegement;
    }

    if (filterFlavor.length > 0) {
      filter["flavor"] = filterFlavor;
    }

    if (accountTypeSelected && accountTypeSelected !== "ALL") {
      filter['accountType'] = accountTypeSelected;
    }

    if (filterIsVip === true) {
      filter["isVip"] = true;
    } else if (filterIsVip === false) {
      filter["isVip"] = false;
    }

    if (filterFromDate) {
      filter["fromDate"] = moment
        .utc([
          filterFromDate.getFullYear(),
          filterFromDate.getMonth(),
          filterFromDate.getDate(),
          filterFromDate.getHours(),
          filterFromDate.getMinutes(),
        ])
        .toDate();
    }
    if (filterToDate) {
      filter["toDate"] = moment
        .utc([
          filterToDate.getFullYear(),
          filterToDate.getMonth(),
          filterToDate.getDate(),
          filterToDate.getHours(),
          filterToDate.getMinutes(),
        ])
        .toDate();
    }

    if (filterstatus === "all") {
      delete filter["isActive"];
    } else {
      filter["isActive"] = filterstatus;
    }


    setSortedField('kpi')
    const newObj = { ...sortOrder }
    newObj['kpi'] = 'DESC';
    setSortOrder(newObj);
    setAddLoading(true);
    if(callMangeHistory){
      manageHistoryForBlendSummary(filter)
    }
    blendData(filter);
    allTimeBlend();
    setAddLoading(false);
  };

  const manageHistoryForBlendSummary = async (filter:any) => {
    let queryParams:string = '';
    const fromDate = filter?.fromDate || null;
    const toDate = filter?.toDate || null;
    const customerType = filter?.customerIdFilterType?.toLowerCase() || null;
    const locationType = filter?.locationIdFilterType?.toLowerCase() || null;
    const machineType = filter?.machineIdFilterType?.toLowerCase() || null;
    const accountType = filter?.accountType?.toLowerCase() || null;
    const currentStatus = filter?.isActive ? filter?.isActive : filter?.isActive === false ? false : 'all';
    const onlyVipCustomerStatus = filter?.isVip ? filter?.isVip : filter?.isVip === false ? false : 'all';
    const locations = filter?.locationId?.length > 0 ? filter?.locationId : [];
    const machines = filter?.machineId?.length > 0 ? filter?.machineId : [];
    const marketSegments  = filter?.marketSegmentId?.length > 0 ? filter?.marketSegmentId : [];
    const region  = filter?.regionId?.length > 0 ? filter?.regionId : [];
    const customers  = filter?.customerId?.length > 0 ? filter?.customerId : [];
    const machineStatus  = filter?.machineStatusId?.length > 0 ? filter?.machineStatusId : [];
    const locationTypes  = filter?.locationTypeId?.length > 0 ? filter?.locationTypeId : [];
    const flavor = filter?.flavor?.length > 0 ? filter?.flavor?.map((x:any)=>x.toLowerCase()) : [];
    
    if(fromDate && fromDate !== null){
     const formattedFromDate = moment(new Date(fromDate)).utc().format('YYYY-MM-DD HH:mm');
      queryParams += `?from_date=${formattedFromDate}`;
    }

    if(toDate && toDate !== null){
      const formattedToDate = moment(new Date(toDate)).utc().format('YYYY-MM-DD HH:mm');
       queryParams += `&to_date=${formattedToDate}`;
     }

    if (accountType && accountType !==  null) {
       queryParams += `&account_type=${accountType}`;
    }

    if(accountTypeSelected === 'ALL'){
      queryParams += `&account_type=all`;
    }

    if (customerType && customerType !==  null) {
       queryParams += `&customer_type=${customerType}`;
    }

    if (machineType && machineType !==  null) {
       queryParams += `&machine_type=${machineType}`;
    }

    if (locationType && locationType !==  null) {
      queryParams += `&location_type=${locationType}`;
    }

    if(currentStatus !== null && (currentStatus === true || currentStatus === false || currentStatus === 'all')){
      queryParams += `&current_status=${currentStatus}`
    }

    if(onlyVipCustomerStatus !== null && (onlyVipCustomerStatus === true || onlyVipCustomerStatus === false || onlyVipCustomerStatus === 'all')){
      queryParams += `&is_vip=${onlyVipCustomerStatus}`
    }


    if(locations && locations !== null) {
      const locationIds = locations.join(",");
      queryParams += `&location_id=${locationIds}`;
    }

    if(machines && machines !== null) {
      const machineIds = machines.join(",");
      queryParams += `&machine_id=${machineIds}`;
    }

    if(marketSegments && marketSegments !== null) {
      const marketSegmentIds = marketSegments.join(",");
      queryParams += `&market_segment_id=${marketSegmentIds}`;
    }

    if(region && region !== null) {
      const regionIds = region.join(",");
      queryParams += `&region_id=${regionIds}`;
    }

    if(customers && customers !== null) {
      const customerIds = customers.join(",");
      queryParams += `&customer_id=${customerIds}`;
    }

    if(machineStatus && machineStatus !== null) {
      const machineStatusIds = machineStatus.join(",");
      queryParams += `&machine_status_id=${machineStatusIds}`;
    }

    if(locationTypes && locationTypes !== null) {
      const locationTypeIds = locationTypes.join(",");
      queryParams += `&location_type_id=${locationTypeIds}`;
    }

    if(flavor && flavor !== null) {
      const flavorIds = flavor.join(",");
      queryParams += `&flavor_id=${flavorIds}`;
    }
    
    if(queryParams) {
      setBlendSummaryQueryParams(queryParams);
      navigate(`/blendsummary${queryParams}`,{replace:true})
    };

    return queryParams;
  }

  React.useEffect(() => {
    if (callAPIToLoadData === 1) {
      searchData();
    }

    if (populateFilterData === 1) {
      fetchData();
    }
    sortDataByKey(sortedField);
    // eslint-disable-next-line
  }, [callAPIToLoadData, blendSummary, sortedField]);

  const sortDataByKey = (sortKey: string) => {
    const newSortOrder = sortOrder[sortKey] === 'ASC' ? 'DESC' : 'ASC';
    setSortedField(sortKey)
    const newObj = { ...sortOrder }
    newObj[sortKey] = newSortOrder;
    setSortOrder(newObj);
    let sortedblendSummary = [...blendSummary];
    const keySortOrder = sortOrder[sortKey];

    const lowerOrder: any = _.lowerCase(keySortOrder);
    sortedblendSummary = _.orderBy(sortedblendSummary, [sortKey],
      [lowerOrder]);

    setSortedBlendSummary(sortedblendSummary)
  }
  const handleDownloadExcel = () => {
    const excelRecords: any = [];
    const blendSummaryReocrd = Array.from(blendSummary).map(
      (blendSummary: any) => blendSummary
    );

    for (const blendSummary of blendSummaryReocrd) {
      const tempRecord = {
        Machine: blendSummary?.machineName ?? "",
        Location: blendSummary?.locationName ?? "",
        Region: blendSummary?.regionName ?? "",
        "Deployment manager": _.capitalize(
          _.lowerCase(blendSummary?.deploymentManagerName ?? "")
        ),
        "Machine status": _.capitalize(
          _.lowerCase(blendSummary?.machineStatusName ?? "")
        ),
        "Last heartbeat": blendSummary?.lastHeartbeatAt !== "" ? moment.utc(blendSummary?.lastHeartbeatAt).format("YYYY-MM-DD HH:mm") : "",
        "Total blends": blendSummary?.blendCount ?? "",
        "Down time (in %)": blendSummary?.downTimePercentage > 0 ? parseFloat(blendSummary?.downTimePercentage).toFixed(2) + '%' : 0,
        "Up time  (in %)": blendSummary?.upTimePercentage > 0 ? parseFloat(blendSummary?.upTimePercentage).toFixed(2) + '%' : 0,
        "KPI": parseFloat(blendSummary?.kpi).toFixed(2) ?? "",
      };
      excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "BlendSummary_list");
  };

  return (
    <>
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 ">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Blend Summary
              </h1>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#blend-summary-filter"
                  aria-expanded="false"
                  aria-controls="blend-summary-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </button>
              </div>
            </div>
            <div
              className="collapse"
              id="blend-summary-filter"
              aria-labelledby="blend-summary-filter"
            >
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">From Date</label>
                  <Datetime
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={filterFromDate}
                    value={filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      setFilterFromDate(dt);
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">To Date</label>
                  <Datetime
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={filterToDate}
                    value={filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];

                      setFilterToDate(dt);
                    }}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Region</label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any, e: any) => {
                      handleRegionChange(e, selected);
                    }}
                    components={{
                      Option,
                    }}
                    value={locationRegionSelected}
                    options={Array.from(locationRegionDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Machine status
                  </label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any, e: any) => {
                      handleMachineStatusChange(e, selected);
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={machineStatusSelected}
                    options={Array.from(machineStatusDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                          description: value.description,
                        };
                      }
                    )}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Location Type
                  </label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any, e: any) => {
                      handleLocationTypeChange(e, selected);
                    }}
                    components={{
                      Option: customOption,
                    }}
                    value={locationTypeSelected}
                    options={Array.from(locationTypeDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: _.capitalize(_.lowerCase(value.name)),
                        description: value.description,
                      };
                    })}
                    formatOptionLabel={({ value, label, description }: any, { context, selectValue }: any) => {
                      let isChecked = false;
                      const filter = selectValue.filter((x: any) => x.value === value);
                      if (filter.length > 0) {
                        isChecked = true;
                      }
                      return context === 'menu' ? (
                        <div>
                          <label className="form-check form-check-custom form-check-solid">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={isChecked}
                              readOnly
                            />
                            <label style={{marginLeft: '5px'}}>{label}</label>
                          </label>
                          <div className="text-muted">{description}</div>
                        </div>
                      ) : (
                        label
                      );
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Current Status</label>
                  <Select
                    defaultValue={{ label: "Active", value: true }}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterStatus"
                    placeholder=""
                    onChange={(event: any) => {
                      setFilterStatus(event.value);
                    }}
                    options={Array.from(statusDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                    ref={currentStatusInputRef}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 d-block fw-bold ps-1">
                    Customer Filter Type
                  </label>
                  {Array.from(recordFilterTypeDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={'c-f-' + id}>
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={id}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="customerFilterType"
                          value={value.id}
                          checked={customerFilterType ===  value.id}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setCustomerFilterType(value);
                          }}
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customers</label>
                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      setCustomerSelected(selected);
                      const value = selected?.map((e: any) => e.value);
                      setFilterCustomer(value);
                    }}
                    selected={customerSelected}
                    options={Array.from(customerDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 d-block fw-bold ps-1">
                    Location Filter Type
                  </label>
                  {Array.from(recordFilterTypeDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={'l-f-' + id}>
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={id}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="locationFilterType"
                          value={value.id}
                          checked={locationFilterType === value.id}
                          onChange={(e: any) => {
                            const value = e.target.value;
                            setLocationFilterType(value);
                          }}
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Locations</label>
                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setLocationSelected(selected);
                      setFilterLocation(value);
                    }}
                    selected={locationSelected}
                    options={Array.from(locationDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 d-block fw-bold ps-1">
                    Machine Filter Type
                  </label>
                  {Array.from(recordFilterTypeDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex mt-1 ms-2" key={'m-f-' + id}>
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={id}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="machineFilterType"
                          value={value.id}
                          checked={machineFilterType === value.id}
                          onChange={(e: any) => {
                            setMachineFilterType(e.target.value);
                          }}
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Machines</label>
                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setmachineSelected(selected);
                      setFilterMachine(value);
                    }}
                    selected={machineSelected}
                    options={Array.from(machineDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: `${value.name ? value?.name : (value?.location?.name  ?? "")} ${value?.serialNumber}`,
                      };
                    })}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4 ">
                  <label className="form-label fs-4 d-block  fw-bold ps-1">
                    Account Type
                  </label>
                  {Array.from(accountTypeDB).map((value: any, id) => (
                    <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-inline-flex d-flex mt-1 ms-2" key={'ac-' + id}>
                      <label
                        className="form-check-label fs-5 mb-0 ms-2"
                        key={id}
                      >
                        <input
                          id="accountType"
                          className="form-check-input"
                          type="radio"
                          name="accountType"
                          value={value.id}
                          checked={accountTypeSelected === value.id}
                          onChange={() =>
                            SetAccountTypeSelected(value.id)
                          }
                        />
                        <span className="form-check-label fs-5 mb-0 ms-2">
                          {value.name}
                        </span>
                      </label>
                    </div>
                  ))}

                </div>
              </div>

              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <label className="form-label fs-4 required fw-bold">
                    Market Segment
                  </label>

                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setMarketSegmentIdSelected(selected);
                      setFilterMarketSegment(value);
                    }}
                    selected={marketSegmentIdSelected}
                    options={Array.from(marketSegmentDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />

                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <label className="form-label fs-4 required fw-bold">
                    Flavor
                  </label>

                  <MultiSelect
                    class={clsx("react-select")}
                    title=""
                    setSelected={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      setFlavorIdSelected(selected);
                      setFilterFlavor(value);
                    }}
                    selected={flavorIdSelected}
                    options={Array.from(flavorsDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />

                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <label className="form-label fs-4 d-block fw-bold ps-1">
                    Only VIP Customers?
                  </label>

                  <Select
                    defaultValue={{ label: "All", value: "all" }}
                    ref={resetIsVip}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterVip"
                    placeholder=""
                    onChange={(event: any) => {
                      setFilterIsVip(event.value);
                    }}
                    options={Array.from(isVipDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>

                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Distributors
                  </label>
                  <ReactSelect
                    className="react-select"
                    placeholder=""
                    classNamePrefix="my-react-select"
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any, e: any) => {
                      handleDistributorChange(e, selected);
                    }}
                    components={{
                      Option,
                    }}
                    value={distributorSelected}
                    options={Array.from(distributorDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: _.capitalize(_.lowerCase(value.name)),
                      };
                    })}
                  />
                </div>
              </div>


              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={() => searchData(true)}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={() => {

                      setFilterLoading(true);
                      setFilterFromDate(utcStartDate);
                      setFilterToDate(utcEndDate);
                      setLocationRegionSelected(null);
                      setDistributorSelected(null)
                      setFilterDistributor([])
                      setmachineSelected([]);
                      setLocationSelected([]);
                      setFilterMachine([]);
                      setMachineStatusSelected(null);
                      setLocationTypeSelected(null);
                      setFilterRegion([]);
                      setFilterCustomer([]);
                      setFilterLocation([]);
                      setCustomerSelected([]);
                      setFilterMachineStatus([]);
                      setFilterLocationType([]);
                      setCallAPIToLoadData(1);
                      setFilterIsVip(undefined)
                      setFilterLoading(false);
                      setMarketSegmentIdSelected([]);
                      setFilterMarketSegment([]);
                      setFlavorIdSelected([]);
                      setFilterFlavor([]);
                      SetAccountTypeSelected('ALL');
                      setLocationFilterType('INCLUDE')
                      setMachineFilterType('INCLUDE');
                      setCustomerFilterType('INCLUDE');
                      resetIsVip.current.setValue({ label: "All", value: "all" });
                      currentStatusInputRef.current.setValue({ label: "Active", value: true });
                      navigate(`/blendsummary`,{replace:true})
                      setManageHistory({ search: null });
                    }}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="row mt-5">
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <a
                        href="/customers/list"
                        className="totalBlends text-dark text-hover-success fw-bold fs-3"
                      >
                        Total blends / Average blend
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </a>
                      <Tooltip anchorSelect=".totalBlends" className="tooltip-dashboard">
                      Displays the total and daily average of Smoodi cups blended in our machines, based on the selected filter criteria above
                      </Tooltip>
                      <span className="text-muted fw-semibold mt-1">
                        from {totalMachines} machines
                      </span>
                    </div>

                    <span className="symbol symbol-80px">
                      <span className=" fs-2 fw-bold bg-light-success text-success" style={{ padding: "35px 5px" }}>
                        {totalBlends} / {averageBlend}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <div className="averageUpTime text-dark text-hover-success fw-bold fs-3">
                        Average up time
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </div>
                      <Tooltip anchorSelect=".averageUpTime" className="tooltip-dashboard">
                      Shows the average duration our fleet is operational and functioning correctly out of the toal available time
                      </Tooltip>
                      <span className="text-muted fw-semibold mt-1">
                        from {totalMachines} machines
                      </span>
                    </div>

                    <span className="symbol ">
                      <span className="symbol-label fs-2 fw-bold bg-light-success text-success" style={{ width: "auto" }} >
                        {parseFloat(averageUpTime + '').toFixed(2) + '%'}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <a
                        href="/customers/list"
                        className="allTimeBlends text-dark text-hover-success fw-bold fs-3"
                      >
                        All time total blends / average blend
                        <i className="fas fa-info-circle fs-4 ms-2"></i>
                      </a>
                      <Tooltip anchorSelect=".allTimeBlends" className="tooltip-dashboard">
                      Shows the total and average number of smoodis that have been sold in commercial machines since the founding of the company 
                      </Tooltip>
                      <span className="text-muted fw-semibold mt-1">
                        from {allTotalMachines} machines
                      </span>
                    </div>

                    <span className="symbol">
                      <span className=" fs-2 fw-bold bg-light-success text-success" style={{ padding: "35px 5px" }}>
                        {allTimeMachineBlend} / {allTimeAverage}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-lg-3 col-md-6 col-12">
              <div className="card mt-6">
                <div className="card-body d-flex flex-column p-7">
                  <div className="d-flex flex-stack flex-grow-1">
                    <div className="d-flex flex-column">
                      <div className="text-dark text-hover-success fw-bold fs-3">
                        All Time Average Blend
                      </div>

                      <span className="text-muted fw-semibold mt-1">
                        All time average blend from {allTotalMachines} machines
                      </span>
                    </div>

                    <span className="symbol symbol-50px">
                      <span className="symbol-label fs-2 fw-bold bg-light-success text-success">
                        {allTimeAverage}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row mt-5">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <div className="card mt-9">
                <div className="card-body w-100 p-lg-7 p-md-7 p-5">
                  <div className="table-responsive p-0 pb-2 fs-6 border-0 sorttable  dashboardtable">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-xl-4 col-lg-3 col-md-4 col-12">
                        <h5
                          data-bs-toggle="tooltip"
                          title=" Total machines"
                          className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center"
                        >
                          Result from{" "}
                          <span className="text-primary">{totalMachines}</span>{" "}
                          machines
                        </h5>
                      </div>

                      <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                        <button
                          onClick={() => handleDownloadExcel()}
                          className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                        >
                          <span className="indicator-label">
                            {" "}
                            <KTSVG
                              path="/media/icons/duotune/files/fil021.svg"
                              className="svg-icon-4 me-2"
                            />
                            Export Excel
                          </span>
                        </button>
                      </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 col-md-8 col-12"></div>

                    <div className="col-xl-2 col-lg-3 col-md-4 col-12 mt-4">
                      <h3 className="btn btn-bg-light fw-bold m-0 mb-5 p-0 px-5 py-3 align-items-center card-title text-center">
                        <KTSVG
                          path="/media/icons/duotune/general/gen031.svg"
                          className="svg-icon-4 me-1"
                        />
                        Blend Summary
                      </h3>
                    </div>
                    <div className="sorttable">
                      <table className="table dashboard-table  table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fs-5 fw-bold">
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {
                              sortDataByKey('machineName')

                            }}>
                              Machine name {(sortedField === 'machineName') ? (<i className={'fa ' + (sortOrder['machineName'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('locationName')
                            }}>
                              Location name {(sortedField === 'locationName') ? (<i className={'fa ' + (sortOrder['machineName'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('regionName')
                            }}>
                              Region {(sortedField === 'regionName') ? (<i className={'fa ' + (sortOrder['regionName'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted">
                              <span className="sortableBtn" onClick={() => {

                                sortDataByKey('deploymentManagerName')
                              }}>
                                Deployment Manager {(sortedField === 'deploymentManagerName') ? (<i className={'fa ' + (sortOrder['deploymentManagerName'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                              </span>
                            </th>
                            <th className="fs-5 text-muted"> <span className="sortableBtn" onClick={() => {

                              sortDataByKey('machineStatusName')
                            }}>
                              Machine Status {(sortedField === 'machineStatusName') ? (<i className={'fa ' + (sortOrder['machineStatusName'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('lastHeartbeatAt')
                            }}>
                              Last heartbeat {(sortedField === 'lastHeartbeatAt') ? (<i className={'fa ' + (sortOrder['lastHeartbeatAt'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('blendCount')
                            }}>
                              Blend total {(sortedField === 'blendCount') ? (<i className={'fa ' + (sortOrder['blendCount'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('downTimePercentage')
                            }}>
                              Down time (in %) {(sortedField === 'downTimePercentage') ? (<i className={'fa ' + (sortOrder['downTimePercentage'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>

                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('upTimePercentage')
                            }}>
                              Up time (in %) {(sortedField === 'upTimePercentage') ? (<i className={'fa ' + (sortOrder['upTimePercentage'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                            <th className="fs-5 text-muted"><span className="sortableBtn" onClick={() => {

                              sortDataByKey('kpi')
                            }}>
                              KPI {(sortedField === 'kpi') ? (<i className={'fa ' + (sortOrder['kpi'] === 'DESC' ? 'fa-sort-down' : 'fa-sort-up')}></i>) : ''}
                            </span></th>
                          </tr>
                        </thead>
                        <tbody>
                          {sortedBlendSummary.length > 0 ? (
                            Array.from(sortedBlendSummary).map(
                              (blendSummary: any, index: number) => (

                                <tr className="dashboard-body" key={'blend-summary-' + index}>
                                  <td data-title="Machine Name">
                                    <Link
                                      data-bs-toggle="tooltip"
                                      title="Click here to view machine"
                                      to={`/machines/list/history/${blendSummary.machineId}${blendSummaryQueryParams}`}
                                    >
                                      {blendSummary?.machineName ?? ""}
                                    </Link>
                                  </td>
                                  <td data-title="Location Name">
                                    <Link
                                      data-bs-toggle="tooltip"
                                      title="Click here to view location"
                                      to={`/locations/list/history/${blendSummary.locationId}${blendSummaryQueryParams}`}
                                    >
                                      {blendSummary?.locationName ?? ""}
                                    </Link>
                                  </td>
                                  <td data-title="Region">
                                    {blendSummary?.regionName ?? ""}
                                  </td>
                                  <td data-title="Deployment manager">
                                    {blendSummary?.deploymentManagerName ??
                                      ""}
                                  </td>

                                  <td data-title="Machine Status">
                                    {_.capitalize(
                                      _.lowerCase(
                                        blendSummary?.machineStatusName ?? ""
                                      )
                                    )}
                                  </td>
                                  <td data-title="Last heartbeat">
                                    {blendSummary?.lastHeartbeatAt !== "" && blendSummary?.lastHeartbeatAt !== null ? moment.utc(blendSummary?.lastHeartbeatAt).format("YYYY-MM-DD HH:mm") : ""}
                                  </td>
                                  <td data-title="Blend total">
                                    {blendSummary?.blendCount ?? ""}
                                  </td>
                                  <td data-title="Down time (in %)">
                                    {blendSummary?.downTimePercentage > 0 ? parseFloat(blendSummary?.downTimePercentage).toFixed(2) + '%' : 0}
                                  </td>

                                  <td data-title="Up time (in %)">
                                    {blendSummary?.upTimePercentage > 0 ? parseFloat(blendSummary?.upTimePercentage).toFixed(2) + '%' : 0}
                                  </td>
                                  <td data-title="KPI">
                                    {parseFloat(blendSummary?.kpi).toFixed(2) ?? ""}
                                  </td>
                                </tr>

                              )
                            )
                          ) : (
                            <>
                              <tr>
                                <td align="center" colSpan={4}>
                                  No records found
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />

      </Suspense>
    </>
  );
}

export default BlendSummary;
