import React from "react";
import FileUpload from "../../Common/FileUpload";

const SmoothieImgUpload = (props: { values: any; setFieldValue: any }) => {
  const { values, setFieldValue } = props;

  return (
    <FileUpload
      values={values}
      setFieldValue={setFieldValue}
      fieldName="smoothieSetupMedia"
      label="Add pictures of smoothie setup (attachments)"
    />
  );
};

export default SmoothieImgUpload;